<template>
  <div class="Annual-Summary">
    <h1 class="annual-summary-title">Annual Budget</h1>
    <v-switch label="Hide rows with no budget and no spending" v-model="hideEmpty"></v-switch>
    <v-switch label="Show/Hide Budget Fill" v-model="showFill"></v-switch>

    <div class="annual-summary-table">
      <table class="summarytable">
        <thead class="summaryhead">
          <tr>
            <th></th>
            <th v-for="month in months" :key="month">{{ month }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="category in categories" :key="category.id" v-show="!shouldHideCategory(category)">
            <td>{{ category.emoji }} {{ category.name }}</td>
            <td v-for="month in months" :key="month">
              <div class="spent-div" v-html="formatMoney(calculateTotalSpentForCategory(category, month))"></div>
              <input
                type="number"
                class="budget-input"
                placeholder="0"
                :class="{
                  editing: editedCategoryId === category.id && editedMonth === month,
                  'zero-budget': getCategoryBudget(category, month) === 0,
                }"
                :value="getCategoryBudget(category, month)"
                @input="updateBudget(category, month, $event.target.value)"
                @blur="stopEditing(category, month)"
                @keyup.enter="stopEditing(category, month)"
                :id="`budget-input-${category.id}-${month}`"
                @click="handleInputClick(category, month)"
              />
              <div class="fill-div" :style="getFillStyle(category, month)"></div>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { collection, getDocs, getDoc, limit, setDoc, addDoc, writeBatch, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';

export default {
  data() {
    return {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      categories: [],
      hideEmpty: false,
      showFill: true,
      editedBudget: null,
      editedCategoryId: null,
      editedMonth: null,
      selectedDate: {
        month: new Date().getMonth(),
        year: new Date().getFullYear()
      },
    };
  },
  async mounted() {
    await this.retrieveCategories();
  },
  methods: {
    updateBudget(category, month, newBudget) {
      this.editedCategoryId = category.id;
      this.editedMonth = month;

      // Validate the input value
      const parsedBudget = newBudget === '' ? 0 : parseFloat(newBudget);
      if (isNaN(parsedBudget)) {
        // If the input value is not a valid number, reset the input and return
        this.stopEditing(category, month);
        return;
      }
      

      // Find the index of the month
      const monthIndex = this.months.indexOf(month);

      // Update the budget for the selected category and month
      const user = auth.currentUser;
      if (user) {
        const categoryRef = doc(db, `users/${user.uid}/categories/${category.id}`);
        const updatedBudget = category.budget || [];
        const existingBudgetIndex = updatedBudget.findIndex(
          (b) => b.month === monthIndex && b.year === this.selectedDate.year
        );
        if (existingBudgetIndex !== -1) {
          updatedBudget[existingBudgetIndex].amount = parsedBudget;
        } else {
          updatedBudget.push({
            month: monthIndex,
            year: this.selectedDate.year,
            amount: parsedBudget,
          });
        }
        updateDoc(categoryRef, { budget: updatedBudget }).then(() => {
          // Update the local state or perform any other necessary actions
        });
      }
    },
    handleInputClick(category, month) {
      if (this.getCategoryBudget(category, month) === '') {
        this.categoryBudgets[category.id] = '';
      }
    },
    stopEditing(category, month) {
      this.editedCategoryId = null;
      this.editedMonth = null;
    },
    async retrieveCategories() {
      try {
        const user = auth.currentUser;
        if (user) {
          const categoriesCollection = collection(db, `users/${user.uid}/categories`);
          const orderedQuery = query(categoriesCollection, orderBy('order'));
          const querySnapshot = await getDocs(orderedQuery);

          this.categories = querySnapshot.docs.map((doc) => {
            const data = doc.data();
            return {
              id: doc.id,
              name: data.name,
              emoji: data.emoji,
              group: data.group,
              order: data.order,
              budget: data.budget,
            };
          });
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error retrieving categories:', error);
      }
    },


    getFillStyle(category, month) {
      const totalSpent = this.calculateTotalSpentForCategory(category, month);
      const budget = this.getCategoryBudget(category, month);
      let percentage = (totalSpent / budget) * 100;
      
      if (percentage <= 100) {
        return {
          height: `${percentage}%`,
          backgroundColor: '#b8d2db'  // Normal fill color
        };
      } else {
        return {
          height: '100%',  // Always fill 100% of the cell
          backgroundImage: `linear-gradient(to top, #b8d2db ${100 - (percentage - 100)}%, #b8d2db ${100 - (percentage - 100)}%, #ff7b7b 100%)`
        };
      }
    },


    calculateTotalSpentForCategory(category, month) {
      const monthIndex = this.months.indexOf(month);
      const transactions = this.$store.state.transactions.filter(transaction => {
        const transactionDate = new Date(transaction.date);
        return transaction.category === category.name &&
               transactionDate.getMonth() === monthIndex &&
               transactionDate.getFullYear() === this.selectedDate.year;
      });
      return transactions.reduce((total, transaction) => total + transaction.amount, 0);
    },
    getCategoryBudget(category, month) {
      const monthIndex = this.months.indexOf(month);
      const budget = (category.budget || []).find(b => b.month === monthIndex && b.year === this.selectedDate.year);
      return budget ? budget.amount : ''; // Return an empty string if no budget is found
    },
    getPercentageClass(category, month) {
      if (!this.showFill) return ''; // Return an empty string if showFill is false

      const totalSpent = this.calculateTotalSpentForCategory(category, month);
      const budget = this.getCategoryBudget(category, month);
      if (budget === 0) return 'fill-0';
      let percentage = (totalSpent / budget) * 100;

      // Handling over 100% scenario
      if (percentage > 100) {
        const overBudgetPercentage = (totalSpent - budget) / budget * 100;
        const cappedPercentage = 100 + Math.min(overBudgetPercentage, 100); // Cap the overflow at 200%
        return `fill-${Math.floor(cappedPercentage)}`;
      }

      return `fill-${Math.floor(percentage)}`;
    },

    formatMoney(amount) {
      const numericAmount = Number(amount);
      if (numericAmount === 0) {
        return `<span style="color: lightgrey;">$${numericAmount.toFixed(2)}</span>`;
      } else if (numericAmount < 0) {
        return `<span style="color: green;">+$${Math.abs(numericAmount).toFixed(2)}</span>`;
      }
      return `$${numericAmount.toFixed(2)}`;
    },
    shouldHideCategory(category) {
      if (!this.hideEmpty) return false;
      return this.months.every(month => {
        const totalSpent = this.calculateTotalSpentForCategory(category, month);
        const budget = this.getCategoryBudget(category, month);
        return totalSpent === 0 && budget === 0;
      });
    },
  },
};
</script>

<style scoped>
.budget-input {
  padding: 8px;
  border-top: solid thin #b6b6b6;
  z-index: 1;
  position: relative;
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

.spent-div {
  padding: 8px;
  z-index: 1;
    position: relative;
}

.fill-div {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.summarytable td {
  min-width: 100px !important;
}

.fill-0 { height: 0; background-color: #f1f1f1; }
.fill-10 { height: 10%; background-color: #f1f1f1; }
.fill-20 { height: 20%; background-color: #f1f1f1; }
.fill-30 { height: 30%; background-color: #f1f1f1; }
.fill-40 { height: 40%; background-color: #f1f1f1; }
.fill-50 { height: 50%; background-color: #f1f1f1; }
.fill-60 { height: 60%; background-color: #f1f1f1; }
.fill-70 { height: 70%; background-color: #f1f1f1; }
.fill-80 { height: 80%; background-color: #f1f1f1; }
.fill-90 { height: 90%; background-color: #f1f1f1; }
.fill-100 { height: 100%; background-color: #f1f1f1; }
.fill-110 { height: 100%; background: linear-gradient(to top, #f1f1f1 90%, #ff4848 100%); }
.fill-120 { height: 100%; background: linear-gradient(to top, #f1f1f1 80%, #ff4848 100%); }
.fill-130 { height: 100%; background: linear-gradient(to top, #f1f1f1 70%, #ff4848 100%); }
.fill-140 { height: 100%; background: linear-gradient(to top, #f1f1f1 60%, #ff4848 100%); }
.fill-150 { height: 100%; background: linear-gradient(to top, #f1f1f1 50%, #ff4848 100%); }
.fill-160 { height: 100%; background: linear-gradient(to top, #f1f1f1 40%, #ff4848 100%); }
.fill-170 { height: 100%; background: linear-gradient(to top, #f1f1f1 30%, #ff4848 100%); }
.fill-180 { height: 100%; background: linear-gradient(to top, #f1f1f1 20%, #ff4848 100%); }
.fill-190 { height: 100%; background: linear-gradient(to top, #f1f1f1 10%, #ff4848 100%); }
.fill-200 { height: 100%; background-color: #ff4848; }

td {
  position: relative;
}

.budget-input {
  width: 70px;
  margin: 0px -5px 0px 0px;
  padding: 10px 10px 5px 10px;
  text-align: right;
  font-size: 16px;
  color: #333777;
  font-weight: bold;
  background-color: unset;
  border: thin solid #fff0;
  outline: none;
  border-radius: 5px;
}

.budget-input:hover {
  border: 1px solid #777;
}

.budget-input.editing {
  background-color: #fff;
  border: 2px solid #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.budget-input.saved {
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.budget-input.zero-budget {
  font-weight: normal;
  color: lightgrey;
}

</style>