<template>
  <div class="annual-spend-chart-container">
    <canvas ref="annualSpendChartCanvas"></canvas>
  </div>
</template>

<script>
/* eslint-disable */
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);

// Center Text Plugin
const centerAnnualTextPlugin = {
  id: 'centerAnnualTextPlugin',
  afterDraw: (chart) => {
    if (chart.tooltip._active && chart.tooltip._active.length) {
      const activePoint = chart.tooltip._active[0];
      const ctx = chart.ctx;
      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      
      const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
      const centerY = chart.chartArea.top + 50;

      // Extract month, budget, and spend data
      const monthName = chart.data.labels[activePoint.index];
      const budgetValue = chart.data.datasets[0].data[activePoint.index];
      const spendValue = chart.data.datasets[1].data[activePoint.index];
      const difference = spendValue - budgetValue;
      const overOrUnder = difference > 0 ? "Over" : "Under";


      // Formatting values
      const formatCurrency = (value) => `$${Math.abs(value).toLocaleString('en-US')}`;

      // Drawing the text
      ctx.fillStyle = '#000';
      ctx.font = 'bold 24px Arial';
      ctx.fillText( monthName, centerX, centerY);
      
      ctx.font = '14px Arial';
      ctx.fillText(`Budget: ${formatCurrency(budgetValue)}`, centerX, centerY + 20);
      ctx.fillText(`Spent: ${formatCurrency(spendValue)}`, centerX, centerY + 40);

      ctx.fillStyle = overOrUnder === "Over" ? "#FF0000" : "#00FF00";
      ctx.fillText(`${overOrUnder}: ${formatCurrency(difference)}`, centerX, centerY + 60);

      ctx.restore();
    }
  }
};

export default {
  props: {
    annualData: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
    };
  },
  watch: {
    // Watch for changes in annualData and update the chart accordingly
    annualData: {
      handler(newData) {
        this.createAnnualSpendChart(newData);
      },
      deep: true
    }
  },
  mounted() {
    if (this.annualData.length > 0) {
      this.createAnnualSpendChart(this.annualData);
    }
  },
  beforeUnmount() {
    if (this.annualSpendChart) {
      this.annualSpendChart.destroy();
    }
  },
  methods: {
    createAnnualSpendChart(data) {
      if (this.annualSpendChart) {
        this.annualSpendChart.destroy();
      }

      const ctx = this.$refs.annualSpendChartCanvas.getContext('2d');

      // Adjust for high pixel density screens
      const pixelRatio = window.devicePixelRatio || 1;
      this.$refs.annualSpendChartCanvas.width = this.$refs.annualSpendChartCanvas.offsetWidth * pixelRatio;
      this.$refs.annualSpendChartCanvas.height = this.$refs.annualSpendChartCanvas.offsetHeight * pixelRatio;
      ctx.scale(pixelRatio, pixelRatio);

      const months = data.map(d => d.monthName);
      const budgetData = data.map(d => d.budgetAmount);
      const spendData = data.map(d => d.totalSpend);

      const gradientFill = ctx.createLinearGradient(0, 0, 0, ctx.canvas.height);
      gradientFill.addColorStop(0, '#4c6a94');
      gradientFill.addColorStop(1, 'rgba(205, 255, 255, 0)');

      // Create the chart
      this.annualSpendChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: months,
            datasets: [{
                label: 'Budget',
                data: budgetData,
                // borderColor: '#FFCE56', 
                borderDash: [10, 10],
                fill: false
            },
            {
              label: 'Spend',
              data: spendData,
              backgroundColor: gradientFill,
              borderColor: 'rgb(184, 210, 219)',
              borderWidth: 1,
              fill: true
            }]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false,
                hoverRadius: 5,
            },
          scales: {
            x: {
              grid: {
                display: false // Hide the grid lines for the x-axis
              }
            },
            y: {
              display: false, // Hide the y-axis
              grid: {
                display: false // Hide the grid lines for the y-axis
              }
            }
          },
          elements: {
            point: {
              radius: 0,  // This hides the data points on the line by default
              hoverRadius: 5,  // This sets the radius of the point when hovered
              hoverBorderWidth: 2,  // Optional: This sets a border width to the hover point
              hoverBorderColor: 'black'  // Optional: This sets a border color to the hover point
            },
            line: {
              tension: 0.4  // This makes the line smoother
            }
          },
          plugins: {
            tooltip: {
              enabled: false,  // This disables the tooltip
              mode: 'index',
              intersect: false,
              axis: 'x'  // This ensures it uses the x-axis to find the nearest point
            },
            legend: {
              display: false // Hide the legend
            },
            datalabels: {
              display: function(context) {
                return context.active;  // Only display the label when the point is active (hovered)
              },
              align: 'end',
              anchor: 'end',
              offset: 5, // Adjusts the position above the data point
              formatter: function(value) {
                return new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                  minimumFractionDigits: 0, // Removes decimal points
                  maximumFractionDigits: 0
                }).format(value);
              }
            }
          },
          hover: {
            mode: 'index',  // Affect the nearest item on the x-axis
            intersect: false
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 40,
              bottom: 20
            }
          },
        },
        plugins: [centerAnnualTextPlugin]
      });
    }
  }
};
</script>

<style scoped>
.annual-spend-chart-container {
  /* width: 100%; */
  height: 500px; /* Adjust the height as needed */
  margin: 50px 50px 0px 50px;
  background-color: white;
}
</style>

