<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);


export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    unifiedDates: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // chart: null,
      // chartGenerationInProgress: false  // Add this flag
    };
  },
  methods: {
    
    getRandomColor(index) {
      // const hue = Math.floor(Math.random() * 360);
      const hue = 900; // Fixed hue value for blueish colors - 900 = teal, 500 = green, 550 = blue/green, 750 orangeish, 200 blueish
      const saturation = 50 + Math.random() * 5;
      const lightness = 20 + index * 10; // Decrease lightness based on index
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    generateLineChart() {
      // Check if the chart generation is already in progress
      if (this.chartGenerationInProgress) {
        return;
      }
      // Set the flag to true to signal that chart generation has started
      this.chartGenerationInProgress = true;

      if (!this.items || !this.unifiedDates || this.items.length === 0 || this.unifiedDates.length === 0) {
        // If the method returns early, make sure to set the flag to false
        this.chartGenerationInProgress = false;
        return;
      }

      if (this.chart) {
        this.chart.destroy();
      }

      let totalValues = Array(this.unifiedDates.length).fill(0);

      const datasets = this.items.map((item) => {
        let lastKnownValue = 0;
        const data = this.unifiedDates.map((date, index) => {
          const entry = item.valueHistory.find(entry => entry.date === date);
          if (entry) {
            lastKnownValue = entry.value;
          }
          totalValues[index] += lastKnownValue; // update total values
          return lastKnownValue;
        });

        return {
          data: data,
          tension: 0.1,
          borderRadius: 0, // set the border radius value as desired
        };
      });

      const totalLineDataset = {
        label: 'Total ',
        data: totalValues,
        type: 'line',
        borderColor: 'black',
        backgroundColor: 'rgba(255, 0, 0, 0.1)',
        opacity: 1,
        tension: 0.4,
        borderWidth: 1, // adjust the line width as desired
        order: 1,
        pointRadius: 0, // Set pointRadius to 0 to hide data points
        };

      datasets.push(totalLineDataset); // add the total line dataset

      const chartData = {
        labels: this.unifiedDates.map(date => {
          const formattedDate = new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric'
          });
          return formattedDate;
        }),
        datasets: [totalLineDataset] // Only include the total line dataset
      };

      // Ensure the canvas reference is available
      this.$nextTick(() => {
        const ctx = this.$refs.canvas.getContext('2d');
        this.chart = new Chart(ctx, {
          type: 'line',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            interaction: {
              mode: 'index', // Use index mode for selecting nearest point
              intersect: false, // Ensure the tooltip is shown even if not directly over a point
            },
            layout: {
              padding: {
                top: 30, // Adjust the top padding
                right: 40, // Adjust the right padding
                bottom: 50, // Adjust the bottom padding
                left: 40, // Adjust the left padding
              }
            },
            scales: {
              x: {
                display: false,
                stacked: false, // enable stacking on x-axis
                grid: {
                  display: false, // remove x-axis grid lines
                  drawOnChartArea: false // remove lines from the chart area
                }
              },
              y: {
                display: false,
                stacked: false, // enable stacking on y-axis
                grid: {
                  display: false, // remove y-axis grid lines
                  drawOnChartArea: false // remove lines from the chart area
                },
                ticks: {
                  callback: function (value) {
                    let sign = value < 0 ? '-' : '';
                    let absoluteValue = Math.abs(value);
                    let formattedValue;

                    if (absoluteValue >= 1000000) {
                      formattedValue = `${sign}$${(absoluteValue / 1000000).toFixed(2)}M`;
                    } else if (absoluteValue >= 1000) {
                      formattedValue = `${sign}$${Math.round(absoluteValue / 1000)}K`;
                    } else {
                      formattedValue = `${sign}$${absoluteValue}`;
                    }

                    return formattedValue;
                  }
                }
              }
            },
            plugins: {
              datalabels: {
                display: false // hide data labels for bar chart
              },
              tooltip: {
                intersect: false,
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || '';
                    var value = context.parsed.y;
                    let sign = value < 0 ? '-' : '';
                    let absoluteValue = Math.abs(value);

                    if (absoluteValue >= 1000000) {
                      label += `${sign}$${(absoluteValue / 1000000).toFixed(2)}M`;
                    } else if (absoluteValue >= 1000) {
                      label += `${sign}$${Math.round(absoluteValue / 1000)}K`;
                    } else {
                      label += `${sign}$${absoluteValue}`;
                    }

                    return label;
                  }
                }
              },
              legend: {
                display: false,
                position: 'right',
              },
            },
          },
        });

        // Set the flag to false to signal that chart generation has finished
        this.chartGenerationInProgress = false;
      });
    },

  },
  watch: {
    items: {
      handler() {
        this.generateLineChart();
      },
      deep: true
    },
    unifiedDates() {
      this.generateLineChart();
    }
  },
  mounted() {
    this.generateLineChart();
  }
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
}
</style>