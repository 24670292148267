<template>
  <h1>Privacy Policy</h1>
  <div class="PrivacyPolicy">
    <p>At FIYR, a product of Fivebags Inc., we understand that by using our app, you're trusting us with your data. We take this responsibility seriously and are committed to protecting your privacy and ensuring the security of your personal and financial information. This privacy policy outlines our approach to data privacy and security.</p>

      <h2>Data Access and Control</h2>

      <p>We believe you should have access to and control over your data. You can download all your transactional data, including categories and notes, from your FIYR app settings.</p>

      <p>Your personal FIYR financial account data, such as budgets and transactions, is only accessed by the FIYR team when necessary to provide our services, such as when you request support for a data issue. We use aggregated and anonymized data for internal analytics and business purposes.</p>

      <h2>Data Security</h2>

      <p>We employ several security measures to keep your data safe:</p>

      <p>- 256-bit encryption to protect data at rest<br>
      - Transport Layer Security (TLS) to protect data in transit<br>
      - We do not see or store your bank login credentials<br>
      - We partner with trusted data aggregators like Plaid for secure connections to financial institutions<br>
      - Our infrastructure is built on the Google Cloud Platform (GCP), which adheres to industry standard security, privacy, and compliance controls</p>

      <h2>Third-Party Services</h2>

      <p>We use Google Firestore for our database and Firebase for authentication. These services are part of the Google Cloud Platform, which is used by leading financial companies worldwide.</p>

      <p>For importing financial data from other accounts, we use Plaid. Plaid adheres to its own security policies, which you can review on their website.</p>

      <h2>Data Sharing</h2>

      <p>We do not sell or share your personal data with any third parties for advertising purposes. Your data is only used to provide and improve our services.</p>

      <h2>Data Retention</h2>

      <p>You can delete your FIYR account at any time from the Settings menu or by contacting support@fivebags.co. Upon account deletion, we remove all your linked financial data and FIYR account data, except in limited circumstances where required by law, to resolve disputes, protect FIYR and our users, or enforce our agreements. In cases where we have no such obligations, your data will be completely removed from all our internal systems, including backups, within 60 days.</p>

      <h2>Support and Data Access</h2>

      <p>Our team may access your data when necessary to provide support or resolve issues. This access is strictly limited to what's required to assist you.</p>

      <h2>Contact Us</h2>

      <p>If you have any questions or concerns about our privacy practices, please contact us at support@fivebags.co.</p>

      <h2>Updates to This Policy</h2>

      <p>We may update this privacy policy from time to time. We will notify you of any significant changes and keep this page up-to-date.</p>

      <p>At FIYR, we're committed to transparency and to protecting your privacy. We build tools to help you improve your finances, and we believe that respecting your privacy is fundamental to that mission.</p>

  </div>
</template>


<script>
/* eslint-disable */


export default {
  name: 'Terms of Service',
  components: {
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  computed: {
  },

};
</script>

<style scoped>

.PrivacyPolicy {
  width: 80%;
  margin: 0 auto;
}

.PrivacyPolicy h2 {
  text-align: left;
  font-size: 16px;
}

.PrivacyPolicy p {
  text-align: left;

  font-size: 14px;
}


</style>