<template>
  <div v-if="isOpen" @click.self="close">
    <div class="modal-content-template" >

      <!-- <p class="modal-section-title">Current Template: {{ selectedTemplateName }}</p> -->

        <p class="modal-section-title">Apply Existing Template:</p>
        <div class="select-template-div">
          <select class="select-template"  v-model="selectedTemplate">
            <option :value="null" disabled> Select a Template</option>
            <option v-for="template in availableTemplates" :key="template.value.name" :value="template.value.name">{{ template.label }}</option>
          </select>

          <button class="confirm-button" @click="confirmSelection"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg></button>
        </div>

        <div v-if="templateChanges.length > 0" class="template-changes">
            <p>Changes:</p>
            <ul class="budget-changes">
            <li v-for="change in templateChanges" :key="change.categoryName" class="budget-change-item">
                <span class="budget-name">{{ change.categoryName }}:</span>
                <span class="budget-change">{{ change.original }} → {{ change.updated }}</span>
            </li>
            </ul>
        </div>
        

    <!-- <p class="modal-section-title">Update Current Template:</p>
        <div class="update-template-div">
            <span class="selected-template-name"> {{ selectedTemplateName }}</span>

            <button class="confirm-button"  @click="updateTemplate">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
                    <path d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/>
                </svg>
            </button>
       </div>   -->


        <p class="modal-section-title">Create New Template:</p>
        <div class="create-new-template">
            <input class="input-template" type="text" v-model="templateName" />

            <!-- Update the click event to pass the isDefaultTemplate value -->
            <button class="confirm-button" @click="saveBudgetAsTemplate(isDefaultTemplate); close()">➕</button>
        </div>
        <!-- Add checkbox below -->
        <label class="is-default">
            <input type="checkbox" v-model="isDefaultTemplate" />
            <span>Set as default</span>
        </label>

      <div class="close-modal-button" @click="close">x</div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { db, auth } from '../firebaseInit';
import { collection, addDoc, doc, getDoc, getDocs, writeBatch } from 'firebase/firestore';



export default {
  props: {
    isOpen: Boolean,
    templates: Array,
    selectedDate: Object,
    categoryBudgets: Object,
  },
  data() {
    return {
      selectedTemplate: null,
      isCreatingNewtemplate: false,
      templateName: '',
      availableTemplates: [],
      isDefaultTemplate: false,
      categories: [],
    };
  },
  mounted() {
    this.fetchAvailableTemplates();
  },
  computed: {
    templateChanges() {
        const changes = [];
        const selectedTemplate = this.availableTemplates.find(t => t.value.name === this.selectedTemplate);

        if (selectedTemplate && selectedTemplate.value.categoriesBudget) {
        selectedTemplate.value.categoriesBudget.forEach((templateBudget) => {
            const currentBudget = this.categoryBudgets[templateBudget.categoryName];
            if (currentBudget !== undefined && currentBudget !== templateBudget.amount) {
            changes.push({
                categoryName: templateBudget.categoryName,
                original: currentBudget, // This is the current/updated value
                updated: templateBudget.amount // This is the value from the new template
            });
            }
        });
        }

        return changes;
        },
        selectedTemplateName() {
          const { month, year } = this.selectedDate;

          // Iterate over each category
          for (const categoryName in this.categoryBudgets) {
            let budgetEntries = this.categoryBudgets[categoryName];

            // Check if budgetEntries is an array
            if (Array.isArray(budgetEntries)) {
              // Find the budget entry for the selected month and year
              const budgetEntry = budgetEntries.find(b => b.month === month && b.year === year);
              
              // If a budget entry is found and it has a template name, return that
              if (budgetEntry && budgetEntry.templateName) {
                return budgetEntry.templateName;
              }
            }
          }
          
          return "No template applied";
        },

    },
  methods: {

    close() {
      this.$emit('close-modal');
    },

    async confirmSelection() {
      await this.handleTemplateChange();
    },

    handleTemplateChange() {
        console.log('Handle template change triggered');
        const selectedTemplateName = this.selectedTemplate; // Use the v-model bound value directly
        console.log('Selected template name:', selectedTemplateName);
        const selectedTemplate = this.availableTemplates.find(t => t.value.name === selectedTemplateName);
        console.log('Selected template object:', selectedTemplate);
        if (selectedTemplate) { // Ensure the selectedTemplate is found before applying
            this.applyBudgetTemplate(this.selectedDate.month, this.selectedDate.year, selectedTemplate.value);
        }
    },

    async fetchAvailableTemplates() {
      try {
        const user = auth.currentUser;
        if (user) {
          const templatesCollection = collection(db, `users/${user.uid}/budgetTemplates`);
          const querySnapshot = await getDocs(templatesCollection);
          this.availableTemplates = querySnapshot.docs.map((doc) => {
            return {
              label: doc.data().name,
              value: doc.data()
            };
          });
        }
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    },

    async applyBudgetTemplate(month, year, templateData) {
        console.log('Applying template:', templateData, 'for', month, year);

        const user = auth.currentUser;
        if (user) {
            try {
                if (!templateData || !templateData.categoriesBudget) {
                    console.error('Invalid template data:', templateData);
                    return;
                }

                // Initialize Firestore batch
                const batch = writeBatch(db);

                // Prepare an object to accumulate local state updates
                const localStateUpdates = {};

                // Map over categories and prepare promises
                const promises = templateData.categoriesBudget.map(async categoryBudget => {
                    console.log('Preparing budget for category:', categoryBudget.categoryName);

                    const categoryDocRef = doc(db, `users/${user.uid}/categories/${categoryBudget.categoryName}`);
                    const categoryDoc = await getDoc(categoryDocRef);
                    
                    if (categoryDoc.exists()) {
                        const categoryData = categoryDoc.data();
                        if (!categoryData) {
                            console.error('Invalid category data:', categoryData);
                            return;
                        }

                        const existingBudgetIndex = categoryData.budget 
                            ? categoryData.budget.findIndex(b => b.month === month && b.year === year)
                            : -1;
                        
                        if (existingBudgetIndex > -1) {
                            categoryData.budget[existingBudgetIndex].amount = categoryBudget.amount;
                            categoryData.budget[existingBudgetIndex].templateName = templateData.name; 
                        } else {
                            const newBudget = {
                                amount: categoryBudget.amount,
                                month: month,
                                year: year,
                                templateName: templateData.name 
                            };
                            categoryData.budget = categoryData.budget || [];
                            categoryData.budget.push(newBudget);
                        }

                        // Add to Firestore batch
                        batch.update(categoryDocRef, { budget: categoryData.budget });
                        
                        // Accumulate local state updates
                        localStateUpdates[categoryBudget.categoryName] = categoryBudget.amount;
                    }
                });

                // Wait for all promises to resolve
                await Promise.all(promises);

                // Commit the batch
                await batch.commit();
                this.$emit('close-modal');
                this.$emit('template-applied');

                // Update local state in one go
                // this.categoryBudgets = { ...this.categoryBudgets, ...localStateUpdates };

                // Trigger a UI update, if necessary

            } catch (error) {
                console.error('Error applying template:', error);
            }
        }
    },

    handleCategoryBudgetsUpdate(updatedBudgets) {
        this.categoryBudgets = updatedBudgets;
    },

    async saveBudgetAsTemplate(isDefault) {
        const user = auth.currentUser;
        if (user && this.templateName) {
            const templateData = {
                name: this.templateName,
                isDefault: isDefault,
                categoriesBudget: []
            };
            for (const [categoryId, amount] of Object.entries(this.categoryBudgets)) {
                console.log(categoryId, amount); // Debugging
                templateData.categoriesBudget.push({
                    categoryName: categoryId, // Or retrieve the name from this.categories if needed
                    amount: amount
                });
            }

            // Start a batch write operation
            const batch = writeBatch(db);

            // Add the new template to the batch
            const newTemplateRef = doc(collection(db, `users/${user.uid}/budgetTemplates`));
            batch.set(newTemplateRef, templateData);

            // If the new template is set to default, update all other templates to not be the default
            if (isDefault) {
                const templatesCollection = collection(db, `users/${user.uid}/budgetTemplates`);
                const querySnapshot = await getDocs(templatesCollection);
                querySnapshot.docs.forEach((docSnapshot) => {
                    if (docSnapshot.id !== newTemplateRef.id) { // Skip the new template
                        const templateDocRef = doc(db, `users/${user.uid}/budgetTemplates`, docSnapshot.id);
                        batch.update(templateDocRef, { isDefault: false });
                    }
                });
            }

            // Commit the batch write operation
            await batch.commit();
        }
    },
  }
};
</script>

<style>
.select-template-div, .create-new-template, .update-template-div {
  display: flex;
  height: 50px;
  align-items: center;
}

.confirm-button {
  transition: all 0.3s ease-in-out;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin: 20px 0px 20px 5px;
}

.confirm-button:hover {
  transition: all 0.3s ease-in-out;
  background-color: white;
  font-size: 125%;
  fill: #4caf50;
}

.remove-button {
  transition: all 0.3s ease-in-out;
  background-color: #f1f1f1;
  border-radius: 5px;
  color: grey;
  margin: 20px 0px 20px 5px;
}

.remove-button:hover {
  background-color: rgb(246 246 246);
  transition: all 0.3s ease-in-out;
  color: red;
}

.close-modal-button {
  transition: all 0.3s ease-in-out;
  position: absolute;
  top: 10px;
  right: 20px;
  font-weight: 500;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.close-modal-button:hover {
  transition: all 0.3s ease-in-out;
  color: red;  /* You can style the hover state if you like. */
  font-weight: 800;
}

.select-template, .input-template {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f1f1f1;
  font-size: 14px;
  color: grey;
  margin: 1%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.modal-section-title {
  text-align: left;
  margin: 40px 5px 0px;
  font-size: 1em;
  font-weight: 600;
  color: darkslategrey;
}

.is-default {
    display: flex;      
    align-items: center;
    gap: 8px;           
  }

  .is-default span {
    margin: 0; 
  }

  .is-default input {
    width: unset ;
  }

  .budget-changes {
  list-style: none;
  padding: 0;
  margin: 0;
}

.budget-change-item {
  display: flex;
  justify-content: space-between; /* This will push the two spans to the edges */
  margin-bottom: 5px; /* Add some spacing between items */
}

.budget-name {
  text-align: right;
  flex: 1; /* Take up as much space as possible */
}

.budget-change {
  text-align: left;
  flex: 1; /* Take up as much space as possible */
  margin-left: 10px; /* Add some spacing between the name and the change */
}

.modal-content-template {
  width: 500px !important;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 50px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  position: relative;
  transition: 0.3s ease-in-out;
}



.selected-template-name {
    text-align: left;
    float: left;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f1f1f1;
    font-size: 14px;
    color: grey;
    margin: 1%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
</style>
