<template>
  <div class="liabilities">
  </div>
</template>

<script>
export default {
  name: 'LiabilitiesView',
  data() {
    return {
      liabilities: 0, // Initialize the liabilities value
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>

h2 {
  text-align: center;
}
</style>
