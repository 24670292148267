<template>
    <div class="category-view">
    </div>
  </template>
  
  <script>
  export default {
    name: 'CategoryView',
    data() {
      return {
      };
    },
    mounted() {
    },
    methods: {
    },
  };
  </script>
  
  <style scoped>
  
  h2 {
    text-align: center;
  }
  </style>
  