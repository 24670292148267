<template>
  <div class="login-container">
    <form @submit.prevent="loginUser" class="login-form">
      <div class="form-group">

        <v-text-field
          v-model="email"
          label="Email"
          type="email"
          required
        ></v-text-field>
      </div>
      <div class="form-group">
        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          autocomplete="current-password"
          required
        ></v-text-field>
      </div>
      <!-- This will display the error message -->
      <div v-if="loginError" class="error-message">
        {{ loginError }}
      </div>
      <div style="display: inline-flex; gap: 10px;">
        <div class="form-group">
          <v-btn style="box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important; color: black" type="submit" :disabled="loading">Login</v-btn>
        </div>
        <div class="form-group">
          <v-btn style="box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important; color: black" type="button" @click="signInWithGoogle">Sign in with Google</v-btn>
        </div>
      </div>
      <div class="forgot-password">
        <a href="#" @click.prevent="showResetPassword">Forgot Password?</a>
      </div>
    </form>
    <div v-if="loading" class="login-loading-screen">
      <div style="display: inline-block">
        <!-- <div class="tally-container">
          <div class="tally">
            <div class="tally-mark"></div>
            <div class="tally-mark"></div>
            <div class="tally-mark"></div>
            <div class="tally-mark"></div>
            <div class="tally-mark"></div>
          </div>
        </div> -->
        <transition name="fade" mode="out-in">
          <p :key="loadingText">{{ loadingText }}<span class="dots"></span></p>
        </transition>
        <div class="loader-container-login">
          <div class="dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>






<script>
/* eslint-disable */
import { auth, signInWithPopup, googleProvider } from '../firebaseInit';


export default {
  data() {
    return {
      email: '',
      password: '',
      loginError: '',
      loading: false,
      $googleProvider: googleProvider,
      loadingTextList: ['Signing in...', 'Fetching transactions...', 'Loading settings...'],
      currentTextIndex: 0,
    };
  },
  computed: {
    loadingText() {
      return this.loadingTextList[this.currentTextIndex];
    },
  },
  methods: {
    async signInWithGoogle() {
      try {
        const result = await signInWithPopup(auth, googleProvider);
        this.$router.push({ name: 'Spending' });
        this.setContentWrapperMargin('200px');
      } catch (error) {
        this.handleFirebaseError(error);
      }
    },
    showResetPassword() {
      this.$emit('show-reset-password');
    },
    async loginUser() {
      this.loading = true;
      this.$emit('loading-state-changed', true);
      this.startLoadingTextTransition();
      try {
        const result = await this.$store.dispatch('loginUser', {
          email: this.email,
          password: this.password,
        });
        if (result.success) {
          setTimeout(() => {
            this.$router.push({ name: result.redirectTo });
            this.setContentWrapperMargin('200px');
            this.$emit('loading-state-changed', false);
          }, 1000);
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        this.handleFirebaseError(error);
      } finally {
        this.loading = false;
        this.$emit('loading-state-changed', false);
      }
    },
    handleFirebaseError(error) {
      switch (error.code) {
        case 'auth/wrong-password':
          this.loginError = 'Incorrect password. Please try again.';
          break;
        case 'auth/user-not-found':
          this.loginError = 'No account found with this email. Please check your email or sign up.';
          break;
          case 'auth/no-document-found':
          this.loginError = 'No account found with this email. Please check your email or sign up.';
          break;
        case 'auth/invalid-email':
          this.loginError = 'Invalid email address. Please enter a valid email.';
          break;
        case 'auth/user-disabled':
          this.loginError = 'This account has been disabled. Please contact support.';
          break;
        case 'auth/too-many-requests':
          this.loginError = 'Too many failed login attempts. Please try again later.';
          break;
        default:
          this.loginError = 'An error occurred during login. Please try again.';
      }
    },
    setContentWrapperMargin(margin) {
      const elements = document.getElementsByClassName('content-wrapper');
      for (let i = 0; i < elements.length; i++) {
        elements[i].style.marginLeft = margin;
      }
    },
    startLoadingTextTransition() {
      const transitionDuration = 3000;
      const totalDuration = transitionDuration * this.loadingTextList.length;

      const timer = setInterval(() => {
        this.currentTextIndex = (this.currentTextIndex + 1) % this.loadingTextList.length;
      }, transitionDuration);

      setTimeout(() => {
        clearInterval(timer);
      }, totalDuration);
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 10px;
}

.login-form {
  padding: 20px;
  border-radius: 5px;
}
.login-container  {
  width: 600px;
  margin-top: 10px;
}

.login-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  flex: 2;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.login-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.login-loading-screen p {
  color: #59a7ff;
  font-size: 28px;
  font-weight: 600;
}


.loader-container-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border-top: 6px solid #9ec9ff;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}






.loader-container-login {
  --uib-size: 75px;
  --uib-color: #83c4ff;
  --uib-speed: 2s;
  position: relative;
  margin: 0 auto;
  height: var(--uib-size);
  width: var(--uib-size);
  animation-delay: 4s; /* Add this line to delay the animation by 2 seconds */
}

.loader-container-login::before,
.loader-container-login::after,
.dot::before,
.dot::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--uib-color);
  animation: pulse var(--uib-speed) linear infinite;
  transform: scale(0);
  opacity: 0;
  transition: background-color 0.3s ease;
}

.loader-container-login::after {
  animation-delay: calc(var(--uib-speed) / -4 + 4s); /* Add 2s to the existing delay */
}

.dot::before {
  animation-delay: calc(var(--uib-speed) * -0.5 + 2s); /* Add 2s to the existing delay */
}

.dot::after {
  animation-delay: calc(var(--uib-speed) * -0.75 + 2s); /* Add 2s to the existing delay */
}

.forgot-password {
  margin-top: 10px;
  text-align: left;
}

.forgot-password a {
  color: #59a7ff;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

  .tally-container {
      margin-bottom: 20px;
    }

    .tally {
      width: 80px;
      height: 100px;
      position: relative;
    }

    .tally-mark {
      position: absolute;
      width: 20px;
      height: 100px;
      background-color: #333;
      transform-origin: center;
      animation: drawTally .4s linear forwards;
      opacity: 0;
    }

    .tally-mark:nth-child(1) {
      left: 30px;
      animation-delay: 0s;
    }

    .tally-mark:nth-child(2) {
      left: 60px;
      animation-delay: 0.4s;

    }

    .tally-mark:nth-child(3) {
      left: 90px;
      animation-delay: 0.8s;
    }

    .tally-mark:nth-child(4) {
      left: 120px;
      animation-delay: 1.2s;
    }

    .tally-mark:nth-child(5) {
      width: 170px;
      height: 18px !important;
      top: 40px;
      transform: rotate(338deg);
      /* border-radius: 6px; */
      left: 0px;
      animation-delay: 1.6s;
    }

    @keyframes drawTally {
      0% {
        height: 0;
        opacity: 1;
      }
      100% {
        height: 100%;
        opacity: 1;
      }
    }

</style>
