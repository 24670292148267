<template>
  <div class="subscription-success">
    <p>Checking your subscription status...</p>
    <!-- You can add more UI elements here to indicate progress or provide feedback to the user -->
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { auth, db } from '../firebaseInit'; // Ensure these are correctly imported from your Firebase setup file
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

const router = useRouter();

onMounted(() => {
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      // Reference to the user's document in Firestore. Adjust the path as necessary.
      const userRef = doc(db, 'users', user.uid);
      
      try {
        const userDoc = await getDoc(userRef);
        if (userDoc.exists() && userDoc.data().subscriptionActive) {
          // Redirect to /onboarding if the subscription is active
          router.push('/onboarding');
        } else {
          // Optionally handle non-active subscription or missing data as needed
          console.log('Subscription not active or user data missing. Redirecting to home.');
          router.push('/');
        }
      } catch (error) {
        console.error('Error fetching user subscription status:', error);
        // Handle error, maybe redirect to an error page or display a message
        router.push('/');
      }
    } else {
      // User not logged in, redirect to login page
      console.log('User not logged in. Redirecting to login.');
      router.push('/login');
    }
  });
});
</script>

<style scoped>
/* Add styles for your subscription success page here */
.subscription-success {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Example style */
}
</style>
