<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(ChartDataLabels);




export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    unifiedDates: {
      type: Array,
      required: true
    },
    selectedTimeFrame: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // chart: null,
      // chartGenerationInProgress: false  // Add this flag
      datasetColors: [],
    };
  },

  methods: {
    updateChartData() {
      const now = new Date();
      let cutoffDate = new Date();

      switch (this.selectedTimeFrame) {
        case '5Y':
          cutoffDate.setFullYear(now.getFullYear() - 5);
          break;
        case '3Y':
          cutoffDate.setFullYear(now.getFullYear() - 3);
          break;
        case '2Y':
          cutoffDate.setFullYear(now.getFullYear() - 2);
          break;
        case '1Y':
          cutoffDate.setFullYear(now.getFullYear() - 1);
          break;
        case '6M':
          cutoffDate.setMonth(now.getMonth() - 6);
          break;
        case '3M':
          cutoffDate.setMonth(now.getMonth() - 3);
          break;
        case '1M':
          cutoffDate.setMonth(now.getMonth() - 1);
          break;
        default:
          cutoffDate = new Date('1900-01-01'); // Show all if no filter
          break;
      }

      // Filter the unifiedDates array and keep only the last entry for each month
      const filteredDates = this.unifiedDates
        .filter(date => new Date(date) >= cutoffDate)
        .reduce((dates, date) => {
          const month = new Date(date).toISOString().slice(0, 7); // Extract year and month (YYYY-MM)
          dates[month] = date;
          return dates;
        }, {});

      const uniqueFilteredDates = Object.values(filteredDates);

      let totalValues = Array(uniqueFilteredDates.length).fill(0);

      const datasets = this.items.map((item, index) => {
        let lastKnownValue = 0;
        const data = uniqueFilteredDates.map((date, index) => {
          const entry = item.valueHistory.find(entry => entry.date === date);
          if (entry) {
            lastKnownValue = entry.value;
          }
          totalValues[index] += lastKnownValue; // update total values
          return lastKnownValue;
        });

        const isLiability = item.accountType === 'Liability';
        const colorFunction = isLiability ? this.getLiabilityColor : this.getRandomColor;
        const baseColor = this.datasetColors[index] || colorFunction(index);

        if (!this.datasetColors[index]) {
          this.datasetColors[index] = baseColor;
        }


        return {
          label: item.name,
          data: data,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return baseColor;
            }

            const gradientBottom = chartArea.bottom;
            const gradientTop = chartArea.top;
            const gradient = ctx.createLinearGradient(0, gradientBottom, 0, gradientTop);
            gradient.addColorStop(0, baseColor);
            gradient.addColorStop(1, baseColor.replace(')', ', 0)').replace(isLiability ? 'hsl' : 'hsl', 'hsla'));

            return gradient;
          },
          borderColor: baseColor,
          borderRadius: 0,
          borderWidth: 0.4,
          order: 2,
          pointRadius: 0,
        };
      });

      const totalLineDataset = {
        label: 'Total',
        data: totalValues,
        type: 'line',
        borderColor: '#1f7c7c',
        backgroundColor: 'rgba(255, 165, 0, 0.1)',
        opacity: 1,
        tension: 0.4,
        borderWidth: 6,
        pointRadius: 0,
        order: 0,
      };

      datasets.push(totalLineDataset);

      this.chart.data.labels = uniqueFilteredDates.map(date => {
        const formattedDate = new Date(date).toLocaleDateString('en-US', {
          month: 'short',
          year: 'numeric'
        });
        return formattedDate;
      });

      this.chart.data.datasets = datasets;
      this.chart.update();
    },


    getLiabilityColor(index) {
      // Adjust the hue for red variations
      const hue = 0; // Red
      const saturation = 50 + (index % 5) * 10; // Varying saturation based on index
      const lightness = 30 + Math.floor(index / 5) * 10; // Adjust lightness based on index for variation
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },
    
    getRandomColor(index) {
      // const hue = Math.floor(Math.random() * 360);
      const hue = 900; // Fixed hue value for blueish colors - 900 = teal, 500 = green, 550 = blue/green, 750 orangeish, 200 blueish
      const saturation = 70 + Math.random() * 30; // Increased saturation for brighter colors
      const lightness = 30 + index * 10; // Adjusted lightness for darker shades
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    generateBarChart() {
      // Check if the chart generation is already in progress
      if (this.chartGenerationInProgress) {
        return;
      }
      // Set the flag to true to signal that chart generation has started
      this.chartGenerationInProgress = true;

      if (!this.items || !this.unifiedDates || this.items.length === 0 || this.unifiedDates.length === 0) {
        // If the method returns early, make sure to set the flag to false
        this.chartGenerationInProgress = false;
        return;
      }

      if (this.chart) {
        this.chart.destroy();
      }

      let totalValues = Array(this.unifiedDates.length).fill(0);

      this.$nextTick(() => {
      const ctx = this.$refs.canvas.getContext('2d');
      const datasets = this.items.map((item, index) => {
        let lastKnownValue = 0;
        const data = this.unifiedDates.map((date, index) => {
          const entry = item.valueHistory.find(entry => entry.date === date);
          if (entry) {
            lastKnownValue = entry.value;
          }
          totalValues[index] += lastKnownValue; // update total values
          return lastKnownValue;
        });
        // Determine if the item is an asset or liability
        const isLiability = item.accountType === 'Liability'; // Assuming each item has a 'type' property

        // Choose color function based on item type
        const colorFunction = isLiability ? this.getLiabilityColor : this.getRandomColor;
        const baseColor = this.datasetColors[index] || colorFunction(index);

        // Store the generated color if it doesn't exist
        if (!this.datasetColors[index]) {
          this.datasetColors[index] = baseColor;
        }


        return {
          label: item.name,
          data: data,
          backgroundColor: (context) => {
            const chart = context.chart;
            const { ctx, chartArea } = chart;

            if (!chartArea) {
              return baseColor;
            }

            const gradientBottom = chartArea.bottom;
            const gradientTop = chartArea.top;
            const gradient = ctx.createLinearGradient(0, gradientBottom, 0, gradientTop);
            gradient.addColorStop(0, baseColor); // Start with solid color at the bottom
            gradient.addColorStop(1, baseColor.replace(')', ', 0)').replace(isLiability ? 'hsl' : 'hsl', 'hsla')); // End transparent at the top

            return gradient;
          },
          borderColor: baseColor,

          borderRadius: 0,
          borderWidth: .4,
          order: 2,
          pointRadius: 0,
        };
      });

      const totalLineDataset = {
        label: 'Total',
        data: totalValues,
        type: 'line',
        borderColor: '#1f7c7c',
        backgroundColor: 'rgba(255, 165, 0, 0.1)', // Adjusted for clarity
        opacity: 1,
        tension: 0.4,
        borderWidth: 6, // adjust the line width as desired
        pointRadius: 0,
        order: 0,
        };

      datasets.push(totalLineDataset); // add the total line dataset
      

      const chartData = {
        labels: this.unifiedDates.map(date => {
          const formattedDate = new Date(date).toLocaleDateString('en-US', {
            month: 'short',
            year: 'numeric' // Add the 'year' option to include the year
          });
          return formattedDate;
        }),
        datasets: datasets
      };

      // Ensure the canvas reference is available

        this.chart = new Chart(ctx, {
          type: 'bar',
          data: chartData,
          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                stacked: true, // enable stacking on x-axis
                grid: {
                  display: false, // remove x-axis grid lines
                  drawOnChartArea: false // remove lines from the chart area
                }
              },
              y: {
                stacked: true, // enable stacking on y-axis
                grid: {
                  display: false, // remove y-axis grid lines
                  drawOnChartArea: false // remove lines from the chart area
                },
                ticks: {
                  callback: function (value) {
                    let sign = value < 0 ? '-' : '';
                    let absoluteValue = Math.abs(value);
                    let formattedValue;

                    if (absoluteValue >= 1000000) {
                      formattedValue = `${sign}$${(absoluteValue / 1000000).toFixed(2)}M`;
                    } else if (absoluteValue >= 1000) {
                      formattedValue = `${sign}$${Math.round(absoluteValue / 1000)}K`;
                    } else {
                      formattedValue = `${sign}$${absoluteValue}`;
                    }

                    return formattedValue;
                  }
                }
              }
            },
            interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false
            },
            plugins: {
              datalabels: {
                display: false // hide data labels for bar chart
              },
              tooltip: {
                enabled: true,
                callbacks: {
                  label: function (context) {
                    var label = context.dataset.label || '';

                    if (label) {
                      label += ': ';
                    }

                    var value = context.parsed.y;
                    let sign = value < 0 ? '-' : '';
                    let absoluteValue = Math.abs(value);

                    if (absoluteValue >= 1000000) {
                      label += `${sign}$${(absoluteValue / 1000000).toFixed(2)}M`;
                    } else if (absoluteValue >= 1000) {
                      label += `${sign}$${Math.round(absoluteValue / 1000)}K`;
                    } else {
                      label += `${sign}$${absoluteValue}`;
                    }

                    return label;
                  }
                }
              },
              legend: {
                display: false,
                position: 'right',
              },
            },
          },
        });

        // Set the flag to false to signal that chart generation has finished
        this.chartGenerationInProgress = false;
      });
    },

  },
  watch: {
    items: {
      handler() {
        this.generateBarChart();
      },
      deep: true
    },
    selectedTimeFrame() {
      this.updateChartData();
    },
    unifiedDates() {
      this.generateBarChart();
    }
  },
  mounted() {
    this.generateBarChart();
  }
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 100%;
  padding: 30px;
}
</style>