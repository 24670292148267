<template>
  <div :class="{ 'flex-display': shouldApplyFlexDisplay, 'light-mode': lightMode }" id="app">
    <div v-if="showSidebar" class="sidebar-wrapper">
      <Sidebar :collapsed="sidebarIsCollapsed" @sidebar-collapse-state-changed="handleSidebarCollapse" />
    </div>
    <div class="content-wrapper" :class="{ 'collapsed-sidebar': sidebarIsCollapsed, 'with-sidebar': shouldApplyMargin }">
      <router-view />
    </div>

  </div>
  <!-- <div v-if="showModal" class="modal-overlay">
    <div class="modal-content">
      <h2>Inactivity Warning</h2>
      <p>You will be logged out in <span>{{ countdown }}</span> seconds due to inactivity.</p>
      <button @click="closeModal">Stay Logged In</button>
    </div>
  </div> -->
  <!-- Loading Overlay -->
  <div v-if="$store.state.isFetchingAll" class="loading-overlay">
    <div class="loading-message">⏳ Fetching transactions...</div>
  </div>
</template>


<script>
/* eslint-disable */
import Sidebar from './components/SideBar.vue';
import { Transition } from 'vue'; // Import the Transition component
import { auth } from "./firebaseInit";
import { onMessageReceived } from './fcmHandler';
import { useRouter } from 'vue-router';  // Add this import







export default {
  name: 'App',
  data() {
    return {
      sidebarIsCollapsed: false,
      sidebarWidth: '200px',
      // inactivityTimeout: null,
      showModal: false,
      // countdown: 120, // 2 minutes in seconds
      // countdownInterval: null,
    };
  },
  provide() {
    return {
      sidebarWidth: this.sidebarWidth,
    };
  },
  components: {
    Sidebar,
    Transition,
  },
  computed: {
    showSidebar() {
      const excludedRoutes = ['/', '/home', '/register', '/login', '/homepage','/manifesto', '/terms-of-service', '/privacy-policy'];
      return !this.isMobile() && !excludedRoutes.includes(this.$route.path) && !this.$route.path.startsWith('/blog') && this.$store.state.isAuthenticated;
    },
    shouldApplyFlexDisplay() {
      const excludedRoutes = ['/', '/home', '/register', '/login', '/homepage', '/manifesto', '/terms-of-service', '/privacy-policy'];
      return !this.isMobile() && !excludedRoutes.includes(this.$route.path) && !this.$route.path.startsWith('/blog') && this.$store.state.isAuthenticated;
    },
    shouldApplyMargin() {
      const excludedRoutes = ['/']; // Add other excluded routes if needed
      return this.showSidebar && !this.sidebarIsCollapsed && !excludedRoutes.includes(this.$route.path);
    },
    lightMode() {
      return this.$store.state.lightMode;
    },

  },
  methods: {
    // showInactivityModal() {
    //   this.showModal = true;
    //   this.countdownInterval = setInterval(() => {
    //     if (this.countdown > 0) {
    //       this.countdown--;
    //     } else {
    //       this.logoutUser();
    //     }
    //   }, 1000);
    // },
    async logoutUser() {
      try {
        await auth.signOut(); // Sign out from Firebase
        this.$store.dispatch('clearUserData'); // Assuming there's an action to clear user data
        this.$router.push('/login'); // Redirect to login
      } catch (error) {
        console.error("Error signing out: ", error);
      }
    },
    isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i;
        const isMobileByUA = mobileRegex.test(userAgent.toLowerCase());
        
        // Check screen size
        const isSmallScreen = window.innerWidth <= 768;

        return isMobileByUA || isSmallScreen;
      },
    handleFCMMessage(message) {
      if (message.data.type === 'subscriptionUpdated') {
        const subscription = JSON.parse(message.data.subscription);
        this.$store.dispatch('updateSubscription', subscription);
      }
    },

    // closeModal() {
    //   this.showModal = false;
    //   clearInterval(this.countdownInterval);
    //   this.countdown = 120;
    //   this.resetInactivityTimer();
    // },

    // resetInactivityTimer() {
    //   clearTimeout(this.inactivityTimeout);
    //   clearInterval(this.countdownInterval);
    //   this.countdown = 120;
    //   this.showModal = false;

    //   this.inactivityTimeout = setTimeout(() => {
    //     this.showInactivityModal();
    //   }, 600000); // 10 minutes in milliseconds
    // },

    // setupInactivityListener() {
    //   document.addEventListener('mousemove', this.resetInactivityTimer);
    //   document.addEventListener('keypress', this.resetInactivityTimer);
    //   // Add other event listeners as needed
    // },
    handleSidebarCollapse(state) {
      this.sidebarIsCollapsed = state;
      localStorage.setItem('sidebarIsCollapsed', state);
    },
    applyMargin(isCollapsed) {
      const excludedRoutes = ['/']; // Add other excluded routes if needed
      if (!excludedRoutes.includes(this.$route.path)) {
        const elements = document.getElementsByClassName('content-wrapper');
        for (let i = 0; i < elements.length; i++) {
          elements[i].style.marginLeft = isCollapsed ? '0px' : '200px';
        }
      }
    },
    handleKeyDown(e) {
      // Check if ⌘ + Shift + . are pressed
      if (e.metaKey && e.shiftKey && e.keyCode === 190) {
        // Dispatch the action to the Vuex store
        this.$store.dispatch('fetchAllTransactions');
      }
    },
  },
  async mounted() {
    console.log('App mounted');
    console.log('Is mobile:', this.isMobile());
    console.log('Screen width:', window.innerWidth);
    console.log('Current route:', this.$route.path);

    onMessageReceived(this.handleFCMMessage);
    
    const router = useRouter();
    await router.isReady();
    
    if (this.isMobile() && this.$route.path === '/') {
      console.log('Redirecting to login');
      this.$router.push('/login');
    }
  },
  beforeUnmount() {
    // clearTimeout(this.inactivityTimeout);
    // document.removeEventListener('mousemove', this.resetInactivityTimer);
    // document.removeEventListener('keypress', this.resetInactivityTimer);
    // document.removeEventListener('keydown', this.handleKeyDown);
    // Remove other event listeners as needed
    onMessageReceived(this.handleFCMMessage)();
  },
  created() {
    const persistedState = localStorage.getItem('sidebarIsCollapsed');
    if (persistedState === null) {
      this.sidebarIsCollapsed = false;
      localStorage.setItem('sidebarIsCollapsed', false);
    } else {
      this.sidebarIsCollapsed = persistedState === 'true';
    }
  },
  watch: {
    sidebarIsCollapsed(newVal) {
      this.applyMargin(newVal);
    },
    lightMode(newMode) {
      document.body.classList.toggle('light-mode', newMode);
    },
  },
};
</script>



<style>
iframe#webpack-dev-server-client-overlay{display:none!important}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
  text-align: center !important;
  color: #2c3e50 !important;
  background-color: aliceblue;
  display: flex;
  height: 100vh; /* Set the app height to cover the entire viewport */
}




@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.animated.delay-2s {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

.animated.delay-3s {
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}

.animated.delay-4s {
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.animated.delay-5s {
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}

.animated.fast {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.animated.slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.slower {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
}

@media (print), (prefers-reduced-motion: reduce) {
  .animated {
    -webkit-animation-duration: 1ms !important;
    animation-duration: 1ms !important;
    -webkit-transition-duration: 1ms !important;
    transition-duration: 1ms !important;
    -webkit-animation-iteration-count: 1 !important;
    animation-iteration-count: 1 !important;
  }
}



/* Apply flex display only when the route is not "/", "/home", "/register", "/login", or "/homepage" */
#app:not(.flex-display) {
  display: initial;
}

.container {
  height: 100vh !important;
}

.content-wrapper {
  flex: 1;
  /* padding: 20px; */
  overflow-y: auto; /* Enable vertical scrolling if the content exceeds the viewport height */
  transition: margin-left 0.4s ease;
}

.content-wrapper > router-view {
  /* Add any specific styles for the router-view component */
  margin: 0 auto; /* Center the router-view horizontally */
}

.with-sidebar {
  margin-left: 200px;
}

.content-wrapper.collapsed-sidebar {
  margin-left: 0px;
}

.main-content {
  flex: 1;
  /* Add any styles for the main content */
}

html,
body {
  margin: 0;
  padding: 0;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}


.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Make sure it's on top */
}

.loading-message {
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.sidebar-wrapper {
  width: 80px;
}

textarea:focus-visible {
    background-color: #fff !important;
}

.v-field--appended {
    padding-inline-end: 0px !important;
}

.v-input--density-default {
    --v-input-control-height: unset !important;
    --v-input-padding-top: 16px;
}

@media (max-width: 768px) {
  #app {
    flex-direction: column;
  }

  .sidebar-wrapper {
    width: 100%;
    height: auto;
  }

  .content-wrapper {
    margin-left: 0 !important;
  }

  .with-sidebar {
    margin-left: 0;
  }
}

.loading-spinner {
  border: 16px solid #f3f3f3; /* Light gray */
  border-top: 16px solid #3498db; /* Blue or any color you prefer */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  margin-bottom: 20px;
  animation: spin 2s linear infinite;
}

/* Spinner animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
