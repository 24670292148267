import { getMessaging, onMessage } from 'firebase/messaging';

export function onMessageReceived(callback) {
  const messaging = getMessaging();

  const unsubscribe = onMessage(messaging, (payload) => {
    console.log('FCM message received:', payload);
    callback(payload);
  });

  return unsubscribe;
}