<template>
  <!-- we likely don’t need the "Import Plaid" button since transactions should be automatically synced after initial import -->
  <!-- <button class="plaid-button" @click="openPlaid">Import Plaid</button>  -->
  <div class="loader-container hidden">
      <span class="loader"></span>
  </div>
  <v-btn class="plaid-button" @click="openPlaid">Sync Transactions</v-btn>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { query, where, addDoc, getDocs, doc, getDoc, collection, setDoc } from 'firebase/firestore';
import { auth, db } from '../firebaseInit'; // Import the initialized Firebase auth instance


export default {
  props: {
    onTransactionsFetched: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      linkToken: null,
      accessTokens: {}, // Store multiple tokens
    };
  },
  methods: {
    async linkNewAccount() {
      await this.createLinkToken();
      this.openPlaidLink();
    },

    async openPlaidLink() {
      console.log('Creating Plaid Link handler...');
      const linkHandler = window.Plaid.create({
        token: this.linkToken,
        onSuccess: async (public_token) => {
          console.log('Public token:', public_token);
          const response = await axios.post('https://fivebags-server.nn.r.appspot.com/exchange_public_token', { public_token });

          // Extract access_token and item_id from the response
          const access_token = response.data.access_token;
          const item_id = response.data.item_id; 
          console.log('Access token:', access_token);
          console.log('Item ID:', item_id);

          // Get user_id from Firebase Authentication
          const user = auth.currentUser;
          const user_id = user ? user.uid : null;

          try {
            // Fetch account data from Plaid
            const accounts = await this.fetchAccounts(access_token);

            // Send access_token, item_id, user_id, and accounts to your server for storage
            await axios.post('https://fivebags-server.nn.r.appspot.com/store_access_token', {
              access_token,
              item_id,
              user_id,
              accounts, // Include the accounts array
            });

            // Show the loader
            document.querySelector('.loader-container').classList.remove('hidden');

            // Process each account
            for (const account of accounts) {
              await this.processAccount(account, user_id);
            }

            // Fetch and process investments
            await this.fetchInvestments(access_token, user_id);

            const transactions = await this.fetchTransactions(access_token);
            this.onTransactionsFetched(transactions);

            // Hide the loader
            document.querySelector('.loader-container').classList.add('hidden');
          } catch (error) {
            if (error.response && error.response.status === 409) {
              const { accountName, accountMask } = error.response.data;
              this.errorMessage = `The account "${accountName}" (${accountMask}) is already linked. Please unselect this account in the Plaid modal and try again with only new accounts.`;
              console.log('Error message:', this.errorMessage); // Add this line
            } else {
              console.error('Error sending access token to server:', error);
              this.errorMessage = 'An error occurred while processing your request. Please try again later.';
              console.log('Error message:', this.errorMessage); // Add this line
            }
          }
        },
        onExit: (error, metadata) => {
          if (error) {
            console.log('Plaid error:', error);
          }
          console.log('Plaid metadata:', metadata);
        },
      });

      console.log('Opening Plaid Link...');
      linkHandler.open();
    },
    async fetchExistingAccessTokens() {
      try {
        const user = auth.currentUser;
        const response = await axios.get(`https://fivebags-server.nn.r.appspot.com/get_access_token?user_id=${user.uid}`);
        this.accessTokens = response.data.tokens || {}; // Store all tokens
      } catch (error) {
        console.error('Error fetching existing access tokens:', error);
        this.accessTokens = {};
      }
    },
    async createLinkToken() {
      console.log('Starting to create link token...');
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/create_link_token');
        this.linkToken = response.data.link_token;
        console.log('Fetched link token:', this.linkToken);
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    },
    async openPlaid() {
      await this.fetchExistingAccessTokens();

      // Check if there are existing tokens
      if (Object.keys(this.accessTokens).length > 0) {
        console.log('Using existing access tokens:', this.accessTokens);
        for (const [item_id, tokenData] of Object.entries(this.accessTokens)) {
          const { access_token, institution_id, accounts, cursor } = tokenData;
          const user_id = auth.currentUser.uid;

          const response = await axios.post('https://fivebags-server.nn.r.appspot.com/sync_transactions', {
            access_token,
            user_id,
            item_id,
            institution_id,
            accounts,
            cursor,
          });

          const transactions = response.data.transactions;

          // Fetch account data from Plaid
          const fetchedAccounts = await this.fetchAccounts(access_token);

          // Process each account
          for (const account of fetchedAccounts) {
            await this.processAccount(account, user_id);
          }

          // this.onTransactionsFetched(transactions);
        }
      } else {
        return;
      }
    },
    async fetchTransactions(access_token) {
      let cursor = "";
      let transactions = [];
      let hasMore = false;

      do {
        try {
          const response = await axios.post('https://fivebags-server.nn.r.appspot.com/get_transactions', {
            access_token,
            cursor,
          });

          // Update the cursor and hasMore flag
          cursor = response.data.next_cursor;
          hasMore = response.data.has_more;

          // Add the returned transactions to the list
          transactions = transactions.concat(response.data.added);
        } catch (error) {
          console.error('Error fetching transactions:', error);
          
          // If a request fails, wait a bit and then try again
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } while (hasMore);

      return transactions;
    },
    interpolateValues(startDate, endDate, startValue, endValue) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffYear = end.getFullYear() - start.getFullYear();
      const diffMonth = end.getMonth() - start.getMonth();
      const monthDiff = diffYear * 12 + diffMonth;
      let interpolatedValues = [];

      for (let i = 0; i <= monthDiff; i++) {
        const date = new Date(start.getFullYear(), start.getMonth() + i, 1);
        const interpolatedValue = startValue + (endValue - startValue) * (i / monthDiff);
        interpolatedValues.push({
          date: date.toISOString().split('T')[0], // format YYYY-MM-DD
          value: Math.round(interpolatedValue)
        });
      }
      return interpolatedValues;
    },
    async fetchInvestments(access_token, user_id) {
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/get_investments', {
          access_token,
        });

        console.log('Investments Response:', response.data);

        const { accounts, holdings } = response.data.holdings;

        if (!Array.isArray(accounts) || !Array.isArray(holdings)) {
          throw new Error("Invalid data structure for accounts or holdings");
        }

        const assetsLiabilitiesMapRef = doc(db, `users/${user_id}/mappings`, 'assetsLiabilitiesMap');
        const mapSnapshot = await getDoc(assetsLiabilitiesMapRef);
        const assetsLiabilitiesMap = mapSnapshot.exists() ? mapSnapshot.data() : {};

        const currentDate = new Date().toISOString().split('T')[0];

        for (const account of accounts) {
          const accountHoldings = holdings.filter(holding => holding.account_id === account.account_id);
          let valueHistory = accountHoldings.map(holding => ({
            date: holding.institution_price_as_of,
            value: Number(holding.institution_value.toFixed(2))
          }));

          // Sort valueHistory by date to ensure correct order
          valueHistory.sort((a, b) => new Date(a.date) - new Date(b.date));

          let earliestDate = valueHistory.length > 0 ? valueHistory[0].date : currentDate;
          const startValue = valueHistory.length > 0 ? valueHistory[0].value : account.balances.current;
          const endValue = account.balances.current;

          // Interpolate missing values between earliestDate and currentDate
          const interpolatedValues = this.interpolateValues(earliestDate, currentDate, startValue, endValue);

          let assetClass = "🧩 Other Assets"; // Default class
          let accountType = "Other"; // Default type
          const accountSubtypeLower = account.subtype.toLowerCase();
          const accountTypeLower = account.type.toLowerCase();
          for (const [classKey, classDetails] of Object.entries(assetsLiabilitiesMap)) {
            if (classDetails.types.map(value => value.toLowerCase()).includes(accountSubtypeLower) ||
                classDetails.types.map(value => value.toLowerCase()).includes(accountTypeLower)) {
              assetClass = classKey;
              accountType = classDetails.accountType || "Other";
              break;
            }
          }

          const accountData = {
            ...account,
            purchaseDate: earliestDate,
            currentValue: account.balances.current,
            holdings: accountHoldings,
            valueHistory: interpolatedValues,
            assetClass: assetClass,
            accountType: accountType,
          };

          let collectionRef;
          if (accountType === 'Asset') {
            collectionRef = collection(db, `users/${user_id}/assets`);
          } else if (accountType === 'Liability') {
            collectionRef = collection(db, `users/${user_id}/liabilities`);
            accountData.currentValue = -Math.abs(accountData.currentValue);
            accountData.valueHistory = accountData.valueHistory.map(vh => ({
              ...vh,
              value: -Math.abs(vh.value)
            }));
          } else {
            console.error('Unknown account type:', accountType);
            continue;
          }

          const matchingAccountsQuery = query(collectionRef, where("mask", "==", account.mask), where("name", "==", account.name));
          const querySnapshot = await getDocs(matchingAccountsQuery);
          if (querySnapshot.empty) {
            await addDoc(collectionRef, accountData);
          } else {
            const docRef = querySnapshot.docs[0].ref;
            await setDoc(docRef, accountData, { merge: true });
          }
        }

      } catch (error) {
        console.error('Error fetching investments:', error);
      }
    },

    async fetchAccounts(access_token) {
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/accounts_get', {
          access_token,
        });
        return response.data.accounts; // Return the accounts array
      } catch (error) {
        console.error('Error fetching accounts:', error);
        throw error;
      }
    },
    async processAccount(account, user_id) {
      const userAccountsRef = collection(db, `users/${user_id}/accounts`);

      // Query Firestore for an existing account by account_id, mask, and official_name
      const querySnapshot = await getDocs(query(userAccountsRef, where("mask", "==", account.mask), where("official_name", "==", account.official_name)));
      
      let existingAccountRef = null;

      // Check if any document matches either account_id or both mask and official_name
      querySnapshot.forEach((doc) => {
        if (doc.id === account.account_id || (doc.data().mask === account.mask && doc.data().official_name === account.official_name)) {
          existingAccountRef = doc.ref;
        }
      });

      if (existingAccountRef) {
        // Update the existing account if it exists and fields are different
        const existingAccountData = (await getDoc(existingAccountRef)).data();
        if (JSON.stringify(account) !== JSON.stringify(existingAccountData)) {
          await setDoc(existingAccountRef, account, { merge: true });
        }
      } else {
        // Add the account if it does not exist
        const newAccountRef = doc(userAccountsRef, account.account_id);
        await setDoc(newAccountRef, account);
      }
    },

  }
};
</script>

<style>
.plaid-button {
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important;
  width: max-content;
  padding: 7.5px 12px;
  background: white;
  height: 35px;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
  margin: 0 4px !important;
  font-size: .75rem !important;
  color: black;
}

.plaid-button:hover {
  background-color: #666766;
  color: white;
  transition: 0.3s ease-in-out;
}

.loader-container {
  display: flex;
  background-color: #000000;
  opacity: 40%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full height of the viewport */
  width: 100vw; /* Full width of the viewport */
  position: fixed; /* So that it doesn't affect other elements */
  top: 0;
  left: 0;
}

.loader {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border-top: 6px solid #9ec9ff;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}
</style>