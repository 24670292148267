<template>
   <div :class="{ 'light-mode': lightMode }">

    <TheHeader @toggle-light-mode="toggleLightMode" :class="{ 'hidden': isLoading }" />
      <div class="container">

        <v-btn-toggle v-model="selectedForm" mandatory style="border: solid thin lightgrey;">
          <v-btn value="register">Register</v-btn>
          <v-btn value="login">Login</v-btn>
        </v-btn-toggle>
        <RegistrationForm v-if="selectedForm === 'register'" />
        <LoginForm v-if="selectedForm === 'login'" @loading-state-changed="updateLoadingState" />
        <TheFooter class="footer-container" :lightMode="lightMode" :class="{ 'hidden': isLoading }"></TheFooter>
      </div>
    </div>
</template>

<script>
import RegistrationForm from '../components/RegistrationForm.vue';
import LoginForm from '../components/LoginForm.vue';
import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'


export default {
  components: {
    RegistrationForm,
    LoginForm,
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      selectedForm: 'register', // Default selected form
      lightMode: false,
      isLoading: false,
    };
  },
  methods: {
    toggleLightMode() {
      this.lightMode = !this.lightMode;
    },
    updateLoadingState(isLoading) {
      this.isLoading = isLoading;
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100vw;
}






.container {
  background-color: black;
  color: white;
  text-align: left;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.container a {
  color: white;
}

.light-mode .container {
  background-color: white;
  color: black;
}


.light-mode .container a {
  color: black;
}

.light-mode button  {
  color: black;
}


</style>