<template>
  <div class="registration-container">
    <form class="registration-form" @submit.prevent="registerUser">
      <div class="form-group">
        <v-text-field
          v-model="name"
          label="Name"
          required
        ></v-text-field>
      </div>
      <p class="error" v-if="birthdateError">{{ birthdateError }}</p>
      <div class="form-group">
        <v-text-field
          style="color:grey"
          v-model="birthdate"
          label="Birthdate"
          type="date"
          required
        ></v-text-field>
      </div>
      <p class="error" v-if="errorMessage">{{ errorMessage }}</p>
      <div class="form-group">
        <v-text-field
          v-model="email"
          label="Email"
          type="email"
          required
        ></v-text-field>
      </div>
      <p class="error" v-if="passwordError">{{ passwordError }}</p>
      <div class="form-group">
        <v-text-field
          v-model="password"
          label="Password"
          type="password"
          required
        ></v-text-field>
      </div>
      <div class="terms-group">
        <a href="/terms-of-service" target="_blank" class="terms-link">I agree to the Terms & Conditions</a>
        <v-checkbox
          v-model="termsAccepted"
          color="#3e9bff"
          hide-details
        ></v-checkbox>

      </div>
      <div style="display: inline-flex; gap: 10px;">
        <div class="form-group">
          <v-btn style="box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important; color: black" type="submit" :disabled="!termsAccepted">Register</v-btn>
        </div>
        <v-btn style="box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important; color: black" type="button" :disabled="!termsAccepted" @click="signInWithGoogle">Sign up with Google</v-btn>
      </div>
      <div v-if="loading" class="login-loading-screen">
        <div style="display: inline-block">
          <transition name="fade" mode="out-in">
            <p :key="loadingText">{{ loadingText }}<span class="dots"></span></p>
          </transition>
          <div class="loader-container-login">
            <div class="dot"></div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { doc, setDoc, writeBatch } from 'firebase/firestore';
import { auth, db } from '../firebaseInit'; // Import the initialized Firebase auth instance


export default {
  data() {
    return {
      name: '',
      email: '',
      password: '',
      birthdate: '',
      errorMessage: '',
      passwordError: '',
      birthdateError: '',
      termsAccepted: false,
      loading: false,
      loadingTextList: ['Creating your account...', 'Setting up your profile...', 'Almost there...'],
      currentTextIndex: 0,
    };
  },
  computed: {
    loadingText() {
      return this.loadingTextList[this.currentTextIndex];
    },
  },
  methods: {

    async registerUser() {
      this.loading = true;
      this.$emit('loading-state-changed', true);
      this.startLoadingTextTransition();
      try {
        const { email, password, name, birthdate } = this;

        // Some error handling for birthdate. Adjust as needed for your use case.
        if (!birthdate) throw { code: 'auth/invalid-birthdate', message: 'Invalid birthdate.' };

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const userId = userCredential.user.uid;

        try {
          await userCredential.user.getIdToken(true);

          const docRef = doc(db, 'users', userId);
          await setDoc(docRef, { name, email, birthdate });
          await this.addDefaultCategories(userId);
          await this.addDefaultAssetClasses(userId);
          await this.addDefaultLiabilityClasses(userId);
          await this.addCategoryMap(userId); 
          await this.addAssetsLiabilitiesMap(userId); 
          await this.initializeSpendingTotals(userId);
          await this.addDefaultLabels(userId);
          // Fetch the default categories and add them to the Vuex store
          await this.$store.dispatch('fetchCategories');

          this.$router.push({ name: 'Subscription' });
          this.$emit('loading-state-changed', false);
        } catch (error) {
          // Delete the authenticated user before throwing the error
          this.errorMessage = error.message;
          this.loading = false;
          this.$emit('loading-state-changed', false);
          await userCredential.user.delete();
          throw error;
        }
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          this.errorMessage = 'Email is already in use.';
        } else if (error.code === 'auth/weak-password') {
          this.passwordError = 'Password should be at least 6 characters.';
        } else if (error.code === 'auth/invalid-birthdate') {
          this.birthdateError = 'Invalid birthdate.';
        } else {
          console.error('Error registering user:', error);
        }
      }
    },
    async signInWithGoogle() {
      const provider = new GoogleAuthProvider();
      try {
        const result = await signInWithPopup(auth, provider);
        if (result.additionalUserInfo && result.additionalUserInfo.isNewUser) {
          const user = result.user;
          const userId = user.uid;

          await this.initializeSpendingTotals(userId);
          await this.addDefaultLabels(userId);
          await this.addDefaultCategories(userId);
          await this.addDefaultAssetClasses(userId);
          await this.addDefaultLiabilityClasses(userId);
          await this.addCategoryMap(userId);
          await this.addAssetsLiabilitiesMap(userId);

          const docRef = doc(db, 'users', userId);
          await setDoc(docRef, { name: user.displayName || '', email: user.email, birthdate: '' });

          this.$router.push({ name: 'Onboarding' });
        } else {
          this.$router.push({ name: 'Spending' });
        }
      } catch (error) {
        this.errorMessage = error.message;
      }
    },
    startLoadingTextTransition() {
      const transitionDuration = 3000; // 3 seconds
      const totalDuration = transitionDuration * this.loadingTextList.length;

      const timer = setInterval(() => {
        this.currentTextIndex = (this.currentTextIndex + 1) % this.loadingTextList.length;
      }, transitionDuration);

      setTimeout(() => {
        clearInterval(timer);
      }, totalDuration);
    },
    async initializeSpendingTotals(userId) {
      const docRef = doc(db, `users/${userId}/totals`, 'spendingTotals');
      await setDoc(docRef, {
        averageDailySpend: 0,
        totalNumberOfTransactions: 0
      });
    },
    async addDefaultLabels(userId) {
      const labels = [
        { name: 'review' },
        { name: 'ignore' }
      ];
      const batch = writeBatch(db);

      labels.forEach(label => {
        const docRef = doc(db, `users/${userId}/labels`, label.name);
        batch.set(docRef, label); // Sets each label as a separate document
      });

      return batch.commit();
    },
    async addDefaultCategories(userId) {
      const defaultCategories = [
        { name: 'Eating Out', emoji: '🍔', group: 'Food & Drink', order: 1 },
        { name: 'Groceries', emoji: '🍎', group: 'Food & Drink', order: 2 },
        { name: 'Housewares', emoji: '🍽️', group: 'Home', order: 3 },
        { name: 'Home Improvement', emoji: '🏚️', group: 'Home', order: 4 },
        { name: 'Furniture', emoji: '🛋️', group: 'Home', order: 5 },
        { name: 'Coffee & Tea', emoji: '☕', group: 'Food & Drink', order: 6 },
        { name: 'Self Care', emoji: '💆‍♀️', group: 'Personal Care & Wellness', order: 7 },
        { name: 'Online Services', emoji: '💻', group: 'Online & Digital Services', order: 8 },
        { name: 'Gifts', emoji: '🎁', group: 'Shopping & Entertainment', order: 9 },
        { name: 'Clothing', emoji: '👕', group: 'Shopping & Entertainment', order: 10 },
        { name: 'Entertainment', emoji: '🎬', group: 'Shopping & Entertainment', order: 11 },
        { name: 'Charity', emoji: '❤️', group: 'Charitable Giving', order: 12 },
        { name: 'Church', emoji: '⛪️', group: 'Charitable Giving', order: 13 },
        { name: 'Pets', emoji: '🐶', group: 'Family & Pets', order: 14 },
        { name: 'Gym & Fitness', emoji: '🏋️‍♀️', group: 'Personal Care & Wellness', order: 15 },
        { name: 'Auto Payment', emoji: '🚗', group: 'Transportation & Auto', order: 16 },
        { name: 'Loan Interest', emoji: '💰', group: 'Finance & Loans', order: 17 },
        { name: 'Hobbies & Recreation', emoji: '🎨', group: 'Shopping & Entertainment', order: 18 },
        { name: 'Gas', emoji: '⛽', group: 'Transportation & Auto', order: 19 },
        { name: 'Medical', emoji: '🏥', group: 'Personal Care & Wellness', order: 20 },
        { name: 'Dentist', emoji: '🦷', group: 'Personal Care & Wellness', order: 21 },
        { name: 'Auto Maintenance', emoji: '🔧', group: 'Transportation & Auto', order: 22 },
        { name: 'Parking & Tolls', emoji: '🅿️', group: 'Transportation & Auto', order: 23 },
        { name: 'Phone', emoji: '☎️', group: 'Utilities', order: 24 },
        { name: 'Electric', emoji: '💡', group: 'Utilities', order: 25 },
        { name: 'Mortgage', emoji: '🏡', group: 'Housing', order: 26 },
        { name: 'Rent', emoji: '🏘️', group: 'Housing', order: 27 },
        { name: 'Internet', emoji: '💻', group: 'Utilities', order: 28 },
        { name: 'Water', emoji: '💧', group: 'Utilities', order: 29 },
        { name: 'Natural Gas', emoji: '🔥', group: 'Utilities', order: 30 },
        { name: 'Garbage', emoji: '🗑️', group: 'Utilities', order: 31 },
        { name: 'Insurance', emoji: '📄', group: 'Finance & Loans', order: 32 },
        { name: 'Car Rental', emoji: '🚗', group: 'Transportation & Auto', order: 33 },
        { name: 'Transportation', emoji: '🚋', group: 'Transportation & Auto', order: 34 },
        { name: 'Hotels & Lodging', emoji: '🏨', group: 'Travel & Leisure', order: 35 },
        { name: 'Flights', emoji: '✈️', group: 'Travel & Leisure', order: 36 },
        { name: 'Books', emoji: '📚', group: 'Shopping & Entertainment', order: 37 },
        { name: 'Flowers', emoji: '💐', group: 'Shopping & Entertainment', order: 38 },
        { name: 'Taxes', emoji: '💰', group: 'Finance & Loans', order: 39 },
        { name: 'Wedding', emoji: '💍', group: 'Special Events', order: 40 },
        { name: 'Child Care', emoji: '👶', group: 'Family & Pets', order: 41 },
        { name: 'Child Activities', emoji: '🎈', group: 'Family & Pets', order: 42 },
        { name: 'Student Loans', emoji: '🎓', group: 'Finance & Loans', order: 43 },
        { name: 'Loan Repayment', emoji: '💰', group: 'Finance & Loans', order: 44 },
        { name: 'Financial & Legal Services', emoji: '👨‍💼', group: 'Finance & Loans', order: 45 },
        { name: 'Financial Fees', emoji: '💰', group: 'Finance & Loans', order: 46 },
        { name: 'Cash & ATM', emoji: '💵', group: 'Finance & Loans', order: 47 },
        { name: 'Uncategorized', emoji: '❓', group: 'Transfers & Adjustments', order: 48 },
        { name: 'Miscellaneous', emoji: '🎲', group: 'Shopping & Entertainment', order: 49 },
        { name: 'Advertising & Promotion', emoji: '📣', group: 'Business Expenses', order: 50 },
        { name: 'Business Utilities', emoji: '💡', group: 'Business Expenses', order: 51 },
        { name: 'Employee Wages & Labor', emoji: '💸', group: 'Business Expenses', order: 52 },
        { name: 'Business Travel', emoji: '✈️', group: 'Business Expenses', order: 53 },
        { name: 'Business Meals', emoji: '🍽️', group: 'Business Expenses', order: 54 },
        { name: 'Business Auto Expenses', emoji: '🚚', group: 'Business Expenses', order: 55 },
        { name: 'Business Insurance', emoji: '📄', group: 'Business Expenses', order: 56 },
        { name: 'Office Supplies & Expenses', emoji: '🖇️', group: 'Business Expenses', order: 57 },
        { name: 'Office Rent', emoji: '🏢', group: 'Business Expenses', order: 58 },
        { name: 'Postage & Shipping', emoji: '📦', group: 'Business Expenses', order: 59 },
        { name: 'Business Fees', emoji: '💰', group: 'Business Expenses', order: 60 },
        { name: 'Transfer', emoji: '🔁', group: 'Transfers & Adjustments', order: 61 },
        { name: 'Credit Card Payment', emoji: '💳', group: 'Finance & Loans', order: 62 },
        { name: 'Education', emoji: '🔬', group: 'Shopping & Entertainment', order: 63 },
        { name: 'Investments', emoji: '📈', group: 'Shopping & Entertainment', order: 64 },
        { name: 'Payroll Income', emoji: '💰', group: 'Income', order: 65 },
        { name: 'Other Income', emoji: '💰', group: 'Income', order: 66 },
        { name: 'Incoming Transfer & Deposits', emoji: '💰', group: 'Income', order: 67 },
        { name: 'Rental Income', emoji: '💰', group: 'Income', order: 68 },
        { name: 'Business Income', emoji: '💰', group: 'Income', order: 69 },
      ]
      const batch = writeBatch(db);

      defaultCategories.forEach((category) => {
        const docRef = doc(db, `users/${userId}/categories`, category.name);
        batch.set(docRef, category); // Now category is an object with a name and emoji property
      });

      return batch.commit();
    },
    async addDefaultAssetClasses(userId) {
      const defaultAssetClasses = [
        '💵 Cash', 
        '📈 Stocks', 
        '🏦 Bonds', 
        '🏡 Real Estate', 
        '📦 Personal Property', 
        '💼 Retirement', 
        '🏭 Business Interests', 
        '💎 Precious Metals', 
        '💍 Jewelry',
        '🖼️ Collectibles', 
        '🪙 Cryptocurrency', 
        '🏎️ Vehicles',
        '🧩 Other'
      ].map((assetClass, index) => ({ name: assetClass, order: index + 1 }));

      const batch = writeBatch(db);

      defaultAssetClasses.forEach((assetClass) => {
        const docRef = doc(db, `users/${userId}/assetclasses`, assetClass.name);
        batch.set(docRef, assetClass); // Now assetClass is an object with a name property
      });

      return batch.commit();
    },
    
    async addDefaultLiabilityClasses(userId) {
      const defaultLiabilityClasses = [
        '💳 Credit Card Debt',
        '🏠 Mortgage',
        '🏗️ Construction Loans',
        '🏠 Home Equity Line of Credit (HELOC)',
        '🚗 Vehicle Loans',
        '💼 Retirement Loan',
        '🏢 Business Loans',
        '📉 Stock Margin Loans',
        '📑 Bond Financing',
        '🔗 Secured Loans',
        '🌐 Cryptocurrency Debt',
        '💰 Personal Loans',
        '📚 Educational Loans',
        '🛍️ Consumer Loans',
        '🔗 Line of Credit',
        '🧩 Other Liabilities',
      ].map((liabilityClass, index) => ({ name: liabilityClass, order: index + 1 }));

      const batch = writeBatch(db);

      defaultLiabilityClasses.forEach((liabilityClass) => {
        const docRef = doc(db, `users/${userId}/liabilityclasses`, liabilityClass.name);
        batch.set(docRef, liabilityClass); // 
      });

      return batch.commit();
    },

    async addCategoryMap(userId) {
      const categoryMap = {
        "INCOME_DIVIDENDS": "Other Income",
        "INCOME_INTEREST_EARNED": "Other Income",
        "INCOME_RETIREMENT_PENSION": "Other Income",
        "INCOME_TAX_REFUND": "Other Income",
        "INCOME_UNEMPLOYMENT": "Other Income",
        "INCOME_WAGES": "Payroll Income",
        "INCOME_OTHER_INCOME": "Other Income",
        "TRANSFER_IN_CASH_ADVANCES_AND_LOANS": "Incoming Transfer & Deposits",
        "TRANSFER_IN_DEPOSIT": "Incoming Transfer & Deposits",
        "TRANSFER_IN_INVESTMENT_AND_RETIREMENT_FUNDS": "Incoming Transfer & Deposits",
        "TRANSFER_IN_SAVINGS": "Incoming Transfer & Deposits",
        "TRANSFER_IN_ACCOUNT_TRANSFER": "Incoming Transfer & Deposits",
        "TRANSFER_IN_OTHER_TRANSFER_IN": "Incoming Transfer & Deposits",
        "TRANSFER_OUT_INVESTMENT_AND_RETIREMENT_FUNDS": "Investments",
        "TRANSFER_OUT_SAVINGS": "Transfer",
        "TRANSFER_OUT_WITHDRAWAL": "Cash & ATM",
        "TRANSFER_OUT_ACCOUNT_TRANSFER": "Transfer",
        "TRANSFER_OUT_OTHER_TRANSFER_OUT": "Transfer",
        "LOAN_PAYMENTS_CAR_PAYMENT": "Auto Payment",
        "LOAN_PAYMENTS_CREDIT_CARD_PAYMENT": "Credit Card Payment",
        "LOAN_PAYMENTS_PERSONAL_LOAN_PAYMENT": "Loan Repayment",
        "LOAN_PAYMENTS_MORTGAGE_PAYMENT": "Mortgage",
        "LOAN_PAYMENTS_STUDENT_LOAN_PAYMENT": "Student Loans",
        "LOAN_PAYMENTS_OTHER_PAYMENT": "Loan Repayment",
        "BANK_FEES_ATM_FEES": "Financial Fees",
        "BANK_FEES_FOREIGN_TRANSACTION_FEES": "Financial Fees",
        "BANK_FEES_INSUFFICIENT_FUNDS": "Financial Fees",
        "BANK_FEES_INTEREST_CHARGE": "Loan Interest",
        "BANK_FEES_OVERDRAFT_FEES": "Financial Fees",
        "BANK_FEES_OTHER_BANK_FEES": "Financial Fees",
        "ENTERTAINMENT_CASINOS_AND_GAMBLING": "Entertainment",
        "ENTERTAINMENT_MUSIC_AND_AUDIO": "Entertainment",
        "ENTERTAINMENT_SPORTING_EVENTS_AMUSEMENT_PARKS_AND_MUSEUMS": "Entertainment",
        "ENTERTAINMENT_TV_AND_MOVIES": "Entertainment",
        "ENTERTAINMENT_VIDEO_GAMES": "Entertainment",
        "ENTERTAINMENT_OTHER_ENTERTAINMENT": "Entertainment",
        "FOOD_AND_DRINK_BEER_WINE_AND_LIQUOR": "Eating Out",
        "FOOD_AND_DRINK_COFFEE": "Coffee & Tea",
        "FOOD_AND_DRINK_FAST_FOOD": "Eating Out",
        "FOOD_AND_DRINK_GROCERIES": "Groceries",
        "FOOD_AND_DRINK_RESTAURANT": "Eating Out",
        "FOOD_AND_DRINK_VENDING_MACHINES": "Eating Out",
        "FOOD_AND_DRINK_OTHER_FOOD_AND_DRINK": "Eating Out",
        "GENERAL_MERCHANDISE_BOOKSTORES_AND_NEWSSTANDS": "Books",
        "GENERAL_MERCHANDISE_CLOTHING_AND_ACCESSORIES": "Clothing",
        "GENERAL_MERCHANDISE_CONVENIENCE_STORES": "Miscellaneous",
        "GENERAL_MERCHANDISE_DEPARTMENT_STORES": "Miscellaneous",
        "GENERAL_MERCHANDISE_DISCOUNT_STORES": "Miscellaneous",
        "GENERAL_MERCHANDISE_ELECTRONICS": "Miscellaneous",
        "GENERAL_MERCHANDISE_GIFTS_AND_NOVELTIES": "Gifts",
        "GENERAL_MERCHANDISE_OFFICE_SUPPLIES": "Office Supplies & Expenses",
        "GENERAL_MERCHANDISE_ONLINE_MARKETPLACES": "Online Services",
        "GENERAL_MERCHANDISE_PET_SUPPLIES": "Pets",
        "GENERAL_MERCHANDISE_SPORTING_GOODS": "Hobbies & Recreation",
        "GENERAL_MERCHANDISE_SUPERSTORES": "Miscellaneous",
        "GENERAL_MERCHANDISE_TOBACCO_AND_VAPE": "Miscellaneous",
        "GENERAL_MERCHANDISE_OTHER_GENERAL_MERCHANDISE": "Miscellaneous",
        "HOME_IMPROVEMENT_FURNITURE": "Furniture",
        "HOME_IMPROVEMENT_HARDWARE": "Home Improvement",
        "HOME_IMPROVEMENT_REPAIR_AND_MAINTENANCE": "Home Improvement",
        "HOME_IMPROVEMENT_SECURITY": "Home Improvement",
        "HOME_IMPROVEMENT_OTHER_HOME_IMPROVEMENT": "Home Improvement",
        "MEDICAL_DENTAL_CARE": "Dentist",
        "MEDICAL_EYE_CARE": "Medical",
        "MEDICAL_NURSING_CARE": "Medical",
        "MEDICAL_PHARMACIES_AND_SUPPLEMENTS": "Medical",
        "MEDICAL_PRIMARY_CARE": "Medical",
        "MEDICAL_VETERINARY_SERVICES": "Pets",
        "MEDICAL_OTHER_MEDICAL": "Medical",
        "PERSONAL_CARE_GYMS_AND_FITNESS_CENTERS": "Gym & Fitness",
        "PERSONAL_CARE_HAIR_AND_BEAUTY": "Self Care",
        "PERSONAL_CARE_LAUNDRY_AND_DRY_CLEANING": "Self Care",
        "PERSONAL_CARE_OTHER_PERSONAL_CARE": "Self Care",
        "GENERAL_SERVICES_ACCOUNTING_AND_FINANCIAL_PLANNING": "Financial & Legal Services",
        "GENERAL_SERVICES_AUTOMOTIVE": "Auto Maintenance",
        "GENERAL_SERVICES_CHILDCARE": "Child Care",
        "GENERAL_SERVICES_CONSULTING_AND_LEGAL": "Financial & Legal Services",
        "GENERAL_SERVICES_EDUCATION": "Education",
        "GENERAL_SERVICES_INSURANCE": "Insurance",
        "GENERAL_SERVICES_POSTAGE_AND_SHIPPING": "Postage & Shipping",
        "GENERAL_SERVICES_STORAGE": "Miscellaneous",
        "GENERAL_SERVICES_OTHER_GENERAL_SERVICES": "Miscellaneous",
        "GOVERNMENT_AND_NON_PROFIT_DONATIONS": "Charity",
        "GOVERNMENT_AND_NON_PROFIT_GOVERNMENT_DEPARTMENTS_AND_AGENCIES": "Taxes",
        "GOVERNMENT_AND_NON_PROFIT_TAX_PAYMENT": "Taxes",
        "GOVERNMENT_AND_NON_PROFIT_OTHER_GOVERNMENT_AND_NON_PROFIT": "Miscellaneous",
        "TRANSPORTATION_BIKES_AND_SCOOTERS": "Transportation",
        "TRANSPORTATION_GAS": "Gas",
        "TRANSPORTATION_PARKING": "Parking & Tolls",
        "TRANSPORTATION_PUBLIC_TRANSIT": "Transportation",
        "TRANSPORTATION_TAXIS_AND_RIDE_SHARES": "Transportation",
        "TRANSPORTATION_TOLLS": "Parking & Tolls",
        "TRANSPORTATION_OTHER_TRANSPORTATION": "Transportation",
        "TRAVEL_FLIGHTS": "Flights",
        "TRAVEL_LODGING": "Hotels & Lodging",
        "TRAVEL_RENTAL_CARS": "Car Rental",
        "TRAVEL_OTHER_TRAVEL": "Travel",
        "RENT_AND_UTILITIES_GAS_AND_ELECTRICITY": "Electric",
        "RENT_AND_UTILITIES_INTERNET_AND_CABLE": "Internet",
        "RENT_AND_UTILITIES_RENT": "Rent",
        "RENT_AND_UTILITIES_SEWAGE_AND_WASTE_MANAGEMENT": "Garbage",
        "RENT_AND_UTILITIES_TELEPHONE": "Phone",
        "RENT_AND_UTILITIES_WATER": "Water",
        "RENT_AND_UTILITIES_OTHER_UTILITIES": "Natural Gas"
    };
      const docRef = doc(db, `users/${userId}/mappings`, 'categoryMap');
      await setDoc(docRef, categoryMap);
    },
    async addAssetsLiabilitiesMap(userId) {
      const assetsLiabilitiesMap = {
        "💵 Cash": {
          types: ["Checking", "Savings", "HSA", "CD", "Money Market", "PayPal Depository Account", "Prepaid Debit Card", "Cash Management", "EBT"],
          accountType: "Asset"
        },
        "📈 Stocks": {
          types: ["Brokerage", "Mutual Fund", "Stock Plan", "Non-taxable Brokerage Account"],
          accountType: "Asset"
        },
        "🏦 Bonds": {
          types: ["Mutual Fund"], // If the fund's nature is bond-oriented
          accountType: "Asset"
        },
        "🏡 Real Estate": {
          types: [],
          accountType: "Asset"
        },
        "📦 Personal Property": {
          types: [],
          accountType: "Asset"
        },
        "💼 Retirement": {
          types: ["529", "401a", "401k", "403B", "457b", "IRA", "Roth IRA", "Roth 401k", "SARSEP", "SEP IRA", "SIMPLE IRA", "SIPP", "TFSA", "RRSP", "RRIF", "PRIF", "LRSP", "RLIF", "RDSP", "RESP", "Pension", "Profit Sharing Plan", "Education Savings Account", "Fixed Annuity", "Health Reimbursement Arrangement", "HSA (non-cash)", "Keogh", "LIF", "Life Insurance", "LIRA", "LRIF", "Other Annuity", "Other Insurance", "QSHR", "Trust", "UGMA", "UTMA", "Variable Annuity"],
          accountType: "Asset"
        },
        "🏭 Business Interests": {
          types: [],
          accountType: "Asset"
        },
        "💎 Precious Metals": {
          types: [],
          accountType: "Asset"
        },
        "💍 Jewelry": {
          types: [],
          accountType: "Asset"
        },
        "🖼️ Collectibles": {
          types: [],
          accountType: "Asset"
        },
        "🪙 Cryptocurrency": {
          types: ["Crypto Exchange", "Non-custodial Wallet"],
          accountType: "Asset"
        },
        "🏎️ Vehicles": {
          types: [],
          accountType: "Asset"
        },
        "🧩 Other Assets": {
          types: [],
          accountType: "Asset"
        },
        "💳 Credit Card Debt": {
          types: ["Credit Card", "PayPal-issued Credit Card"],
          accountType: "Liability"
        },
        "🏠 Mortgage": {
          types: ["Mortgage", "Mortgage Loan"],
          accountType: "Liability"
        },
        "🏗️ Construction Loans": {
          types: ["Construction", "Construction Loan"],
          accountType: "Liability"
        },
        "🏠 Home Equity Line of Credit (HELOC)": {
          types: ["Home Equity Line of Credit (HELOC)", "Home Equity Line of Credit", "HELOC", "(HELOC)"],
          accountType: "Liability"
        },
        "🚗 Vehicle Loans": {
          types: ["Auto", "Auto Loan"],
          accountType: "Liability"
        },
        "💼 Retirement Loan": {
          types: [],
          accountType: "Liability"
        },
        "🏢 Business Loans": {
          types: ["Business", "BusinessLoan", "Commercial", "Commercial Loan"],
          accountType: "Liability"
        },
        "📉 Stock Margin Loans": {
          types: [],
          accountType: "Liability"
        },
        "📑 Bond Financing": {
          types: [],
          accountType: "Liability"
        },
        "🔗 Secured Loans": {
          types: ["Overdraft", "Secured Line of Credit", "Line of Credit (Secured)"],
          accountType: "Liability"
        },
        "🌐 Cryptocurrency Debt": {
          types: [],
          accountType: "Liability"
        },
        "💰 Personal Loans": {
          types: ["General", "General Loan"],
          accountType: "Liability"
        },
        "📚 Educational Loans": {
          types: ["Student", "Student Loan"],
          accountType: "Liability"
        },
        "🛍️ Consumer Loans": {
          types: ["Consumer", "Consumer Loan"],
          accountType: "Liability"
        },
        "🔗 Line of Credit": {
          types: ["Overdraft", "Line of Credit", "Unsecured Line of Credit", "Line of Credit (Unsecured)"],
          accountType: "Liability"
        },
        "🧩 Other Liabilities": {
          types: ["Other"],
          accountType: "Liability"
        }
      };

      const docRef = doc(db, `users/${userId}/mappings`, 'assetsLiabilitiesMap');
      await setDoc(docRef, assetsLiabilitiesMap);
    },
  },
};
</script>



<style scoped>

.error {
  font-size: 12px;
  color: red;
  margin-bottom: 0;
  text-align: right;
}
.registration-container {
  width: 600px;
  margin: 0 auto;
}

.registration-form {
  padding: 20px;
  border-radius: 5px;
}

.registration-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

label {
  flex: 1;
  font-weight: bold;
  margin-right: 10px;
  text-align: right;
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"] {
  flex: 2;
  width: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}


.terms-group {
  display: flex;
  align-items: center;
  margin: 15px;
}

.terms-group label {
  margin-left: 5px;
}

.terms-link {
  color: #3e9bff;
  text-decoration: none;
}


.login-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.login-loading-screen p {
  color: #59a7ff;
  font-size: 28px;
  font-weight: 600;
}


.loader-container-login {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  width: 78px;
  height: 78px;
  border-radius: 50%;
  border-top: 6px solid #9ec9ff;
  border-right: 6px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}






.loader-container-login {
  --uib-size: 75px;
  --uib-color: #83c4ff;
  --uib-speed: 2s;
  position: relative;
  margin: 0 auto;
  height: var(--uib-size);
  width: var(--uib-size);
  animation-delay: 4s; /* Add this line to delay the animation by 2 seconds */
}

.loader-container-login::before,
.loader-container-login::after,
.dot::before,
.dot::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: var(--uib-color);
  animation: pulse var(--uib-speed) linear infinite;
  transform: scale(0);
  opacity: 0;
  transition: background-color 0.3s ease;
}

.loader-container-login::after {
  animation-delay: calc(var(--uib-speed) / -4 + 4s); /* Add 2s to the existing delay */
}

.dot::before {
  animation-delay: calc(var(--uib-speed) * -0.5 + 2s); /* Add 2s to the existing delay */
}

.dot::after {
  animation-delay: calc(var(--uib-speed) * -0.75 + 2s); /* Add 2s to the existing delay */
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

  .tally-container {
      margin-bottom: 20px;
    }

    .tally {
      width: 80px;
      height: 100px;
      position: relative;
    }

    .tally-mark {
      position: absolute;
      width: 20px;
      height: 100px;
      background-color: #333;
      transform-origin: center;
      animation: drawTally .4s linear forwards;
      opacity: 0;
    }

    .tally-mark:nth-child(1) {
      left: 30px;
      animation-delay: 0s;
    }

    .tally-mark:nth-child(2) {
      left: 60px;
      animation-delay: 0.4s;

    }

    .tally-mark:nth-child(3) {
      left: 90px;
      animation-delay: 0.8s;
    }

    .tally-mark:nth-child(4) {
      left: 120px;
      animation-delay: 1.2s;
    }

    .tally-mark:nth-child(5) {
      width: 170px;
      height: 18px !important;
      top: 40px;
      transform: rotate(338deg);
      /* border-radius: 6px; */
      left: 0px;
      animation-delay: 1.6s;
    }

    @keyframes drawTally {
      0% {
        height: 0;
        opacity: 1;
      }
      100% {
        height: 100%;
        opacity: 1;
      }
    }

</style>