<template>
  <div class="spending-view parent">
    <div v-for="(notification, index) in notifications" :key="index" class="notification-container" :style="{ bottom: `${20 + 80 * index}px` }">
      <!-- <p class="notification-title">{{ notification.transactionName }}</p> -->
      <p class="notification-detail" v-html="notification.detailMessage"></p>
      <div class="notification-progress"></div>
      <button @click="openCreateRuleModal(notification)" class="create-rule-button">Create Rule</button>
    </div>

    <!-- Loading bar for bulk edit -->
    <div v-if="bulkEditing" class="bulk-modal-backdrop">
      <div class="bulk-modal-content">
        <div class="progress-bar-container">
          <div class="progress-bar" :style="{ width: bulkEditingProgressBarWidth }"></div>
        </div>
        <p>Editing transactions: {{ transactionsEdited }}/{{ totalTransactionsToBeEdited }} ({{ editingTransactionsProgressBarPercentage }}%)</p>
      </div>
    </div>

    <!-- Overlay modal for deleting transactions -->
    <div v-if="showDeleteConfirmation" class="overlay">
      <div  @click="showDeleteConfirmation = false"></div>
      <div class="modal-content" @click.stop>
        <p>Are you sure you want to delete this transaction?</p>
        <p style="color: red; font-size: 14px;">This action is permanent and cannot be undone.</p>
        <div style="display: flex; justify-content: center;">
          <v-btn color="red" class="delete-confirm-button" @click="confirmDeleteTransaction">Delete</v-btn>
          <v-btn class="cancel-button" @click="showDeleteConfirmation = false">Cancel</v-btn>
        </div>
      </div>
    </div>

    <!-- Overlay modal for editing duplicate or uncategorized transactions -->
    <div v-if="showTransactionEditModal" class="overlay" @click="showTransactionEditModal = false">
    <div class="review-transactions-modal-content" @click.stop>
      <v-btn-toggle
        v-model="reviewToggle"
        mandatory
        style="margin-bottom: 20px;"
      >
        <v-btn value="uncategorized" style="background-color: #f6f6f6;">Uncategorized</v-btn>
        <v-btn value="duplicates" style="background-color: #f6f6f6;">Potential Duplicates</v-btn>
      </v-btn-toggle>

      <div v-if="reviewToggle === 'uncategorized'">
        <p class="review-transactions-message" v-if="uncategorizedTransactions.length === 0">
          No transactions awaiting review 🎉<br><br>
        </p>
        <table v-else>
          <thead>
            <tr>
              <th>Date</th>
              <th>Name</th>
              <th style="text-align: right;">Category</th>
              <th style="text-align: right;">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="transaction in uncategorizedTransactions" :key="transaction.id">
              <td>
                {{ transaction.date }}
              </td>
              <td class="name-td" @click.stop>
                <input style="width: 100%;" v-if="!isTransactionBeingEdited(transaction.id, 'name')" :value="transaction.name" @focus="startEditing(transaction.id, 'name')" @input="transaction.name = $event.target.value" @blur="commitTransaction(transaction)" type="text" @keyup.enter="commitTransaction(transaction)" />
                <input v-else v-model="transaction.name" type="text" @blur="saveTransaction(transaction)" />
              </td>
              <td class="categories-td" @click.stop>
                <select 
                  class="categories-select"
                  id="categories" 
                  v-model="transaction.category" 
                  @focus="storePreviousCategory(transaction)" 
                  @change="checkCategory(transaction);" 
                  @blur="restoreCategory(transaction); 
                  saveTransaction(transaction);">
                  <option v-for="category in categories" :key="category.name" :value="category.name"> {{ category.emoji }}&nbsp;&nbsp;{{ category.name }}&nbsp;&nbsp; </option>
                </select>
              </td>
              <td class="amount-td" @click.stop>
                <input 
                  v-if="!isTransactionBeingEdited(transaction.id, 'amount')" 
                  :value="formatDisplayAmount(parseFloat(transaction.amount).toFixed(2))"
                  :class="{'green-amount': parseFloat(transaction.amount) < 0}" 
                  @focus="startEditing(transaction.id, 'amount')" 
                  @blur="updateTransactionAmount(transaction, $event.target.value)" 
                  @keyup.enter="updateTransactionAmount(transaction, $event.target.value)" 
                  type="text" 
                  :readonly="isTransactionBeingEdited(transaction.id, 'amount')" />
              </td>
              <td class="delete-td">
                <button class="delete-button" @click="startDeleteTransaction(transaction)"> x </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else-if="reviewToggle === 'duplicates'">
        <p class="review-transactions-message" v-if="potentialDuplicateTransactions.length === 0">
          Transactions with the same name, amount, and date will be automatically flagged as potential duplicates. You don't appear to have any currently.
        </p>
        <div v-else>
          <div v-for="(group, index) in groupedDuplicates" :key="index" class="duplicate-group">
            <table style="padding: 0;">
              <!-- <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th style="text-align: right;">Category</th>
                  <th style="text-align: right;">Amount</th>
                  <th></th>
                </tr>
              </thead> -->
              <tbody>
                <tr v-for="transaction in group" :key="transaction.id">
                  <td>
                    {{ transaction.date }}
                  </td>
                  <td class="name-td" @click.stop>
                    <input style="width: 100%;" v-if="!isTransactionBeingEdited(transaction.id, 'name')" :value="transaction.name" @focus="startEditing(transaction.id, 'name')" @input="transaction.name = $event.target.value" @blur="commitTransaction(transaction)" type="text" @keyup.enter="commitTransaction(transaction)" />
                    <input v-else v-model="transaction.name" type="text" @blur="saveTransaction(transaction)" />
                  </td>
                  <td class="categories-td" @click.stop>
                    <select 
                      class="categories-select"
                      id="categories" 
                      v-model="transaction.category" 
                      @focus="storePreviousCategory(transaction)" 
                      @change="checkCategory(transaction);" 
                      @blur="restoreCategory(transaction); 
                      saveTransaction(transaction);">
                      <option v-for="category in categories" :key="category.name" :value="category.name"> {{ category.emoji }}&nbsp;&nbsp;{{ category.name }}&nbsp;&nbsp; </option>
                    </select>
                  </td>
                  <td class="amount-td" @click.stop>
                    <input 
                      v-if="!isTransactionBeingEdited(transaction.id, 'amount')" 
                      :value="formatDisplayAmount(parseFloat(transaction.amount).toFixed(2))"
                      :class="{'green-amount': parseFloat(transaction.amount) < 0}" 
                      @focus="startEditing(transaction.id, 'amount')" 
                      @blur="updateTransactionAmount(transaction, $event.target.value)" 
                      @keyup.enter="updateTransactionAmount(transaction, $event.target.value)" 
                      type="text" 
                      :readonly="isTransactionBeingEdited(transaction.id, 'amount')" />
                  </td>
                  <td class="delete-td">
                    <button class="delete-button" @click="startDeleteTransaction(transaction)"> x </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <v-btn @click="markAsNotDuplicate(group)" class="not-duplicate-button">Mark as Not Duplicate</v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
    
    <!-- Overlay modal for creating a rule -->
    <div v-if="showCreateRuleModal" class="modal-backdrop" @click="showCreateRuleModal = false">
      <div class="transaction-rule-modal-content" @click.stop>
        <h2 style="padding-bottom: 25px">Create Transaction Rule</h2>
        <!-- Form for creating the rule -->
        <form @submit.prevent="createRule">
          <div class="transaction-rules-parent">
            <div class="match-conditions-group">
              <p>If transaction has:</p>
              
              <!-- Name Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNameExpanded = !isNameExpanded">
                  <span class="transaction-rule-title">Name</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="nameToggle" v-model="isNameExpanded" @click.stop>
                    <label for="nameToggle" class="slider" @click="isNameExpanded = !isNameExpanded"></label>
                  </div>
                </div>
                <input v-if="isNameExpanded" type="text" id="conditionName" v-model="conditionName">
                <select v-if="isNameExpanded" class="categories-select-rule" v-model="conditionNameMatch">
                  <option selected value="Exact Match">Exact Match</option>
                  <option value="Contains">Contains</option>
                </select>
              </div>

              <!-- Amount Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isAmountExpanded = !isAmountExpanded">
                  <span class="transaction-rule-title">Amount</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="amountToggle" v-model="isAmountExpanded" @click.stop>
                    <label for="amountToggle" class="slider" @click="isAmountExpanded = !isAmountExpanded"></label>
                  </div>
                </div>
                <input v-if="isAmountExpanded" type="number" id="conditionAmount" v-model="conditionAmount" step="0.01">
                <select v-if="isAmountExpanded" class="categories-select-rule" v-model="conditionAmountMatch" required>
                  <option selected value="Equals">Equals</option>
                  <option value="More than">More than</option>
                  <option value="Less than">Less than</option>
                  <option value="Within">Within</option> 
                </select>
                <div class="amount-range" v-if="isAmountExpanded && conditionAmountMatch === 'Within'">
                  <input type="number" placeholder="Minimum Amount" v-model="conditionAmountMin" step="0.01">
                  <input type="number" placeholder="Maximum Amount" v-model="conditionAmountMax" step="0.01">
                </div>
              </div>

              <!-- Category Group -->
              <div class="form-group">
                <div class="form-group-header"  @click="isCategoryExpanded = !isCategoryExpanded">
                  <span class="transaction-rule-title">Category</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="categoryToggle" v-model="isCategoryExpanded" @click.stop>
                    <label for="categoryToggle" class="slider" @click="isCategoryExpanded = !isCategoryExpanded"></label>
                  </div>
                </div>
                <select v-if="isCategoryExpanded" class="categories-select-rule" v-model="conditionCategory">
                  <option disabled :value="null">Category</option>
                  <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.emoji }} {{ category.name }}</option>
                </select>
              </div>
            </div>
            <div class="new-values-group">
              <p>Update to:</p>

              <!-- New Name Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewNameExpanded = !isNewNameExpanded">
                  <span class="transaction-rule-title">New Name</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newNameToggle" v-model="isNewNameExpanded" @click.stop>
                    <label for="newNameToggle" class="slider" @click="isNewNameExpanded = !isNewNameExpanded"></label>
                  </div>
                </div>
                <input v-if="isNewNameExpanded" type="text" id="newName" v-model="newName">
              </div>

              <!-- New Amount Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewAmountExpanded = !isNewAmountExpanded">
                  <span class="transaction-rule-title">New Amount</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newAmountToggle" v-model="isNewAmountExpanded" @click.stop>
                    <label for="newAmountToggle" class="slider" @click="isNewAmountExpanded = !isNewAmountExpanded"></label>
                  </div>
                </div>
                <input v-if="isNewAmountExpanded" type="number" id="newAmount" v-model="newAmount" step="0.01">
              </div>

              <!-- New Category Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewCategoryExpanded = !isNewCategoryExpanded">
                  <span class="transaction-rule-title">New Category</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newCategoryToggle" v-model="isNewCategoryExpanded" @click.stop>
                    <label for="newCategoryToggle" class="slider" @click="isNewCategoryExpanded = !isNewCategoryExpanded"></label>
                  </div>
                </div>
                <select v-if="isNewCategoryExpanded" class="categories-select-rule" v-model="newCategory">
                  <option disabled :value="null">Category</option>
                  <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.emoji }} {{ category.name }}</option>
                </select>
              </div>

              <!-- New Label Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewLabelExpanded = !isNewLabelExpanded">
                  <span class="transaction-rule-title">New Label</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newLabelToggle" v-model="isNewLabelExpanded" @click.stop>
                    <label for="newLabelToggle" class="slider" @click="isNewLabelExpanded = !isNewLabelExpanded"></label>
                  </div>
                </div>
                  <select class="label-dropdown-rule" v-if="isNewLabelExpanded" v-model="newLabel">
                    <option v-for="label in labels" :key="label" :value="label" class="label-item">{{ label }}</option>
                  </select>

              </div>
            </div>
          </div>
        </form>
        <!-- Loading bar for bulk edit -->
        <div v-if="bulkEditing" class="modal-backdrop">
          <div class="modal-content">
            <div class="progress-bar-container">
              <div class="progress-bar" :style="{ width: bulkEditingProgressBarWidth }"></div>
            </div>
            <p>Editing transactions: {{ transactionsEdited }}/{{ totalTransactionsToBeEdited }} ({{ editingTransactionsProgressBarPercentage }}%)</p>
          </div>
        </div>
        <!-- Loading bar for bulk delete -->
        <div v-if="bulkDeleting">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: bulkEditingProgressBarWidth }"></div>
          </div>
          <p>Deleting transactions: {{ transactionsEdited }}/{{ totalTransactionsToBeEdited }} ({{ editingTransactionsProgressBarPercentage }}%)</p>
        </div>
        
        <!-- Loading bar and transaction count -->
        <div v-if="applyingRules">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: transactionRulesProgressBarWidth }"></div>
          </div>
          <p>{{ transactionsUpdated }}/{{ totalTransactionsToBeUpdated }} ({{ transactionRulesProgressBarPercentage }}%)</p>
        </div>
        <div class="updated-transactions-list" @click="isTransactionsListExpanded = !isTransactionsListExpanded">
          
          <h3 v-if="filteredRuleTransactions.length > 0">
            {{ filteredRuleTransactions.length }} matching transactions found<br>
            <p v-if="!isTransactionsListExpanded" class="click-to-view">Click to view transactions to be updated</p>
          </h3>
          <h3 v-else>
            {{ filteredRuleTransactions.length }} matching transactions found<br>
          </h3>
          <transition name="fade">
            <ul v-if="isTransactionsListExpanded">
              <li v-for="transaction in filteredRuleTransactions" :key="transaction.id">
                {{ transaction.name }} - {{ transaction.date }} - {{ transaction.amount }} - {{ transaction.category }}
              </li>
            </ul>
          </transition>
        </div>
        <div class="form-actions">
          <v-btn class="create-rule-modal-button" @click="createRule">Create Rule</v-btn>

          <button @click="closeCreateRuleModal" id="close-modal-rule-button" class="close-modal-button">X</button>
        </div>
      </div>
    </div>

    <!-- Overlay modal for CSV Import -->
    <div v-if="showCSVModal" class="modal-backdrop">
      <div class="modal-content">
        <div v-for="(value, key) in columnMappings" :key="key" class="modal-field2">
          <label>{{ key.charAt(0).toUpperCase() + key.slice(1) }}:</label>
          <select v-if="key !== 'labels'" class="csv-column-map-select" v-model="columnMappings[key]" :class="{ 'error': errors[key] }">
            <option disabled value="">Select a column</option>
            <option v-for="(header, index) in csvHeaders" :value="index.toString()" :key="index">
              {{ header }}
            </option>
          </select>
          <select v-else class="csv-column-map-select" v-model="columnMappings[key]">
            <option value="">Select a column (optional)</option>
            <option v-for="(header, index) in csvHeaders" :value="index.toString()" :key="index">
              {{ header }}
            </option>
          </select>
          <div v-if="errors[key]" class="error-message">{{ errors[key] }}</div>
        </div>
        <!-- Loading bar and transaction count -->
        <div v-if="importing">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progressBarWidth }"></div>
          </div>
          <p>{{ transactionsImported }}/{{ totalTransactions }} ({{ progressBarPercentage }}%)</p>
        </div>
        <div class="csv-import-buttons">
          <!-- <v-btn v-if="!importing" @click="processCsv">Import</v-btn> -->
          <v-btn v-if="!importing" @click="validateAndImport" ref="importButton">Import</v-btn>
          <v-btn @click="showCSVModal = false; showCsvImport = false">Cancel</v-btn>
        </div>
      </div>
    </div>
    <div v-if="showCategoryMappingModal" class="modal-backdrop">
      <div class="modal-content" style="max-height: 80%; overflow: scroll;">
          <h2 style="margin-bottom: 20px;">Map CSV Categories</h2>
          <div v-for="(csvCategory, index) in csvCategories" :key="index" class="category-mapping">
            <label>{{ csvCategory }}</label>
            <select class="csv-select" v-model="categoryMappings[csvCategory]">
              <option value="">Select a category</option>
              <option v-for="category in categories" :key="category.name" :value="category.name">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="modal-actions">
            <v-btn @click="showCategoryMappingModal = false">Save</v-btn>
          </div>
        </div>
      </div>
    <div v-if="isModalOpen" class="modal-backdrop">
      <RecurringRulesModal :isOpen="isModalOpen" :rules="allRecurringRules" :userId="userId" :transaction="selectedRecurringTransaction" @close-modal="isModalOpen = false" @confirm-selection="selectRule" @create-new-rule="createRule" />
    </div>
    <div v-if="showPlaidModal" class="modal-backdrop">
      <div class="modal-content">
        <!-- Loading bar and transaction count -->
        <div v-if="importingPlaid">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: plaidProgressBarWidth }"></div>
          </div>
          <p>Importing Transactions: {{ plaidTransactionsImported }}/{{ totalPlaidTransactions }} ({{ plaidProgressBarPercentage }}%)</p>
        </div>
        <button @click="showPlaidModal = false">Cancel</button>
      </div>
    </div>
    <!-- <div class="spending-div1">
    </div> -->
    <div class="button-row1">
      <!-- <button class="filter-button" @click="toggleFilters">
        {{ showFilters ? 'Clear Filters' : 'Filter' }}
      </button> -->
      <!-- <PlaidLink /> hiding sync transactions button for now -->
      <v-btn style="width: 110px" class="create-rule-button2" @click="openCreateRuleModal()">Create Rule</v-btn>
      <v-btn style="width: 110px" class="export-csv-button" @click="exportTransactions">Export CSV</v-btn>
      <!-- CSV Import -->
      <v-btn style="width: 110px" class="import-csv-button" @click="openFileInput">Import CSV</v-btn>
      <v-btn style="width: 110px" class="import-csv-button" @click="identifyAndEditTransactions">Review</v-btn>
      <div class="csvimport" v-if="showCsvImport">
        <input type="file" ref="fileInput" @change="handleCsvFileChange" accept=".csv">
      </div>
    </div>
    <div class="sticky-wrapper">
      <div class="spending-wrapper">
        <div class="spending-div7">
          <div class="categoriesPie spending-div3">
            <SpendingPie 
            :sortedVuexTransactions="sortedVuexTransactions" 
            :date-option="selectedDateOption"
            />
          </div>
        </div>
        <div class="spending-div8">

          <div class="summary">

            <div class="summary-container">
              <h3 class="summary-title">Summary</h3>
              <h2 class="selected-date-summary">{{ summaryTitle }}</h2>
            </div>
            <p class="summary-details">
              <span class="title-bold">Transactions</span> {{ totalNumberOfTransactions }}
            </p>
            <p class="summary-details">
              <span class="title-bold">Spend Frequency</span> {{ frequencyText }}
            </p>
            <p class="summary-details">
              <span class="title-bold">Average Transaction</span> {{ formatCurrency(parseFloat(averageTransactionAmount)) }}
            </p>
            <p class="summary-details">
              <span class="title-bold">Daily Average</span> {{ formatCurrency(parseFloat(averageDailySpend)) }}
            </p>
            <p class="summary-details" v-if="mostCommonCategory">
              <span class="title-bold">Top Category</span> {{ mostCommonCategory }}
            </p>
            <p class="summary-details" v-if="mostFrequentTransaction">
              <span class="title-bold">Most Frequent</span> <span style="max-width: 300px; text-align: right;">{{ mostFrequentTransaction.name }} - {{ mostFrequentTransaction.count }} times</span>
            </p>
            <p class="summary-details">
              <span class="title-bold">Total Income</span> {{ formatCurrency(parseFloat(totalIncome)) }}
            </p>
            <p class="summary-details">
              <span class="title-bold">Total Spend</span> {{ formatCurrency(parseFloat(totalAmountOfAllTransactions)) }}
            </p>
            <p class="summary-details">
              <span class="title-bold">Cash Flow</span> 
              <span :class="{ 'positive-cash-flow': cashFlow > 0, 'negative-cash-flow': cashFlow < 0 }">
                {{ formatCurrency(parseFloat(cashFlow)) }}
              </span>
            </p>
            
            <!-- <p class="summary-details" v-if="largestTransaction">
              <span class="title-bold">Largest</span> <span style="max-width: 300px; text-align: right;">{{ formatCurrency(parseFloat(largestTransaction.amount)) }} - {{ largestTransaction.name }} on {{ formatTransactionDate(largestTransaction.date) }}</span>
            </p>
            <p class="summary-details" v-if="smallestTransaction">
              <span class="title-bold">Smallest</span> <span style="max-width: 300px; text-align: right;">{{ formatCurrency(parseFloat(smallestTransaction.amount)) }} - {{ smallestTransaction.name }} on {{ formatTransactionDate(smallestTransaction.date) }}</span>
            </p>
            <p class="summary-details">
              <span class="title-bold">Earliest</span> {{ formatTransactionDate(dateOfEarliestTransaction) }}
            </p>
            <p class="summary-details">
              <span class="title-bold">Latest</span> {{ formatTransactionDate(dateOfMostRecentTransaction) }}
            </p>
             -->
          </div>
        </div>
        <div class="spending-div9">
          <CashFlow></CashFlow> 
        </div>
      </div>
    </div>
    <div class="spending-div2">
      <h1 class="current-month-spend-amount">{{ formatCurrencyWithDefault(totalSpendCurrentMonth) }}</h1>
      <p class="current-month-spend">{{ currentMonthName }} Spending</p>
      <p class="spend-difference" v-html="spendDifferenceMessage"></p>
    </div>
    <div class="spending-div4">

        <LineChart :data="sortedVuexTransactions" :date-option="selectedDateOption"/>
      <!-- Date Options -->
      <div class="options-row">
        <button class="time-select-button" 
          v-for="option in dateOptions"
          :key="option.value"
          v-bind:class="{ active: selectedDateOption === option.value }" 
          v-on:click="setDateOption(option.value)">
          {{ option.text }}
        </button>
      </div>
    </div>

    <div class="spending-div6">
      <div class="table-group">

        <div class="table-options">
          <input class="search-transactions" type="text" placeholder="Search" v-model="searchQuery">

          <v-btn class="edit-multiple-transaction-button" @click="showTransactionForm = true">Add Transaction</v-btn>
          <v-btn class="edit-multiple-transaction-button" @click="toggleFilters">
            {{ showFilters ? 'Clear Filters' : 'Filter' }}
          </v-btn>

          <div>
            <v-btn class="edit-multiple-transaction-button" @click="isMultiEditMode = !isMultiEditMode">Edit Multiple</v-btn>
          </div>
        </div>
        <div @click="toggleFilters" v-show="showFilters">
            <div class="filter-sidebar2" @click.stop :class="{ 'filter-sidebar-shown': showFilters }">
              <div class="filter-column">
                <label class="filter-label" for="transaction-name"></label>
                <input class="filter-class" id="transaction-name" v-model="filters.transactionName" type="text" placeholder="Name">
                <div class="filter-column">
                  <label class="filter-label" for="category-filter"></label>
                  <select id="category-filter" v-model="filters.category">
                    <option class="category-dropdown-class" value="" selected>All</option>
                    <option v-for="category in categories" :key="category.name" :value="category.name">
                      {{ category.emoji }} {{ category.name }}
                    </option>
                  </select>
                </div>
                <div class="filter-column-manual">
                  <input type="checkbox" id="manually-created-filter" v-model="filters.manuallyCreated">
                  <label class="manual-checkbox" for="manually-created-filter">Manually Created</label>
                </div>
              </div>
              <div class="filter-column">
                <label class="filter-label" for="from-date"></label>
                <input class="filter-class" id="from-date" v-model="filters.fromDate" type="date" placeholder="Select From Date">
                <div class="filter-column">
                  <label class="filter-label" for="to-date"></label>
                  <input class="filter-class" id="to-date" v-model="filters.toDate" type="date" placeholder="Select To Date">
                </div>
              </div>
              <div class="filter-column">
                <label class="filter-label" for="min-amount"></label>
                <input class="filter-class" id="min-amount" v-model.number="filters.minAmount" type="number" placeholder="Min Amount">
                <div class="filter-column">
                  <label class="filter-label" for="max-amount"></label>
                  <input class="filter-class" id="max-amount" v-model.number="filters.maxAmount" type="number" placeholder="Max Amount">
                </div>
              </div>
              <div class="filter-column">
                <label class="filter-label" for="labels-filter"></label>
                <select id="labels-filter" class="filter-label-select" v-model="filterLabels" multiple placeholder="Select Labels">
                  <option class="select-label-filter" value="" disabled selected>Select Labels</option>
                  <option v-for="label in labels" :key="label" :value="label">{{ label }}</option>
                </select>
              </div>
            </div>
        </div>
        <!-- Form for adding a new transaction -->
        <form v-if="showTransactionForm" @submit.prevent="addTransaction" class="add-transaction-form">
          <div class="input-field">
            <input v-model="newTransaction.date" type="date" placeholder="Date" required>
            <br>
            <span v-if="dateError" class="error-message">{{ dateError }}</span>
          </div>
          <div class="input-field">
            <input v-model="newTransaction.name" type="text" placeholder="Name" required>
          </div>
          <div class="input-field">
            <input v-model="newTransaction.amount" type="number" placeholder="Amount" step="0.01" required>
          </div>
          <div class="input-field">
            <select v-model="newTransaction.category" required>
              <option>Category</option>
              <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.emoji }} {{ category.name }}</option>
            </select>
          </div>
          <button class="add-transaction-button" type="submit">Add Transaction</button>
          <button class="close-add-transaction-button" type="button" @click="showTransactionForm = false">Close</button>
        </form>
        <div class="filter-container" v-if="isMultiEditMode">
          <h2 class="edit-multiple-header">Edit multiple transactions</h2>
          <div class="edit-multiple-options">
            <div class="edit-multi-col1">
              <div>
                <label class="multi-label" for="name"></label>
                <input class="multi-class" type="text" id="name" v-model="newName" placeholder="Name" />
              </div>
              <div>
                <label class="multi-label" for="date"></label>
                <input class="multi-class" type="date" id="date" v-model="newDate" />
              </div>
              <div>
                <label class="multi-label" for="amount"></label>
                <input class="multi-class" type="number" id="amount" v-model="newAmount" placeholder="0" />
              </div>
              <div>
                <label class="multi-label" for="category"></label>
                <select id="category-multi" v-model="newCategory">
                  <option disabled value="">Select New Category</option>
                  <option v-for="category in categories" :key="category.name" :value="category.name">
                    {{ category.emoji }} {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="edit-multi-col2">
              <div>
                <select class="multi-label-select" v-model="newLabels" multiple>
                  <option disabled value="Labels">Labels</option>
                  <option v-for="label in labels" :key="label" :value="label">{{ label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="edit-multiple-buttons">
            <v-btn @click="updateSelectedTransactions">Save</v-btn>
            <v-btn @click="cancelEditing">Cancel</v-btn>
            <v-btn class="delete-multi-button" :disabled="isLoading" @click="deleteSelectedTransactions">
              <span v-if="isLoading">Deleting...</span>
              <span v-else>Delete Selected</span>
            </v-btn>
          </div>
        </div>
        <div class="select-transactions-group">
          <div class="select-all-container" v-if="isMultiEditMode">
            <input type="checkbox" id="select-all" v-model="selectAll">
            <label for="select-all">Select All</label>
          </div>
          <div class="selected-transactions-count" v-if="isMultiEditMode">
            <label>{{ selectedTransactions.length }} Selected</label>
          </div>
        </div>

        <!-- Table displaying transactions -->
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th style="text-align: center;">Labels</th>
              <th style="text-align: center;">Goal</th>
              <th style="text-align: right;">Category</th>
              <th style="text-align: right;">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Group transactions by date -->
            <template v-for="(group, date) in groupedTransactionsDescending" :key="date">
            <tr class="date-row">
              <td class="date-td" 
                  :colspan="8" 
                  :title="formatTransactionDateShort(date).fullDate"
                  @mouseenter="showFullDate = date" 
                  @mouseleave="showFullDate = null">
                {{ showFullDate === date ? formatTransactionDateShort(date).fullDate : formatTransactionDateShort(date).shortDate }}
                <span class="daily-sum"> {{ formatCurrency(group.spendSum) }}</span>
                
              </td>
            </tr>
            <tr v-for="(transaction, index) in group.transactions" :key="index"
            :class="{ 'review-label': transaction.labels.includes('review'), 'pending-transaction': transaction.pending }">
                <!-- The checkbox column -->
                <td class="checkbox-td">
                  <!-- <input type="checkbox" v-model="selectedTransactions" :value="transaction" v-show="isMultiEditMode" /> -->
                  <input type="checkbox" v-model="selectedTransactions" :value="transaction" v-show="isMultiEditMode" @click="handleCheckboxClick($event, transaction)" />
                </td>
                <td class="recurring-td" @click="toggleRecurring(transaction)">
                  <span v-if="transaction.recurringId && transaction.recurringName !== 'Not Recurring'">↻</span>
                </td>
                <!-- <td class="logo-td">
                  <img style="width: 40px; height: 40px; border-radius: 50%;" v-if="transaction.logo_url" :src="transaction.logo_url" alt="Transaction Logo" class="transaction-logo" />
                </td> -->
                <!-- Name -->
                <td class="name-td">
                  <input style="width: 100%;" v-if="!isTransactionBeingEdited(transaction.id, 'name')" :value="transaction.name" @focus="startEditing(transaction.id, 'name')" @input="transaction.name = $event.target.value" @blur="commitTransaction(transaction)" type="text" @keyup.enter="commitTransaction(transaction)" />
                  <input v-else v-model="transaction.name" type="text" @blur="saveTransaction(transaction)" />
                </td>
                <!-- Labels -->

                <td class="labels-td" @click="openLabelManager(transaction); enableLabelDropdown(transaction)" @mouseleave="disableLabelDropdown(transaction); closeLabelManager()">
                  <inline-label-manager v-show="isLabelManagerVisible && editingTransaction === transaction"></inline-label-manager>
                  <div class="labels-container" v-if="!transaction.showDropdown" @mouseleave="disableLabelDropdown(transaction)">
                    <span v-for="label in transaction.labels" :key="label" class="label-item" :class="{ 'label-selected': isSelectedLabel(transaction, label) }">
                      <span class="label-text"> {{ label }} </span>
                    </span>
                    <!-- If no labels exist, show "Add label" button -->
                    <button class="add-label-button" @click="openLabelManager(transaction); enableLabelDropdown(transaction)" v-show="transaction.labels.length === 0">Add label</button>
                  </div>
                  <select class="label-dropdown" v-else multiple v-model="transaction.labels" @focus="startEditingLabels(transaction)" @blur="saveTransaction(transaction)" @click.stop>
                    <option v-for="label in labels" :key="label" :value="label" class="label-item">{{ label }}</option>
                  </select>
                </td>
                <!-- Goals -->
                <td class="goal-td">
                  <select class="goal-select" v-model="transaction.goalId" @focus="captureOldGoalId(transaction)" @change="handleGoalSelection($event, transaction)">
                    <option disabled value="">Select a goal</option>
                    <option value="no_goal">Don't Spend From Goal</option>
                    <option v-for="goal in goalsWithAvailableBalance" :value="goal.id" :key="goal.id" :disabled="goal.availableBalance < transaction.amount">
                      {{ displayOption(goal, transaction.goalId) }}
                    </option>
                  </select>
                </td>

                <!-- Category -->
                <td class="categories-td">
                  <select 
                    class="categories-select"
                    id="categories" 
                    v-model="transaction.category" 
                    @focus="storePreviousCategory(transaction)" 
                    @change="updateTransactionCategory(transaction)">
                    <option v-for="category in categories" :key="category.name" :value="category.name"> {{ category.name }}&nbsp;&nbsp;{{ category.emoji }}&nbsp;&nbsp;</option>
                  </select>
            
                </td>
                <!-- Amount -->
                <td class="amount-td">
                  <input 
                    v-if="!isTransactionBeingEdited(transaction.id, 'amount')" 
                    :value="formatDisplayAmount(parseFloat(transaction.amount).toFixed(2))"
                    :class="{'green-amount': parseFloat(transaction.amount) < 0}" 
                    @focus="startEditing(transaction.id, 'amount')" 
                    @blur="updateTransactionAmount(transaction, $event.target.value)" 
                    @keyup.enter="updateTransactionAmount(transaction, $event.target.value)" 
                    type="text" 
                    :readonly="isTransactionBeingEdited(transaction.id, 'amount')" />
                </td>

                <td class="delete-td">
                  <!-- Delete button -->
                  <button class="delete-button" @click="startDeleteTransaction(transaction)"> x </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div v-if="isLoading" ref="loadMoreRef" class="Loading-more-transactions"> ⏳Loading transactions... </div>
        <!-- If there are no transactions -->
        <div v-else-if="!transactions.length" class="Loading-more-transactions"> ➕ Import transactions to get started. </div>
        <div v-else ref="loadMoreRef" class="Loading-more-transactions">
          {{ hasMoreTransactions ? '⏳Loading more transactions...' : 'You\'ve reached the end of your transactions 💰' }}
        </div>
      </div>
    </div>
  </div>
  <transition name="slide"></transition>
</template>

<script>
/* eslint-disable */
import { collection, addDoc, doc, getDoc, updateDoc, onSnapshot, query, where, orderBy, deleteDoc, getDocs, deleteField, startAfter, limit, arrayUnion, arrayRemove } from 'firebase/firestore'
import { auth, db } from '../firebaseInit'; // Import the initialized Firebase auth and Firestore instances
import LineChart from '../components/LineChart.vue';
import CashFlow from './CashFlow.vue';
import SpendingPie from '../components/SpendingPie.vue';
import InlineLabelManager from '../components/InlineLabelManager.vue';
import { unparse as convertToCsv } from 'papaparse';
import PlaidLink from '../components/PlaidLink.vue';
import RecurringRulesModal from '../components/RecurringRulesModal.vue';
import Dropdown from 'primevue/dropdown';


export default {
  name: 'SpendingView',
  components: {
    LineChart,
    SpendingPie,
    PlaidLink,
    InlineLabelManager,
    RecurringRulesModal,
    Dropdown,
    CashFlow
  },
  data() {
    return {
      transactions: [],
      totalIncome: 0,
      notDuplicateIds: new Set(),
      loadMoreRef: 'loadMore',
      showFullDate: null,
      reviewToggle: 'uncategorized', 
      observer: null,
      lastDocSnapshot: null,
      loadStep: 60,
      transactionsLoadedOnce: false, 
      hasMoreTransactions: true,
      showTransactionForm: false,
      // Calculations
      spendingTotals: {},
      totalSpendCurrentMonth: 0,
      spendDifferenceMessage: '',
      // Transaction Editing
      editedTransaction: null,
      editingTransactionIndex: null,
      categories: [],
      newTransaction: { date: this.getCurrentDate(), name: '', amount: 0, category: null, labels: [] },  
      labels: [], 
      sortBy: 'date', 
      dateError: '',
      // Filtering
      filters: {
        fromDate: null,
        toDate: null,
        category: null,
        transactionName: '',
        minAmount: null,
        maxAmount: null,
        manuallyCreated: false
      },
      searchQuery: '',
      pieChartData: {},
      showFilters: false,
      filterLabels: [], 
      previousCategory: null,
      selectedTransactions: [], // Holds the IDs of selected transactions
      isLabelManagerVisible: false,
      showDropdown: false,
      userId: null,
      // Recurring rules
      isModalOpen: false,
      allRecurringRules: [],
      selectedRecurringTransaction: null,
      isLoading: false,
      isFetching: false,
      notifications: [], 
      // Multi-edit
      isMultiEditMode: false,
      newName: '', 
      newDate: null, 
      newAmount: null, 
      newLabels: [], 
      selectAll: false, 
      transactionsEdited: 0,
      totalTransactionsToBeEdited: 0,
      bulkEditing: false,
      bulkDeleting: false,
      lastClickedTransaction: null,
      showDeleteConfirmation: false, // Add this line
      transactionToDelete: null, // To store the transaction that may be deleted
      // Csv import
      errors: {
        name: '',
        amount: '',
        date: '',
        category: '',
      },
      csvCategories: [],
      categoryMappings: {},
      showCategoryMappingModal: false,
      showCsvImport: false,
      showCSVModal: false,
      importing: false,
      totalTransactions: 0,
      transactionsImported: 0,
      columnMappings: { 
        name: '',
        amount: '',
        date: '',
        category: '',
        labels: ''
      },
      // Plaid import
      showPlaidModal: false,
      importingPlaid: false,
      plaidTransactionsImported: 0,
      totalPlaidTransactions: 0,
      // Transaction rules
      categoryMap: {},
      showCreateRuleModal: false,
      lastEditedTransactionHistory: null,
      lastEditedTransaction: null,
      lastEditedTransactionNewValues: null,
      conditionName: '',
      conditionAmount: '',
      conditionCategory: '',
      conditionNameMatch: 'Exact Match', // Exact or Contains match
      conditionAmountMatch: 'Equals', // Use to check if amount is equals, less than, more than, etc
      conditionAmountMin: null,
      conditionAmountMax: null,
      newName: '',
      newAmount: '',
      newCategory: '',
      isNameExpanded: false,
      isAmountExpanded: false,
      isCategoryExpanded: false,
      isNewNameExpanded: false,
      isNewAmountExpanded: false,
      isNewCategoryExpanded: false,
      isNewLabelExpanded: false,
      isTransactionsListExpanded: false,
      applyingRules: false,
      // DATE OPTIONS
      selectedDateOption: 'month', // default selection
      noTransactionsForPeriod: false,
      dateOptions: [
        { value: 'week', text: '1W' },
        { value: 'month', text: 'M' },
        { value: '30', text: '30' },
        { value: '60', text: '60' },
        { value: '90', text: '90' },
        { value: 'year', text: 'YTD' },
        { value: '1year', text: '1Y' },
        // { value: '2year', text: '2Y' }, disabling for now as there are issues with the charts
        { value: 'all', text: 'ALL' }, // this should be hidden by default unless the user presses ⌘ + shift + .
      ],
      goals: [], // To store goals fetched from Firestore
      showingGoalSelectorForTransactionId: null,
      selectedTransactionForGoal: null,
      // REVIEW TRANSACTIONS MODAL

      showTransactionEditModal: false,

      // Summary calculations
      largestTransaction: '',
      dateOfMostRecentTransaction: '',
      dateOfEarliestTransaction: '',
      mostFrequentTransaction: '',
      mostCommonCategory: '',
      smallestTransaction: '',
      averageDailySpend: '',
      averageTransactionAmount: '',
      totalAmountOfAllTransactions: '',
      frequencyText: '',
      totalNumberOfTransactions: '',
      dateOfMostRecentTransaction: '',
    };
  },
  mounted() {
    try {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.userId = user.uid;
        this.calculateAndStoreMetrics();
        this.updateIncome();
        this.fetchCategoryMap();
        this.fetchGoals();
        this.createIntersectionObserver();
        this.fetchNotDuplicateTransactions();
        const labelsCollection = collection(db, `users/${user.uid}/labels`);
        onSnapshot(labelsCollection, (snapshot) => {
          this.labels = snapshot.docs.map((doc) => doc.data().name);
        });

        } else {
          console.log('No user is logged in.');
        }
      });
      } catch (error) {
      console.error('Error retrieving documents:', error);
    }
  },
  created() {
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  computed: {
    selectAll: {
      get() {
        const allTransactions = Object.values(this.groupedTransactionsDescending).flat();
        return allTransactions.length === this.selectedTransactions.length;
      },
      set(value) {
        if (value) {
          const allTransactions = Object.values(this.groupedTransactionsDescending).flat();
          this.selectedTransactions = [...allTransactions];
        } else {
          this.selectedTransactions = [];
        }
      }
    },
    categories() {
      return this.$store.state.categories;
    },
    transactions() {
      return this.$store.state.transactions;
    },
    filteredTransactions() {
      return this.transactions.filter(transaction => {
        return transaction.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    goalsWithAvailableBalance() {
      return this.goals.map(goal => {
        const availableBalance = goal.totalSpentFromGoal
          ? goal.currentBalance - goal.totalSpentFromGoal
          : goal.currentBalance;
        return { ...goal, availableBalance };
      });
    },
    sortedVuexTransactions() {
      const vuexTransactions = this.$store.state.transactions;
      return vuexTransactions
        .filter(transaction => transaction.amount > 0 && (!transaction.labels || !transaction.labels.includes('ignore'))) 
        // Filter out negative transactions and those with the 'ignore' label
        .sort((a, b) => {
          if (a[this.sortBy] === b[this.sortBy]) {
            return new Date(a.date) - new Date(b.date);  // Sort by date when `sortBy` field is the same
          }
          return a[this.sortBy] < b[this.sortBy] ? -1 : 1;
        });
    },
    summaryTitle() {
      switch (this.selectedDateOption) {
        case 'week':
          return 'Last Week';
        case 'month':
          return 'Current Month';
        case '30':
          return 'Last 30 Days';
        case '60':
          return 'Last 60 Days';
        case '90':
          return 'Last 90 Days';
        case 'year':
          return 'Year to Date';
        case '1year':
          return 'Last Year';
        case '2year':
          return 'Last 2 Years';
        case 'all':
          return 'All Time';
        default:
          return 'Summary';
      }
    },
    // dateOptions() {
    //   let options = [
    //     { value: 'week', text: '1W' },
    //     { value: 'month', text: 'M' },
    //     { value: '30', text: '30' },
    //     { value: '60', text: '60' },
    //     { value: '90', text: '90' },
    //     { value: 'year', text: 'YTD' },
    //     { value: '1year', text: '1Y' },
    //     // { value: '2year', text: '2Y' } disabling for now as there are issues with the charts
    //   ];

    //   // Use a direct reference to the Vuex state to ensure reactivity
    //   if (this.$store.state.allTransactionsFetched) {
    //     options = [...options, { value: 'all', text: 'ALL' }];
    //   }

    //   return options;
    // },
    categoryToEmoji() {
      const map = {};
      this.categories.forEach(category => {
        map[category.name] = category.emoji;
      });
      return map;
    },
    cashFlow() {
      // Use the absolute value of totalAmountOfAllTransactions since it represents spending
      return this.totalIncome - Math.abs(this.totalAmountOfAllTransactions);
    },
    filteredRuleTransactions() {
      // Check if at least one condition is set and its corresponding section is expanded
      const isAnyConditionSet = (this.isNameExpanded && this.conditionName) ||
                                (this.isAmountExpanded && (this.conditionAmount != null || (this.conditionAmountMatch === 'Within' && this.conditionAmountMin != null && this.conditionAmountMax != null))) ||
                                (this.isCategoryExpanded && this.conditionCategory);

      if (!isAnyConditionSet) {
        return []; // Return an empty array if no conditions are set
      }

      return this.$store.state.transactions.filter(transaction => {
        let matchesName = true;
        let matchesAmount = true;
        let matchesCategory = true;

        if (this.isNameExpanded && this.conditionName) {
          if (this.conditionNameMatch === 'Exact Match') {
            matchesName = transaction.name === this.conditionName;
          } else if (this.conditionNameMatch === 'Contains') {
            const transactionNameLower = transaction.name.toLowerCase();
            const conditionNameLower = this.conditionName.toLowerCase();
            matchesName = transactionNameLower.includes(conditionNameLower);
          }
        }

        if (this.isAmountExpanded && this.conditionAmount != null) {
          switch (this.conditionAmountMatch) {
            case 'Equals':
              matchesAmount = transaction.amount === this.conditionAmount;
              break;
            case 'More than':
              matchesAmount = transaction.amount > this.conditionAmount;
              break;
            case 'Less than':
              matchesAmount = transaction.amount < this.conditionAmount;
              break;
            case 'Within':
              matchesAmount = transaction.amount >= this.conditionAmountMin && transaction.amount <= this.conditionAmountMax;
              break;
            default:
              matchesAmount = true;
          }
        }

        if (this.isCategoryExpanded && this.conditionCategory) {
          matchesCategory = transaction.category === this.conditionCategory;
        }

        return matchesName && matchesAmount && matchesCategory;
      });
    },

    sortedTransactions() {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.transactions
        .filter(transaction => {
          if (this.filters.fromDate && transaction.date < this.filters.fromDate) {
            return false;
          }
          if (this.filters.toDate && transaction.date > this.filters.toDate) {
            return false;
          }
          if (this.filters.category && transaction.category !== this.filters.category) {
            return false;
          }
          if (this.filters.transactionName && !transaction.name.toLowerCase().includes(this.filters.transactionName.toLowerCase())) {
            return false;
          }
          if (this.filters.minAmount && transaction.amount < this.filters.minAmount) {
            return false;
          }
          if (this.filters.maxAmount && transaction.amount > this.filters.maxAmount) {
            return false;
          }

          if (this.filters.manuallyCreated && !transaction.manuallyCreated) {
              return false; // Skip transactions that are not manually created
          }
          // Filter by label(s)
          if (this.filterLabels.length > 0) {
            if (!transaction.labels.some(label => this.filterLabels.includes(label))) {
                return false;
            }
          }
          // Unified Search filter condition
          if (this.searchQuery) {
            const matchesSearch = transaction.name.toLowerCase().includes(searchQueryLower) ||
                                  transaction.category.toLowerCase().includes(searchQueryLower) ||
                                  transaction.labels.some(label => label.toLowerCase().includes(searchQueryLower)) ||
                                  transaction.amount.toString().includes(this.searchQuery);
            if (!matchesSearch) {
              return false;
            }
          }
          return true;
        })
        .sort((a, b) => {
          if (a[this.sortBy] === b[this.sortBy]) {
            return new Date(a.date) - new Date(b.date);  // Sort by date when `sortBy` field is the same
          }
          return a[this.sortBy] < b[this.sortBy] ? -1 : 1;
        });
    },

    groupedTransactionsDescending() {
      // Get the transactions from the Vuex store
      const transactions = this.$store.state.transactions;

      // Apply filters to the transactions
      const filteredTransactions = transactions.filter(transaction => {
        if (this.filters.fromDate && transaction.date < this.filters.fromDate) {
          return false;
        }
        if (this.filters.toDate && transaction.date > this.filters.toDate) {
          return false;
        }
        if (this.filters.category && transaction.category !== this.filters.category) {
          return false;
        }
        if (this.filters.transactionName && !transaction.name.toLowerCase().includes(this.filters.transactionName.toLowerCase())) {
          return false;
        }
        if (this.filters.minAmount && transaction.amount < this.filters.minAmount) {
          return false;
        }
        if (this.filters.maxAmount && transaction.amount > this.filters.maxAmount) {
          return false;
        }
        if (this.filters.manuallyCreated && !transaction.manuallyCreated) {
          return false;
        }
        if (this.filterLabels.length > 0 && !transaction.labels.some(label => this.filterLabels.includes(label))) {
          return false;
        }
        if (this.searchQuery && !transaction.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          return false;
        }
        return true;
      });

      // Reverse the order of the filtered transactions to show the newest first
      const reversedTransactions = filteredTransactions.slice().reverse();

      // Slice the reversed transactions based on the current load step
      const slicedTransactions = reversedTransactions.slice(0, this.loadStep);

       // Group transactions by date
        const groups = slicedTransactions.reduce((groups, transaction) => {
          const date = transaction.date;
          if (!groups[date]) {
            groups[date] = [];
          }
          groups[date].push(transaction);
          return groups;
        }, {});

        // Calculate the sum of positive transactions (spending) for each date
        const groupsWithSpendSum = Object.entries(groups).reduce((acc, [date, transactions]) => {
          const spendSum = transactions.reduce((total, transaction) => {
            const amount = parseFloat(transaction.amount);
            // Check if the transaction amount is positive and doesn't have the "ignore" label
            if (amount > 0 && (!transaction.labels || !transaction.labels.includes('ignore'))) {
              return total + amount;
            }
            return total;
          }, 0);
          acc[date] = { transactions, spendSum };
          return acc;
        }, {});

        // Get the dates (keys of the groups object) and sort them in descending order
        const sortedDates = Object.keys(groupsWithSpendSum).sort().reverse();

        // Create a new object and add groups to it in descending order of dates
        const sortedGroups = sortedDates.reduce((sorted, date) => {
          sorted[date] = groupsWithSpendSum[date];
          return sorted;
        }, {});

        return sortedGroups;
      },

    progressBarWidth() {
      return `${(this.transactionsImported / this.totalTransactions) * 100}%`;
    },
    progressBarPercentage() {
      return Math.round((this.transactionsImported / this.totalTransactions) * 100);
    },
    transactionRulesProgressBarWidth() {
      if (this.totalTransactionsToBeUpdated === 0) return '0%';
      const width = (this.transactionsUpdated / this.totalTransactionsToBeUpdated) * 100;
      return `${width}%`;
    },
    transactionRulesProgressBarPercentage() {
      if (this.totalTransactionsToBeUpdated === 0) return '0';
      return Math.round((this.transactionsUpdated / this.totalTransactionsToBeUpdated) * 100);
    },
    plaidProgressBarWidth() {
      return `${(this.plaidTransactionsImported / this.totalPlaidTransactions) * 100}%`;
    },
    plaidProgressBarPercentage() {
      return Math.round((this.plaidTransactionsImported / this.totalPlaidTransactions) * 100);
    },
    bulkEditingProgressBarWidth() {
      const progress = this.transactionsEdited / this.totalTransactionsToBeEdited * 100;
      return `${progress}%`;
    },
    editingTransactionsProgressBarPercentage() {
      return Math.round(this.transactionsEdited / this.totalTransactionsToBeEdited * 100);
    },
    currentMonthName() {
      const monthNames = ["January", "February", "March", "April", "May", "June",
                          "July", "August", "September", "October", "November", "December"];
      const currentMonthIndex = new Date().getMonth();
      return monthNames[currentMonthIndex];
    },
    uncategorizedTransactions() {
      const currentDate = new Date();
      const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());

      return this.sortedVuexTransactions.filter(transaction =>
        transaction.category === "Uncategorized" && new Date(transaction.date) >= oneYearAgo
      );
    },
    potentialDuplicateTransactions() {
      let duplicates = [];
      const currentDate = new Date();
      const oneYearAgo = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate());

      this.sortedVuexTransactions.filter(transaction => new Date(transaction.date) >= oneYearAgo)
        .forEach((transaction, index, self) => {
          // Skip if this transaction is already marked as not a duplicate
          if (this.notDuplicateIds.has(transaction.id)) return;

          // Check for transactions with the same exact amount and date
          const exactDuplicates = self.filter(t =>
            t.amount === transaction.amount &&
            t.date === transaction.date &&
            t.id !== transaction.id &&
            !this.notDuplicateIds.has(t.id)
          );

          // Check for transactions with the same name and amount but within 1 to 3 days apart
          const nearDuplicates = self.filter(t =>
            t.name === transaction.name &&
            t.amount === transaction.amount &&
            t.id !== transaction.id &&
            Math.abs(new Date(t.date) - new Date(transaction.date)) <= 3 * 24 * 60 * 60 * 1000 && // 3 days in milliseconds
            !this.notDuplicateIds.has(t.id)
          );

          // Combine exact duplicates and near duplicates
          const allDuplicates = [...exactDuplicates, ...nearDuplicates];

          // Add the current transaction to duplicates if there are any duplicates
          if (allDuplicates.length > 0) {
            duplicates.push(transaction);
          }
        });

      return duplicates;
    },

    groupedDuplicates() {
      const groups = [];
      const processedIds = new Set();

      this.potentialDuplicateTransactions.forEach(transaction => {
        if (!processedIds.has(transaction.id)) {
          const group = [transaction];
          processedIds.add(transaction.id);

          this.potentialDuplicateTransactions.forEach(otherTransaction => {
            if (
              !processedIds.has(otherTransaction.id) &&
              (
                (otherTransaction.amount === transaction.amount && otherTransaction.date === transaction.date) ||
                (otherTransaction.name === transaction.name && 
                 otherTransaction.amount === transaction.amount && 
                 Math.abs(new Date(otherTransaction.date) - new Date(transaction.date)) <= 3 * 24 * 60 * 60 * 1000)
              )
            ) {
              group.push(otherTransaction);
              processedIds.add(otherTransaction.id);
            }
          });

          if (group.length > 1) {
            groups.push(group);
          }
        }
      });

      return groups;
    }
  },
  directives: { 
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          // Check if the click was outside the element
          if (!(el === event.target || el.contains(event.target))) {
            // If so, call the provided method
            binding.value();
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    },
  },
  watch: {
    filters: {
      deep: true,
    },
    '$store.state.allTransactionsFetched'(newValue) {
      if (newValue) {
        this.selectedDateOption = 'all';
      }
    },

    showCreateRuleModal(newVal) {
      if (newVal && this.editedTransaction) {
        this.setRuleConditionsFromEditedTransaction();
      }
    },
    editedTransaction: {
      handler(newVal) {
        if (this.showCreateRuleModal) {
          this.setRuleConditionsFromEditedTransaction();
        }
      },
      deep: true
    },
    selectAll(newValue) {
      if (newValue) {
        // When selectAll becomes true, select all transactions
        this.selectedTransactions = this.transactions.slice();
      } else {
        // When selectAll becomes false, deselect all transactions
        this.selectedTransactions = [];
      }
    },
    selectedDateOption(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.calculateAndStoreMetrics();
        this.updateIncome();
      }
    },

    // sortedVuexTransactions: {
    //   handler(newVal) {
    //     console.log('Sorted Vuex Transactions:', newVal);
    //   },
    //   deep: true
    // },
  },
  methods: {

    // GOALS
    async fetchGoals() {
      try {
        const userGoalsRef = collection(db, `/users/${auth.currentUser.uid}/goals`);
        const querySnapshot = await getDocs(userGoalsRef);
        this.goals = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })).map(goal => {
          const availableBalance = goal.totalSpentFromGoal
            ? goal.currentBalance - goal.totalSpentFromGoal
            : goal.currentBalance;
          return { ...goal, availableBalance };
        });
      } catch (error) {
        console.error("Error fetching goals:", error);
      }
    },
    async markAsNotDuplicate(group) {
      // Add all transaction IDs in the group to notDuplicateIds
      group.forEach(transaction => {
        this.notDuplicateIds.add(transaction.id);
      });

      // Update transactions in Firestore to mark them as not duplicates
      const promises = group.map(transaction => 
        updateDoc(doc(db, `users/${this.userId}/transactions`, transaction.id), {
          notDuplicate: true
        })
      );

      try {
        await Promise.all(promises);
        console.log("Transactions marked as not duplicate");
        // Optionally, you can update the Vuex store here if needed
        group.forEach(transaction => {
          this.$store.commit('updateTransaction', { ...transaction, notDuplicate: true });
        });
      } catch (error) {
        console.error("Error marking transactions as not duplicate:", error);
      }
    },

    // This method should be called when the component is created or mounted
    async fetchNotDuplicateTransactions() {
      const transactionsRef = collection(db, `users/${this.userId}/transactions`);
      const q = query(transactionsRef, where("notDuplicate", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        this.notDuplicateIds.add(doc.id);
      });
    },
    formatCurrencyWithDefault(value) {
      if (value === null || value === undefined || isNaN(value)) {
        return '$0.00';
      }
      return new Intl.NumberFormat('en-US', { 
        style: 'currency', 
        currency: 'USD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2 
      }).format(value);
    },
    updateCategoryMapping(csvCategory, selectedCategory) {
      this.categoryMappings[csvCategory] = selectedCategory;
    },
    createIntersectionObserver() {
      const options = {
        root: null, // observes entries in the viewport
        rootMargin: '0px',
        threshold: 1.0 // Trigger when 100% of the observed element is visible
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting && this.hasMoreTransactions) {
            this.fetchMoreTransactions();
          }
        });
      }, options);

      const loadMoreElement = this.$refs.loadMoreRef;
      if (loadMoreElement) {
        this.observer.observe(loadMoreElement);
      }
    },
    displayOption(goal, selectedGoalId) {
      if (goal.id === selectedGoalId) {
        return `${goal.emoji} ${goal.name}`;
      }
      const balance = goal.availableBalance ?? goal.currentBalance;
      const formattedBalance = balance.toFixed(2); // Format the balance with 2 decimal places
      return `${goal.emoji} ${goal.name} - $${formattedBalance}`;
    },
    captureOldGoalId(transaction) {
        // Assuming transaction has a property to temporarily hold the old goal ID
        transaction.oldGoalId = transaction.goalId;
        console.log("Captured old goal ID:", transaction.oldGoalId);
    },
    async handleGoalSelection(event, transaction) {
      const newGoalId = event.target.value;
      // Use the captured old goal ID
      const oldGoalId = transaction.oldGoalId;

      if (oldGoalId && oldGoalId !== newGoalId) {
        await this.unlinkTransactionFromGoal(transaction.id, oldGoalId);
      }

      // Update transaction with the new goal, only if not selecting 'no_goal'
      if (newGoalId !== 'no_goal') {
        await this.updateTransactionGoal({ ...transaction, goalId: newGoalId });
        transaction.goalId = newGoalId; // Ensure the transaction's goalId is updated

        // Commit the updated transaction to the Vuex store
        this.$store.commit('updateTransaction', transaction);
      } else {
        // If 'no_goal' is selected, clear the goalId from the transaction
        const transactionRef = doc(db, `users/${this.userId}/transactions`, transaction.id);
        await updateDoc(transactionRef, { goalId: deleteField() });
        transaction.goalId = null; // Clear the goalId from the transaction

        // Commit the updated transaction to the Vuex store
        this.$store.commit('updateTransaction', transaction);
      }
    },


    async updateTransactionGoal(transaction) {
      // Ensure a goal is selected
      if (!transaction.goalId) {
        console.error("No goal selected.");
        return;
      }

      try {
        const transactionRef = doc(db, `users/${this.userId}/transactions`, transaction.id);
        await updateDoc(transactionRef, {
          goalId: transaction.goalId,
        });

        // Fetch the current goal document
        const goalRef = doc(db, `users/${this.userId}/goals`, transaction.goalId);
        const goalDoc = await getDoc(goalRef);
        if (goalDoc.exists()) {
          let goalData = goalDoc.data();
          // Initialize connectedTransactions and totalSpentFromGoal if they don't exist
          if (!goalData.connectedTransactions) {
            goalData.connectedTransactions = [];
          }
          if (!goalData.totalSpentFromGoal) {
            goalData.totalSpentFromGoal = 0;
          }

          // Add the current transaction to the connectedTransactions array
          // Ensure no duplicates
          if (!goalData.connectedTransactions.some(t => t.id === transaction.id)) {
            goalData.connectedTransactions.push({ id: transaction.id, amount: transaction.amount });
          }

          // Calculate the new totalSpentFromGoal and availableBalance
          goalData.totalSpentFromGoal += transaction.amount;
          const updatedAvailableBalance = goalData.currentBalance - goalData.totalSpentFromGoal;

          // Update the goal document
          await updateDoc(goalRef, {
            connectedTransactions: goalData.connectedTransactions,
            totalSpentFromGoal: goalData.totalSpentFromGoal,
            // No need to explicitly store availableBalance, it's calculated dynamically
          });

          // Update goals array to trigger reactivity
          this.goals = this.goals.map(goal => {
            if (goal.id === transaction.goalId) {
              return { ...goal, totalSpentFromGoal: goalData.totalSpentFromGoal, availableBalance: updatedAvailableBalance };
            }
            return goal;
          });
        }

        // Commit the updated transaction to the Vuex store
        this.$store.commit('updateTransaction', transaction);

        console.log(`Transaction ${transaction.id} updated with goal ${transaction.goalId}`);
      } catch (error) {
        console.error("Error updating transaction goal:", error);
      }
    },

    async unlinkTransactionFromGoal(transactionId, oldGoalId) {
      try {
        // Update the transaction to remove the goalId association
        const transactionRef = doc(db, `users/${this.userId}/transactions`, transactionId);
        await updateDoc(transactionRef, { goalId: deleteField() });

        // Fetch the old goal document to update
        const oldGoalRef = doc(db, `users/${this.userId}/goals`, oldGoalId);
        const oldGoalDoc = await getDoc(oldGoalRef);
        if (oldGoalDoc.exists()) {
          let oldGoalData = oldGoalDoc.data();

          // Remove the transaction from the old goal's connectedTransactions
          if (oldGoalData.connectedTransactions) {
            const index = oldGoalData.connectedTransactions.findIndex(t => t.id === transactionId);
            if (index > -1) {
              // Subtract the transaction amount from totalSpentFromGoal
              oldGoalData.totalSpentFromGoal -= oldGoalData.connectedTransactions[index].amount;

              // Remove the transaction from connectedTransactions
              oldGoalData.connectedTransactions.splice(index, 1);

              // Update the old goal document
              await updateDoc(oldGoalRef, {
                connectedTransactions: oldGoalData.connectedTransactions,
                totalSpentFromGoal: oldGoalData.totalSpentFromGoal,
              });

              // Recalculate availableBalance for the old goal
              const updatedAvailableBalance = oldGoalData.currentBalance - oldGoalData.totalSpentFromGoal;

              // Update goals array to trigger reactivity
              this.goals = this.goals.map(goal => {
                if (goal.id === oldGoalId) {
                  return { ...goal, totalSpentFromGoal: oldGoalData.totalSpentFromGoal, availableBalance: updatedAvailableBalance };
                }
                return goal;
              });
            }
          }
        }
      } catch (error) {
        console.error("Error unlinking transaction from old goal:", error);
      }
      // It seems you're already calling fetchGoals at the end of this method; consider if it's necessary or if the direct update to this.goals above suffices.
    },




    // REVIEW TRANSACTIONS METHOD
    identifyAndEditTransactions() {
      this.showTransactionEditModal = true;
    },



    // DATE OPTIONS FOR CHARTS

    async setDateOption(option) {
      const currentTime = new Date().getTime();

      // Assuming lastChangeTime is a property that stores the timestamp of the last change
      const lastChangeTime = this.lastChangeTime || 0;
      const timeDiff = currentTime - lastChangeTime;

      // Check if the last change was within 2 seconds (2000 milliseconds)
      if (timeDiff < 2000) {
        // Add a 1-second delay (1000 milliseconds)
        await new Promise(resolve => setTimeout(resolve, 1000));
      }

      this.lastChangeTime = currentTime; // Update the lastChangeTime with the current time

      if (option === '2year') {
        await this.$store.dispatch('fetchTransactionsFromTwoYearsAgo');
      }

      this.selectedDateOption = option;

      this.$nextTick(() => {
        this.calculateAndStoreMetrics();
      });
    },


// TRANSACTION RULES MODAL
    openCreateRuleModal() {
      const history = this.lastEditedTransactionHistory?.changes || {};
      const transaction = this.lastEditedTransaction || {};
      const newValues = this.lastEditedTransactionNewValues || {};

      // Set old values
      this.conditionName = history.originalName || transaction.name || '';
      this.conditionAmount = history.originalAmount || transaction.amount || '';
      this.conditionCategory = history.originalCategory || transaction.category || '';

      // Set new values
      this.newName = newValues.name || '';
      this.newAmount = newValues.amount || '';
      this.newCategory = newValues.category || '';
      this.newLabel = newValues.label || ''; // Add this line

      this.showCreateRuleModal = true;
    },
    setRuleConditionsFromEditedTransaction() {
      console.log("Setting rule conditions from:", this.editedTransaction);
      if (this.editedTransaction && this.editedTransaction.oldValues) {
        this.conditionName = this.editedTransaction.oldValues['name'] || '';
        this.conditionAmount = this.editedTransaction.oldValues['amount'] || '';
        this.conditionCategory = this.editedTransaction.oldValues['category'] || '';
        console.log("Conditions set to:", this.conditionName, this.conditionAmount, this.conditionCategory);
      }
    },
    closeCreateRuleModal() {
      // Handle logic to close the overlay modal
      this.showCreateRuleModal = false;

      // Resetting toggles
      this.isNameExpanded = false;
      this.isAmountExpanded = false;
      this.isCategoryExpanded = false;
      this.isNewNameExpanded = false;
      this.isNewAmountExpanded = false;
      this.isNewCategoryExpanded = false;
      this.isNewLabelExpanded = false;

      // Resetting condition match types to their default values
      this.conditionNameMatch = "Exact Match"; // Set to default value
      this.conditionAmountMatch = "Equals"; // Set to default value

      // Resetting other form fields logic (if any) goes here
      this.conditionAmountMin = null;
      this.conditionAmountMax = null;
      this.conditionName = '';
      this.conditionAmount = '';
      this.conditionCategory = '';
      this.newName = '';
      this.newAmount = '';
      this.newCategory = '';
      this.newLabel = '';

      // Resetting last edited transaction related data
      this.lastEditedTransactionHistory = null;
      this.lastEditedTransaction = null;
      this.lastEditedTransactionNewValues = null;  // Reset new values
    },
    async createRule() {
      console.log('Creating rule...');
      // Initialize an empty conditions object
      let conditions = {};
      
      // Add name condition based on toggle state and match type
      if (this.isNameExpanded && this.conditionName) {
        conditions.name = {
          value: this.conditionName,
          matchType: this.conditionNameMatch // 'Exact Match' or 'Contains'
        };
      }
      // Handle the amount condition based on the selected match type
      if (this.isAmountExpanded && this.conditionAmount) {
        if (this.conditionAmountMatch === 'Within' && this.conditionAmountMin != null && this.conditionAmountMax != null) {
          conditions.amount = {
            matchType: 'Within',
            min: this.conditionAmountMin,
            max: this.conditionAmountMax
          };
        } else {
          conditions.amount = {
            matchType: this.conditionAmountMatch, //', 'More than', 'Less than'
            value: this.conditionAmount
          };
        }
      }
      if (this.isCategoryExpanded && this.conditionCategory) {
        conditions.category = this.conditionCategory;
      }

      // Initialize an empty newValues object
      let newValues = {};

      // Add new values based on toggle state
      if (this.isNewNameExpanded && this.newName) {
        newValues.name = this.newName;
      }
      if (this.isNewAmountExpanded && this.newAmount) {
        newValues.amount = this.newAmount;
      }
      if (this.isNewCategoryExpanded && this.newCategory) {
        newValues.category = this.newCategory;
      }
      if (this.isNewLabelExpanded && this.newLabel) {
        if (!newValues.labels) {
          newValues.labels = [];
        }
        newValues.labels.push(this.newLabel);
      }

      // Create the rule only if there are conditions to apply
      if (Object.keys(conditions).length > 0) {
        const rule = { conditions, newValues };
        const rulesCollection = collection(db, `users/${auth.currentUser.uid}/transactionRules`);
        
        // Add the rule to Firestore once
        const docRef = await addDoc(rulesCollection, rule);
        const addedRule = { id: docRef.id, ...rule };


        // Start applying rules
        this.applyingRules = true;
        this.transactionsUpdated = 0;
        this.totalTransactionsToBeUpdated = this.filteredRuleTransactions.length;

        for (const transaction of this.filteredRuleTransactions) {
          await this.applyRulesToTransaction(transaction, addedRule); // Use addedRule
          this.transactionsUpdated++;
        }

        // End applying rules
        this.applyingRules = false;

      // Dispatch the added rule to Vuex store
      this.$store.dispatch('addRule', addedRule);

      // Close modal and reset form fields
      this.closeCreateRuleModal();
      } else {
      // Handle the case where no conditions are set
      }
    },
    async applyRulesToTransaction(transaction, rule) {
    // This runs when a new rule is created and it updates all matched transactions

      // Apply the new values from the provided rule to the transaction
      const updatedTransactionData = { ...transaction, ...rule.newValues };

      // Ensure the labels field is an array
      if (!updatedTransactionData.labels) {
        updatedTransactionData.labels = [];
      }

      // Add the new labels from the rule to the transaction
      if (rule.newValues.labels) {
        updatedTransactionData.labels = [...new Set([...updatedTransactionData.labels, ...rule.newValues.labels])];
      }

      // Update the transaction in the Firestore database
      const transactionDocRef = doc(db, `users/${auth.currentUser.uid}/transactions`, transaction.id);
      await updateDoc(transactionDocRef, updatedTransactionData);

      return updatedTransactionData; // Return the updated transaction
    },
    async applyTransactionRules(transaction, rules) {
      // Log the original transaction details
      console.log("Original transaction:", transaction);

      // Iterate through each rule and apply if the transaction matches
      for (const rule of rules) {
        if (this.doesTransactionMatchRule(transaction, rule.conditions)) {
          // If transaction matches, prepare updated transaction data
          const updatedTransactionData = { ...transaction, ...rule.newValues };

          // Ensure the labels field is an array
          if (!transaction.labels) {
            transaction.labels = [];
          }

          // Merge the existing labels with the new labels from the rule
          if (rule.newValues.labels) {
            updatedTransactionData.labels = Array.from(new Set([...transaction.labels, ...rule.newValues.labels]));
          }

          // Log the updated transaction details
          console.log("Updated transaction:", updatedTransactionData);

          return updatedTransactionData;
        }
      }

      // Return original transaction if no rule matches
      return transaction;
    },
    doesTransactionMatchRule(transaction, conditions) {
      let nameMatches = true;
      let amountMatches = true;
      let categoryMatches = true;

      if (conditions.name) {
        const transactionNameLower = transaction.name.toLowerCase();
        const conditionNameLower = conditions.name.value.toLowerCase();

        if (conditions.name.matchType === 'Exact Match') {
          nameMatches = transactionNameLower === conditionNameLower;
        } else if (conditions.name.matchType === 'Contains') {
          nameMatches = transactionNameLower.includes(conditionNameLower);
        }
      }

      if (conditions.amount) {
        switch (conditions.amount.matchType) {
          case 'Equals':
            amountMatches = transaction.amount === conditions.amount.value;
            break;
          case 'More than':
            amountMatches = transaction.amount > conditions.amount.value;
            break;
          case 'Less than':
            amountMatches = transaction.amount < conditions.amount.value;
            break;
          case 'Within':
            amountMatches = transaction.amount >= conditions.amount.min && transaction.amount <= conditions.amount.max;
            break;
          default:
            amountMatches = true;
        }
      }

      if (conditions.category) {
        categoryMatches = transaction.category === conditions.category;
      }

      return nameMatches && amountMatches && categoryMatches;
    },
    async calculateAndStoreMetrics() {
        if (this.calculateMetricsTimeout) {
          clearTimeout(this.calculateMetricsTimeout);
        }

        this.calculateMetricsTimeout = setTimeout(async () => {
          console.log("Calculating Transaction Summaries..");
          const transactions = this.$store.state.transactions;

          if (!transactions || transactions.length === 0) {
            console.log("No transactions available for calculations.");
            return;
          }

          const currentSummaryDate = new Date(); // Get current date
          currentSummaryDate.setUTCHours(0, 0, 0, 0); // Convert to start of the day in UTC

          let startDate = new Date(currentSummaryDate); // Use UTC date for startDate



          switch (this.selectedDateOption) {
            case 'week':
              startDate.setDate(currentSummaryDate.getDate() - 7);
              break;
            case 'month':
              startDate = new Date(Date.UTC(currentSummaryDate.getFullYear(), currentSummaryDate.getMonth(), 1, 0, 0, 0));
              break;
            case '30':
              startDate.setDate(currentSummaryDate.getDate() - 30);
              break;
            case '60':
              startDate.setDate(currentSummaryDate.getDate() - 60);
              break;
            case '90':
              startDate.setDate(currentSummaryDate.getDate() - 90);
              break;
            case 'year':
              startDate = new Date(new Date().getFullYear(), 0, 1); // Set to January 1st of the current year
              break;
            case '1year':
              startDate.setFullYear(currentSummaryDate.getFullYear() - 1);
              break;
            case '2year':
              startDate.setFullYear(currentSummaryDate.getFullYear() - 2);
              break;
            case 'all':
              startDate = new Date(Math.min(...transactions.map(t => new Date(t.date).getTime())));
              break;
            // Add more cases as needed
            default:
              // Default to the full range of transactions
              startDate = new Date(Math.min(...transactions.map(t => new Date(t.date).getTime())));
          }

          startDate.setHours(0, 0, 0, 0); 

        
          // Filter transactions based on the start date, ensure amount is positive, and exclude transactions with 'ignore' label
          const filteredSummaryTransactions = transactions.filter(t => {
            const transactionDate = new Date(t.date);
            return transactionDate >= startDate && t.amount > 0 && (!t.labels || !t.labels.includes('ignore'));
          });

          // Check if there are no transactions for the selected period
          this.noTransactionsForPeriod = filteredSummaryTransactions.length === 0;

          if (this.noTransactionsForPeriod) {
            console.log("No transactions for selected period.");
            return;
          }

          // Initialize accumulators
          let totalAmount = 0;
          // let totalsByCategory = {};
          let transactionCounts = {};
          let categoryCounts = {};
          let largestTransaction = filteredSummaryTransactions[0];
          let smallestTransaction = filteredSummaryTransactions[0];
          let dateOfEarliestTransaction = new Date(filteredSummaryTransactions[0].date);
          let dateOfMostRecentTransaction = new Date(filteredSummaryTransactions[0].date);

          filteredSummaryTransactions.forEach(transaction => {
            if (!isNaN(transaction.amount)) { // Check if amount is not NaN
              // Sum the total amount
              totalAmount += transaction.amount;

              // Calculate totals by category
              // if (!totalsByCategory[transaction.category]) {
              //   totalsByCategory[transaction.category] = { total: 0, emoji: transaction.categoryEmoji };
              // }
              // totalsByCategory[transaction.category].total += transaction.amount;

              // Count transactions by name
              transactionCounts[transaction.name] = (transactionCounts[transaction.name] || 0) + 1;

              // Count transactions by category
              categoryCounts[transaction.category] = (categoryCounts[transaction.category] || 0) + 1;

              // Find the largest and smallest transactions
              if (transaction.amount > largestTransaction.amount) {
                largestTransaction = transaction;
              }
              if (transaction.amount < smallestTransaction.amount) {
                smallestTransaction = transaction;
              }

              // Update earliest and latest transaction dates
              const transactionDate = new Date(transaction.date);
              if (transactionDate < dateOfEarliestTransaction) {
                dateOfEarliestTransaction = transactionDate;
              }
              if (transactionDate > dateOfMostRecentTransaction) {
                dateOfMostRecentTransaction = transactionDate;
              }
            }
          });

          // More calculations
          const totalNumberOfTransactions = filteredSummaryTransactions.length;
          const averageTransactionAmount = totalAmount / totalNumberOfTransactions;
          
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth(); // January is 0, December is 11
          const currentDay = currentDate.getDate();
          const timeDifference = currentDate - dateOfEarliestTransaction;
          const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
          const averageTransactionsPerDay = parseFloat((totalNumberOfTransactions / dayDifference).toFixed(2));

          // Filter transactions for the current month up to today
          const currentMonthTransactions = transactions.filter(transaction => {
            const transactionDate = new Date(transaction.date);
            transactionDate.setUTCHours(0, 0, 0, 0);
            return transactionDate.getUTCFullYear() === currentSummaryDate.getUTCFullYear() &&
                  transactionDate.getUTCMonth() === currentSummaryDate.getUTCMonth() &&
                  (!transaction.labels || !transaction.labels.includes('ignore')) &&
                  transaction.categoryGroup !== 'income';
          });

          // Calculate total spend for the current period
          const totalSpendCurrentMonth = currentMonthTransactions
          .reduce((sum, transaction) => transaction.amount > 0 ? sum + transaction.amount : sum, 0);

          // Update formattedTotalSpendCurrentMonth directly based on the calculated value
          this.formattedTotalSpendCurrentMonth = this.formatCurrency(totalSpendCurrentMonth);
            
          let frequencyText;
            if (averageTransactionsPerDay >= 1) {
              frequencyText = `${averageTransactionsPerDay} per day`;
            } else {
              const daysForOneTransaction = Math.round(1 / averageTransactionsPerDay);
              frequencyText = `Once every ${daysForOneTransaction} day${daysForOneTransaction === 1 ? '' : 's'}`;
            }

          const averageDailySpend = totalAmount / dayDifference;

          // Filter transactions for the same period last month
          const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
          const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;
          const lastMonthTransactions = transactions.filter(transaction => {
              const transactionDate = new Date(transaction.date);
              return transactionDate.getFullYear() === previousYear &&
                    transactionDate.getMonth() === previousMonth &&
                    transactionDate.getDate() <= currentDay;
          });

          // Calculate total spend for the same period last month
          const totalSpendLastMonth = lastMonthTransactions.reduce((total, transaction) => {
            return (!isNaN(transaction.amount) && transaction.amount > 0) ? total + transaction.amount : total; // Skip NaN and negative amounts
          }, 0);


          // Calculate the difference
          const spendDifference = totalSpendCurrentMonth - totalSpendLastMonth;

          // Update the spendDifferenceMessage directly
          if (isNaN(spendDifference)) {
            this.spendDifferenceMessage = "You haven't spent any money so far this month.";
          } else {
            const formattedAmount = this.formatCurrency(parseFloat(Math.abs(spendDifference)).toFixed(2));
            const redOrGreen = spendDifference >= 0 ? 'red' : 'green';
            const moreOrLess = spendDifference >= 0 ? 'more' : 'less';
            this.spendDifferenceMessage = `You've spent <span style="color: ${redOrGreen};">${formattedAmount}</span> ${moreOrLess}<br> than this time last month`;
          }

          // Determine most common category
          const mostCommonCategory = Object.keys(categoryCounts).reduce((a, b) => categoryCounts[a] > categoryCounts[b] ? a : b);

          // Determine most frequent transaction
          let mostFrequentTransactionName = null;
          let highestCount = 0;
          for (let name in transactionCounts) {
            if (transactionCounts[name] > highestCount) {
              mostFrequentTransactionName = name;
              highestCount = transactionCounts[name];
            }
          }
          const mostFrequentTransaction = mostFrequentTransactionName ? { name: mostFrequentTransactionName, count: highestCount } : null;

          // Create the metrics object
          const metrics = {
            totalNumberOfTransactions,
            dateOfEarliestTransaction: dateOfEarliestTransaction.toISOString().split('T')[0],
            dateOfMostRecentTransaction: dateOfMostRecentTransaction.toISOString().split('T')[0],
            totalAmountOfAllTransactions: totalAmount,
            averageTransactionAmount,
            frequencyText,
            averageDailySpend,
            largestTransaction,
            smallestTransaction,
            mostCommonCategory,
            mostFrequentTransaction,
            totalSpendCurrentMonth,
            totalSpendLastMonth,
            spendDifference,
          };

            this.totalNumberOfTransactions = metrics.totalNumberOfTransactions;
            this.averageTransactionsPerDay = metrics.averageTransactionsPerDay;
            this.frequencyText = metrics.frequencyText;
            this.dateOfEarliestTransaction = metrics.dateOfEarliestTransaction;
            this.dateOfMostRecentTransaction = metrics.dateOfMostRecentTransaction;
            this.totalAmountOfAllTransactions = metrics.totalAmountOfAllTransactions;
            this.averageTransactionAmount = metrics.averageTransactionAmount;
            this.averageDailySpend = metrics.averageDailySpend;
            this.largestTransaction = metrics.largestTransaction;
            this.smallestTransaction = metrics.smallestTransaction;
            this.mostCommonCategory = metrics.mostCommonCategory;
            this.mostFrequentTransaction = metrics.mostFrequentTransaction;
            this.totalSpendCurrentMonth = metrics.totalSpendCurrentMonth;
            this.totalSpendLastMonth = metrics.totalSpendLastMonth;
            this.spendDifference = metrics.spendDifference;

          // Save the calculated data to Firestore
          // await this.saveMetricsToFirestore(metrics);
          // console.log("Transaction Summaries have been calculated!")
          this.calculateMetricsTimeout = null;
      }, 500); // Adjust the debounce delay (in milliseconds) as needed
    },
    calculateIncome() {
      const transactions = this.$store.state.transactions;
      const currentSummaryDate = new Date();
      currentSummaryDate.setUTCHours(0, 0, 0, 0);

      let startDate = new Date(currentSummaryDate);

      switch (this.selectedDateOption) {
        case 'week':
          startDate.setDate(currentSummaryDate.getDate() - 7);
          break;
        case 'month':
          startDate = new Date(Date.UTC(currentSummaryDate.getFullYear(), currentSummaryDate.getMonth(), 1, 0, 0, 0));
          break;
        case '30':
          startDate.setDate(currentSummaryDate.getDate() - 30);
          break;
        case '60':
          startDate.setDate(currentSummaryDate.getDate() - 60);
          break;
        case '90':
          startDate.setDate(currentSummaryDate.getDate() - 90);
          break;
        case 'year':
          startDate = new Date(new Date().getFullYear(), 0, 1);
          break;
        case '1year':
          startDate.setFullYear(currentSummaryDate.getFullYear() - 1);
          break;
        case '2year':
          startDate.setFullYear(currentSummaryDate.getFullYear() - 2);
          break;
        case 'all':
          startDate = new Date(Math.min(...transactions.map(t => new Date(t.date).getTime())));
          break;
        default:
          startDate = new Date(Math.min(...transactions.map(t => new Date(t.date).getTime())));
      }

      startDate.setHours(0, 0, 0, 0);

      const filteredIncomeTransactions = transactions.filter(t => {
        const transactionDate = new Date(t.date);
        return transactionDate >= startDate && t.amount < 0 && (!t.labels || !t.labels.includes('ignore'));
      });

      const totalIncome = filteredIncomeTransactions.reduce((sum, t) => sum + Math.abs(t.amount), 0);

      return totalIncome;
    },

    // You might want to add a method to update the income in your component's data
    updateIncome() {
      this.totalIncome = this.calculateIncome();
    },
    async saveMetricsToFirestore(metrics) {
      const docRef = doc(db, `users/${auth.currentUser.uid}/totals/spendingTotals`);

      try {
        await updateDoc(docRef, metrics);
      } catch (error) {
        console.error("Error saving metrics to Firestore:", error);
      }
    },


// FORMATTING & DATES
    formatCurrency(value) {
      if (typeof value !== 'number') {
        value = Number(value);
      }
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    },
    formatDisplayAmount(value) {
      if (value < 0) {
        // Convert to positive and prepend with '+'
        return '+' + this.formatCurrency(Math.abs(value));
      }
      return this.formatCurrency(value);
    },
    formatTransactionDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const date = new Date(dateString + 'T00:00');
      return date.toLocaleDateString('en-US', options);
    },
    formatTransactionDateShort(dateString) {
      const currentYear = new Date().getFullYear();
      const transactionDate = new Date(dateString + 'T00:00');
      const transactionYear = transactionDate.getFullYear();

      const options = transactionYear === currentYear
        ? { month: 'short', day: 'numeric' } 
        : { year: 'numeric', month: 'short', day: 'numeric' };

      const formattedDate = transactionDate.toLocaleDateString('en-US', options);
      const dayOfWeek = transactionDate.toLocaleDateString('en-US', { weekday: 'long' });

      return { 
        shortDate: formattedDate, 
        fullDate: `${formattedDate} - ${dayOfWeek}`
      };
    },

    getCurrentDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0
      let yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },



// LOAD TRANSACTIONS
fetchMoreTransactions() {
    // Check if we have more transactions to load
    if (this.loadStep >= this.$store.state.transactions.length) {
      this.hasMoreTransactions = false; // No more transactions to load
      return;
    }

    // Increase loadStep to load more transactions
    this.loadStep += 60; // Increment the load step by the size of one page
  },




// EDIT TRANSACTION
handleCheckboxClick(event, clickedTransaction) {
  if (event.target.id === 'select-all') {
    // Handle the "Select All" checkbox click
    if (event.target.checked) {
      const allTransactions = Object.values(this.groupedTransactionsDescending).flat();
      this.selectedTransactions = [...allTransactions];
    } else {
      this.selectedTransactions = [];
    }
  } else {
    // Handle individual transaction checkbox clicks
    if (event.shiftKey && this.lastClickedTransaction) {
      const flattenedTransactions = Object.values(this.groupedTransactionsDescending).flat();
      const start = flattenedTransactions.indexOf(this.lastClickedTransaction);
      const end = flattenedTransactions.indexOf(clickedTransaction);
      const startIndex = Math.min(start, end);
      const endIndex = Math.max(start, end);

      const transactionsToSelect = flattenedTransactions.slice(startIndex, endIndex + 1);
      if (event.target.checked) {
        // Select all transactions in the range
        transactionsToSelect.forEach(transaction => {
          if (!this.selectedTransactions.includes(transaction)) {
            this.selectedTransactions.push(transaction);
          }
        });
      } else {
        // Deselect all transactions in the range
        this.selectedTransactions = this.selectedTransactions.filter(transaction => !transactionsToSelect.includes(transaction));
      }
    } else {
      if (event.target.checked && !this.selectedTransactions.includes(clickedTransaction)) {
        this.selectedTransactions.push(clickedTransaction);
      } else {
        this.selectedTransactions = this.selectedTransactions.filter(transaction => transaction !== clickedTransaction);
      }
    }
    this.lastClickedTransaction = clickedTransaction; // Remember the last clicked transaction
  }
},
    async updateTransactionCategory(transaction) {
      try {
        // Check if the selected category is different from the previous category
        if (transaction.category !== this.previousCategory) {
          // Update the transaction in Firestore
          const transactionRef = doc(db, `users/${this.userId}/transactions`, transaction.id);
          await updateDoc(transactionRef, {
            category: transaction.category
          });

          // Commit the updated transaction to the Vuex store
          this.$store.commit('updateTransaction', transaction);

          // Show the update notification
          const detailMessage = `Updated category from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${this.previousCategory}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${transaction.category}</p>`;
          this.showUpdateNotification(transaction.name, detailMessage);
        }
      } catch (error) {
        console.error('Error updating transaction category:', error);
      }
    },

    updateTransactionAmount(transaction, value) {
      let parsedValue = parseFloat(value.replace(/[$,]/g, ''));

      // Check if the original amount was negative (credit) and if the parsed value is positive
      if (transaction.amount < 0 && parsedValue > 0) {
        // Convert the parsed value to negative to maintain the credit nature
        parsedValue = -parsedValue;
      }

      if (!isNaN(parsedValue)) {
        transaction.amount = parsedValue; // Store as a number, not a string
        this.commitTransaction(transaction); // Commit the transaction after updating the amount
      }
    },

    async updateSelectedTransactions() {
      if (!this.selectedTransactions.length) {
          console.error('No transactions to edit.');
          return;
      }

      try {
        this.bulkEditing = true;
        this.transactionsEdited = 0;
        this.totalTransactionsToBeEdited = this.selectedTransactions.length;

          for (const transaction of this.selectedTransactions) {
              // Only change values when the user has entered a new value
              if (this.newName !== "") {
                  transaction.name = this.newName;
              }

              // check if the new date is not an empty string and it is a valid date before assigning it to the transaction's date
              if (this.newDate !== "" && !isNaN(Date.parse(this.newDate))) {
                  transaction.date = this.newDate;
              }

              // Update the amount field only if a valid newAmount is provided
              if (this.newAmount !== null && this.newAmount !== "") {
                transaction.amount = parseFloat(this.newAmount);
              }

              if (this.newCategory !== "" && this.newCategory !== "Category") {
                  transaction.category = this.newCategory;
              }

              if (this.newLabels && this.newLabels.length > 0) {
                transaction.labels = this.newLabels;
              }

              // Update the transaction in Firestore
              const transactionId = transaction.id;
              const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);
              await updateDoc(doc(transactionsCollection, transactionId), transaction);

              // Commit the updated transaction to Vuex
              this.$store.commit('updateTransaction', transaction);

              this.transactionsEdited++;
          }

          // Reset after operation is complete
          this.bulkEditing = false;
          this.transactionsEdited = 0;
          this.totalTransactionsToBeEdited = 0;

          // Clear the form and selected transactions
          this.newName = '';
          this.newDate = '';
          this.newAmount = null;
          this.newCategory = '';
          this.selectedTransactions = [];
          this.newLabels = [];
          this.isMultiEditMode = false;
      } catch (error) {
          console.error('Error updating transactions:', error);
      }
    },
    cancelEditing() {
      this.editingTransactionIndex = null;
      this.editingTransactionField = null;
      this.editedTransaction = null;

      // Deselect all transactions
      this.selectedTransactions = [];

      // Toggle multi-edit mode off
      this.isMultiEditMode = false;

      // Uncheck the 'Select All' checkbox
      this.selectAll = false;
    },
    async commitTransaction(transaction) {
      await this.saveTransaction(transaction);
      this.cancelEditing();
    },
    startEditing(transactionId, field) {
      this.editingTransactionField = field;
      this.editingTransactionIndex = this.transactions.findIndex(t => t.id === transactionId);
      const transaction = this.$store.state.transactions.find(t => t.id === transactionId);

      if (!transaction) {
        console.error('Transaction not found for editing:', transactionId);
        return;
      }

      // Capture the old values
      this.editedTransaction = {
        ...transaction, // Clone the current transaction
        oldValues: {
          id: transaction.id, // Add the transaction ID
          amount: transaction.amount,
          category: transaction.category,
          date: transaction.date,
          name: transaction.name,
        }
      };
      console.log("Edited transaction set:", this.editedTransaction);
    },
    isTransactionBeingEdited(index, field) {
      return this.editingTransactionIndex === index && this.editingTransactionField === field;
    },
    async saveTransaction(transaction) {
      try {

        console.log('Saving transaction:', transaction);

        if (this.editedTransaction && transaction.id === this.editedTransaction.id) {
        const transactionId = transaction.id;
        const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);

        // Create history entry
        const historyEntry = this.createHistoryObject(this.editedTransaction, transaction);
        this.lastEditedTransactionHistory = historyEntry;
        this.lastEditedTransaction = transaction;



        // Check if changes object is not empty
        if (Object.keys(historyEntry.changes).length > 0) {
          // Update the transaction with the new values and append history
          await updateDoc(doc(transactionsCollection, transactionId), {
            ...transaction,
            history: arrayUnion(historyEntry)
          });
        } else {
          // Update the transaction without appending history
          await updateDoc(doc(transactionsCollection, transactionId), transaction);
        }

        let message = '';

        const oldId = this.editedTransaction.oldValues['id'];
        const newId = transaction.id;
        // Check which field was edited and create an appropriate message
        switch (this.editingTransactionField) {
          case 'category':
            const oldCategory = this.previousCategory;
            const newCategory = transaction.category;
            if (oldCategory !== newCategory) {
              const detailMessage = `Updated category from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldCategory}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newCategory}</p>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }
            break;
          case 'amount':
            const oldAmount = parseFloat(this.editedTransaction.oldValues['amount']).toFixed(2);
            const newAmount = parseFloat(transaction.amount).toFixed(2);

            if (oldAmount !== newAmount  && oldId === newId) {
              const detailMessage = `Updated amount from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldAmount}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newAmount}</p>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }

            break;
          case 'name':
            const oldName = this.editedTransaction.oldValues['name'];
            const newName = transaction.name;
            if (oldName !== newName && oldId === newId) {
              const detailMessage = `Updated name from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldName}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newName}</p>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }
            break;
          case 'labels':
            const oldLabels = this.editedTransaction.oldValues['labels'] || [];
            const newLabels = transaction.labels || [];
            const oldLabelsFormatted = oldLabels.join(', ');
            const newLabelsFormatted = newLabels.join(', ');
            if (oldLabelsFormatted !== newLabelsFormatted) {
              const detailMessage = `Updated labels from <span style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldLabelsFormatted}</span> to <span style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newLabelsFormatted}</span>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }
            break;
            }
            this.$store.commit('updateTransaction', transaction);
            // After saving the transaction
            this.lastEditedTransactionNewValues = {
              name: transaction.name,
              amount: transaction.amount,
              category: transaction.category,
            };
            
            // Show the notification if there's a message
            if (message) {
              this.showUpdateNotification(message);
            }
          } else {
        }

        // Reset the editing state
        this.editedTransaction = null;
        this.editingTransactionField = null;
        this.editingTransactionIndex = null;
        this.previousCategory = null;
      } catch (error) {
        console.error('Error updating transaction:', error);
      }
    },
    // Helper function to create a history object
    createHistoryObject(editedTransaction, transaction) {
      const historyEntry = {
        dateModified: new Date().toISOString(), // Current date and time
        changes: {}
      };

      // Check each field for changes and add to the history entry
      for (const key in editedTransaction.oldValues) {
        if (editedTransaction.oldValues[key] !== transaction[key]) {
          historyEntry.changes[`original${key.charAt(0).toUpperCase() + key.slice(1)}`] = editedTransaction.oldValues[key];
        }
      }

      return historyEntry;
    },


// ADD TRANSACTION
    async addTransaction() {
      try {
        // Validate transaction date
        const currentDate = new Date();
        const oneYearFromNowDate = new Date();
        oneYearFromNowDate.setFullYear(currentDate.getFullYear() + 1);
        const oneYearFromNow = oneYearFromNowDate.toISOString().split('T')[0];

        if (this.newTransaction.date < '1980-01-01') {
          this.dateError = 'Dates before Jan 1st, 1980 are not supported';
          return; // Exit the method if the date is invalid
        } else if (this.newTransaction.date > oneYearFromNow) {
          this.dateError = 'Dates more than a year in advance are not supported.';
          return; // Exit the method if the date is invalid
        }

        // Clear the date error
        this.dateError = '';

         // Find the category object from the selected category name
        const selectedCategory = this.categories.find(category => category.name === this.newTransaction.category);

        const transactionToAdd = {
          date: this.newTransaction.date,
          name: this.newTransaction.name,
          amount: this.newTransaction.amount,
          category: this.newTransaction.category,
          // categoryEmoji: selectedCategory.emoji, 
          labels: Array.isArray(this.newTransaction.labels)
            ? this.newTransaction.labels
            : this.newTransaction.labels.split(' ').filter(label => label !== ''),
          manuallyCreated: true // Add this line to include the manuallyCreated field
        };

        // Get the current user
        const user = auth.currentUser;
        const updatedTransaction = await this.applyTransactionRules(transactionToAdd, this.$store.state.rules);
        console.log("Transaction after applying rules:", updatedTransaction); // Diagnostic logging

        // If the user is logged in, use their unique ID to store the transaction
        if (user) {
          const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
          const docRef = await addDoc(transactionsCollection, updatedTransaction);
          // this.$store.commit('addTransaction', { id: docRef.id, ...updatedTransaction });
        } else {
          // Handle case where user is not logged in
          console.log('No user is logged in.');
        }
        this.calculateAndStoreMetrics();
        this.newTransaction = { date: '', name: '', amount: 0, category: '', labels: [] }; // Update to initialize labels as an empty array
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },



// DELETE TRANSACTION
    startDeleteTransaction(transaction) {
      this.transactionToDelete = transaction;
      this.showDeleteConfirmation = true;
    },
    confirmDeleteTransaction() {
      if (this.transactionToDelete) {
        // Your existing logic to delete the transaction
        // For example:
        this.deleteTransaction(this.transactionToDelete.id);

        // Hide the modal and reset the transactionToDelete
        this.showDeleteConfirmation = false;
        this.transactionToDelete = null;
      }
    },
    async deleteTransaction(transactionId) {
      try {
        const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);
        const transactionDoc = doc(transactionsCollection, transactionId);
        const transactionSnap = await getDoc(transactionDoc);
        const transactionData = transactionSnap.data();

        await deleteDoc(transactionDoc);
        this.$store.commit('deleteTransaction', transactionId);

        // If the transaction is part of a recurring rule
        if (transactionData.recurringId) {
          // Fetch the recurring rule
          const ruleDoc = doc(db, `users/${auth.currentUser.uid}/recurringRules`, transactionData.recurringId);
          const ruleSnap = await getDoc(ruleDoc);
          const ruleData = ruleSnap.data();

          // Remove the transaction from the rule's transactions array
          const updatedTransactions = ruleData.transactions.filter(t => t.id !== transactionId);

          // Update the rule in Firestore
          await updateDoc(ruleDoc, {
            transactions: updatedTransactions,
            total: ruleData.total - transactionData.amount,
            minAmount: Math.min(...updatedTransactions.map(t => t.amount)),
            maxAmount: Math.max(...updatedTransactions.map(t => t.amount)),
            numberOfTransactions: updatedTransactions.length
          });
        }
        this.calculateAndStoreMetrics();
        console.log(`Transaction ${transactionId} and its reference in the recurring rule have been deleted.`);
      } catch (error) {
        console.error('Error deleting transaction:', error);
      }
    },
    async deleteSelectedTransactions() {
      if (window.confirm('Are you sure you want to delete the selected transactions? This cannot be undone.')) {
        this.bulkDeleting = true;
        this.transactionsEdited = 0;
        this.totalTransactionsToBeEdited = this.selectedTransactions.length;

        try {
          const promises = this.selectedTransactions.map(async transaction => {
            // Create a reference to the document in the Firestore collection
            const transactionRef = doc(db, `users/${auth.currentUser.uid}/transactions`, transaction.id);
            // Delete the transaction in the Firestore collection
            await deleteDoc(transactionRef);

            // Commit mutation to Vuex store for deleting the transaction
            this.$store.commit('deleteTransaction', transaction.id);

            // If the transaction is part of a recurring rule
            if (transaction.recurringId) {
              // Fetch the recurring rule
              const ruleDoc = doc(db, `users/${auth.currentUser.uid}/recurringRules`, transaction.recurringId);
              const ruleSnap = await getDoc(ruleDoc);
              if (ruleSnap.exists()) {
                const ruleData = ruleSnap.data();

                // Remove the transaction from the rule's transactions array
                const updatedTransactions = ruleData.transactions.filter(t => t.id !== transaction.id);

                // Update the rule in Firestore
                await updateDoc(ruleDoc, {
                  transactions: updatedTransactions,
                  total: updatedTransactions.reduce((total, t) => total + t.amount, 0),
                  minAmount: updatedTransactions.length ? Math.min(...updatedTransactions.map(t => t.amount)) : 0,
                  maxAmount: updatedTransactions.length ? Math.max(...updatedTransactions.map(t => t.amount)) : 0,
                  numberOfTransactions: updatedTransactions.length
                });
              }
            }
            // Update progress
            this.transactionsEdited++;
          });

          await Promise.all(promises);
          this.calculateAndStoreMetrics();
          this.cancelEditing();
          this.isMultiEditMode = false;
        } catch (error) {
          console.error('Error deleting transactions:', error);
        } finally {
          // Reset progress and loading state
          this.bulkDeleting = false;
          this.transactionsEdited = 0;
          this.totalTransactionsToBeEdited = 0;
        }
      }
    },





// TRANSACTION UPDATED NOTIFICATION
    showUpdateNotification(transactionName, detailMessage) {
      const notification = {
        transactionName: transactionName,
        detailMessage: detailMessage,
        timeout: null,
      };

      this.notifications.push(notification);

      // Set a timeout to remove this notification after 10 seconds
      notification.timeout = setTimeout(() => {
        const index = this.notifications.indexOf(notification);
        if (index > -1) {
          this.notifications.splice(index, 1);
        }
      }, 10000);
    },


// RECURRING RULES MODAL
    async toggleRecurring(transaction) {
        const rulesRef = collection(db, `users/${this.userId}/recurringRules`);

        // If the transaction has a recurringId
        if (transaction.recurringId && transaction.recurringName !== 'Not Recurring') {

        const rulesSnapshot = await getDocs(rulesRef);
        this.allRecurringRules = rulesSnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));

          // Open the modal to confirm deletion
            this.isModalOpen = true;
            this.selectedRecurringTransaction = transaction;
        } else {
            // Fetch all rules and populate allRecurringRules for the modal dropdown
            const rulesSnapshot = await getDocs(rulesRef);
            this.allRecurringRules = rulesSnapshot.docs.map(docSnap => ({ id: docSnap.id, name: docSnap.data().name }));

            // Store the transaction you want to make recurring
            this.selectedRecurringTransaction = transaction;

            // Open the modal
            this.isModalOpen = true;
        }
    },

// PLAID
    findBestCategoryMatch(plaidCategory) {
      let myCategory = 'Uncategorized';

      if (plaidCategory) {
        let categoryKey = plaidCategory.toLowerCase();
        let mappedCategories = Object.keys(this.categoryMap).map(key => key.toLowerCase());

        let matchIndex = mappedCategories.indexOf(categoryKey);
        if (matchIndex > -1) {
          // Get the original key (with original case) from the category map
          let originalKey = Object.keys(this.categoryMap)[matchIndex];
          myCategory = this.categoryMap[originalKey];
        }
      }

      return myCategory;
    },

    
    async fetchExistingTransactionsIdentifiers(transactionsCollection) {
      console.log("Running fetchExistingTransactionsIdentifiers");
      const snapshot = await getDocs(transactionsCollection);
      const identifiers = new Set();
      const plaidIds = new Set(); 
      
      snapshot.forEach(doc => {
        const data = doc.data();
        // console.log(`Transaction ID: ${doc.id}, History:`, data.history);

        let historicalName = data.name;
        let historicalAmount = data.amount;

        // Iterate through the history array
        if (data.history && data.history.length) {
          for (const historyEntry of data.history) {
            // Access the 'changes' map for originalName and originalAmount
            if (historyEntry.changes.originalName) {
              historicalName = historyEntry.changes.originalName;
            }
            if (historyEntry.changes.originalAmount) {
              historicalAmount = historyEntry.changes.originalAmount;
            }
            if (historicalName && historicalAmount) break;
          }
        }

        const identifier = `${data.date}-${historicalAmount}-${historicalName}`;
        identifiers.add(identifier);

        if (data.plaidId) {
          plaidIds.add(data.plaidId);
        }
      });

      return { identifiers, plaidIds }; // Return both sets
    },



// LABELS
    startEditingLabels(transaction) {
      this.editingTransactionField = 'labels';
      this.editedTransaction = {
        ...transaction,
        oldValues: {
          // ... other fields if needed
          labels: [...transaction.labels],
        }
      };
    },
    enableLabelDropdown(transaction) {
      transaction.showDropdown = true;
    },
    disableLabelDropdown(transaction) {
      transaction.showDropdown = false;
    },
    openLabelManager(transaction) {
      this.editingTransaction = transaction;
      this.isLabelManagerVisible = true;
    },
    closeLabelManager() {
      this.isLabelManagerVisible = false;
      this.editingTransaction = null;
    },
    isSelectedLabel(transaction, label) {
      return Array.isArray(transaction.labels) && transaction.labels.includes(label);
    },



// FILTERS
    toggleFilters() {
        if (this.showFilters) {
            this.clearAllFilters();
        }
        this.showFilters = !this.showFilters;
    },
    clearAllFilters() {
        this.filters = {
            category: '',
            transactionName: '',
            fromDate: '',
            toDate: '',
            minAmount: null,
            maxAmount: null,
            manuallyCreated: false
        };
        this.filterLabels = [];
        console.log("Filters cleared:", this.filters, this.filterLabels);
    },



// CATEGORY
    storePreviousCategory(transaction) {
      this.previousCategory = transaction.category;
      this.editingTransactionField = 'category';


      // Capture the old category value
      if (!this.editedTransaction || this.editedTransaction.id !== transaction.id) {
        this.editedTransaction = {
          ...transaction,
          oldValues: {
            // ... other fields if needed
            category: transaction.category,
          }
        };
      }
    },
    checkCategory(transaction) {
      if (!this.categories.some(category => category.name === transaction.category)) {
        this.previousCategory = transaction.category;
      }
    },
    restoreCategory(transaction) {
      if (!this.categories.some(category => category.name === transaction.category)) {
        transaction.category = this.previousCategory;
        this.updateTransactionCategory(transaction); // Call updateTransactionCategory to save the restored category
      }
    },



// CSV
    openFileInput() {
      if (this.showCsvImport) {
        // If the CSV import section is already shown, trigger the file input click
        this.$refs.fileInput.click();
      } else {
        // Otherwise, show the CSV import section first
        this.showCsvImport = true;
        // Use nextTick to wait for the next DOM update cycle to complete
        this.$nextTick(() => {
          this.$refs.fileInput.click();
        });
      }
    },
    validateAndImport() {
      const requiredFields = ['name', 'amount', 'date', 'category'];
      let isValid = true;

      // Reset all error messages
      requiredFields.forEach(field => {
        this.errors[field] = '';
      });

      // Check each required field and set error messages if necessary
      requiredFields.forEach(field => {
        if (this.columnMappings[field] === '') {
          this.errors[field] = 'Please select a column';
          isValid = false;
        }
      });

      if (isValid) {
        this.processCsv();
      }
    },
    async handleCsvFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const csvData = await this.readFileContent(file);
          this.fullCsvData = csvData; // Store the full CSV data
          const csvHeaders = await this.parseCsvFile(file);
          this.csvHeaders = csvHeaders;
          this.columnMappings = this.initializeColumnMappings(csvHeaders);
          this.showCSVModal = true; // Show the modal for mapping after file is read
        } catch (error) {
          console.error('Error reading file:', error);
        }
      }
    },
    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (event) => {
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    normalizeHeaderName(header) {
      return header.replace(/['"]/g, '').toLowerCase();
    },
    initializeColumnMappings(csvHeaders) {
      const mappings = {
        name: '',
        amount: '',
        date: '',
        category: '',
        labels: ''
      };

      csvHeaders.forEach((header, index) => {
        const normalizedHeader = this.normalizeHeaderName(header);

        // Check and assign the index if the header matches any field name
        if (normalizedHeader === 'name') {
          mappings.name = index.toString(); // Convert to string
        } else if (normalizedHeader === 'amount') {
          mappings.amount = index.toString();
        } else if (normalizedHeader === 'date') {
          mappings.date = index.toString();
        } else if (normalizedHeader === 'category') {
          mappings.category = index.toString();
        } else if (normalizedHeader === 'labels') {
          mappings.labels = index.toString();
        }
      });

      return mappings;
    },
    parseCsvFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = event.target.result;
          const rows = csvData.split('\n');
          const header = rows[0];
          const columns = header.split(',').map(col => col.trim().replace(/['"]/g, '')); // Remove quotes
          resolve(columns); // Resolve with the header columns
        };
        reader.onerror = (event) => {
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    async processCsvData(csvData, columnMappings) {
      if (typeof csvData !== 'string') {
        console.error('Invalid CSV data:', csvData);
        return { processedTransactions: [], skippedTransactions: [] };
      }
      const rows = csvData.split('\n').slice(1);

      // Filter out empty rows
      const filteredRows = rows.filter(row => row.trim() !== '');

      const csvTransactions = []; // To store valid transactions
      const skippedTransactions = []; // To store details of skipped transactions

      // Extract unique categories from the "category" column
      const categoryColumnIndex = parseInt(columnMappings.category);
      const uniqueCategories = new Set(
        filteredRows.map(row => {
          const rowValues = row.split(',');
          return this.removeQuotes(rowValues[categoryColumnIndex])?.trim();
        })
      );
      this.csvCategories = Array.from(uniqueCategories);

      // Process each row
      filteredRows.forEach(row => {
        const rowValues = row.split(',');

        // Parse amount and check if it's valid
        const amountValue = this.removeQuotes(rowValues[columnMappings.amount])?.trim();
        const amount = Number(amountValue);
        if (isNaN(amount) || amountValue === '') {
          // Add details of the skipped transaction to the skippedTransactions array
          skippedTransactions.push({
            reason: 'Invalid amount',
            rowData: row,
          });
          return;
        }

        // Create and add valid transaction to csvTransactions array
        const transaction = {
          date: this.formatDate(this.removeQuotes(rowValues[columnMappings.date])),
          name: this.removeQuotes(rowValues[columnMappings.name])?.trim() || 'No Name',
          amount: amount,
          category: this.removeQuotes(rowValues[columnMappings.category])?.trim() || 'Uncategorized',
          labels: columnMappings.labels ? this.removeQuotes(rowValues[columnMappings.labels])?.trim().split(';') : []
        };
        csvTransactions.push(transaction);
      });

      // Return both processed and skipped transactions
      return { processedTransactions: csvTransactions, skippedTransactions: skippedTransactions };
    },
    async processCsv() {
      console.log("CSV Data:", this.fullCsvData);

      const { processedTransactions, skippedTransactions } = await this.processCsvData(this.fullCsvData, this.columnMappings);
      this.processedTransactions = processedTransactions;

      await this.mapCategories();
      this.displaySkippedTransactions(skippedTransactions);
    },
    async mapCategories() {
      // Create an object to store the category mappings
      this.categoryMappings = {};

      // Initialize the category mappings with empty values
      this.csvCategories.forEach(category => {
        this.categoryMappings[category] = '';
      });

      return new Promise((resolve) => {
        // Show the category mapping modal
        this.showCategoryMappingModal = true;

        // Create a watcher to detect when the modal is closed
        const unwatch = this.$watch('showCategoryMappingModal', (newValue) => {
          if (!newValue) {
            // Modal is closed, resolve the promise
            unwatch(); // Unregister the watcher
            console.log("Category Mappings:", this.categoryMappings); // Log the category mappings
            resolve();
          }
        });
      }).then(() => {
        return this.processCsvDataWithCategoryMappings();

      });
    },
    async processCsvDataWithCategoryMappings() {
      const mappedTransactions = this.processedTransactions.map(transaction => {
        const mappedCategory = this.categoryMappings[transaction.category] || 'Uncategorized';
        console.log("Original Category:", transaction.category, "Mapped Category:", mappedCategory);
        return {
          ...transaction,
          category: mappedCategory,
        };
      });

      console.log("Mapped Transactions:", mappedTransactions);
      await this.handleCsvTransactions(mappedTransactions);
      this.showCSVModal = false; // Close the CSV modal after handling transactions
    },
    displaySkippedTransactions(skippedTransactions) {
      if (skippedTransactions.length > 0) {
        // Example: Display skipped transactions in the console
        // You can replace this with your preferred method of displaying
        console.log('Skipped Transactions:', skippedTransactions);

        // Alternatively, you can set this data to a component data property
        // and display it in your template (e.g., in a modal or a table)
        this.skippedTransactionsData = skippedTransactions;
      } else {
        console.log('No transactions were skipped.');
      }
    },
    removeQuotes(string) {
      if (string == null) {
        return '';
      }
      return string.replace(/['"]/g, '');
    },
    formatDate(dateString) {
      if (!dateString) {
        console.error('Invalid or missing date:', dateString);
        return ''; // Return a default value if the date is invalid
      }

      // Check if dateString is in the format 'YYYY-MM-DD'
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (isoDateRegex.test(dateString)) {
        // Return the date as is since it's in the correct ISO format
        return dateString;
      }

      // Check and handle other expected formats, e.g., 'MM/DD/YYYY' or 'M/D/YY'
      const parts = dateString.split('/');
      if (parts.length === 3) {
        let [month, day, year] = parts;
        
        // Parse the month and day as numbers
        month = parseInt(month, 10);
        day = parseInt(day, 10);

        // If the year is in two-digit format, determine the century
        if (year.length === 2) {
          const currentYear = new Date().getFullYear();
          const currentCentury = Math.floor(currentYear / 100);
          const yearNumber = parseInt(year, 10);
          
          // Assume the year belongs to the current century if it's less than or equal to the current year's last two digits
          // Otherwise, assume it belongs to the previous century
          year = yearNumber <= currentYear % 100 ? currentCentury * 100 + yearNumber : (currentCentury - 1) * 100 + yearNumber;
        }
        
        // Create a new Date object with the parsed values
        const date = new Date(year, month - 1, day);
        
        // Format the date in the desired 'YYYY-MM-DD' format
        const formattedDate = date.toISOString().slice(0, 10);
        return formattedDate;
      }

      // Log error and return a default value for unexpected formats
      console.error('Unexpected date format:', dateString);
      return ''; // Return a default value
    },
    async handleCsvTransactions(csvTransactions) {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user is logged in.');
        return;
      }

      this.importing = true;
      this.totalTransactions = csvTransactions.length;
      this.transactionsImported = 0;

      const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
      const existingIdentifiers = await this.fetchExistingTransactionsIdentifiers(transactionsCollection);

      const transactionsToAdd = csvTransactions.map(transaction => {
        return {
          id: '',
          date: transaction.date,
          name: transaction.name,
          amount: transaction.amount,
          category: transaction.category, 
          labels: [],
        };
      });

      // Write new transactions to Firestore
      for (const transaction of transactionsToAdd) {
        console.log('Adding transaction:', transaction); // Log the transaction being added

        try {
          const updatedTransaction = await this.applyTransactionRules(transaction, this.$store.state.rules);
          console.log("Updated Transaction:", updatedTransaction);
          const docRef = await addDoc(transactionsCollection, updatedTransaction);
          updatedTransaction.id = docRef.id;
          await updateDoc(docRef, updatedTransaction);
          this.transactionsImported++;
        } catch (error) {
          console.error('Error adding document:', error);
        }
      }

      if (this.transactionsImported === this.totalTransactions) {
        this.importing = false;
      }
    },
    findCsvCategoryMatch(csvCategory) {
      let matchedCategory = 'Uncategorized'; // Default category
      if (this.categoryMap[csvCategory]) {
        matchedCategory = this.categoryMap[csvCategory];
      } else {
        const categoryExists = this.categories.some(category => category.name === csvCategory);
        if (categoryExists) {
          matchedCategory = csvCategory;
        }
      }
      return matchedCategory;
    },
    async fetchCategoryMap() {
      const docRef = doc(db, `users/${auth.currentUser.uid}/mappings`, 'categoryMap');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.categoryMap = docSnap.data(); // Assuming this.categoryMap is defined in data()
      } else {
        console.log("No category map document found!");
      }
    },
    exportTransactions() {
      // Access transactions from Vuex store
      const csvData = this.$store.state.transactions.map(transaction => ({
        'Transaction Date': transaction.date,
        'Name': transaction.name,
        'Amount': transaction.amount,
        'Category': transaction.category,
        'Labels': transaction.labels ? transaction.labels.join(', ') : ''
      }));

      const csv = convertToCsv(csvData);
      const csvFile = new Blob([csv], { type: 'text/csv' });
      const csvUrl = URL.createObjectURL(csvFile);

      const link = document.createElement('a');
      link.href = csvUrl;
      link.download = 'transactions.csv';
      link.click();
    },
  }
};
</script>

<style scoped>

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.add-transaction-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  padding-right: 20px;
}

.table-options {
  margin: 0px 20px 20px 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.spending-view {
  /* width: 80vw; */
  margin: 0 auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  padding: 8px;
  text-align: left;
  font-family: unset;
}

td {
  padding: 4px;
  text-align: left;
  font-family: system-ui;
}

.date-td {
  color: #003f76;
  padding-left: 18px;
}

.checkbox-td {
  padding-left: 10px;
}

td:not(.date-td):not(.checkbox-td):not(.delete-td):not(.emoji-td):not(.labels-td):hover {
  outline: #e3e3e3 solid thin;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}
td.labels-td:hover {
  outline: none;
  background-color: unset;
  cursor: pointer;
}
td:not(.delete-td,.emoji-td,):hover {
  outline: #e3e3e3;
  outline-style: solid;
  border-radius: 5px;
  outline-width: thin;
  background-color: white;
  cursor: pointer;
}

th {
  background-color: #ffffff;
}

th.text-left, td.text-left {
  text-align: left;
}

tr:not(.date-row,):hover {
  background-color: #fbfafa;
}

tr {
  height: 14px;
}

.date-row {
  background: #a5d8d82e;
  font-size: 18px;
  font-weight: 700;
  font-family: sans-serif;
  height: 35px;
  border-radius: 5px;
}

input {
  border: none;
  background: none;
}


.input-field {
  position: relative;
  width: -webkit-fill-available;
}

.input-field input,
.input-field select {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 14px;
  color: gray;
  font-family: system-ui;
}
.input-field input:focus,
.input-field select:focus {
  outline: none;
  background-color: #e0e0e0;
}

.input-field select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-field select::-ms-expand {
  display: none;
}
.input-field select option:hover {
  background-color: #e0e0e0;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

button {
  white-space: nowrap;
  font-size: .75rem;
  margin: 5px;
}

.delete-button {
  transition: color 0.3s ease, font-size 0.3s ease, font-weight 0.3s ease;
  padding: 5px 10px;
  margin: 0px auto;
  border: none;
  border-radius: 5px;
  background-color: #ffffff00;
  color: #dfdfdf;
  font-size: 14px;
  cursor: pointer;
  font-weight: bolder;
  display: inline-block;
  opacity: 0;
}

tr:hover .delete-button {
    opacity: 1; 
}

.delete-button:hover {
  background-color: white;
  font-weight: 800;
  color: darkgoldenrod;
}
.delete-multi-button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
.delete-multi-button:hover {
  background-color: rgb(255, 43, 43);
  color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input:focus-visible {
  outline: none;
  transition: 0.3s ease-in-out;
  /* height: 30px; */
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
}
select:focus-visible:not(.edit-multiple-select):not(.filter-label-select) {
  outline: none;
}

.csvimport {
  display: none;
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-multiple-options {
  display: flex;
}

.filter-row {
  display: flex;
  gap: 10px;
  text-align: right;
}

.filter-column {
  flex: 1;
  align-items: center;
}


.filter-class {
  width: -webkit-fill-available;
  padding: 10px;
  margin: 4px;
  height: 56px;
  background-color: #ffffff;
  border-radius: 5px;
  color: gray;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}


.filter-container {
  padding: 1% 3%;
  border-radius: 10px;
}

.filter-label {
  width: 120px;
  text-align: left;
  font-weight:700;
  border: none;
}

#category-filter {
  width: -webkit-fill-available;
  border: none;
  height: 56px;
  /* margin-left: 0; */
  color: grey;
  padding: 10px;
  margin: 4px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: solid;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}

.filter-label-select {
  width: -webkit-fill-available;
  border: none;
  overflow: auto;
  appearance: none;
  /* margin-left: 0; */
  border-radius: 5px;
  color: grey;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  margin: 4px;
  padding-bottom: 4px;
  font-family: system-ui;
}



.filter-label-select option {
  background-color: #f4f4f4;
  width: fit-content;
  padding: 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  font-family: system-ui;
}

.filter-label-select option:disabled {
  background-color: #fff;
  font-family: system-ui;
}

.category-dropdown {
  border: none;
  text-align: right;
  padding-right: 0;
  background: none;
  border-radius: 3px;
  overflow-y: auto;
  width: 200px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator,
input[type="date"]:focus::-webkit-calendar-picker-indicator {
  visibility: visible;
}

.labels {
  color: #96c4ff;
  background: #F4F5F7;
  border-radius: 3px;
  cursor: pointer;
}

  .label-container:hover {
    background-color: white;
  }

  .label-item {
    padding: 4px 6px;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin: 3px;
    font-size: 14px;
    color: grey;
    display: inline-flex;
  }

  .label-item:hover {
    cursor: pointer;
    background-color: #d0d0d0;
  }


.label-dropdown {
  width: 100%;
  height: auto;
  padding: 1px;
  border-radius: 4px;
  overflow: auto;
  border: none;
  background-color: white;
  display: inline-flex;
}

.labels-container {
  max-width: 130px;
  padding: 1px;
  border-radius: 4px;
  overflow: auto;
  white-space: nowrap;       /* Prevent text from wrapping */
  overflow: hidden;          /* Hide overflowing content */
  text-overflow: ellipsis;   /* Display ellipsis (...) for overflow */
  border: none;
  margin: 0 auto;
  }

.label-dropdown option {
  /* Add desired styles for the dropdown options */
  padding: 4px;
  background-color: #f3f3f3;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 4px;
  width: min-content;
  display: inline-flex;
}

.label-dropdown option:checked {
  background-color: #96c4ff;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 3px;
  width: max-content;
  color: #fff;
  display: inline-flex;
}

.edit-multiple-select {
  width: 100%;
  color: grey;
  padding: 10px;
  border-radius: 5px;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  appearance: none;
  overflow: auto;
}

.edit-multiple-select option {
  background: #f4f4f4;
  margin: 4px;
  border-radius: 5px;
  padding: 4px;
  width: min-content;
}

.edit-multiple-select option:checked {
  background-color: #96c4ff;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 3px;
  width: max-content;
  color: #fff;
  display: inline-flex;
}

.edit-multiple-select option:disabled {
  background-color: white;
  margin-top: 0px;
}

.select-label-filter {
  background-color: white;
  margin-top: 0px;
}

.optionbuttons {
  padding-right: 0px;
}

.label-item:hover {
  cursor: pointer;
  background-color: #d0d0d0;
}

.hashtag {
  color: blue !important;
  background-color: red;
}

.categoriesPie {
  /* width: 500px; */
  display: flex;
  margin: auto auto;
}


.chartsdiv {
  display: flex;
  align-items: baseline;
  border-radius: 10px;
  width: fit-content !important;
}

.chartswide {
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  /* background-color: white; */
  border-radius: 10px;
  margin: 2%;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.table-group {
  align-items: center;
  overflow: scroll;
  padding: 2% 0%;
  margin: 2%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.firstrowcharts {
  display: flex;
  justify-content: space-evenly;
}

.edit-multiple-buttons {
  display: flex;
  margin: 2% auto;
  justify-content: left;
}

.edit-multiple-buttons button {
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
}
.parent-edit-multiple-buttons {
  display: flex;
  margin: 0 auto;
  width: fit-content;
}

.notransactions {
  background-color: white;
  grid-area: 2 / 1 / 3 / 3;
  border-radius: 10px;
  padding: 5%;
  margin: 2%;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.categories-td {
  align-items: center;
  cursor: pointer;
}

.emoji-td {
  margin: 0 auto;
  text-align: center;
  padding-left: 0px;
}
.name-td {
  width: 35%;
}

.goal-select {
  padding: 10px 0px;
  background-color: unset;
  width: min-content;
  border: none;
  float: right;
  text-align: center;
  border-radius: 15px;
  font-size: 14px;
  color: gray;
  margin: 1%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}



.amount-td input {
  width: 100px;
  text-align: right;
}

.amount-td {
  margin: 0 auto;
  text-align: right;
}

.labels-td {
  width: 20%;
}


.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh; /* set the height to fit the viewport height */
  overflow: auto; /* allow scrolling */
}

/* .spending-div1 { 
  grid-area: 1 / 1 / 2 / 2; 
  text-align: left;
  padding-left: 5%;
} */
.spending-div2 { grid-area: 1 / 2 / 2 / 3; }
.spending-div3 { grid-area: 2 / 1 / 3 / 2; 
  max-height: calc(100vh - 100px); /* Adjust this value as needed */
  overflow: auto; /* Enable scrolling if content exceeds max-height */
}
.spending-div4 { grid-area: 2 / 1 / 3 / 3; 
}
/* .spending-div4 { 
  grid-area: 2 / 2 / 3 / 3; 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  background-color: #ffffff;
  margin: 5% 5% 0% 0%;
  height: 30vh;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
} */
/* .spending-div5 { grid-area: 3 / 1 / 4 / 3; } */
.spending-div6 { 
  grid-area: 3 / 1 / 6 / 3; 
  font-size: 14px;
}

.sticky-wrapper {
  grid-area: 1 / 3 / 6 / 4; 
  position: sticky; 
  top: 0; 
  display: flex;
  flex-direction: column;
}

.spending-div7 { 
  grid-area: 1 / 3 / 3 / 4; 
  position: sticky; 
  background-color: white;
  /* height: 60%; */
  min-height: 400px;
  top: 0; /* stick to the top */
  display: flex;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 16px;
  margin: 12px 12px 12px 0px;
}
.spending-div8 { 
  grid-area: 3 / 3 / 6 / 4; 
  position: sticky; 
  background-color: #fff;
  top: 0; /* stick to the top */
  height: -webkit-fill-available;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
  border-radius: 16px;
  margin: 12px 12px 12px 0px;
  max-height: calc(100vh - 100px); /* Adjust this value as needed */
  overflow: auto; /* Enable scrolling if content exceeds max-height */
}

.spending-div9 {
  position: sticky;
  background-color: #fff;
  top: 0;
  height: -webkit-fill-available;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
  border-radius: 16px;
  margin: 12px 12px 12px 0px;
  max-height: calc(100vh - 100px);
  overflow: auto;
}

.export-csv-button, .filter-button, .import-csv-button, .create-rule-button2 {
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  padding: 7.5px 12px;
  background: white;
  height: 35px;
  color: black;
  transition: 0.3s ease-in-out;
  margin: 0 4px;
  display: inline-block;
  width: 90px;
}

.export-csv-button:hover, .filter-button:hover, .import-csv-button:hover, .create-rule-button2:hover {
  background-color: #666766;
  color: white;
  transition: 0.3s ease-in-out;
}

/* input::file-selector-button {
  margin: 10px 10px;
  background-color: #f8f7f1;
  color: black;
  width: -webkit-fill-available;
  padding: 20px 0px !important;
  border-radius: 13px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: #f1f0e9;
  outline-style: solid;
} */

.button-row1 {
  display: flex;
  margin: 12px;
}
.button-row2 {
  display: flex;
  margin: 10px;
}

.summary {
  text-align: left;
  padding-top: 10px;
  padding-left: 10px;
  font-size: 14px;
  padding-bottom: 20px;
}

.summary-details{
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  margin-bottom: 6px;
  transition: all .3s ease-in-out;
}

.summary-details:hover {
  font-weight: 800;
  padding-left: 20px;
  margin-right: 10px;
  background-color: #fbfbfb;
  transition: all .3s ease-in-out;
}

.summary-title {
  font-size: 2em;
  margin-left: 20px;
  margin-bottom: 0px;
}

.spending-bar-chart {
  padding: 5%;
}


.add-label-button {
  opacity: 0;
  transition: display 0.3s ease, box-shadow 0.3s ease;
}

.add-label-button:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.labels-td:hover .add-label-button {
  opacity: 1;
  display: flex;
  background-color: #f0f0f0;
  color: grey;
  padding: 5px;
  margin: 0 auto;
  font-size: 12px;
  font-family: unset;
  border-radius: 5px;
}


.select-transactions-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}

.selected-transactions-count {
  text-align: right;
  font-weight: 800;
}

#select-all {
  width: fit-content;
  margin: 10px;
}
.select-all-container {
  text-align: left;
  font-weight: 800;
  width: 150px;
  align-items: center;
}

.categories-select {
  padding: 10px 0px;
  background-color: unset;
  width: min-content;
  border: none;
  float: right;
  text-align: right;
  border-radius: 15px;
  font-size: 14px;
  color: gray;
  margin: 1% 0%;
  width: 180px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

/* .categories-select:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
} */

.emoji-span {
  margin-right: 10px;
}

.category-name {
  padding: 10px;
}

.Loading-more-transactions {
  text-align: left;
  font-weight: 800;
  border-radius: 10px;
  padding: 2%;
  background: #ffffff;
}


.filter-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
}

.filter-sidebar {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  transition: transform 0.5s ease;
  transform: translateX(100%);
}


.filter-sidebar2 {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  transition: transform 0.5s ease;
  transform: translateX(100%);
  display: flex;
}

.filter-sidebar-shown {
  transform: translateX(0);
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter-from, .slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to, .slide-leave-from {
  transform: translateX(0);
}

.transaction-rule-modal-content {

  min-width: 60%;
  background-color: #fff;
  border-radius: 8px;   /* Rounded corners */
  padding: 30px 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  position: relative;
  transition: 0.3s ease-in-out;
}

.modal-field2 label {
  width: 80px;
  margin: 10px 0px;
  text-align: left;
}

.modal-field2 {
  display: flex;
  text-align: left;
}
.csv-column-map-select {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 14px;
  color: gray;
  font-family: system-ui;
  appearance: none;
}

.csv-column-map-select:hover {
  cursor: pointer;
}


.title-bold {
    font-weight: bold;
}

.filter-column-manual {
  float: left;
  text-align: left;
  text-wrap: nowrap;
  width: 30px;
  color: gray;
  margin-top: 10px;
}

.notification-container {
  position: fixed;
  right: 15px;
  width: max-content;
  background-color: #ffffff;
  text-align: left;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  vertical-align: middle;
  min-height: 60px;
  z-index: 100;
  box-shadow: 0px 6px 15px 10px rgb(29 29 29 / 5%);
  transition: bottom 0.3s;
}

.notification-progress {
  position: absolute;
  bottom: 0px;
  left: 0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 5px;
  background-color: #88c6c8; /* or any color of your choice */
  width: 100%;
  animation: decreaseWidth 10s linear;
}

@keyframes decreaseWidth {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.notification-title {
  font-weight: bold;
  margin: 5px;
  padding: 10px;
  font-size: 20px;
}

/* .notification-detail {
 color: black;
} */

.notification-detail {
  font-size: 14px;
  display: flex;
  margin: auto 15px;
}

.create-rule-button {
  margin: 0;
  width: -moz-min-content;
  font-size: 14px;
  border: 1px solid rgb(232 232 232);
  font-weight: 600;
  padding: 20px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #f1f1f1;
  white-space: unset;
  color: #555555;
  transition: 0.3s ease-in-out;
}
.create-rule-button:hover {
  background: #ffffff;
  transition: 0.3s ease-in-out;
}

.current-month-spend {
  font-size: 1.5vw;
  margin: 0px;
  text-align: right;
  padding-right: 18px;
  padding-top: 10px;
  margin-top: -25px;
  color: #006979;
}

.spend-difference {
  font-size: 1vw;
  margin: 0px;
  text-align: right;
  padding-right: 18px;
  padding-top: 10px;
}

.current-month-spend-amount {
  text-align: right;
  margin: 0px;
  color: #23e2ff;
  padding-right: 18px;
  font-size: 4vw;
}

.form-group {
  text-align: left;
  display: grid;
  align-items: center;
  padding: 10px 20px;
  background: #f6eef5;
  border-radius: 6px;
  margin: 10px 0px;
  transition: 0.3s ease-in-out;
  border: 1px solid rgb(218, 225, 234);
}

.form-group input {
  background-color: white;
  border: 1px solid rgb(218, 225, 234);
  padding: 6px;
  margin: 15px 0px;
  border-radius: 3px;
}

.form-group label {
  text-align: left;
}

.categories-select-rule {
  padding: 6px;
  border: 1px solid rgb(218, 225, 234) !important;
  background-color: white;
  width: -moz-min-content;
  border: none;
  float: right;
  text-align: left;
  border-radius: 3px;
  font-size: 14px;
  color: #131313;
  margin: 15px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.add-transaction-button, .edit-multiple-transaction-button, .close-add-transaction-button {
  background-color: #ffffff;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  transition: 0.3s ease-in-out;
  font-family: system-ui;
  width: 150px;
  padding: 8px;
}

.add-transaction-button:hover, .edit-multiple-transaction-button:hover, .close-add-transaction-button:hover {
  background-color: #666766;
  color: white;
  transition: 0.3s ease-in-out;
}

.create-rule-modal-button {
  margin: 10px auto;
  background: #88c6c8;
  transition: 0.3s ease-in-out;
}
.create-rule-modal-button:hover {
  background: #3d6869;
  transition: 0.3s ease-in-out;
}

.match-conditions-group {
  background: #ffffff;
}
.match-conditions-group, .new-values-group {
  width: 50%;
  text-align: left;
  padding: 3%;
}

.new-values-group {
  border-left: 1px solid rgb(218, 225, 234);
}

#close-modal-rule-button {
  color: black;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}

#close-modal-rule-button:hover {
  color: white;
  scale: 1.1;
  transition: 0.3s ease-in-out;
}

.transaction-rules-parent {
  display: flex; /* changed from inline-flex to flex */
  width: 100%; /* ensure it takes full width */
  margin: 0 auto;
  justify-content: space-between; /* Adjust as needed */
  align-items: stretch; /* Adjust as needed */
  border-top: 1px solid rgb(218, 225, 234);
  border-bottom: 1px solid rgb(218, 225, 234);
}

.transaction-rules-parent p {
  font-size: 18px;
  font-weight: 600;
}

#ruleName {
  border: 1px solid rgb(218, 225, 234);
}

.amount-more {
  color: red;
}

.amount-less {
  color: green;
}

.form-group-header {
  display: flex;
  justify-content: space-between; /* Aligns children to opposite ends */
  align-items: center; /* Centers children vertically */
  width: 100%; /* Ensures the header takes the full width of its parent */
  cursor: pointer;
}

/* The switch - the box around the slider */
.transaction-rule-toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px; /* Width of the switch */
  height: 17px; /* Height of the switch */
}

/* Hide default checkbox */
.transaction-rule-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Slider background when it's not checked */
  transition: 0.4s ease-in-out;
  border-radius: 34px; /* Rounded corners for the slider */
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px; /* Height of the slider button */
  width: 13px; /* Width of the slider button */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.3s ease-in-out;
  border-radius: 50%; /* Rounded corners for the slider button */
}

/* When the checkbox is checked, change the slider */
input:checked + .slider {
  background-color: #2196F3; /* Slider background when it's checked */
}

input:checked + .slider:before {
  transform: translateX(13px); /* Move the slider button when it's checked */
}

.updated-transactions-list {
  /* margin-top: 20px; */
  cursor: pointer;
  padding: 10px;
  max-height: 40vh; /* 40% of the viewport height */
  overflow-y: auto; /* Enables vertical scrolling if content exceeds max-height */
  border: 1px solid rgb(218, 225, 234);
  transition: 0.3s ease-in-out;
}

.updated-transactions-list:hover {
  background-color: #f7f6f6;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  transition: 0.3s ease-in-out;
}

.updated-transactions-list ul {
  list-style: none;
  padding: 0;
  transition: 0.3s ease-in-out;
}

.updated-transactions-list li {
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;
  /* Additional styling */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.amount-range {
  display: flex;
  justify-content: space-evenly;
}

.click-to-view {
  color: #292997;
  font-size: 1rem;
  font-weight: 300;
  transition: 0.3s ease-in-out;
}

.edit-multiple-header {
  text-align: left;
  margin: 0;
  font-size: 20px;
}

.csv-import-buttons {
  margin: 20px 10px 0px;
  display: flex;
  justify-content: flex-end;
}







.multi-row {
  display: flex;
  gap: 10px;
  text-align: right;
}

.multi-column {
  flex: 1;
  align-items: center;
}


.multi-class {
  width: -webkit-fill-available;
  padding: 10px;
  margin: 4px;

  background-color: #ffffff;
  border-radius: 5px;
  color: gray;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}


.multi-container {
  padding: 1% 3%;
  border-radius: 10px;
}

.multi-label {
  width: 120px;
  text-align: left;
  font-weight:700;
  border: none;
}

#category-multi {
  width: -webkit-fill-available;
  border: none;
  /* margin-left: 0; */
  color: grey;
  padding: 10px;
  margin: 4px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: solid;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}

.multi-label-select {
  width: -webkit-fill-available;
  border: none;
  overflow: auto;
  appearance: none;
  /* margin-left: 0; */
  border-radius: 5px;
  color: grey;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  margin: 4px;
  height: 200px;
  font-family: system-ui;
}

.multi-label-select option {
  background-color: #f4f4f4;
  width: fit-content;
  padding: 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  font-family: system-ui;
}

.multi-label-select option:disabled {
  background-color: #fff;
  font-family: system-ui;
}

.edit-multi-col2, .edit-multi-col1 {
  width: 50%;
}

.search-transactions {
  width: -webkit-fill-available;
  height: 30px;
  padding: 8px;
  margin: 4px;
  border-radius: 5px;
  color: gray;
  /* outline-style: solid; */
  background-color: white;
  outline-width: thin;
  outline-color: #dcdcdc;
  font-family: system-ui;
}


.select-button {
  background-color: #ffffff00;
  color: gray;
  border: 1px solid #e9e9e9;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}

.select-button:hover {
  background-color: #eeeeee;
  color: black;
  cursor: pointer;
}

.options-row {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
}

.time-select-button {
  background-color: #ffffff00;
  color: #aaaaaa;
  border: none;
  width: 35px;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  margin: 5px;
}

.time-select-button:hover {
  background-color: #bdccd9;
  color: black;
  cursor: pointer;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.time-select-button.active {
  background-color: #c7e6ff !important;
  color: black;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.summary-container {
  display: inline;
  align-items: baseline;
  margin-right: 20px;
  justify-content: space-between;
}

.selected-date-summary {
  color: #78440f;
  margin-left: 20px;
  margin-top: 0px;
  font-size: 18px;
}

.no-transactions-message {
padding: 10%;
}

.review-label {
  border: 1px solid red;
}

.green-amount {
  color: green;
}




.overlay {
  position: fixed; /* Use fixed positioning to cover the entire viewport */
  top: 0;
  left: 0;
  width: 100vw; /* Full viewport width */
  height: 100vh; /* Full viewport height */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 1001; /* Ensure it's above other content */
}

.manual-checkbox {
  margin-left: 10px;
}

.modal-content, .modal-backdrop {
  transition: 0.3s ease-in-out;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(-20px); }
    to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
    from { opacity: 1; transform: translateY(0); }
    to { opacity: 0; transform: translateY(-20px); }
}

.modal-backdrop {
    animation: fadeIn .3s ease-in forwards;
}

.modal-backdrop.hide {
    animation: fadeOut .3s ease-out forwards;
}

.review-transactions-modal-content {
    width: 60%;
    background-color: #fff;
    overflow: scroll;
    max-height: 80%;
    border-radius: 5px;
    padding: 20px 50px 50px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: 0.3s ease-in-out;
    margin: 15% auto;
    border: 1px solid #888;
}

.review-transactions-message {
  width: 70%;
  margin: 20px auto;
  font-size: 18px;
}

.bulk-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.bulk-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  width: 500px;
}

.progress-bar-container {
  width: 100%;
  height: 20px;
  background-color: #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  transition: width 0.3s ease-in-out;
}

.label-dropdown-rule {
  background-color: white;
    border: 1px solid rgb(218, 225, 234);
    padding: 6px;
    margin: 15px 0px;
    border-radius: 3px;
}

.pending-transaction {
  /* font-style: italic; */

}

.pending-transaction> .name-td input {
  font-style: italic;
  color: #5f5f5f;
}

#app {
    display: initial;
}

.csv-column-map-select.error {
  border-color: red;
}


.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.category-mapping {
  margin-bottom: 10px;
  text-align: left;
}



.modal-actions {
  margin-top: 20px;
}

.csv-select {
  text-align: left;
    display: grid;
    align-items: center;
    padding: 10px 20px;
    background: #f6eef5;
    border-radius: 6px;
    width: 100%;
    margin: 10px 0px;
    transition: 0.3s ease-in-out;
    border: 1px solid rgb(218, 225, 234);
}


.v-btn-toggle {
  margin-bottom: 20px;
}

.v-btn {
  height: 30px;
}

.v-btn-toggle>.v-btn {
  margin: 0px;
}


.duplicate-group {
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
}

.duplicate-group table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}

.duplicate-group th {
  background-color: #f0f0f0;
  padding: 10px;
  text-align: left;
}

.duplicate-group td {
  background-color: #ffffff;
}

.duplicate-group tr:hover {
  background-color: #f5f5f5;
}

.positive-cash-flow {
  color: green;
}

.negative-cash-flow {
  color: red;
}


/* Media query for mobile devices */
@media screen and (max-width: 1168px) {
  .parent {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;
    padding: 20px;
  }

  .spending-div2,
  .spending-div3,
  .spending-div6,
  .spending-div7,
  .spending-div8 {
    grid-column: 1 / -1;
    width: 100%;
  }

  .spending-div2 {
    margin-top: 400px;
  }

  .sticky-wrapper {
    position: static;
    grid-column: 1 / -1;
    display: flex;
    flex-direction: column;
  }

  .spending-div7,
  .spending-div8 {
    position: static;
    width: calc(50% - 30px);
    height: 390px;
    min-height: 300px;
    margin: 5px;
    flex-grow: 1;
  }

  .spending-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .spending-div4 {
    grid-column: 1 / -1;
    width: 100%;
  }

  .button-row1 {
    flex-wrap: wrap;
    justify-content: center;
  }

  .export-csv-button,
  .filter-button,
  .import-csv-button,
  .create-rule-button2 {
    width: calc(50% - 10px);
    margin: 5px;
  }

  .table-group {
    overflow-x: auto;
  }

  .categories-select,
  .goal-select {
    width: 100%;
    text-align: left;
  }

  .amount-td input {
    width: 100%;
  }

  .labels-td,
  .name-td {
    width: 100%;
  }
}

/* Additional styles for very small screens */
@media screen and (max-width: 480px) {
  .spending-div7,
  .spending-div8 {
    width: 100%;
    margin: 5px 0;
  }

  .current-month-spend {
    font-size: 4vw;
  }

  .current-month-spend-amount {
    font-size: 8vw;
  }

  .spend-difference {
    font-size: 3vw;
  }

  .summary-title {
    font-size: 1.5em;
  }

  .summary-details {
    font-size: 0.9em;
    padding: 5px 10px;
  }

  .transaction-rule-modal-content {
    padding: 15px;
    min-width: 90%;
  }

  .transaction-rules-parent {
    flex-direction: column;
  }

  .match-conditions-group,
  .new-values-group {
    width: 100%;
    padding: 10px;
  }
}

.daily-sum {
  float: right;
    font-weight: bold;
    font-size: 0.9em;
    color: #003f76;
    padding-right: 32px;
}


</style>
