<template>
  <div class="net-worth">
  </div>
</template>

<script>
export default {
  name: 'NetWorth',
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style scoped>

h2 {
  text-align: center;
}
</style>
