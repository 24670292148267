<template>
  <div class="modal">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h3 style="font-size: 25px;">Select a New Category</h3>
      <p style="font-size: 18px;">Choose a new category for transactions currently under "{{ oldCategoryName }}"</p>
      <select class="category-delete-modal" v-model="selectedCategory">
        <option disabled>Select a Category</option>
        <option v-for="category in availableCategories" :key="category.name" :value="category.name">
          {{ category.emoji }} {{ category.name }}
        </option>
      </select>
      <v-btn @click="confirmSelection">Confirm</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    oldCategoryName: String,
    availableCategories: Array,
    showModal: Boolean
  },
  data() {
    return {
      selectedCategory: ''
    };
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    confirmSelection() {
      if (this.selectedCategory) {
        this.$emit('confirm', this.selectedCategory);
      } else {
        alert('Please select a category.');
      }
    }
  }
};
</script>

<style>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 30%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.category-delete-modal {
  width: 50%;
  padding: 10px;
  margin: 5px;
  border: 1px solid rgb(216 216 216);
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 14px;
  color: gray;
  font-family: system-ui;
  appearance: none;
  cursor: pointer;
}
</style>
