<template>
  <div class="not-found">
    <h1>404</h1>
    <h1>Page Not Found</h1>
    <p>Oops! The page you're looking for does not exist.</p>
    <router-link to="/">Go back to Home</router-link>
  </div>
</template>

<style>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

router-link {
  font-size: 1.2rem;
  color: #0000ff;
  text-decoration: none;
}

router-link:hover {
  text-decoration: underline;
}
</style>

<script>
export default {
  name: 'NotFound',
};
</script>
