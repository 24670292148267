<template>
  <div>
    <canvas v-if="showChart" :id="'lineChart' + chartId" ref="chartCanvas"></canvas>
    <div class="options-row">
      <button class="time-select-button" :class="{ active: dateOption === 'week' }" @click="setDateOption('week')">1W</button>
      <button class="time-select-button" :class="{ active: dateOption === 'month' }" @click="setDateOption('month')">M</button>
      <button class="time-select-button" :class="{ active: dateOption === '30' }" @click="setDateOption('30')">1M</button>
      <button class="time-select-button" :class="{ active: dateOption === '60' }" @click="setDateOption('60')">2M</button>
      <button class="time-select-button" :class="{ active: dateOption === '90' }" @click="setDateOption('90')">3M</button>
      <button class="time-select-button" :class="{ active: dateOption === 'year' }" @click="setDateOption('year')">YTD</button>
      <button class="time-select-button" :class="{ active: dateOption === '1year' }" @click="setDateOption('1year')">1Y</button>
      <!-- <button class="time-select-button" :class="{ active: dateOption === 'all' }" @click="setDateOption('all')">ALL</button> -->
    </div>
  </div>
</template>

<script>
import { Chart, CategoryScale, LinearScale, LineElement, PointElement, Legend, Tooltip } from 'chart.js/auto';
import { ref, onMounted, onBeforeUnmount, nextTick, watchEffect } from 'vue';

Chart.register(CategoryScale, LinearScale, LineElement, PointElement, Legend, Tooltip);

export default {
  name: 'LineChart',
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const showChart = ref(true);
    const dateOption = ref('90');
    const chartId = '1';
    const chartCanvas = ref(null);
    let chartInstance = null;
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


    const setDateOption = (option) => {
      dateOption.value = option;
    }

    const chartOptions = {
      scales: {
        x: {
          display: false,
          grid: {
            display: false
          }
        },
        y: {
          display: false,
          grid: {
            display: false
          },
        }
      },
      plugins: {
        legend: {
          display: true, // Set to true to show the legend
          position: 'left', // You can change the legend position: 'top', 'bottom', 'left', 'right', or 'chartArea'
          labels: {
            color: 'grey', // Change the font color of the legend labels
            font: {
              size: 10, // Change the font size of the legend labels
              weight: 'normal' // You can also use 'bold', 'normal', etc.
            },
            padding: 10, // Add padding between the legend labels and the legend box
            usePointStyle: true, // Set to true if you want to use point style (for scatter plots)
          },
          borderWidth: 0, // Change the border width of the legend box
          borderRadius: 0, // Change the border-radius of the legend box
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // Change the background color of the legend box
        },
        datalabels: {
          color: 'grey',
          display: false,
          anchor: 'end',
          align: 'top',
          formatter: function(value) {
            return '$' + value;
          }
        }
      }
    };

    const renderChart = () => {
      if (!showChart.value) {
        return;
      }

      if (chartInstance) {
        chartInstance.destroy();
      }

      const filteredData = filterDataByDateOption();
      const categories = Array.from(new Set(filteredData.map(entry => entry.category)));

      // Generate a list of all dates within the selected range
      const today = new Date();
      const startDate = new Date();
      switch (dateOption.value) {
        case 'month':
          startDate.setDate(1);
          break;
        case '30':
          startDate.setDate(today.getDate() - 30);
          break;
        case '60':
          startDate.setDate(today.getDate() - 60);
          break;
        case '90':
          startDate.setDate(today.getDate() - 90);
          break;
        case 'year':
          startDate.setMonth(0, 1);
          break;
        default:
          startDate.setMonth(parseInt(dateOption.value) - 1, 1);
          break;
      }
      const dateRange = [];
      for (let d = new Date(startDate); d <= today; d.setDate(d.getDate() + 1)) {
        dateRange.push(new Date(d));
      }

      // Format the dates in the list as strings for use as labels
      const labels = dateRange.map(date => date.toISOString().slice(0, 10));

      const datasets = categories.map((category, index) => {
      const data = labels.map(date => {
        const entries = filteredData.filter(entry => entry.category === category && entry.date === date);
        const sum = entries.reduce((a, b) => a + b.amount, 0);
        return sum ? Math.round(sum) : null;
      });

      return {
        label: category,
        data: data,
        fill: false,
        borderColor: getRandomColor(index),
        tension: 0.4,
        spanGaps: true,
      };
    });

    nextTick(() => {
      chartInstance = new Chart(chartCanvas.value, {
        type: 'line',
        data: {
          labels: labels,
          datasets: datasets,
        },
        options: chartOptions // Add this line to set the chart options
      });
    });
    }


    const destroyChart = () => {
      if (chartInstance) {
        chartInstance.destroy();
        chartInstance = null;
      }
    }

    const filterDataByDateOption = () => {
      const today = new Date();
      const startDate = new Date();

      switch (dateOption.value) {
        case 'month':
          startDate.setDate(1);
          break;
        case '30':
          startDate.setDate(today.getDate() - 30);
          break;
        case '60':
          startDate.setDate(today.getDate() - 60);
          break;
        case '90':
          startDate.setDate(today.getDate() - 90);
          break;
        case 'year':
          startDate.setMonth(0, 1);
          break;
        default:
          startDate.setMonth(parseInt(dateOption.value) - 1, 1);
          break;
      }

      return props.data.filter(entry => {
        const entryDate = new Date(entry.date);
        return entryDate >= startDate && entryDate <= today;
      });
    }

    const getRandomColor = (index) => {
      const hue = 200; // Fixed hue value for blueish colors
      const saturation = 60 + Math.random() * 10;
      const lightness = 60 + index * 10; // Decrease lightness based on index
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    }

    watchEffect(() => {
      if (props.data && dateOption.value) {
        renderChart();
      }
    });

    onMounted(() => {
      nextTick(() => {
        renderChart();
      });
    });

    onBeforeUnmount(() => {
      destroyChart();
    });

    return {
      showChart,
      chartId,
      chartCanvas,
      months,
      dateOption,
      setDateOption,
    };
  },
};

</script>


<style scoped>

.select-button {
  background-color: #ffffff00;
  color: gray;
  border: 1px solid #e9e9e9;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}

.select-button:hover {
  background-color: #eeeeee;
  color: black;
  cursor: pointer;
}


.options-row {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding-top: 15px;
}

.time-select-button {
  background-color: #ffffff00;
  color: #aaaaaa;
  border: none;
  width: 35px;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  margin: 5px;
}

.time-select-button:hover {
  background-color: #bdccd9;
  color: black;
  cursor: pointer;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.time-select-button.active {
  background-color: #c7e6ff;
  color: black;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

</style>