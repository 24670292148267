/* eslint-disable */
import { createApp } from 'vue';
import App from './App.vue';
import { onAuthStateChanged } from 'firebase/auth';
import store from './store';
import router from './router'; // import the router here
import { db, auth } from './firebaseInit'; // import from firebaseInit
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-blue/theme.css';
// Vuetify Imports
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.min.css';
import 'vuetify/styles'; // Make sure to import the styles

// Place this code at the very top of the file, before any other code
window.onerror = function (message, source, lineno, colno, error) {
  if (message && message.includes("Cannot read properties of null (reading 'getContext')")) {
    // Prevent infinite reload loops by using sessionStorage
    if (!sessionStorage.getItem('reloaded')) {
      sessionStorage.setItem('reloaded', 'true');
      // Reload the page
      window.location.reload();
    } else {
      // Error persists after reload; you might want to handle it differently
      console.error('Error persists after reload:', error);
    }
  } else {
    // Handle other errors or pass them to the console
    console.error('An error occurred:', error);
  }
};

const vuetify = createVuetify({
  components,
  directives,
});

// Create the app instance before mounting
const app = createApp(App);

// Set global error handler on the app instance
app.config.errorHandler = function (err, vm, info) {
  // Check if the error message matches the specific error
  if (err.message && err.message.includes("Cannot read properties of null (reading 'getContext')")) {
    // Prevent infinite reload loops by using sessionStorage
    if (!sessionStorage.getItem('reloaded')) {
      sessionStorage.setItem('reloaded', 'true');
      // Reload the page
      window.location.reload();
    } else {
      // Error persists after reload; you might want to handle it differently
      console.error('Error persists after reload:', err);
    }
  } else {
    // Handle other errors or pass them to the console
    console.error('An error occurred:', err);
  }
};

// Wait for Firebase authentication to initialize before mounting the app
onAuthStateChanged(auth, () => {
  if (!app._container) {
    // Add plugins and components to the app instance
    app.use(store); // Use Vuex store
    app.use(router); // Use Vue Router
    app.component('VueDatePicker', VueDatePicker);
    app.use(PrimeVue); // PrimeVue usage with global configuration
    app.use(vuetify); // Use Vuetify instance here

    // Add the auth and db instances to the app's global properties
    app.config.globalProperties.$auth = auth;
    app.config.globalProperties.$db = db;

    // Mount the app
    app.mount('#app');
  }
});