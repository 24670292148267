<template>
  <div v-if="showQuotes" class="random-quote">
    {{ randomQuote }}
  </div>
  <div v-if="showHoursUntilDeath" class="hours-until-death">
    Hours until you die: {{ hoursUntilDeath }}
  </div>
  <div class="life-grid" :class="{ 'compact-view': compactView }">
    <div v-if="hoveredWeek" class="month-year-overlay" ref="overlay">
      {{ formatMonthYear(hoveredWeek.week) }}
    </div>
    <div
      v-for="(year, yearIndex) in years"
      :key="yearIndex"
      :class="['year-row', { 'year-gap': showYearGaps && (yearIndex + 1) % 5 === 0 }]"
    >
    <div
      v-for="(week, weekIndex) in year"
      :key="weekIndex"
      :class="[
        'square',
        { 'past-week': week.isPast },
        { 'current-week': week.isCurrent },
        { 'has-milestone': hasMilestone(week.number) },
        getLifeStageColor(week.age)
      ]"
      @click="zoomWeek(week)"
      @mouseenter="showOverlay(week, $event)"
      @mouseleave="hoveredWeek = null"
    >
        <div v-if="hasMilestone(week.number) && !isZoomedViewOpen" class="milestone-indicator"></div>
      </div>
    </div>
    <div v-if="zoomedWeek" class="zoomed-view-backdrop" @click.self="closeZoomedView">
      <div class="zoomed-view" ref="zoomedViewRef">
        <div class="arrow-button left-arrow" @click="goToPreviousWeek">&lt; {{ zoomedWeek.number - 1 }}</div>
        <div class="arrow-button right-arrow" @click="goToNextWeek">{{ zoomedWeek.number + 1 }} &gt;</div>
        <div class="progress-container">
          <div class="progress-bar" :style="{ width: progressPercentage + '%' }"></div>
        </div>
        <div class="week-header">
          
          <div class="week-number">#{{ zoomedWeek.number }}<div class="week-title">WEEK</div></div>
          <div class="age">{{ formatAge(zoomedWeek.age) }}<div class="age-title">AGE</div></div>
        </div>
        <div class="week-details">
            <p>{{ formatDate(zoomedWeek.startDate) }} - {{ formatDate(zoomedWeek.endDate) }}</p>
          </div>
          <div class="summary-section">
            <div v-if="!editingSummary" class="summary-text" :class="{ 'no-summary': !weekSummary }" @click="startEditingSummary">
              {{ weekSummary || 'Click to add a summary' }}</div>
            <v-textarea v-else v-model="weekSummary" density="compact" label="Summary" variant="outlined" @blur="saveSummary"></v-textarea>
          </div>
        <v-btn v-if="editingSummary" class="weekly-summary-button" @click="saveSummary" :disabled="isSaving">
            {{ isSaving ? 'Saving...' : 'Save Summary' }}
          </v-btn>
        <div v-if="weekGoals.length > 0" class="goal-list">
          <h3>Milestones:</h3>
          <div v-for="(goal, index) in weekGoals" :key="index">
            <v-icon class="delete-icon" @click="deleteMilestone(index)">mdi-close</v-icon>
            {{ goal }}
          </div>
        </div>

        <div class="goal-section">
          <v-text-field density="compact" v-model="goalInput" label="Milestones" placeholder="Enter a milestone for this week" variant="outlined" @keyup.enter="saveGoal"></v-text-field>
          <v-btn  class="weekly-summary-button"  @click="saveGoal">ADD</v-btn>
        </div>
        <div v-if="weekTodos.length > 0" class="to-do-list">
          <h3>To Do:</h3>
          <div class="todo-progress-container">
            <div class="todo-progress-bar" :style="{ width: todoProgress + '%' }"></div>
          </div>
          <div v-for="(todo, index) in weekTodos" :key="index" class="todo-item">
            <div class="todo-content">
              <v-icon class="delete-icon" @click="deleteTodo(todo)">mdi-close</v-icon>
              <v-checkbox v-model="todo.done" @change="updateTodoStatus(todo)" class="todo-checkbox" hide-details></v-checkbox>
              <span :class="{ 'todo-done': todo.done }" class="todo-text">{{ todo.text }}</span>
            </div>
          </div>
        </div>
        <div class="todo-section">
          <v-text-field density="compact" v-model="todoInput" label="Add To Do" placeholder="Enter a to-do item" variant="outlined" class="todo-input" @keyup.enter="addTodo"></v-text-field>
          <v-btn class="add-todo-button weekly-summary-button" @click="addTodo">ADD</v-btn>
        </div>
        <div class="weeks-until-80">{{ weeksUntil80 }} weeks until you turn 80</div>
      </div>
    </div>
    <v-icon class="settings-icon" @click="toggleSettingsDrawer">mdi-cog</v-icon>

    <v-dialog v-model="showSettingsDrawer" max-width="300" :position="{ top: '60px', right: '20px' }" @click:outside="showSettingsDrawer = false">
      <v-card>
          <v-card-title>Settings</v-card-title>
          <v-card-text>
            <v-switch v-model="showYearGaps" label="Show 5 Year Gaps"></v-switch>
            <v-switch v-model="compactView" label="Compact View"></v-switch>
            <v-switch v-model="showHoursUntilDeath" label="Show Hours Until Death"></v-switch>
            <v-switch v-model="showQuotes" label="Show Quotes"></v-switch>
          </v-card-text>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
/* eslint-disable */
import { collection, getDocs, getDoc, addDoc, where, writeBatch, deleteDoc, query, orderBy, updateDoc, doc, setDoc, deleteField } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';

export default {
  data() {
    return {
      birthdate: null,
      years: [],
      zoomedWeek: null,
      goalInput: '',
      weeksUntil80: 0,
      weekGoals: [],
      showYearGaps: true,
      isSaving: false,
      showSettingsDrawer: false,
      weekSummary: '',
      quotes: [
        "Waste no more time arguing what a good man should be. Be One. – Marcus Aurelius",
        "Think of the life you have lived until now as over and, as a dead man, see what's left as a bonus and live it according to Nature. Love the hand that fate deals you and play it as your own, for what could be more fitting? – Marcus Aurelius",
        "It never ceases to amaze me: we all love ourselves more than other people, but care more about their opinion than our own. – Marcus Aurelius",
        "In your actions, don't procrastinate. In your conversations, don't confuse. In your thoughts, don't wander. In your soul, don't be passive or aggressive. In your life, don't be all about business. – Marcus Aurelius",
        "If it is not right, do not do it, if it is not true, do not say it. – Marcus Aurelius",
        "The best revenge is not to be like your enemy. – Marcus Aurelius",
        "Choose not to be harmed — and you won't feel harmed. Don't feel harmed — and you haven't been. – Marcus Aurelius",
        "It's time you realized that you have something in you more powerful and miraculous than the things that affect you and make you dance like a puppet. – Marcus Aurelius",
        "External things are not the problem. It's your assessment of them. Which you can erase right now. – Marcus Aurelius",
        "If anyone can refute me—show me I'm making a mistake or looking at things from the wrong perspective—I'll gladly change. It's the truth I'm after, and the truth never harmed anyone. – Marcus Aurelius",
        "You could leave life right now. Let that determine what you do and say and think. – Marcus Aurelius",
        "Be tolerant with others and strict with yourself. – Marcus Aurelius",
        "We are more often frightened than hurt; and we suffer more in imagination than in reality. – Seneca",
        "If a man knows not which port he sails, no wind is favorable. – Seneca",
        "No person has the power to have everything they want, but it is in their power not to want what they don't have, and to cheerfully put to good use what they do have. – Seneca",
        "Nothing, to my way of thinking, is a better proof of a well ordered mind than a man's ability to stop just where he is and pass some time in his own company. – Seneca",
        "He who fears death will never do anything worthy of a man who is alive. – Seneca",
        "This is our big mistake: to think we look forward to death. Most of death is already gone. Whatever time has passed is owned by death. – Seneca",
        "Life is very short and anxious for those who forget the past, neglect the present, and fear the future. – Seneca",
        "I judge you unfortunate because you have never lived through misfortune. You have passed through life without an opponent—no one can ever know what you are capable of, not even you. – Seneca",
        "How does it help…to make troubles heavier by bemoaning them? – Seneca",
        "People are frugal in guarding their personal property; but as soon as it comes to squandering time they are most wasteful of the one thing in which it is right to be stingy. – Seneca",
        "How long are you going to wait before you demand the best for yourself? – Epictetus",
        "Don't seek for everything to happen as you wish it would, but rather wish that everything happens as it actually will—then your life will flow well. – Epictetus",
        "First say to yourself what you would be; and then do what you have to do. – Epictetus",
        "Curb your desire—don't set your heart on so many things and you will get what you need. – Epictetus",
        "That's why the philosophers warn us not to be satisfied with mere learning, but to add practice and then training. For as time passes we forget what we learned and end up doing the opposite, and hold opinions the opposite of what we should. – Epictetus",
        "Don't explain your philosophy. Embody it. – Epictetus",
        "The chief task in life is simply this: to identify and separate matters so that I can say clearly to myself which are externals not under my control, and which have to do with the choices I actually control. Where then do I look for good and evil? Not to uncontrollable externals, but within myself to the choices that are my own… – Epictetus",
        "If anyone tells you that a certain person speaks ill of you, do not make excuses about what is said of you but answer, 'He was ignorant of my other faults, else he would have not mentioned these alone.' – Epictetus",
        "I begin to speak only when I'm certain what I'll say isn't better left unsaid. – Cato",
        "What man actually needs is not a tensionless state but rather the striving and struggling for some goal worthy of him. – Viktor Frankl",
        "When we are no longer able to change a situation, we are challenged to change ourselves. – Viktor Frankl",
        "Life is long if you know how to use it. – Seneca",
        "True happiness is to enjoy the present, without anxious dependence upon the future. – Seneca",
        "It is the power of the mind to be unconquerable. – Seneca",
        "It is not because things are difficult that we do not dare; it is because we do not dare that they are difficult. – Seneca",
        "Difficulties strengthen the mind, as labor does the body. – Seneca",
        "We suffer more in imagination than in reality. – Seneca",
        "Ignorance is the cause of fear. – Seneca",
        "The whole future lies in uncertainty: live immediately. – Seneca",
        "Sometimes even to live is an act of courage. – Seneca",
        "If you really want to escape the things that harass you, what you're needing is not to be in a different place but to be a different person. – Seneca",
        "It's not what happens to you, but how you react to it that matters. – Epictetus",
        "Men are disturbed not by things, but by the views which they take of them. – Epictetus",
        "Freedom is the only worthy goal in life. It is won by disregarding things that lie beyond our control. – Epictetus",
        "Wealth consists not in having great possessions, but in having few wants. – Epictetus",
        "It is difficulties that show what men are. – Epictetus",
        "Happiness and freedom begin with a clear understanding of one principle: Some things are within our control, and some things are not. – Epictetus",
        "The key is to keep company only with people who uplift you, whose presence calls forth your best. – Epictetus",
        "The greater the difficulty, the more glory in surmounting it. Skillful pilots gain their reputation from storms and tempests. – Epictetus",
        "If you want to improve, be content to be thought foolish and stupid. – Epictetus",
        "He is a wise man who does not grieve for the things which he has not, but rejoices for those which he has. – Epictetus",
        "Demand not that things happen as you wish, but wish them to happen as they do, and you will go on well. – Epictetus",
        "The goal of life is living in agreement with nature. – Zeno of Citium",
        "Better to trip with the feet than with the tongue. – Zeno of Citium",
        "Man conquers the world by conquering himself. – Zeno of Citium",
        "A bad feeling is a commotion of the mind repugnant to reason, and against nature. – Zeno of Citium",
        "Happiness is a good flow of life. – Zeno of Citium",
        "Nothing is more hostile to a firm grasp on knowledge than self-deception. – Zeno of Citium",
        "Follow where reason leads. – Zeno of Citium",
        "Well-being is realized by small steps, but it is truly no small thing. – Zeno of Citium",
        "Fate is the endless chain of causation, whereby things are; the reason or formula by which the world goes on. – Zeno of Citium",
        "No loss should be more regrettable to us than losing our time, for it's irretrievable. – Zeno of Citium",
        "If you accomplish something good with hard work, the labor passes quickly, but the good endures; if you do something shameful in pursuit of pleasure, the pleasure passes quickly, but the shame endures. – Gaius Musonius Rufus",
        "The greatest thing in the world is self-sufficiency. – Gaius Musonius Rufus",
        "It is impossible for a man to learn what he thinks he already knows. – Gaius Musonius Rufus",
        "From good people you'll learn good, but if you mingle with the bad, you'll destroy such soul as you had. – Gaius Musonius Rufus",
        "It is not possible to live well today unless you treat it as your last day. – Gaius Musonius Rufus",
        "In order to protect ourselves we must live like doctors and be continually treating ourselves with reason. – Gaius Musonius Rufus",
        "You will earn the respect of all if you begin by earning the respect of yourself. Don't expect to encourage good deeds in people conscious of your own misdeeds. – Gaius Musonius Rufus",
        "Willingly accept the inevitable, and you will lead a life in harmony with the universe. – Gaius Musonius Rufus",
        "Train yourself to strive for giving, rather than receiving. – Gaius Musonius Rufus",
        "The soul is trained for courage when we show patience under hardships. – Gaius Musonius Rufus",
        "It is the privilege of the gods to want nothing, and of godlike men to want little. – Diogenes",
        "It takes a wise man to discover a wise man. – Diogenes",
        "People who talk well but do nothing are like musical instruments; the sound is all they have to offer. – Diogenes",
        "No man is hurt but by himself. – Diogenes",
        "He has the most who is most content with the least. – Diogenes",
        "As a matter of self-preservation, a man needs good friends or ardent enemies, for the former instruct him and the latter take him to task. – Diogenes",
        "Courage is the key to mastery; the key to conquering our fears and unlocking our true potential. – Diogenes",
        "The greatest wealth is to live content with little, for there is never a lack for those who crave much. – Diogenes",
        "The wise man knows that he knows nothing, and yet he still seeks knowledge. – Diogenes",
        "If I gained one thing from philosophy is that at the very least, I am well prepared to confront any change in fortune. – Diogenes",
        "There is only a finger's difference between a wise man and a fool. – Diogenes"
      ],
      randomQuote: '',
      showQuotes: true,
      todoInput: '',
      hoveredWeek: null,
      weekTodos: [],
      editingSummary: false,
      showHoursUntilDeath: false,
      milestones: [],
      compactView: false,
      lifeStages: [
      { name: 'Early Years', startAge: 0, endAge: 5.999, color: '#ffebee' },
      { name: 'School Years', startAge: 6, endAge: 17.999, color: '#e8f5e9' },
      { name: 'Young Adult', startAge: 18, endAge: 29.999, color: '#e3f2fd' },
      { name: 'Adulthood', startAge: 30, endAge: 59.999, color: '#f3e5f5' },
      { name: 'Senior Years', startAge: 60, endAge: 80, color: '#fbe9e7' },
    ],
    };
  },
  async created() {
    await this.fetchUserData();
    await this.fetchMilestones();

    this.generateWeeks();
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyPress);
    this.selectRandomQuote();
  },
  beforeUnmount() {
    window.removeEventListener('keydown', this.handleKeyPress);
    clearInterval(this.quoteChangeInterval);
  },

  watch: {
    showYearGaps(newValue) {
      this.saveShowYearGapsState();
    },
    async showHoursUntilDeath(newValue) {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        showHoursUntilDeath: newValue,
      });
    },
    showQuotes(newValue) {
      this.saveUserSetting('showQuotes', newValue);
    },
    
    compactView(newValue) {
      this.saveCompactViewState();
    }
  },
  computed: {
    isZoomedViewOpen() {
      return !!this.zoomedWeek;
    },
    todoProgress() {
      if (this.weekTodos.length === 0) {
        return 0;
      }
      const completedTodos = this.weekTodos.filter(todo => todo.done).length;
      return (completedTodos / this.weekTodos.length) * 100;
    },
    hoursUntilDeath() {
      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      const lifeExpectancy = 80; // Assuming a life expectancy of 80 years
      const deathDate = new Date(birthDate);
      deathDate.setFullYear(birthDate.getFullYear() + lifeExpectancy);
      const diffTime = Math.abs(deathDate - currentDate);
      const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
      return diffHours;
    },
    progressPercentage() {
      if (this.zoomedWeek) {
        const totalWeeks = 4174; // Total number of weeks
        return ((this.zoomedWeek.number - 1) / (totalWeeks - 1)) * 100;
      }
      return 0;
    },
  },
  methods: {
    getLifeStageColor(age) {
      const lifeStage = this.lifeStages.find(stage => age >= stage.startAge && age <= stage.endAge);
      return lifeStage ? `life-stage-${lifeStage.name.toLowerCase().replace(' ', '-')}` : '';
    },
    toggleSettingsDrawer() {
      this.showSettingsDrawer = !this.showSettingsDrawer;
    },
    selectRandomQuote() {
      const randomIndex = Math.floor(Math.random() * this.quotes.length);
      this.randomQuote = this.quotes[randomIndex];
    },
    async saveCompactViewState() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        compactView: this.compactView
      });
    },
    showOverlay(week, event) {
      this.hoveredWeek = { week, event };
      this.$nextTick(() => {
        const overlay = this.$refs.overlay;
        const rect = event.target.getBoundingClientRect();
        overlay.style.top = `${rect.top}px`;
        overlay.style.left = `${rect.right + 10}px`;
      });
    },
    formatMonthYear(week) {
      const date = new Date(week.startDate);
      const options = { month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    hasMilestone(weekNumber) {
      return this.milestones.some(milestone => milestone.weekNumber === weekNumber);
    },
    async fetchMilestones() {
      try {
        const userId = auth.currentUser.uid;
        const goalSnapshot = await getDocs(collection(db, `users/${userId}/lifeGoals`));
        this.milestones = goalSnapshot.docs.map(doc => doc.data());
      } catch (error) {
        console.error('Error fetching milestones:', error);
      }
    },
    async fetchTodos() {
      try {
        const userId = auth.currentUser.uid;
        const todoSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/todos`),
            where('weekNumber', '==', this.zoomedWeek.number)
          )
        );

        this.weekTodos = todoSnapshot.docs.map((doc) => doc.data());
      } catch (error) {
        console.error('Error fetching todos:', error);
      }
    },
    async saveShowYearGapsState() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      await updateDoc(userRef, {
        showYearGaps: this.showYearGaps
      });
    },
    saveUserSetting(key, value) {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      updateDoc(userRef, {
        [key]: value,
      })
        .catch((error) => {
          console.error('Error saving user setting:', error);
        });
    },
    startQuoteChangeInterval() {
      // Clear any existing interval
      clearInterval(this.quoteChangeInterval);
      
      // Set the interval based on the selected frequency
      switch (this.selectedFrequency) {
        case 'Hourly':
          this.quoteChangeInterval = setInterval(this.selectRandomQuote, 60 * 60 * 1000);
          break;
        case 'Daily':
          this.quoteChangeInterval = setInterval(this.selectRandomQuote, 24 * 60 * 60 * 1000);
          break;
        case 'Weekly':
          this.quoteChangeInterval = setInterval(this.selectRandomQuote, 7 * 24 * 60 * 60 * 1000);
          break;
        default:
          // 'Every Time' or any other value, no interval needed
          break;
      }
    },
    async fetchUserData() {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, `users/${userId}`);
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        this.birthdate = userData.birthdate; // Store the birthdate
        this.userBirthYear = new Date(userData.birthdate).getFullYear();
        this.showYearGaps = userData.showYearGaps !== undefined ? userData.showYearGaps : true; // Use the value from the database or default to true
        this.compactView = userData.compactView || false; // Load the compact view setting
        this.inflationRate = userData.inflationRate || this.inflationRate;
        this.desiredFIDate = userData.desiredFIDate || this.desiredFIDate;
        this.incomeIncrease = userData.incomeIncrease || this.incomeIncrease;
        this.withdrawalRate = userData.withdrawalRate || this.withdrawalRate;
        this.showHoursUntilDeath = userData.showHoursUntilDeath || false;
        this.showQuotes = userData.showQuotes ?? true;
        this.selectedFrequency = userData.quoteChangeFrequency ?? 'Every Time';
        this.savingsRate = userData.savingsRate || this.savingsRate;
        this.manualFIAmountToggle = userData.manualFIAmountToggle || false; // Set the toggle state
        this.manualFIAmount = userData.manualFIAmountToggle ? userData.manualFIAmount : this.manualFIAmount;
        this.customAnnualSpendToggle = userData.customAnnualSpendToggle || false;
        this.customAnnualSpend = userData.customAnnualSpend || this.originalAnnualSpend;
        // Here, we decide which value to use for totalAnnualSpend based on the toggle's state
        if (this.customAnnualSpendToggle) {
          this.totalAnnualSpend = this.customAnnualSpend;
        } else {
          this.totalAnnualSpend = this.originalAnnualSpend;
        }
      } else {
        console.log("No user document found!");
      }
    },
    formatAge(age) {
      return age.toFixed(2);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    },
    closeZoomedView() {
      this.zoomedWeek = null;
    },
    handleKeyPress(event) {
      const focusedElement = document.activeElement;
      const isInputOrTextarea = focusedElement && (focusedElement.tagName === 'INPUT' || focusedElement.tagName === 'TEXTAREA');

      if (!isInputOrTextarea) {
        if (event.key === ' ') {
          if (this.zoomedWeek) {
            this.closeZoomedView();
          } else {
            const currentWeek = this.getCurrentWeek();
            if (currentWeek) {
              this.zoomWeek(currentWeek);
            }
          }
        } else if (this.zoomedWeek) {
          if (event.key === 'ArrowLeft') {
            this.goToPreviousWeek();
          } else if (event.key === 'ArrowRight') {
            this.goToNextWeek();
          }
        }
      }
    },
    getCurrentWeek() {
      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      const diffTime = Math.abs(currentDate - birthDate);
      const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
      return this.getWeekByNumber(diffWeeks);
    },
    generateWeeks() {
      const currentDate = new Date();
      const birthDate = new Date(this.birthdate);
      const diffTime = Math.abs(currentDate - birthDate);
      const diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));

      const years = [];
      let yearWeeks = [];

      for (let i = 0; i < 4174.29; i++) {
        const startDate = new Date(birthDate);
        startDate.setDate(startDate.getDate() + i * 7);
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 6);

        const week = {
          number: i + 1,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          age: i / 52,
          isPast: i < diffWeeks,
          isCurrent: i === diffWeeks - 1, // Add this line
        };

        yearWeeks.push(week);

        if (yearWeeks.length === 52 || i === 4159) {
          years.push(yearWeeks);
          yearWeeks = [];
        }
      }

      this.years = years;
    },
    goToPreviousWeek() {
      const currentIndex = this.zoomedWeek.number - 1;
      if (currentIndex > 0) {
        const previousWeek = this.getWeekByNumber(currentIndex);
        this.zoomWeek(previousWeek);
      }
    },
    goToNextWeek() {
      const currentIndex = this.zoomedWeek.number - 1;
      if (currentIndex < 4159) {
        const nextWeek = this.getWeekByNumber(currentIndex + 2);
        this.zoomWeek(nextWeek);
      }
    },
    getWeekByNumber(weekNumber) {
      const yearIndex = Math.floor((weekNumber - 1) / 52);
      const weekIndex = (weekNumber - 1) % 52;
      return this.years[yearIndex][weekIndex];
    },
    async zoomWeek(week) {
      this.zoomedWeek = week;
      this.weeksUntil80 = 4174 - week.number;

      try {
        const userId = auth.currentUser.uid;
        const goalSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/lifeGoals`),
            where('weekNumber', '==', week.number)
          )
        );

        this.weekGoals = goalSnapshot.docs.map((doc) => doc.data().goal);

        const summarySnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/lifeSummaries`),
            where('weekNumber', '==', week.number)
          )
        );

        if (!summarySnapshot.empty) {
          this.weekSummary = summarySnapshot.docs[0].data().summary;
        } else {
          this.weekSummary = '';
        }

        // Fetch todos for the current week
        await this.fetchTodos();
      } catch (error) {
        console.error('Error fetching goals and summary:', error);
      }
    },
    startEditingSummary() {
      this.editingSummary = true;
      this.$nextTick(() => {
        const textarea = this.$el.querySelector('.summary-section textarea');
        if (textarea) {
          textarea.focus();
        }
      });
    },
    async saveSummary() {
      if (this.weekSummary.trim() !== '') {
        try {
          this.isSaving = true; // Set the saving state to true

          const userId = auth.currentUser.uid;
          const summaryQuery = query(
            collection(db, `users/${userId}/lifeSummaries`),
            where('weekNumber', '==', this.zoomedWeek.number)
          );
          const summarySnapshot = await getDocs(summaryQuery);

          if (summarySnapshot.empty) {
            await addDoc(collection(db, `users/${userId}/lifeSummaries`), {
              weekNumber: this.zoomedWeek.number,
              summary: this.weekSummary,
            });
          } else {
            const summaryRef = summarySnapshot.docs[0].ref;
            await updateDoc(summaryRef, {
              summary: this.weekSummary,
            });
          }

          // Provide visual feedback or perform any desired actions
          console.log('Summary saved successfully');
          // You can show a toast message, display a success alert, etc.

        } catch (error) {
          console.error('Error saving summary:', error);
          this.editingSummary = false; // Set editingSummary back to false if the summary is empty

          // Handle the error and provide appropriate feedback to the user
        } finally {
          this.isSaving = false; // Set the saving state back to false
          this.editingSummary = false; // Set editingSummary back to false if the summary is empty

        }
      }
    },
    async saveGoal() {
      if (this.goalInput.trim() !== '') {
        try {
          const userId = auth.currentUser.uid;
          const docRef = await addDoc(collection(db, `users/${userId}/lifeGoals`), {
            weekNumber: this.zoomedWeek.number,
            goal: this.goalInput,
          });

          // Add the new goal to the weekGoals array
          this.weekGoals.push(this.goalInput);

          // Add the new milestone to the milestones array
          this.milestones.push({ weekNumber: this.zoomedWeek.number, goal: this.goalInput });

          this.goalInput = '';
        } catch (error) {
          console.error('Error saving goal:', error);
        }
      }
    },
    async deleteMilestone(index) {
      try {
        const userId = auth.currentUser.uid;
        const goalSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/lifeGoals`),
            where('weekNumber', '==', this.zoomedWeek.number),
            where('goal', '==', this.weekGoals[index])
          )
        );

        if (!goalSnapshot.empty) {
          const goalRef = goalSnapshot.docs[0].ref;
          await deleteDoc(goalRef);

          // Remove the milestone from the weekGoals array
          this.weekGoals.splice(index, 1);

          // Remove the milestone from the milestones array
          const milestoneIndex = this.milestones.findIndex(
            (milestone) =>
              milestone.weekNumber === this.zoomedWeek.number && milestone.goal === this.weekGoals[index]
          );
          if (milestoneIndex !== -1) {
            this.milestones.splice(milestoneIndex, 1);
          }
        }
      } catch (error) {
        console.error('Error deleting milestone:', error);
      }
    },
    async addTodo() {
      if (this.todoInput.trim() !== '') {
        try {
          const userId = auth.currentUser.uid;
          await addDoc(collection(db, `users/${userId}/todos`), {
            weekNumber: this.zoomedWeek.number,
            text: this.todoInput,
            done: false,
          });

          // Add the new to-do item to the weekTodos array
          this.weekTodos.push({ text: this.todoInput, done: false });

          this.todoInput = '';
        } catch (error) {
          console.error('Error adding to-do item:', error);
        }
      }
    },
    async updateTodoStatus(todo) {
      try {
        const userId = auth.currentUser.uid;
        const todoSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/todos`),
            where('weekNumber', '==', todo.weekNumber),
            where('text', '==', todo.text)
          )
        );

        if (!todoSnapshot.empty) {
          const todoRef = todoSnapshot.docs[0].ref;
          await updateDoc(todoRef, {
            done: todo.done,
          });
        }
      } catch (error) {
        console.error('Error updating to-do item status:', error);
      }
    },
    async deleteTodo(todo) {
      try {
        const userId = auth.currentUser.uid;
        const todoSnapshot = await getDocs(
          query(
            collection(db, `users/${userId}/todos`),
            where('weekNumber', '==', todo.weekNumber),
            where('text', '==', todo.text)
          )
        );

        if (!todoSnapshot.empty) {
          const todoRef = todoSnapshot.docs[0].ref;
          await deleteDoc(todoRef);

          // Remove the to-do item from the weekTodos array
          this.weekTodos = this.weekTodos.filter((item) => item.text !== todo.text);
        }
      } catch (error) {
        console.error('Error deleting to-do item:', error);
      }
    },
  },
};
</script>

<style scoped>
.life-grid {
  width: 75%;
  margin: 20px auto;
}

.year-row {
  display: grid;
  grid-template-columns: repeat(52, 1fr);
  gap: 2px;
  margin-bottom: 2px;
}



.square {
  background-color: #f0f0f0;
  aspect-ratio: 1/1;
  cursor: pointer;
  position: relative;

}


.milestone-indicator {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #fff900;
  border-radius: 50%;
  z-index: 1;
}



.square:hover {
  box-shadow: 0px 1px 10px 2px rgb(56 56 56 / 10%);
    scale: 1.1;
  }

.past-week {
  background-color: #afafaf;
}

.zoomed-view {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  width: 60%;
  min-height: 60%;
  max-height: 80%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 100;
  overflow-y: auto;
}
.zoomed-view-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.goal-week  {
  display: inline-grid;
}

.arrow-button {
  position: absolute;
  bottom: 0;
  transform: translateY(-50%);
  /* background-color: #f0f0f0; */
  color: #8b8b8b;
  font-size: 14px;
  padding: 10px;
  z-index: 100;
  width: 100px;
  cursor: pointer;
  transition: all .3s ease-in;
}

.arrow-button:hover {
  font-weight: 600;
  scale: 1.1;
  color: #333;
  transition: all .3s ease-in;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.goal-list {
  text-align: left;
  width: 80%;
  margin: 20px auto;
}

.goal-list h3 {
  margin-bottom: 10px;
}

.goal-list ul {
  list-style-type: disc;
  padding-left: 20px;
}

.week-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.week-number {
  font-size: 30px;
  font-weight: 600;
}

.weeks-until-80 {
  font-size: 14px;
  bottom: 20px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  transform: translateY(-50%);
}

.age {
  font-size: 30px;
  font-weight: 600;
}

.goal-list div {
  margin-bottom: 5px;
}
.age-title {
  font-size: 10px;
  font-weight: 600;
  line-height: 0px;
  text-align: right;
  margin-right: 5px;
}

.week-title {
  font-size: 10px;
  font-weight: 600;
  line-height: 0px;
  text-align: left;
}

.summary-section, .goal-section, .todo-section {
  width: 80%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 20px;
}

.to-do-list {
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  text-align: left;
}


.summary-saved {
  background-color: #e0ffe0;
  border-color: #4caf50;
}

.weekly-summary-button {
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 20px;
  margin-left: 10px !important;
}

.life-stage-early-years.past-week {
  background-color: #caf0f8;
}

.life-stage-school-years.past-week {
  background-color: #ade8f4;
}

.life-stage-young-adult.past-week {
  background-color: #90e0ef;
}

.life-stage-adulthood.past-week {
  background-color: #48cae4;
}

.life-stage-senior-years.past-week {
  background-color: #00b4d8;
}

.life-stage-senior-years {
  background-color: #eed9c863;
}

.year-row.year-gap {
  margin-bottom: 20px;
}

.delete-icon {
  cursor: pointer;
  margin-left: 5px;
  font-size: 10px;
  color: lightgrey;
}

.delete-icon:hover {
  color: red;
  scale: 1.25;
}
.todo-done {
  text-decoration: line-through;
  color: #b0b0b0;
}

.todo-item {
  display: flex;
  align-items: center;
}

.todo-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.todo-checkbox {
  margin-right: 8px;
  height: auto;
  padding: 0;
  max-width: 30px;
}

.todo-input {
  width: 100%;
}

.todo-text {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.life-grid.compact-view {
  margin: 20px 30%;
}


.compact-view .year-row {
  grid-template-columns: repeat(104, 1fr);
}

.month-year-overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  pointer-events: none;
  z-index: 1000;
  transition: opacity 0.3s;
}

.summary-text {
  padding: 40px;
  border: 1px solid #ededed;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  min-height: 100px;
  transition: background-color 0.3s;
  color: black;
  text-align: left;
  overflow: auto;
}

.summary-text.no-summary {
  color: #bbbbbb;
}

.summary-text:hover {
  background-color: #f0f0f0;
}

.week-details {
  text-align: left;
  width: 80%;
  margin: 50px auto;
}

.week-details p {
  font-size: 16px;
}

.progress-container {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress-bar {
  width: 0;
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s ease;
}

.todo-progress-container {
  width: 100%;
  height: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 10px;
  overflow: hidden;
}

.todo-progress-bar {
  width: 0;
  height: 100%;
  background-color: #4CAF50;
  transition: width 0.5s ease;
}
.random-quote {
  text-align: center;
  font-style: italic;
  width: 50%;
  margin: 20px auto;
}
.current-week {
  background-color: black !important;
}

.settings-icon {
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
}
</style>