<template>
  <header :class="{ 'light-mode': lightMode, 'header': true }">
    <h2 class="logo" @click="goToHome">🔥 fiyr</h2>
    <!-- <v-switch v-model="lightMode" color="red" hide-details></v-switch> -->
    <div class="header-buttons">
      <button :class="{ 'light-mode': lightMode, 'transparent-button': true }" @click="goToBlog">BLOG</button>
      <button :class="{ 'light-mode': lightMode, 'transparent-button': true }" @click="goToManifesto">MISSION</button>
      <button :class="{ 'light-mode': lightMode, 'transparent-button': true }" @click="goToLogin">LOG IN</button>
      <button class="red-button" @click="goToRegister">JOIN NOW</button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheHeader',
  methods: {
    goToHome() {
      this.$router.push('/');
    },
    goToBlog() {
      this.$router.push('/blog');
    },
    goToManifesto() {
      this.$router.push('/manifesto');
    },
    goToLogin() {
      this.$router.push('/login');
    },
    goToRegister() {
      this.$router.push('/register');
    },
    goToWaitlist() {
      this.$router.push('/#early-access');
    },
  },
  computed: {
    lightMode: {
      get() {
        return this.$store.state.lightMode;
      },
      set(value) {
        this.$store.dispatch('toggleLightMode', value);
      },
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10000;
  align-items: center;
  padding: 20px;
  transition: background-color 0.3s ease-in-out;
}

/* .header.light-mode {
  background-color: white;
} */

.logo {
  color: red;
  font-size: 34px;
  font-weight: 900;
  margin: 10px;
  padding: 10px;
  background-color: unset;
}

.logo:hover {
  cursor: pointer;
}

.header-buttons {
  display: flex;
  gap: 10px;
  background-color: unset;
}

.transparent-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: color 0.3s ease-in-out;
}

.transparent-button:hover {
  cursor: pointer;
  color: red !important;
}

.transparent-button.light-mode {
  color: black;
}

.red-button {
  background-color: red;
  color: white;
  padding: 10px;
  border-radius: 3px;
  border: none;
  width: 150px;
  margin: 20px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>