<template>
  <footer :class="{ 'footer': true, 'light-mode': lightMode }">
    <div class="footer-left">
      <p>2024 FIVEBAGS, INC.</p>
      <p>ALL RIGHTS RESERVED</p>
    </div>
    <div class="footer-right">
      <div class="footer-column">
        <a href="https://x.com/FivebagsCo" target="_blank" rel="noopener noreferrer">X TWITTER</a>
        <a href="https://www.instagram.com/fiyr.app/" target="_blank" rel="noopener noreferrer">INSTAGRAM</a>
        <a href="https://www.linkedin.com/company/fiyr/" target="_blank" rel="noopener noreferrer">LINKEDIN</a>
      </div>
      <div class="footer-column">
        <a @click="goToManifesto">MISSION</a>
        <a @click="goToPrivacyPolicy">PRIVACY POLICY</a>
        <a @click="goToTermsOfService">TERMS OF SERVICE</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  computed: {
    lightMode() {
      return this.$store.state.lightMode;
    },
  },
  methods: {
    goToManifesto() {
      this.$router.push('/manifesto');
    },
    goToPrivacyPolicy() {
      this.$router.push('/privacy-policy');
    },
    goToTermsOfService() {
      this.$router.push('/terms-of-service');
    },
  },
};
</script>


<style scoped>
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: black;
  font-size: 20px;
}

.footer.light-mode {
  color: black;
  background-color: white;
}

.footer.light-mode a {
  color: black;
}

a:hover {
  cursor: pointer;
  color: red !important;
}

.footer-left {
  text-align: left;
  margin-left: 10px;
}

.footer-right {
  display: flex;
  gap: 100px;
  text-align: left;
}

.footer-column {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.footer a {
  color: white;
  text-decoration: none;
  font-size: 14px;
}

.footer a.light-mode {
  color: black;
}

.footer p {
  font-size: 14px;
  margin-bottom: 0px;
}
</style>