<template>

<div class="modal" v-if="showEditModal">
  <div class="modal-content">
    <h3 style="margin: 20px;">Edit Category</h3>
    <input 
      class="emoji-input" 
      v-model="editedCategory.emoji" 
      type="text" 
      maxlength="2" 
      required 
      @focus="editedCategory.showEmojiPicker = true"
    />

    <div class="emoji-picker-wrapper-edit" v-if="editedCategory.showEmojiPicker">
      <emoji-picker @emoji-click="onEmojiEditSelected"></emoji-picker>
    </div>

    <input
      v-model="editedCategory.name"
      type="text"
      required
    />
    <div style="display: inline-block; margin: 20px auto 0px;">
      <v-btn class="edit-category-buttons" @click="renameCategory">Save</v-btn>
      <v-btn class="edit-category-buttons" @click="closeEditModal">Cancel</v-btn>
    </div>
  </div>
</div>


  <div style="margin: 0 auto;">
    <v-btn class="manage-classes-button" @click="expandContent">
      {{ isExpanded ? 'Hide Categories' : 'Categories' }}
    </v-btn>

    <div v-if="isExpanded" class="info-container">
      <span class="info-text"> ℹ️     Categories are organized into groups for better budget management. These groups also categorize spending on the budget page. <br> <br> Note: Transactions categorized under "Income" are excluded from budget and spending totals. Additionally, the "Credit Card Payment" category is a fixed category essential for specific application calculations and cannot be modified or removed. </span>
    </div>

    <transition name="slide">
      <div v-if="isExpanded" class="category-manager">
        <!-- Add category form -->
        <div class="new-category-form">
          <form @submit.prevent="addCategory">
            <div class="add-category-buttons input-wrapper">
              <input class="new-category-input" v-model="newCategory" type="text" placeholder="New Category" required>
              <input
                class="new-category-input"
                v-model="newEmoji"
                type="text"
                placeholder="New Emoji"
                maxlength="2"
                required
                @focus="showEmojiPicker = true"
              />

              <div class="emoji-picker-wrapper" v-if="showEmojiPicker">
                <emoji-picker @emoji-click="onEmojiSelected"></emoji-picker>
              </div>
              <label for="group-select"></label>
              <select class="select-group" v-model="selectedGroup" id="group-select" required>
                <option disabled value="">Select a Group</option>
                <option v-for="group in groups" :value="group" :key="group">{{ group }}</option>
              </select>
              <v-btn style="width: 200px; margin: 10px auto 0px; box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;" type="submit">Add Category</v-btn>
            </div>
          </form>
        </div>

        <!-- Display existing categories -->
        <div >
          <div 
              v-for="(categories, group) in groupedCategories" 
              :key="group" 
              :id="group" 
              class="group-section"
              @dragover.prevent
              @drop="drop($event, group)"
            >
          <h3 style="padding-right: 10px; padding-bottom: 10px">{{ group }}</h3>
            <div v-for="category in categories" :key="category.id" class="input-wrapper">
              <li 
                :class="{ grabbing: isDragging }"
                draggable="true"
                @dragstart="dragStart(category)"
                @dragend="dragEnd"
              >
              <template v-if="category.editing && category.name !== 'Credit Card Payment'">
                <input 
                  class="emoji-input" 
                  v-model="category.emoji" 
                  type="text" 
                  maxlength="2" 
                  required 
                  @focus="category.showEmojiPicker = true"
                />

                <div class="emoji-picker-wrapper" v-if="category.showEmojiPicker">
                  <emoji-picker @emoji-click="onEmojiEditSelected($event, category)"></emoji-picker>
                </div>

                <input
                  v-model="category.name"
                  type="text"
                  required
                  @keyup.enter="saveCategory(category)"
                />
                <v-btn class="savecategorybuttons" @click="saveCategory(category)">Save</v-btn>
              </template>
              <template v-else>
                <span class="categoryname">{{ category.emoji }} {{ category.name }}</span>
                <button class="categorybuttons" v-if="category.name !== 'Credit Card Payment'" @click="openEditModal(category)">✏️</button>


                <button class="categorybuttons" v-if="category.name !== 'Credit Card Payment'" @click="deleteCategory(category.id)">❌</button>
              </template>

              </li>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <category-selection-modal
    v-if="showModal"
    :oldCategoryName="oldCategoryName"
    :availableCategories="categories.filter(c => c.name !== oldCategoryName)"
    @close="closeModal"
    @confirm="confirmModalSelection"
  ></category-selection-modal>
</template>

<script>
/* eslint-disable */
import { collection, getDoc, where, setDoc, doc, deleteDoc, getDocs, updateDoc, writeBatch, query, orderBy } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';
import CategorySelectionModal from '../components/CategoriesManagerModal.vue'; 
import 'emoji-picker-element';



export default {
  name: 'CategoriesManager',
  components: {
    CategorySelectionModal
  },
  data() {
    return {
      newCategory: '',
      newEmoji: '',
      showEmojiPicker: false,
      selectedGroup: '',
      categories: [],
      groupedCategories: {},
      isDragging: false,
      isExpanded: false,
      draggedItem: null,
      showModal: false,
      oldCategoryName: '',
      selectedNewCategory: '',
      showEditModal: false,
      editedCategory: null,
    };
  },
  mounted() {
    this.retrieveCategories();
  },
  computed: {
    groups() {
      // Get unique groups from existing categories
      return [...new Set(this.categories.map((category) => category.group))];
    },
  },
  methods: {
    onEmojiSelected(event) {
      this.newEmoji = event.detail.unicode;
      this.showEmojiPicker = false;
    },
    onEmojiEditSelected(event) {
      this.editedCategory.emoji = event.detail.unicode;
      this.editedCategory.showEmojiPicker = false;
    },
    expandContent() {
      this.isExpanded = !this.isExpanded;
    },
    dragStart(category) {
      this.draggedItem = category;
    },
    dragEnd() {
      this.draggedItem = null;
    },
async drop(event, newGroup) {
      if (this.draggedItem) {
        const newCategory = {...this.draggedItem, group: newGroup};
        await this.saveCategory(newCategory);
        this.retrieveCategories();
      }
    },
    openEditModal(category) {
      this.editedCategory = { 
        ...category, 
        id: category.id // Include the id property
      };
      this.showEditModal = true;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editedCategory = null;
    },
    async retrieveCategories() {
      try {
        const user = auth.currentUser;
        if (user) {
          const categoriesCollection = collection(db, `users/${user.uid}/categories`);
          const orderedQuery = query(categoriesCollection, orderBy('order'));
          const querySnapshot = await getDocs(orderedQuery);

          this.categories = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
            emoji: doc.data().emoji,
            group: doc.data().group, 
            order: doc.data().order,
            editing: false,
            showEmojiPicker: false,
          }));

          this.groupCategories();
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error retrieving categories:', error);
      }
    },

    groupCategories() {
      this.groupedCategories = {};
      this.categories.forEach((category) => {
        if (!this.groupedCategories[category.group]) {
          this.groupedCategories[category.group] = [];
        }
        this.groupedCategories[category.group].push(category);
      });
    },


    async addCategory() {
      try {
        const user = auth.currentUser;
        if (user) {
          const category = {
            name: this.newCategory,
            emoji: this.newEmoji,
            group: this.selectedGroup, // Store the selected group for the new category
            order: this.categories.length,
          };
          const categoriesCollection = collection(db, `users/${user.uid}/categories`);
          const categoryDocRef = doc(categoriesCollection, this.newCategory); // Using newCategory as the document id
          await setDoc(categoryDocRef, category); // Use setDoc instead of addDoc
          console.log('Category added with name:', this.newCategory);
          this.newCategory = '';
          this.newEmoji = '';
          this.selectedGroup = ''; // Reset the selected group
          this.retrieveCategories(); // Update the displayed categories
          // Dispatch an action to fetch the updated categories
          await this.$store.dispatch('fetchCategories');

        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error adding category:', error);
      }
    },

    toggleEditing(category) {
      if (category.name !== 'Credit Card Payment') {
        category.editing = !category.editing;
      }
    },
    async saveCategory(category) {
      try {
        const user = auth.currentUser;
        if (user) {
          const categoriesCollection = collection(db, `users/${user.uid}/categories`);
          const categoryDocRef = doc(categoriesCollection, category.id);

          // Preserve old category name before updating
          const oldCategoryName = (await getDoc(categoryDocRef)).data().name;
          console.log("Old category name:", oldCategoryName);

          await updateDoc(categoryDocRef, { 
            name: category.name, 
            emoji: category.emoji, 
            group: category.group 
          });

          // Call to update transactions after category update
          if (oldCategoryName !== category.name) {
            await this.updateMappingsForRenamedCategory(oldCategoryName, category.name);            
            this.updateTransactionsCategory(oldCategoryName, category);

          }

          console.log('Category saved:', category.id);
          this.toggleEditing(category);
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error saving category:', error);
      }
    },
    async updateTransactionsCategory(oldCategoryName, updatedCategory) {
      try {
        const user = auth.currentUser;
        if (user) {
          // Query to find transactions with the old category name
          const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
          const q = query(transactionsCollection, where("category", "==", oldCategoryName));
          const querySnapshot = await getDocs(q);

          // Batch write to update transactions
          const batch = writeBatch(db);
          querySnapshot.forEach((docSnapshot) => {
          const transactionDocRef = doc(db, `users/${user.uid}/transactions`, docSnapshot.id);
          batch.update(transactionDocRef, {
            category: updatedCategory.name,
            categoryEmoji: updatedCategory.emoji
          });
        });
          await batch.commit();
        }
      } catch (error) {
        console.error('Error updating transactions:', error);
      }
    },

    async renameCategory() {
      try {
        const user = auth.currentUser;
        if (user) {
          const categoriesCollection = collection(db, `users/${user.uid}/categories`);
          const categoryDocRef = doc(categoriesCollection, this.editedCategory.id);

          // Preserve old category name before updating
          const oldCategoryName = (await getDoc(categoryDocRef)).data().name;
          console.log("Old category name:", oldCategoryName);

          await updateDoc(categoryDocRef, { 
            name: this.editedCategory.name, 
            emoji: this.editedCategory.emoji, 
            group: this.editedCategory.group 
          });

          // Call to update transactions after category update
          if (oldCategoryName !== this.editedCategory.name) {
            await this.updateMappingsForRenamedCategory(oldCategoryName, this.editedCategory.name);            
            this.updateTransactionsCategory(oldCategoryName, this.editedCategory);
          }

          console.log('Category saved:', this.editedCategory.id);
          this.retrieveCategories(); // Update the displayed categories
          this.closeEditModal();
          await this.$store.dispatch('fetchCategories');
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error saving category:', error);
      }
    },
    async updateMappingsForRenamedCategory(oldName, newName) {
      const user = auth.currentUser;
      if (user) {
        const mappingsDocRef = doc(db, `users/${user.uid}/mappings`, 'categoryMap');
        const mappingsSnapshot = await getDoc(mappingsDocRef);
        if (mappingsSnapshot.exists()) {
          let mappings = mappingsSnapshot.data();
          console.log("Fetched mappings:", mappings);

          // Update the mappings where the value matches the oldName
          for (let [key, value] of Object.entries(mappings)) {
            if (value === oldName) {
              console.log(`Updating mapping for key: ${key}, from ${value} to ${newName}`);
              mappings[key] = newName;
            }
          }

          // Save the updated mappings back to Firestore
          await updateDoc(mappingsDocRef, mappings);

          // Confirm the update
          const updatedMappingsSnapshot = await getDoc(mappingsDocRef);
          if (updatedMappingsSnapshot.exists()) {
            console.log("Updated mappings:", updatedMappingsSnapshot.data());
          }
        }
      }
    },
    openModal(categoryName) {
      console.log("Opening modal for category: ", categoryName);
      this.oldCategoryName = categoryName;
      this.showModal = true;
    },
    closeModal() {
      console.log("Closing modal");
      this.showModal = false;
      this.selectedNewCategory = '';
    },
    async confirmModalSelection(selectedCategory) {
      console.log("Confirming selection: ", selectedCategory);
      this.selectedNewCategory = selectedCategory;
      this.showModal = false;

      // Reassign transactions to the new category
      await this.reassignTransactions(this.oldCategoryName, this.selectedNewCategory);

      // Delete the category
      const categoryId = this.categories.find(c => c.name === this.oldCategoryName).id;
      await deleteDoc(doc(db, `users/${auth.currentUser.uid}/categories`, categoryId));
      console.log('Category deleted:', categoryId);

      // Update mappings and re-fetch categories
      await this.updateMappingsForDeletedCategory(this.oldCategoryName);
      await this.$store.dispatch('fetchCategories');
      this.retrieveCategories();

      // Reset
      this.oldCategoryName = '';
      this.selectedNewCategory = '';
    },
    async deleteCategory(categoryId) {
      const user = auth.currentUser;
      if (user) {
        const categoryDocRef = doc(db, `users/${user.uid}/categories`, categoryId);
        const categorySnapshot = await getDoc(categoryDocRef);

        if (categorySnapshot.exists()) {
          const categoryName = categorySnapshot.data().name;

          // Open the modal to let the user select a new category
          this.openModal(categoryName);
        }
      } else {
        console.log('No user is logged in.');
      }
    },
    async promptForNewCategory(oldCategoryName) {
      // Filter out the old category from the list of categories
      const availableCategories = this.categories.filter(c => c.name !== oldCategoryName);

      // Convert available categories to a string for prompt
      const categoryNames = availableCategories.map(c => c.name).join(", ");

      // Prompt user to enter a new category from the list
      let newCategoryName = prompt(`Please enter a new category for transactions currently under "${oldCategoryName}". Available categories: ${categoryNames}`);

      // Check if the entered category is valid
      if (availableCategories.some(c => c.name === newCategoryName)) {
        return newCategoryName;
      } else {
        alert("Invalid category selected. Operation cancelled.");
        return null;
      }
    },
    async reassignTransactions(oldCategoryName, newCategoryName) {
      try {
        const user = auth.currentUser;
        if (user) {
          // Query to find transactions with the old category name
          const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
          const q = query(transactionsCollection, where("category", "==", oldCategoryName));
          const querySnapshot = await getDocs(q);

          // Batch write to update transactions
          const batch = writeBatch(db);
          querySnapshot.forEach((docSnapshot) => {
            const transactionDocRef = doc(db, `users/${user.uid}/transactions`, docSnapshot.id);
            batch.update(transactionDocRef, {
              category: newCategoryName
            });
          });

          await batch.commit();
          console.log('Transactions reassigned to new category');
        }
      } catch (error) {
        console.error('Error reassigning transactions:', error);
      }
    },
    async updateMappingsForDeletedCategory(deletedName) {
      const user = auth.currentUser;
      if (user) {
        const mappingsDocRef = doc(db, `users/${user.uid}/mappings`, 'categoryMap');
        const mappingsSnapshot = await getDoc(mappingsDocRef);
        if (mappingsSnapshot.exists()) {
          let mappings = mappingsSnapshot.data();

          // Update the mappings where the value matches the deletedName
          for (let [key, value] of Object.entries(mappings)) {
            if (value === deletedName) {
              mappings[key] = 'Uncategorized';
            }
          }

          // Save the updated mappings back to Firestore
          await updateDoc(mappingsDocRef, mappings);
        }
      }
    },
    async updateCategoriesOrder(group) {
      try {
        const user = auth.currentUser;
        if (user) {
          const categoriesCollection = collection(db, `users/${user.uid}/categories`);

          // Create a batch write operation
          const batch = writeBatch(db);

          // Loop through each category in the new order
          const categoriesToUpdate = this.groupedCategories[group];
          for (let i = 0; i < categoriesToUpdate.length; i++) {
            const category = categoriesToUpdate[i];
            const categoryDocRef = doc(categoriesCollection, category.id);
            // Update the 'order' property of each category
            batch.update(categoryDocRef, { order: i });
          }

          // Commit the batch
          await batch.commit();

          console.log('Categories order updated');
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error updating categories order:', error);
      }
    },
  },
};
</script>


<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  display: inline-grid;
}

li {
  display: grid;
  grid-template-columns: 1fr auto auto;
  background-color: #ffffff;
  padding: 0 16px;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 10px;
  cursor: grab;
  margin-right: 10px; /* Add this */
  transition: box-shadow 0.3s;
}

.emoji-input {
  text-align: center; 
  width: 50px;
  margin-right: 10px;
}

li.grabbing {
  cursor: grabbing;
}

input{
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: white;
    outline-color: lightgrey;
    outline-style: solid;
    outline-width: thin;
    font-size: 14px;
    color: gray;
}

.labelbuttons {
  margin-left: 10px;
}

.labelname {
  width: 140px;
}


.slide-enter-active,
.slide-leave-active {
  transition: height 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  height: 0;
  overflow: hidden;
}

.manage-labels-button {
  margin-bottom: 10px;
}

.manage-classes-button {
  width: 300px;
  height: 60px;
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 10px;
}
.categoryname {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  padding: 18px 0px;
}

.categorybuttons {
  display: block;
  margin: 10px auto;
  padding: 8px 10px;
  background-color: white;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0;  /* Hide the buttons initially */
  transition: opacity 0.3s;
}

li:hover .categorybuttons {
  opacity: 1; 
}

li:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.categorybuttons:hover {
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
}

.settings-title {
  text-align: center;
}

.select-group {
  width: unset !important;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: white;
  outline-color: lightgrey;
  outline-style: solid;
  outline-width: thin;
  font-size: 14px;
  color: gray;
  margin: 5px;
}

.add-category-buttons {
  display: inline-grid;
}

.group-section {
  background: #f1f1f1;
  border-radius: 8px;
  display: inline-grid;
  padding: 20px 10px 20px 20px;
  break-inside: avoid;
  margin-bottom: 1em; /* space between groups */
  width: 20vw;
}

.category-manager {
  column-count: 3; /* number of columns */
  column-gap: 1em; /* space between columns */
}

.new-category-form {
  background: #f1f1f1;
  border-radius: 8px;
  display: inline-grid;
  padding: 30px;
  width: 20vw;
  overflow: scroll;
  break-inside: avoid;
  margin-bottom: 1em; /* space between groups */
}

.new-category-input {
  background-color: white;
  outline-color: lightgrey;
  width: calc(100% - 10px); /* Change this to calc(100% - 10px) */
  outline-style: solid;
  outline-width: thin;
  color: black;
  margin: 5px;
}

.emoji-picker-wrapper {
  /* position: absolute; */
  z-index: 100; /* Ensure it's above other elements */
  left: 0; /* Align with the left edge of the input */
  top: 100%; /* Position it just below the input field */
}

.emoji-picker-wrapper-edit {
  position: absolute;
  z-index: 100; /* Ensure it's above other elements */
  left: 0; /* Align with the left edge of the input */
}

/* Ensure parent elements have a relative position */
.emoji-input {
  position: relative;
}

.input-wrapper {
  position: relative; /* This makes it a positioning context for absolute children */
}

emoji-picker:host {
  width: 400px !important;
}

emoji-picker::part(picker) {
  width: 400px !important;
}

.info-container {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.info-icon {
  cursor: pointer;
  margin-right: 5px;
}

.info-text {
  display: block;
  width: 100%;
  text-align: left;
  background: #f9f9f9;
  margin: 0px 40px 20px 40px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.edit-category-buttons {
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%) !important;
  margin: 5px;
}

</style>
