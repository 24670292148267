<template>
  <div class="dashboard">

    <div v-if="hasTransactions" class="parent">
      <div class="div1dashboard">
        <div class="categoriesPie">
          <SpendingPie v-if="sortedTransactions && sortedTransactions.length > 0" :data="sortedTransactions" :categories="categories" />
        </div>
      </div>

      <div class="div2dashboard">
        <div class="LineChart">
          <LineChart :data="sortedTransactions" />
        </div>
      </div>

      <div class="div3dashboard">
        <CategoryLines :data="sortedTransactions" />
      </div>
    </div>
    <div v-else>
      <p class="notransactions">No transactions found. Please add some data to view the charts.</p>
    </div>
    <div class="">

    </div>
  </div>
</template>

<script>
import { collection, addDoc, doc, updateDoc, onSnapshot, query, orderBy, deleteDoc, getDocs } from 'firebase/firestore'
import { auth, db } from '../firebaseInit'; // Import the initialized Firebase auth and Firestore instances
import LineChart from '../components/LineChart.vue';
import CategoryLines from '../components/CategoryLines.vue';
import SpendingPie from '../components/SpendingPie.vue';


export default {
  name: 'DashboardPage',
  components: {
    LineChart,
    CategoryLines,
    SpendingPie,
  },
  data() {
    return {
      transactions: [],
      editedTransaction: null,
      editedTransactionField: null,
      editingTransactionIndex: null,
      categoriesExpanded: false,
      chartInstance: null,
      showSpendingPie: false,
      newTransaction: { date: '', name: '', amount: 0, category: null, labels: [] },  // Initialize labels as an empty array
      labels: [], // Array to store existing labels
      sortBy: 'date', // Default sort option
      dateError: '',
      filters: {
        fromDate: null,
        toDate: null,
        category: null,
        transactionName: '',
        minAmount: null,
        maxAmount: null
      },
      showFilters: false,
      focusedInputIndex: null,
      filterLabels: '', // Initialize as an empty string or an empty array
    };
  },
  mounted() {
    try {
      auth.onAuthStateChanged((user) => {
        if (user) {
          const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
          const q = query(transactionsCollection, orderBy(this.sortBy));

          onSnapshot(q, (snapshot) => {
            const changes = snapshot.docChanges();
            changes.forEach((change) => {
              if (change.type === 'added') {
                this.transactions.push({ id: change.doc.id, ...change.doc.data() });
              } else if (change.type === 'modified') {
                const index = this.transactions.findIndex((transaction) => transaction.id === change.doc.id);
                this.transactions[index] = { id: change.doc.id, ...change.doc.data() };
              } else if (change.type === 'removed') {
                this.transactions = this.transactions.filter((transaction) => transaction.id !== change.doc.id);
              }
            });
          });

          // Retrieve labels from the Firestore collection
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          onSnapshot(labelsCollection, (snapshot) => {
            this.labels = snapshot.docs.map((doc) => doc.data().name);
          });
        } else {
          console.log('No user is logged in.');
        }
      });
    } catch (error) {
      console.error('Error retrieving documents:', error);
    }
  },
  computed: {
    sortedTransactions() {
    return this.transactions
      .filter(transaction => {
        if (this.filters.fromDate && transaction.date < this.filters.fromDate) {
          return false;
        }
        if (this.filters.toDate && transaction.date > this.filters.toDate) {
          return false;
        }
        if (this.filters.category && transaction.category !== this.filters.category) {
          return false;
        }
        if (this.filters.transactionName && !transaction.name.toLowerCase().includes(this.filters.transactionName.toLowerCase())) {
          return false;
        }
        if (this.filters.minAmount && transaction.amount < this.filters.minAmount) {
          return false;
        }
        if (this.filters.maxAmount && transaction.amount > this.filters.maxAmount) {
          return false;
        }
        
        // Filter by label(s)
        if (this.filterLabels && Array.isArray(transaction.labels)) {
          const selectedLabels = Array.isArray(this.filterLabels) ? this.filterLabels : [this.filterLabels];
          const matchedLabels = transaction.labels.filter((label) => selectedLabels.includes(label));
          if (matchedLabels.length === 0) {
            return false;
          }
        }
        
        return true;
      })
      .slice()
      .sort((a, b) => (a[this.sortBy] < b[this.sortBy] ? -1 : 1));
    },
    filteredLabels() {
      if (this.editingTransactionIndex === null) {
        return this.labels;
      }

      const transaction = this.transactions[this.editingTransactionIndex];

      if (Array.isArray(transaction.labels)) {
        return transaction.labels.filter((label) => this.labels.includes(label));
      }

      return [];
    },
    hasTransactions() {
      return this.transactions.length > 0;
    },
  },
  directives: {
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          if (!(el === event.target || el.contains(event.target))) {
            binding.value();
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },
  methods: {
    filterTransactions() {
      const fromDate = this.filters.fromDate;
      const toDate = this.filters.toDate;
      const category = this.filters.category;
      const transactionName = this.filters.transactionName.toLowerCase();
      const minAmount = this.filters.minAmount;
      const maxAmount = this.filters.maxAmount;

      this.sortedTransactions = this.transactions.filter((transaction) => {
        // Filter by date range
        if (fromDate && transaction.date < fromDate) {
          return false;
        }
        if (toDate && transaction.date > toDate) {
          return false;
        }

        // Filter by category
        if (category && transaction.category !== category) {
          return false;
        }

        // Filter by transaction name
        if (transactionName && !transaction.name.toLowerCase().includes(transactionName)) {
          return false;
        }

        // Filter by amount range
        if (minAmount && transaction.amount < minAmount) {
          return false;
        }
        if (maxAmount && transaction.amount > maxAmount) {
          return false;
        }

        return true;
      });
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    async addTransaction() {
      try {
        // Validate transaction date
        const currentDate = new Date().toISOString().split('T')[0];
        if (this.newTransaction.date < '1980-01-01') {
          this.dateError = 'Dates that far back (1980) are not supported';
          return; // Exit the method if the date is invalid
        } else if (this.newTransaction.date > currentDate) {
          this.dateError = 'Dates that far in advance are not supported.';
          return; // Exit the method if the date is invalid
        }

        // Clear the date error
        this.dateError = '';

        const transactionToAdd = {
          date: this.newTransaction.date,
          name: this.newTransaction.name,
          amount: this.newTransaction.amount,
          category: this.newTransaction.category,
          labels: Array.isArray(this.newTransaction.labels)
            ? this.newTransaction.labels
            : this.newTransaction.labels.split(' ').filter(label => label !== '')
        };
        // Get the current user
        const user = auth.currentUser;

        // If the user is logged in, use their unique ID to store the transaction
        if (user) {
          const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
          const docRef = await addDoc(transactionsCollection, transactionToAdd);
          console.log(`Transaction written with ID: ${docRef.id}`);
        } else {
          // Handle case where user is not logged in
          console.log('No user is logged in.');
        }

        this.newTransaction = { date: '', name: '', amount: 0, category: null, labels: [] }; // Update to initialize labels as an empty array
      } catch (error) {
        console.error('Error adding document:', error);
      }
    },
    async handleCsvFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        const csvData = await this.parseCsvFile(file);
        if (csvData) {
          await this.processCsvData(csvData);
        }
      }
    },
    parseCsvFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = event.target.result;
          // Use a CSV parsing library or custom logic to parse the data
          // Resolve with the parsed CSV data
          resolve(csvData);
        };
        reader.onerror = (event) => {
          // Reject with an error if file reading fails
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    async processCsvData(csvData) {
      // Split the CSV data into rows
      const rows = csvData.split('\n');

    // Extract header and its column order
    const header = rows[0];
      const columns = header.split(',');

      // Create an object to store column positions
      const columnPositions = {
        date: -1,
        name: -1,
        amount: -1,
        category: -1,
      };

      // Map column positions to field names based on header
      columns.forEach((column, index) => {
        const field = column.toLowerCase().trim();
        if (field in columnPositions) {
          columnPositions[field] = index;
        }
      });

      // Extract transaction details from each row
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i];
        const rowValues = row.split(',');

        // Create an object with transaction data
        const transaction = {
          date: this.getFieldValue(rowValues, columnPositions.date),
          name: this.getFieldValue(rowValues, columnPositions.name),
          amount: Number(this.getFieldValue(rowValues, columnPositions.amount)),
          category: this.getFieldValue(rowValues, columnPositions.category)
        };

        // Get the current user
        const user = auth.currentUser;

        // If the user is logged in, use their unique ID to store the transaction
        if (user) {
          const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
          await addDoc(transactionsCollection, transaction);
        }
      }
    },
    getFieldValue(rowValues, columnIndex) {
      return columnIndex !== -1 ? rowValues[columnIndex]?.trim() : '';
    },
    startEditing(index, field) {
      this.editingTransactionIndex = index;
      this.editingTransactionField = field;
    },
    async saveTransaction(transaction) {
      try {
        const transactionId = transaction.id;
        const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);

        // Check if the entered label is not already in the labels array
        if (!this.labels.includes(transaction.labels)) {
          // Add the entered label to the labels array
          this.labels.push(transaction.labels);
        }

        // Remove the "(New Label)" option if it exists
        const newLabelIndex = this.labels.indexOf(transaction.labels + ' (New Label)');
        if (newLabelIndex !== -1) {
          this.labels.splice(newLabelIndex, 1);
        }

        // Update the transaction in the Firestore collection
        await updateDoc(doc(transactionsCollection, transactionId), transaction);
        this.editedTransaction = null;
        this.editedTransactionField = null;
      } catch (error) {
        console.error('Error updating transaction:', error);
      }
    },
    isTransactionBeingEdited(index, field) {
      return this.editingTransactionIndex === index && this.editingTransactionField === field;
    },
    confirmDeleteTransaction(transactionId) {
      if (confirm('Are you sure you want to delete this transaction?')) {
        this.deleteTransaction(transactionId);
      }
    },
    async deleteTransaction(transactionId) {
      try {
        const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);
        await deleteDoc(doc(transactionsCollection, transactionId));
        console.log('Transaction deleted:', transactionId);
      } catch (error) {
        console.error('Error deleting transaction:', error);
      }
    },
    async getLabels() {
    try {
      const user = auth.currentUser;
      if (user) {
        const labelsCollection = collection(db, `users/${user.uid}/labels`);
        const querySnapshot = await getDocs(labelsCollection);
        this.labels = querySnapshot.docs.map((doc) => doc.data().name);
      }
      } catch (error) {
        console.error('Error retrieving labels:', error);
      }
    },
    getSelectedLabels(labels) {
      if (!Array.isArray(labels)) {
        return [];
      }
      return labels;
    },
    toggleLabelSelection(transaction, label) {
        if (this.editingTransactionIndex === null) {
          // Start editing labels
          this.editingTransactionIndex = this.transactions.indexOf(transaction);
          this.editingTransactionField = 'labels';
        } else {
          const labelIndex = transaction.labels.indexOf(label);
          if (labelIndex !== -1) {
            // Label is already selected, remove it
            transaction.labels.splice(labelIndex, 1);
          } else {
            // Label is not selected, add it
            transaction.labels.push(label);
          }
        }
      },
    closeLabelDropdown() {
      this.editingTransactionIndex = null;
      this.editingTransactionField = null;
    },
    isSelectedLabel(transaction, label) {
      return Array.isArray(transaction.labels) && transaction.labels.includes(label);
    },
    isLabelHovered(transaction, label) {
      return (
        transaction.hoveredLabel &&
        transaction.hoveredLabel.label === label &&
        transaction.hoveredLabel.hovered
      );
    },
    setHoveredLabel(transaction, label, hovered) {
      if (!transaction.hoveredLabel) {
        transaction.hoveredLabel = { label, hovered };
      } else {
        transaction.hoveredLabel.label = label;
        transaction.hoveredLabel.hovered = hovered;
      }
    },
    removeLabel(transaction, label) {
      const labelIndex = transaction.labels.indexOf(label);
      if (labelIndex !== -1) {
        transaction.labels.splice(labelIndex, 1);
        this.saveTransaction(transaction);
      }
    },
    toggleExpandCategories() {
      this.categoriesExpanded = !this.categoriesExpanded;
    },
    // formatNotes(notes) {
    //   if (!notes) {
    //     return '';
    //   }
    //   const noteWords = notes.split(' ');
    //   return noteWords
    //     .map((word) => {
    //       if (word.startsWith('#')) {
    //         return `<span class="hashtag"> ${word} </span>`;
    //       }
    //     })
    //     .join(' ');
    // },
  },
};
</script>

<style scoped>

.parent {
  display: grid;
}

.div1dashboard { 
  grid-area: 1 / 1 / 2 / 3; 
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: -moz-fit-content;
  margin: 2%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.div2dashboard { 
  grid-area: 1 / 3 / 2 / 5;
  align-items: baseline;
  padding: 5%;
  height: fit-content;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: -moz-fit-content;
  margin: 2%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.div3dashboard { 
  grid-area: 2 / 1 / 3 / 5; 
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: -moz-fit-content;
  margin: 2%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.div4 { grid-area: 3 / 1 / 4 / 2; }
.div5 { grid-area: 3 / 2 / 4 / 3; }
.div6 { grid-area: 3 / 3 / 4 / 4; }
.div7 { grid-area: 3 / 4 / 4 / 5; }

h2 {
  text-align: center;
}

.notransactions {
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
</style>