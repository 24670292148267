<template>
  <div style="margin: 0 auto;">
    <v-btn class="manage-rules-button" @click="expandContent">
      {{ isExpanded ? 'Hide Rules' : 'Transaction Rules' }}
    </v-btn>
    <transition name="slide">
      <div v-if="isExpanded" class="rule-manager">
        <!-- Display a message if there are no transaction rules -->
        <div v-if="transactionRules.length === 0" class="no-rules-message">
          No transaction rules have been found. Rules can be created on the spending page with the "Create Rule" button.
        </div>
        <!-- Otherwise, display the list of transaction rules -->
        <div v-else>
          <ul class="transaction-rules">
            <li v-for="rule in transactionRules" :key="rule.id" class="inline-rules-li">
              <ul>
              <li class="inline-conditions-li">
                <strong>Conditions:</strong>
                <ul class="rule-details2">
                  <li v-for="(value, key) in rule.conditions" :key="key">
                    <!-- Handle special case for 'Within' matchType -->
                    <span v-if="value.matchType === 'Within'">
                      {{ key.charAt(0).toUpperCase() + key.slice(1) }} {{ value.matchType }}: {{ formatValue(key, value.min) }} - {{ formatValue(key, value.max) }}
                    </span>
                    <!-- Handle nested objects for other conditions -->
                    <span v-else-if="typeof value === 'object' && value !== null">
                      {{ key.charAt(0).toUpperCase() + key.slice(1) }} {{ value.matchType }}: {{ formatValue(key, value.value) }}
                    </span>
                    <!-- Handle simple conditions -->
                    <span v-else>
                      {{ key.charAt(0).toUpperCase() + key.slice(1) }}: {{ formatValue(key, value) }}
                    </span>
                  </li>
                </ul>
              </li>
              <li class="inline-new-values-li">
                <strong>New Values:</strong>
                <ul class="rule-details2">
                  <li v-for="(value, key) in rule.newValues" :key="key">
                    {{ key.charAt(0).toUpperCase() + key.slice(1) }}: {{ formatNewValue(key, value) }}
                  </li>
                </ul>
              </li>
              <div class="rule-header">
                  <!-- <button class="rulesbuttons" @click="deleteRule(rule.id)">Delete</button> -->
                  <span class="delete-icon" @click="deleteRule(rule.id)">X</span>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>


<script>
/* eslint-disable */
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';

export default {
  name: 'ManageTransactionRules',
  data() {
    return {
      transactionRules: [],
      isExpanded: false,
    };
  },
  mounted() {
    this.retrieveTransactionRules();
  },
  methods: {
    async retrieveTransactionRules() {
      try {
        const user = auth.currentUser;
        if (user) {
          const rulesCollection = collection(db, `users/${user.uid}/transactionRules`);
          const querySnapshot = await getDocs(rulesCollection);
          this.transactionRules = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
          }));
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error retrieving transaction rules:', error);
      }
    },

    async deleteRule(ruleId) {
      try {
        const user = auth.currentUser;
        if (user) {
          const rulesCollection = collection(db, `users/${user.uid}/transactionRules`);
          await deleteDoc(doc(rulesCollection, ruleId));
          this.$store.dispatch('deleteRule', ruleId);
          console.log('Transaction rule deleted:', ruleId);
          this.retrieveTransactionRules(); // Refresh the list of rules
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error deleting transaction rule:', error);
      }
    },
    expandContent() {
      this.isExpanded = !this.isExpanded;
    },
    formatValue(key, value) {
      if (key === 'amount' && typeof value === 'number') {
        return `$${value.toFixed(2)}`;
      }
      return value;
    },
    formatNewValue(key, value) {
      if (key === 'amount') {
        return `$${parseFloat(value).toFixed(2)}`; // Format as currency
      }
      return value;
    },
  }
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  display: inline-grid;
}

.inline-conditions-li, .inline-rules-li, .inline-new-values-li {
  display: inline-block;
  height: auto;
  background: #f1f1f1;
  margin: 6px;
  border-radius: 5px;
}

.inline-conditions-li, .inline-new-values-li {
  /* Adjust styles for conditions and new values */
  display: block; /* Change from inline-flex to block */
  margin-bottom: 10px; /* Add space between conditions and new values */
  height: auto;
  text-align: left;
}

input{
  width: auto;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  font-size: 14px;
  color: gray;
}

.rulesbuttons {
  margin: 0 auto;
  padding: 5px;
  background-color: unset;
  opacity: 0;
  color: #333;
}

.rule-details2 {
  /* Style for the container of rule details */
  display: block; /* Change to block layout */
  padding: 10px; /* Add some padding */
  background-color: #f9f9f9; /* Light background for the container */
  border-radius: 5px; /* Rounded corners for the container */
  min-width: 240px;
  min-height: 80px;
}

.rule-details2 li {
  /* Style for list items in rule details */
  margin: 5px; /* Add some space between list items */
  color: #333; /* Set text color */
  font-size: 14px; /* Adjust font size as needed */
  text-align: left;
  height: auto;
}

.rulesbuttons:hover {
  background-color: rgb(225, 225, 225);
  border-radius: 5px;
  padding: 5px;
}

.inline-rules-li:hover .rulesbuttons {
  opacity: 1;
}

.inline-rules-li {
  display: inline-flex;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
}

.labelname {
  width: 140px;
  text-align: left;
}


.slide-enter-active,
.slide-leave-active {
  transition: height 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  height: 0;
  overflow: hidden;
}

.manage-labels-button {
  margin-bottom: 10px;
}

.settings-title {
  text-align: center;
}

.newlabelinput {
  display: inline-flex;
  height: 18px;
  width: 190px;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  margin-bottom: 10px;
}

.rulesbuttons-save {
  margin-left: -20px;
  color: black;
  padding: 5px;
  background-color: unset;
}

.rulesbuttons-save:hover {
  background: #e9e9e9;
}

.rule-header {
  /* Style for the rule header */
  display: flex; /* Ensure buttons are inline */
  justify-content: space-between; /* Space out the buttons and title */
  align-items: center; /* Align items vertically */
}

.transaction-rules {
  display: inline;
}

.delete-icon {
  display: none;
  position: absolute;
  top: -5px;
  right: -4px;
  cursor: pointer;
  padding: 0px 5px;
  /* background: #3f4a40; */
  color: #cacaca;
  border: 1px solid rgb(218, 225, 234);
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}


.delete-icon:hover {

  background: #3f4a40;
  color: #ffffff;
  border-radius: 5px;
  transition: 0.3s ease-in-out;
}

.manage-rules-button {
  width: 300px;
  height: 60px;
  background-color: white !important;
  color: black !important;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  margin-bottom: 10px;
}


.inline-rules-li:hover .delete-icon {
    display: block;
}

.no-rules-message {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
}






</style>

