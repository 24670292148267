<template>
    <div class="onboarding-style-recurring">
    <h1 class="fade-slide-down" id="welcome-text">Review Transactions</h1>
    <h3 class="fade-slide-down" id="import-text-recurring">We've successfully imported your transactions. Next, let's take a look at your uncategorized transactions and clean them up.</h3>
  </div>
  
      <!-- Check if there are no uncategorized transactions -->
      <div v-if="!hasUncategorizedTransactions" class="no-transactions-message">
      You don't have any uncategorized transactions! Let's continue to the next step.
    </div>
  <button class="done-onboarding" @click="goToOnboarding3">Continue</button>
  <div class="spending-view">

    <div v-for="(notification, index) in notifications" :key="index" class="notification-container" :style="{ bottom: `${20 + 80 * index}px` }">
      <p class="notification-detail" v-html="notification.detailMessage"></p>
      <div class="notification-progress"></div>
      <button @click="openCreateRuleModal(notification)" class="create-rule-button">Create Rule</button>
    </div>
    
    <!-- Overlay modal for creating a rule -->
    <div v-if="showCreateRuleModal" class="modal-backdrop">
      <div class="transaction-rule-modal-content">
        <h2>Create Transaction Rule</h2>
        <!-- Form for creating the rule -->
        <form @submit.prevent="createRule">
          <div class="transaction-rules-parent">
            <div class="match-conditions-group">
              <p>If transaction has:</p>
              
              <!-- Name Group -->
              <div class="form-group 2">
                <div class="form-group-header" @click="isNameExpanded = !isNameExpanded">
                  <span class="transaction-rule-title">Name</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="nameToggle" v-model="isNameExpanded" @click.stop>
                    <label for="nameToggle" class="slider" @click="isNameExpanded = !isNameExpanded"></label>
                  </div>
                </div>
                <input v-if="isNameExpanded" type="text" id="conditionName" v-model="conditionName">
                <select v-if="isNameExpanded" class="categories-select-rule" v-model="conditionNameMatch">
                  <option selected value="Exact Match">Exact Match</option>
                  <option value="Contains">Contains</option>
                </select>
              </div>

              <!-- Amount Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isAmountExpanded = !isAmountExpanded">
                  <span class="transaction-rule-title">Amount</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="amountToggle" v-model="isAmountExpanded" @click.stop>
                    <label for="amountToggle" class="slider" @click="isAmountExpanded = !isAmountExpanded"></label>
                  </div>
                </div>
                <input v-if="isAmountExpanded" type="number" id="conditionAmount" v-model="conditionAmount" step="0.01">
                <select v-if="isAmountExpanded" class="categories-select-rule" v-model="conditionAmountMatch" required>
                  <option selected value="Equals">Equals</option>
                  <option value="More than">More than</option>
                  <option value="Less than">Less than</option>
                  <option value="Within">Within</option> 
                </select>
                <div class="amount-range" v-if="isAmountExpanded && conditionAmountMatch === 'Within'">
                  <input type="number" placeholder="Minimum Amount" v-model="conditionAmountMin" step="0.01">
                  <input type="number" placeholder="Maximum Amount" v-model="conditionAmountMax" step="0.01">
                </div>
              </div>

              <!-- Category Group -->
              <div class="form-group">
                <div class="form-group-header"  @click="isCategoryExpanded = !isCategoryExpanded">
                  <span class="transaction-rule-title">Category</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="categoryToggle" v-model="isCategoryExpanded" @click.stop>
                    <label for="categoryToggle" class="slider" @click="isCategoryExpanded = !isCategoryExpanded"></label>
                  </div>
                </div>
                <select v-if="isCategoryExpanded" class="categories-select-rule" v-model="conditionCategory">
                  <option disabled :value="null">Category</option>
                  <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.emoji }} {{ category.name }}</option>
                </select>
              </div>
            </div>
            <div class="new-values-group">
              <p>Update to:</p>

              <!-- New Name Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewNameExpanded = !isNewNameExpanded">
                  <span class="transaction-rule-title">New Name</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newNameToggle" v-model="isNewNameExpanded" @click.stop>
                    <label for="newNameToggle" class="slider" @click="isNewNameExpanded = !isNewNameExpanded"></label>
                  </div>
                </div>
                <input v-if="isNewNameExpanded" type="text" id="newName" v-model="newName">
              </div>

              <!-- New Amount Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewAmountExpanded = !isNewAmountExpanded">
                  <span class="transaction-rule-title">New Amount</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newAmountToggle" v-model="isNewAmountExpanded" @click.stop>
                    <label for="newAmountToggle" class="slider" @click="isNewAmountExpanded = !isNewAmountExpanded"></label>
                  </div>
                </div>
                <input v-if="isNewAmountExpanded" type="number" id="newAmount" v-model="newAmount" step="0.01">
              </div>

              <!-- New Category Group -->
              <div class="form-group">
                <div class="form-group-header" @click="isNewCategoryExpanded = !isNewCategoryExpanded">
                  <span class="transaction-rule-title">New Category</span>
                  <div class="transaction-rule-toggle-switch">
                    <input type="checkbox" id="newCategoryToggle" v-model="isNewCategoryExpanded" @click.stop>
                    <label for="newCategoryToggle" class="slider" @click="isNewCategoryExpanded = !isNewCategoryExpanded"></label>
                  </div>
                </div>
                <select v-if="isNewCategoryExpanded" class="categories-select-rule" v-model="newCategory">
                  <option disabled :value="null">Category</option>
                  <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.emoji }} {{ category.name }}</option>
                </select>
              </div>
            </div>
          </div>
        </form>
        <!-- Loading bar for bulk edit -->
        <div v-if="bulkEditing" class="modal-backdrop">
          <div class="modal-content">
            <div class="progress-bar-container">
              <div class="progress-bar" :style="{ width: bulkEditingProgressBarWidth }"></div>
            </div>
            <p>Editing transactions: {{ transactionsEdited }}/{{ totalTransactionsToBeEdited }} ({{ editingTransactionsProgressBarPercentage }}%)</p>
          </div>
        </div>
        <!-- Loading bar for bulk delete -->
        <div v-if="bulkDeleting">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: bulkEditingProgressBarWidth }"></div>
          </div>
          <p>Deleting transactions: {{ transactionsEdited }}/{{ totalTransactionsToBeEdited }} ({{ editingTransactionsProgressBarPercentage }}%)</p>
        </div>
        
        <!-- Loading bar and transaction count -->
        <div v-if="applyingRules">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: transactionRulesProgressBarWidth }"></div>
          </div>
          <p>{{ transactionsUpdated }}/{{ totalTransactionsToBeUpdated }} ({{ transactionRulesProgressBarPercentage }}%)</p>
        </div>
        <div class="updated-transactions-list" @click="isTransactionsListExpanded = !isTransactionsListExpanded">
          
          <h3 v-if="filteredRuleTransactions.length > 0">
            {{ filteredRuleTransactions.length }} matching transactions found<br>
            <p v-if="!isTransactionsListExpanded" class="click-to-view">Click to view transactions to be updated</p>
          </h3>
          <h3 v-else>
            {{ filteredRuleTransactions.length }} matching transactions found<br>
          </h3>
          <transition name="fade">
            <ul v-if="isTransactionsListExpanded">
              <li v-for="transaction in filteredRuleTransactions" :key="transaction.id">
                {{ transaction.name }} - {{ transaction.date }} - {{ transaction.amount }} - {{ transaction.category }}
              </li>
            </ul>
          </transition>
        </div>
        <div class="form-actions">
          <button class="create-rule-modal-button" @click="createRule">Create Rule</button>

          <button @click="closeCreateRuleModal" id="close-modal-rule-button" class="close-modal-button">X</button>
        </div>
      </div>
    </div>

    <div v-if="isModalOpen" class="modal-backdrop">
      <RecurringRulesModal :isOpen="isModalOpen" :rules="allRecurringRules" :userId="userId" :transaction="selectedRecurringTransaction" @close-modal="isModalOpen = false" @confirm-selection="selectRule" @create-new-rule="createRule" />
    </div>
    <div class="spending-div6">
      <div class="table-group">

        <div class="table-options">
          <input class="search-transactions" type="text" placeholder="Search" v-model="searchQuery">

      
          <v-btn class="edit-multiple-transaction-button" @click="toggleFilters">
            {{ showFilters ? 'Clear Filters' : 'Filter' }}
          </v-btn>

          <div>
            <v-btn class="edit-multiple-transaction-button" @click="isMultiEditMode = !isMultiEditMode">Edit Multiple</v-btn>
          </div>
        </div>
        <div @click="toggleFilters" v-show="showFilters">
            <div class="filter-sidebar2" @click.stop :class="{ 'filter-sidebar-shown': showFilters }">
              <div class="filter-column">
                <label class="filter-label" for="transaction-name"></label>
                <input class="filter-class" id="transaction-name" v-model="filters.transactionName" type="text" placeholder="Name">
                <div class="filter-column">
                  <label class="filter-label" for="category-filter"></label>
                  <select id="category-filter" v-model="filters.category">
                    <option class="category-dropdown-class" value="" selected>All</option>
                    <option v-for="category in categories" :key="category.name" :value="category.name">
                      {{ category.emoji }} {{ category.name }}
                    </option>
                  </select>
                </div>
                
              </div>
              <div class="filter-column">
                <label class="filter-label" for="from-date"></label>
                <input class="filter-class" id="from-date" v-model="filters.fromDate" type="date" placeholder="Select From Date">
                <div class="filter-column">
                  <label class="filter-label" for="to-date"></label>
                  <input class="filter-class" id="to-date" v-model="filters.toDate" type="date" placeholder="Select To Date">
                </div>
              </div>
              <div class="filter-column">
                <label class="filter-label" for="min-amount"></label>
                <input class="filter-class" id="min-amount" v-model.number="filters.minAmount" type="number" placeholder="Min Amount">
                <div class="filter-column">
                  <label class="filter-label" for="max-amount"></label>
                  <input class="filter-class" id="max-amount" v-model.number="filters.maxAmount" type="number" placeholder="Max Amount">
                </div>
              </div>
              <div class="filter-column">
                <label class="filter-label" for="labels-filter"></label>
                <select id="labels-filter" class="filter-label-select" v-model="filterLabels" multiple placeholder="Select Labels">
                  <option class="select-label-filter" value="" disabled selected>Select Labels</option>
                  <option v-for="label in labels" :key="label" :value="label">{{ label }}</option>
                </select>
              </div>
            </div>
        </div>
        <!-- Form for adding a new transaction -->
        <form v-if="showTransactionForm" @submit.prevent="addTransaction" class="add-transaction-form">
          <div class="input-field">
            <input v-model="newTransaction.date" type="date" placeholder="Date" required>
            <br>
            <span v-if="dateError" class="error-message">{{ dateError }}</span>
          </div>
          <div class="input-field">
            <input v-model="newTransaction.name" type="text" placeholder="Name" required>
          </div>
          <div class="input-field">
            <input v-model="newTransaction.amount" type="number" placeholder="Amount" step="0.01" required>
          </div>
          <div class="input-field">
            <select v-model="newTransaction.category" required>
              <option>Category</option>
              <option v-for="category in categories" :key="category.name" :value="category.name">{{ category.emoji }} {{ category.name }}</option>
            </select>
          </div>
          <button class="add-transaction-button" type="submit">Add Transaction</button>
          <button class="close-add-transaction-button" type="button" @click="showTransactionForm = false">Close</button>
        </form>
        <div class="filter-container" v-if="isMultiEditMode">
          <h2 class="edit-multiple-header">Edit multiple transactions</h2>
          <div class="edit-multiple-options">
            <div class="edit-multi-col1">
              <div>
                <label class="multi-label" for="name"></label>
                <input class="multi-class" type="text" id="name" v-model="newName" placeholder="Name" />
              </div>
              <div>
                <label class="multi-label" for="date"></label>
                <input class="multi-class" type="date" id="date" v-model="newDate" />
              </div>
              <div>
                <label class="multi-label" for="amount"></label>
                <input class="multi-class" type="number" id="amount" v-model="newAmount" placeholder="0" />
              </div>
              <div>
                <label class="multi-label" for="category"></label>
                <select id="category-multi" v-model="newCategory">
                  <option disabled value="">Select New Category</option>
                  <option v-for="category in categories" :key="category.name" :value="category.name">
                    {{ category.emoji }} {{ category.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="edit-multi-col2">
              <div>
                <select class="multi-label-select" v-model="newLabels" multiple>
                  <option disabled value="Labels">Labels</option>
                  <option v-for="label in labels" :key="label" :value="label">{{ label }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="edit-multiple-buttons">
            <v-btn @click="updateSelectedTransactions">Save</v-btn>
            <v-btn @click="cancelEditing">Cancel</v-btn>
            <v-btn class="delete-multi-button" :disabled="isLoading" @click="deleteSelectedTransactions">
              <span v-if="isLoading">Deleting...</span>
              <span v-else>Delete Selected</span>
            </v-btn>
          </div>
        </div>
        <div class="select-transactions-group">
          <div class="select-all-container" v-if="isMultiEditMode">
            <input type="checkbox" id="select-all" v-model="selectAll">
            <label for="select-all">Select All</label>
          </div>
          <div class="selected-transactions-count" v-if="isMultiEditMode">
            <label>{{ selectedTransactions.length }} Selected</label>
          </div>
        </div>

        <!-- Table displaying transactions -->
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th style="text-align: center;">Labels</th>
              <th style="text-align: center;">Goal</th>
              <th style="text-align: right;">Category</th>
              <th style="text-align: right;">Amount</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <!-- Group transactions by date -->
            <template v-for="(transactions, date) in groupedTransactionsDescending" :key="date">
              <tr class="date-row">
                <td class="date-td" :colspan="8">{{ formatTransactionDateShort(date) }}</td>
              </tr>
              <tr v-for="(transaction, index) in transactions" :key="index"
              :class="{ 'review-label': transaction.labels.includes('review'), 'pending-transaction': transaction.pending }">
                <!-- The checkbox column -->
                <td class="checkbox-td">
                  <!-- <input type="checkbox" v-model="selectedTransactions" :value="transaction" v-show="isMultiEditMode" /> -->
                  <input type="checkbox" v-model="selectedTransactions" :value="transaction" v-show="isMultiEditMode" @click="handleCheckboxClick($event, transaction)" />
                </td>
                <td class="recurring-td" @click="toggleRecurring(transaction)">
                  <span v-if="transaction.recurringId && transaction.recurringName !== 'Not Recurring'">↻</span>
                </td>
                <!-- <td class="logo-td">
                  <img style="width: 40px; height: 40px; border-radius: 50%;" v-if="transaction.logo_url" :src="transaction.logo_url" alt="Transaction Logo" class="transaction-logo" />
                </td> -->
                <!-- Name -->
                <td class="name-td">
                  <input style="width: 100%;" v-if="!isTransactionBeingEdited(transaction.id, 'name')" :value="transaction.name" @focus="startEditing(transaction.id, 'name')" @input="transaction.name = $event.target.value" @blur="commitTransaction(transaction)" type="text" @keyup.enter="commitTransaction(transaction)" />
                  <input v-else v-model="transaction.name" type="text" @blur="saveTransaction(transaction)" />
                </td>
                <!-- Labels -->

                <td class="labels-td" @click="openLabelManager(transaction); enableLabelDropdown(transaction)" @mouseleave="disableLabelDropdown(transaction); closeLabelManager()">
                  <inline-label-manager v-show="isLabelManagerVisible && editingTransaction === transaction"></inline-label-manager>
                  <div class="labels-container" v-if="!transaction.showDropdown" @mouseleave="disableLabelDropdown(transaction)">
                    <span v-for="label in transaction.labels" :key="label" class="label-item" :class="{ 'label-selected': isSelectedLabel(transaction, label) }">
                      <span class="label-text"> {{ label }} </span>
                    </span>
                    <!-- If no labels exist, show "Add label" button -->
                    <button class="add-label-button" @click="openLabelManager(transaction); enableLabelDropdown(transaction)" v-show="transaction.labels.length === 0">Add label</button>
                  </div>
                  <select class="label-dropdown" v-else multiple v-model="transaction.labels" @focus="startEditingLabels(transaction)" @blur="saveTransaction(transaction)" @click.stop>
                    <option v-for="label in labels" :key="label" :value="label" class="label-item">{{ label }}</option>
                  </select>
                </td>
                <!-- Goals -->
                <td class="goal-td">
                  <select class="goal-select" v-model="transaction.goalId" @focus="captureOldGoalId(transaction)" @change="handleGoalSelection($event, transaction)">
                    <option disabled value="">Select a goal</option>
                    <option value="no_goal">Don't Spend From Goal</option>
                    <option v-for="goal in goalsWithAvailableBalance" :value="goal.id" :key="goal.id" :disabled="goal.availableBalance < transaction.amount">
                      {{ displayOption(goal, transaction.goalId) }}
                    </option>
                  </select>
                </td>

                <!-- Category -->
                <td class="categories-td">
                  <select 
                    class="categories-select"
                    id="categories" 
                    v-model="transaction.category" 
                    @focus="storePreviousCategory(transaction)" 
                    @change="updateTransactionCategory(transaction)">
                    <option v-for="category in categories" :key="category.name" :value="category.name"> {{ category.name }}&nbsp;&nbsp;{{ category.emoji }}&nbsp;&nbsp;</option>
                  </select>
            
                </td>
                <!-- Amount -->
                <td class="amount-td">
                  <input 
                    v-if="!isTransactionBeingEdited(transaction.id, 'amount')" 
                    :value="formatDisplayAmount(parseFloat(transaction.amount).toFixed(2))"
                    :class="{'green-amount': parseFloat(transaction.amount) < 0}" 
                    @focus="startEditing(transaction.id, 'amount')" 
                    @blur="updateTransactionAmount(transaction, $event.target.value)" 
                    @keyup.enter="updateTransactionAmount(transaction, $event.target.value)" 
                    type="text" 
                    :readonly="isTransactionBeingEdited(transaction.id, 'amount')" />
                </td>

                <td class="delete-td">
                  <!-- Delete button -->
                  <button class="delete-button" @click="startDeleteTransaction(transaction)"> x </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
        <div v-if="isLoading" ref="loadMoreRef" class="Loading-more-transactions"> ⏳Loading transactions... </div>
        <!-- If there are no transactions -->
        <div v-else-if="!transactions.length" class="Loading-more-transactions"> ➕ Import transactions to get started. </div>
        <div v-else ref="loadMoreRef" class="Loading-more-transactions">
          {{ hasMoreTransactions ? '⏳Loading more transactions...' : 'You\'ve reached the end of your transactions 💰' }}
        </div>
      </div>
    </div>
  </div>
  <transition name="slide"></transition>
</template>

<script>
/* eslint-disable */
import { collection, addDoc, doc, getDoc, updateDoc, onSnapshot, query, orderBy, deleteDoc, getDocs, startAfter, limit, arrayUnion, arrayRemove } from 'firebase/firestore'
import { auth, db } from '../firebaseInit'; // Import the initialized Firebase auth and Firestore instances
import InlineLabelManager from '../components/InlineLabelManager.vue';
import { unparse as convertToCsv } from 'papaparse';
import RecurringRulesModal from '../components/RecurringRulesModal.vue';

export default {
  name: 'SpendingView',
  components: {
    InlineLabelManager,
    RecurringRulesModal,
  },
  data() {
    return {
      transactions: [],
      loadMoreRef: 'loadMore',
      observer: null,
      lastDocSnapshot: null,
      loadStep: 60,
      transactionsLoadedOnce: false, 
      hasMoreTransactions: true,
      showTransactionForm: false,
      // Calculations
      spendingTotals: {},
      // Transaction Editing
      editedTransaction: null,
      editingTransactionIndex: null,
      categories: [],
      newTransaction: { date: this.getCurrentDate(), name: '', amount: 0, category: null, labels: [] },  
      labels: [], 
      sortBy: 'date', 
      dateError: '',
      // Filtering
      filters: {
        fromDate: null,
        toDate: null,
        category: "Uncategorized",
        transactionName: '',
        minAmount: null,
        maxAmount: null,
        manuallyCreated: false
      },
      searchQuery: '',
      pieChartData: {},
      showFilters: false,
      filterLabels: '', 
      previousCategory: null,
      selectedTransactions: [], // Holds the IDs of selected transactions
      isLabelManagerVisible: false,
      showDropdown: false,
      userId: null,
      // Recurring rules
      isModalOpen: false,
      allRecurringRules: [],
      selectedRecurringTransaction: null,
      isLoading: false,
      isFetching: false,
      notifications: [], 
      // Multi-edit
      isMultiEditMode: false,
      newName: '', 
      newDate: null, 
      newAmount: null, 
      newLabels: [], 
      selectAll: false, 
      transactionsEdited: 0,
      totalTransactionsToBeEdited: 0,
      bulkEditing: false,
      bulkDeleting: false,
      // Csv import
      showCsvImport: false,
      showCSVModal: false,
      importing: false,
      totalTransactions: 0,
      transactionsImported: 0,
      columnMappings: { 
        name: '',
        amount: '',
        date: '',
        category: '',
        labels: ''
      },

      // Transaction rules
      categoryMap: {},
      showCreateRuleModal: false,
      lastEditedTransactionHistory: null,
      lastEditedTransaction: null,
      lastEditedTransactionNewValues: null,
      conditionName: '',
      conditionAmount: '',
      conditionCategory: '',
      conditionNameMatch: 'Exact Match', // Exact or Contains match
      conditionAmountMatch: 'Equals', // Use to check if amount is equals, less than, more than, etc
      conditionAmountMin: null,
      conditionAmountMax: null,
      newName: '',
      newAmount: '',
      newCategory: '',
      isNameExpanded: false,
      isAmountExpanded: false,
      isCategoryExpanded: false,
      isNewNameExpanded: false,
      isNewAmountExpanded: false,
      isNewCategoryExpanded: false,
      isTransactionsListExpanded: false,
      applyingRules: false,
      
    };
  },
  mounted() {
    try {
    auth.onAuthStateChanged((user) => {
      if (user) {
        this.userId = user.uid;

        this.fetchCategoryMap();
        const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
        let q = query(transactionsCollection, orderBy(this.sortBy, 'desc'), limit(this.loadStep));

        onSnapshot(q, (snapshot) => {
          const changes = snapshot.docChanges();
          
          changes.forEach((change) => {
            if (change.type === 'added') {
              this.transactions.push({ id: change.doc.id, ...change.doc.data() });
            } else if (change.type === 'modified') {
              const index = this.transactions.findIndex((transaction) => transaction.id === change.doc.id);
              this.transactions[index] = { id: change.doc.id, ...change.doc.data() };
            } else if (change.type === 'removed') {
              this.transactions = this.transactions.filter((transaction) => transaction.id !== change.doc.id);
            }
          });
          // Set transactionsLoadedOnce to true after the initial transactions are loaded
          this.transactionsLoadedOnce = true;
          // Update lastDocSnapshot
          this.lastDocSnapshot = snapshot.docs[snapshot.docs.length - 1];
          // Set isLoading to false after transactions are loaded
          this.isLoading = false;
        });
        // Retrieve labels from the Firestore collection
        const labelsCollection = collection(db, `users/${user.uid}/labels`);
        onSnapshot(labelsCollection, (snapshot) => {
          this.labels = snapshot.docs.map((doc) => doc.data().name);
        });

        // Retrieve categories from the Firestore collection
        // const categoriesCollection = collection(db, `users/${user.uid}/categories`);
        // onSnapshot(categoriesCollection, (snapshot) => {
        //   this.categories = snapshot.docs.map((doc) => ({ name: doc.data().name, emoji: doc.data().emoji }));
        // });


        this.$nextTick(async () => {
          await new Promise(r => setTimeout(r, 2000)); // Wait for 2 seconds
          const self = this;  // Store `this` context in a variable
          this.observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
              if (entry.isIntersecting && self.transactionsLoadedOnce && !self.isFetching) {  // Use `self` instead of `this`
                self.isFetching = true; // Set isFetching to true before fetching more transactions
                self.fetchMoreTransactions(user).then(() => {  // Fetch more transactions
                  self.isFetching = false; // Set isFetching to false after transactions are loaded
                });
              }
            });
          });
          if (this.$refs.loadMoreRef) {  // Only observe if element is available
            this.observer.observe(this.$refs.loadMoreRef);
          }
        });
        } else {
          console.log('No user is logged in.');
        }
      });
      } catch (error) {
      console.error('Error retrieving documents:', error);
    }
  },
  created() {

  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  computed: {
    categories() {
      return this.$store.state.categories;
    },
    hasUncategorizedTransactions() {
      return this.transactions.some(transaction => transaction.category === "Uncategorized");
    },
    transactions() {
      return this.$store.state.transactions;
    },
    sortedVuexTransactions() {
      const vuexTransactions = this.$store.state.transactions;
      return vuexTransactions
        .filter(transaction => transaction.amount > 0 && (!transaction.labels || !transaction.labels.includes('ignore'))) 
        // Filter out negative transactions and those with the 'ignore' label
        .sort((a, b) => {
          if (a[this.sortBy] === b[this.sortBy]) {
            return new Date(a.date) - new Date(b.date);  // Sort by date when `sortBy` field is the same
          }
          return a[this.sortBy] < b[this.sortBy] ? -1 : 1;
        });
    },

    filteredRuleTransactions() {
      // Check if at least one condition is set and its corresponding section is expanded
      const isAnyConditionSet = (this.isNameExpanded && this.conditionName) ||
                                (this.isAmountExpanded && (this.conditionAmount != null || (this.conditionAmountMatch === 'Within' && this.conditionAmountMin != null && this.conditionAmountMax != null))) ||
                                (this.isCategoryExpanded && this.conditionCategory);

      if (!isAnyConditionSet) {
        return []; // Return an empty array if no conditions are set
      }

      return this.$store.state.transactions.filter(transaction => {
        let matchesName = true;
        let matchesAmount = true;
        let matchesCategory = true;

        if (this.isNameExpanded && this.conditionName) {
          if (this.conditionNameMatch === 'Exact Match') {
            matchesName = transaction.name === this.conditionName;
          } else if (this.conditionNameMatch === 'Contains') {
            const transactionNameLower = transaction.name.toLowerCase();
            const conditionNameLower = this.conditionName.toLowerCase();
            matchesName = transactionNameLower.includes(conditionNameLower);
          }
        }

        if (this.isAmountExpanded && this.conditionAmount != null) {
          switch (this.conditionAmountMatch) {
            case 'Equals':
              matchesAmount = transaction.amount === this.conditionAmount;
              break;
            case 'More than':
              matchesAmount = transaction.amount > this.conditionAmount;
              break;
            case 'Less than':
              matchesAmount = transaction.amount < this.conditionAmount;
              break;
            case 'Within':
              matchesAmount = transaction.amount >= this.conditionAmountMin && transaction.amount <= this.conditionAmountMax;
              break;
            default:
              matchesAmount = true;
          }
        }

        if (this.isCategoryExpanded && this.conditionCategory) {
          matchesCategory = transaction.category === this.conditionCategory;
        }

        return matchesName && matchesAmount && matchesCategory;
      });
    },
    sortedTransactions() {
      const searchQueryLower = this.searchQuery.toLowerCase();
      return this.transactions
        .filter(transaction => {
          if (this.filters.fromDate && transaction.date < this.filters.fromDate) {
            return false;
          }
          if (this.filters.toDate && transaction.date > this.filters.toDate) {
            return false;
          }
          if (this.filters.category && transaction.category !== this.filters.category) {
            return false;
          }
          if (this.filters.transactionName && !transaction.name.toLowerCase().includes(this.filters.transactionName.toLowerCase())) {
            return false;
          }
          if (this.filters.minAmount && transaction.amount < this.filters.minAmount) {
            return false;
          }
          if (this.filters.maxAmount && transaction.amount > this.filters.maxAmount) {
            return false;
          }

          if (this.filters.manuallyCreated && !transaction.manuallyCreated) {
              return false; // Skip transactions that are not manually created
          }
          // Filter by label(s)
          if (this.filterLabels.length > 0) {
            if (!transaction.labels.some(label => this.filterLabels.includes(label))) {
                return false;
            }
          }
          // Unified Search filter condition
          if (this.searchQuery) {
            const matchesSearch = transaction.name.toLowerCase().includes(searchQueryLower) ||
                                  transaction.category.toLowerCase().includes(searchQueryLower) ||
                                  transaction.labels.some(label => label.toLowerCase().includes(searchQueryLower)) ||
                                  transaction.amount.toString().includes(this.searchQuery);
            if (!matchesSearch) {
              return false;
            }
          }
          return true;
        })
        .sort((a, b) => {
          if (a[this.sortBy] === b[this.sortBy]) {
            return new Date(a.date) - new Date(b.date);  // Sort by date when `sortBy` field is the same
          }
          return a[this.sortBy] < b[this.sortBy] ? -1 : 1;
        });
    },
    hasTransactions() {
      return this.sortedTransactions && this.sortedTransactions.length > 0;
    },
    groupedTransactionsDescending() {
      // Group transactions by date
      const groups = this.sortedTransactions.reduce((groups, transaction) => {
        const date = transaction.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(transaction);
        return groups;
      }, {});

      // Get the dates (keys of the groups object) and sort them in descending order
      const sortedDates = Object.keys(groups).sort().reverse();

      // Create a new object and add groups to it in descending order of dates
      const sortedGroups = sortedDates.reduce((sorted, date) => {
        sorted[date] = groups[date];
        return sorted;
      }, {});

      return sortedGroups;
    },  
    groupedTransactions() {
      return this.sortedTransactions.reduce((groups, transaction) => {
        const date = transaction.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(transaction);
        return groups;
      }, {});
    },
    progressBarWidth() {
      return `${(this.transactionsImported / this.totalTransactions) * 100}%`;
    },
    progressBarPercentage() {
      return Math.round((this.transactionsImported / this.totalTransactions) * 100);
    },
    transactionRulesProgressBarWidth() {
      if (this.totalTransactionsToBeUpdated === 0) return '0%';
      const width = (this.transactionsUpdated / this.totalTransactionsToBeUpdated) * 100;
      return `${width}%`;
    },
    transactionRulesProgressBarPercentage() {
      if (this.totalTransactionsToBeUpdated === 0) return '0';
      return Math.round((this.transactionsUpdated / this.totalTransactionsToBeUpdated) * 100);
    },

    bulkEditingProgressBarWidth() {
      const progress = this.transactionsEdited / this.totalTransactionsToBeEdited * 100;
      return `${progress}%`;
    },
    editingTransactionsProgressBarPercentage() {
      return Math.round(this.transactionsEdited / this.totalTransactionsToBeEdited * 100);
    },
    formattedTotalSpendCurrentMonth() {
      const total = parseFloat(this.totalSpendCurrentMonth);
      if (isNaN(total)) {
        return '$0.00';
      }
      return this.formatCurrency(total.toFixed(2));
    },
    spendDifferenceMessage() {
      if (isNaN(this.spendDifference)) {
        return "You haven't spent any money so far this month.";
      } else {
        const formattedAmount = this.formatCurrency(parseFloat(Math.abs(this.spendDifference)).toFixed(2));
        const redOrGreen = this.spendDifference >= 0 ? 'red' : 'green';
        const moreOrLess = this.spendDifference >= 0 ? 'more' : 'less';
        return `You've spent <span style="color: ${redOrGreen};">${formattedAmount}</span> ${moreOrLess}<br> than this time last month`;
      }
    },
    currentMonthName() {
      const monthNames = ["January", "February", "March", "April", "May", "June",
                          "July", "August", "September", "October", "November", "December"];
      const currentMonthIndex = new Date().getMonth();
      return monthNames[currentMonthIndex];
    },
  },
  directives: { 
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          // Check if the click was outside the element
          if (!(el === event.target || el.contains(event.target))) {
            // If so, call the provided method
            binding.value();
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    },
  },
  watch: {
    filters: {
      deep: true,
    },
    '$store.state.allTransactionsFetched'(newValue) {
      if (newValue) {
        this.selectedDateOption = 'all';
      }
    },

    showCreateRuleModal(newVal) {
      if (newVal && this.editedTransaction) {
        this.setRuleConditionsFromEditedTransaction();
      }
    },
    editedTransaction: {
      handler(newVal) {
        if (this.showCreateRuleModal) {
          this.setRuleConditionsFromEditedTransaction();
        }
      },
      deep: true
    },
    selectAll(newValue) {
      if (newValue) {
        // When selectAll becomes true, select all transactions
        this.selectedTransactions = [...this.sortedTransactions];
      } else {
        // When selectAll becomes false, deselect all transactions
        this.selectedTransactions = [];
      }
    },
    selectedDateOption(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.calculateAndStoreMetrics();
      }
    },

    '$store.state.transactions': {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        // Check if selectedDateOption is set before recalculating metrics
        if (this.selectedDateOption) {
          this.calculateAndStoreMetrics();
        }
      }
    },
    sortedVuexTransactions: {
      handler(newVal) {
        console.log('Sorted Vuex Transactions:', newVal);
      },
      deep: true
    },
  },
  methods: {

    goToOnboarding3() {
      this.$router.push({ name: 'Onboarding3' });
    },
    
// TRANSACTION RULES MODAL
    openCreateRuleModal() {
      const history = this.lastEditedTransactionHistory?.changes || {};
      const transaction = this.lastEditedTransaction || {};
      const newValues = this.lastEditedTransactionNewValues || {};

      // Set old values
      this.conditionName = history.originalName || transaction.name || '';
      this.conditionAmount = history.originalAmount || transaction.amount || '';
      this.conditionCategory = history.originalCategory || transaction.category || '';

      // Set new values
      this.newName = newValues.name || '';
      this.newAmount = newValues.amount || '';
      this.newCategory = newValues.category || '';

      this.showCreateRuleModal = true;
    },
    setRuleConditionsFromEditedTransaction() {
      console.log("Setting rule conditions from:", this.editedTransaction);
      if (this.editedTransaction && this.editedTransaction.oldValues) {
        this.conditionName = this.editedTransaction.oldValues['name'] || '';
        this.conditionAmount = this.editedTransaction.oldValues['amount'] || '';
        this.conditionCategory = this.editedTransaction.oldValues['category'] || '';
        console.log("Conditions set to:", this.conditionName, this.conditionAmount, this.conditionCategory);
      }
    },
    closeCreateRuleModal() {
      // Handle logic to close the overlay modal
      this.showCreateRuleModal = false;

      // Resetting toggles
      this.isNameExpanded = false;
      this.isAmountExpanded = false;
      this.isCategoryExpanded = false;
      this.isNewNameExpanded = false;
      this.isNewAmountExpanded = false;
      this.isNewCategoryExpanded = false;

      // Resetting condition match types to their default values
      this.conditionNameMatch = "Exact Match"; // Set to default value
      this.conditionAmountMatch = "Equals"; // Set to default value

      // Resetting other form fields logic (if any) goes here
      this.conditionAmountMin = null;
      this.conditionAmountMax = null;
      this.conditionName = '';
      this.conditionAmount = '';
      this.conditionCategory = '';
      this.newName = '';
      this.newAmount = '';
      this.newCategory = '';

      // Resetting last edited transaction related data
      this.lastEditedTransactionHistory = null;
      this.lastEditedTransaction = null;
      this.lastEditedTransactionNewValues = null;  // Reset new values
    },
    async createRule() {
      console.log('Creating rule...');
      // Initialize an empty conditions object
      let conditions = {};
      
      // Add name condition based on toggle state and match type
      if (this.isNameExpanded && this.conditionName) {
        conditions.name = {
          value: this.conditionName,
          matchType: this.conditionNameMatch // 'Exact Match' or 'Contains'
        };
      }
      // Handle the amount condition based on the selected match type
      if (this.isAmountExpanded && this.conditionAmount) {
        if (this.conditionAmountMatch === 'Within' && this.conditionAmountMin != null && this.conditionAmountMax != null) {
          conditions.amount = {
            matchType: 'Within',
            min: this.conditionAmountMin,
            max: this.conditionAmountMax
          };
        } else {
          conditions.amount = {
            matchType: this.conditionAmountMatch, //', 'More than', 'Less than'
            value: this.conditionAmount
          };
        }
      }
      if (this.isCategoryExpanded && this.conditionCategory) {
        conditions.category = this.conditionCategory;
      }

      // Initialize an empty newValues object
      let newValues = {};

      // Add new values based on toggle state
      if (this.isNewNameExpanded && this.newName) {
        newValues.name = this.newName;
      }
      if (this.isNewAmountExpanded && this.newAmount) {
        newValues.amount = this.newAmount;
      }
      if (this.isNewCategoryExpanded && this.newCategory) {
        newValues.category = this.newCategory;
      }

      // Create the rule only if there are conditions to apply
      if (Object.keys(conditions).length > 0) {
        const rule = { conditions, newValues };
        const rulesCollection = collection(db, `users/${auth.currentUser.uid}/transactionRules`);
        
        // Add the rule to Firestore once
        const docRef = await addDoc(rulesCollection, rule);
        const addedRule = { id: docRef.id, ...rule };


        // Start applying rules
        this.applyingRules = true;
        this.transactionsUpdated = 0;
        this.totalTransactionsToBeUpdated = this.filteredRuleTransactions.length;

        for (const transaction of this.filteredRuleTransactions) {
          await this.applyRulesToTransaction(transaction, addedRule); // Use addedRule
          this.transactionsUpdated++;
        }

        // End applying rules
        this.applyingRules = false;

      // Dispatch the added rule to Vuex store
      this.$store.dispatch('addRule', addedRule);

      // Close modal and reset form fields
      this.closeCreateRuleModal();
      } else {
      // Handle the case where no conditions are set
      }
    },
    async applyRulesToTransaction(transaction, rule) {
    // This runs when a new rule is created and it updates all matched transactions

      // Apply the new values from the provided rule to the transaction
      const updatedTransactionData = { ...transaction, ...rule.newValues };

      // Update the transaction in the Firestore database
      const transactionDocRef = doc(db, `users/${auth.currentUser.uid}/transactions`, transaction.id);
      await updateDoc(transactionDocRef, updatedTransactionData);

      return updatedTransactionData; // Return the updated transaction
    },
    async applyTransactionRules(transaction, rules) {
      // Log the original transaction details
      console.log("Original transaction:", transaction);

      // Iterate through each rule and apply if the transaction matches
      for (const rule of rules) {
        if (this.doesTransactionMatchRule(transaction, rule.conditions)) {
          // If transaction matches, prepare updated transaction data
          const updatedTransactionData = { ...transaction, ...rule.newValues };

          // Log the updated transaction details
          console.log("Updated transaction:", updatedTransactionData);

          return updatedTransactionData;
        }
      }

      // Return original transaction if no rule matches
      return transaction;
    },
    doesTransactionMatchRule(transaction, conditions) {
      let nameMatches = true;
      let amountMatches = true;
      let categoryMatches = true;

      if (conditions.name) {
        const transactionNameLower = transaction.name.toLowerCase();
        const conditionNameLower = conditions.name.value.toLowerCase();

        if (conditions.name.matchType === 'Exact Match') {
          nameMatches = transactionNameLower === conditionNameLower;
        } else if (conditions.name.matchType === 'Contains') {
          nameMatches = transactionNameLower.includes(conditionNameLower);
        }
      }

      if (conditions.amount) {
        switch (conditions.amount.matchType) {
          case 'Equals':
            amountMatches = transaction.amount === conditions.amount.value;
            break;
          case 'More than':
            amountMatches = transaction.amount > conditions.amount.value;
            break;
          case 'Less than':
            amountMatches = transaction.amount < conditions.amount.value;
            break;
          case 'Within':
            amountMatches = transaction.amount >= conditions.amount.min && transaction.amount <= conditions.amount.max;
            break;
          default:
            amountMatches = true;
        }
      }

      if (conditions.category) {
        categoryMatches = transaction.category === conditions.category;
      }

      return nameMatches && amountMatches && categoryMatches;
    },



// FORMATTING & DATES
    formatCurrency(value) {
      if (typeof value !== 'number') {
        value = Number(value);
      }
      return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
    },
    formatDisplayAmount(value) {
      if (value < 0) {
        // Convert to positive and prepend with '+'
        return '+' + this.formatCurrency(Math.abs(value));
      }
      return this.formatCurrency(value);
    },
    formatTransactionDate(dateString) {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const date = new Date(dateString + 'T00:00');
      return date.toLocaleDateString('en-US', options);
    },
    formatTransactionDateShort(dateString) {
      const currentYear = new Date().getFullYear();
      const transactionDate = new Date(dateString + 'T00:00');
      const transactionYear = transactionDate.getFullYear();

      const options = transactionYear === currentYear
        ? { month: 'short', day: 'numeric' } // Format without year for current year
        : { year: 'numeric', month: 'short', day: 'numeric' }; // Include year for previous years

      return transactionDate.toLocaleDateString('en-US', options);
    },

    getCurrentDate() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, '0');
      let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0
      let yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },



// LOAD TRANSACTIONS
    async fetchMoreTransactions(user) {
      console.log("attempting to fetch more transactions...")
        const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
        
        if (this.lastDocSnapshot) {
            const q = query(
                transactionsCollection, 
                orderBy(this.sortBy, 'desc'), 
                startAfter(this.lastDocSnapshot), 
                limit(this.loadStep)
            );

            try {
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    this.transactions = [...this.transactions, ...querySnapshot.docs.map(doc => doc.data())];
                    this.lastDocSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];
                } else {
                    // If the query returned no documents, we've reached the end of the transactions
                    this.hasMoreTransactions = false;
                }
            } catch (error) {
                console.error("Error fetching more transactions:", error);
            }
        } else {
            console.warn("lastDocSnapshot is not defined. Unable to fetch more transactions.");
            // Handle the situation when lastDocSnapshot is undefined or null
        }
    },



// EDIT TRANSACTION
    updateTransactionAmount(transaction, value) {
      let parsedValue = parseFloat(value.replace(/[$,]/g, ''));

      // Check if the original amount was negative (credit) and if the parsed value is positive
      if (transaction.amount < 0 && parsedValue > 0) {
        // Convert the parsed value to negative to maintain the credit nature
        parsedValue = -parsedValue;
      }

      if (!isNaN(parsedValue)) {
        transaction.amount = parsedValue; // Store as a number, not a string
        this.commitTransaction(transaction); // Commit the transaction after updating the amount
      }
    },

    async updateSelectedTransactions() {
      if (!this.selectedTransactions.length) {
          console.error('No transactions to edit.');
          return;
      }

      try {
          this.bulkEditing = true;
          this.transactionsEdited = 0;
          this.totalTransactionsToBeEdited = this.selectedTransactions.length;

          for (const transaction of this.selectedTransactions) {
              // Only change values when the user has entered a new value
              if (this.newName !== "") {
                  transaction.name = this.newName;
              }

              // check if the new date is not an empty string and it is a valid date before assigning it to the transaction's date
              if (this.newDate !== "" && !isNaN(Date.parse(this.newDate))) {
                  transaction.date = this.newDate;
              }

              if (this.newAmount !== null) {
                  transaction.amount = this.newAmount;
              }

              if (this.newCategory !== "" && this.newCategory !== "Category") {
                  transaction.category = this.newCategory;
              }

              if (this.newLabels && this.newLabels.length > 0) {
                transaction.labels = this.newLabels;
              }

              // Update the transaction in Firestore
              const transactionId = transaction.id;
              const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);
              await updateDoc(doc(transactionsCollection, transactionId), transaction);

              // Commit the updated transaction to Vuex
              this.$store.commit('updateTransaction', transaction);

              this.transactionsEdited++;
          }

          // Reset after operation is complete
          this.bulkEditing = false;
          this.transactionsEdited = 0;
          this.totalTransactionsToBeEdited = 0;

          // Clear the form and selected transactions
          this.newName = '';
          this.newDate = '';
          this.newAmount = null;
          this.newCategory = '';
          this.selectedTransactions = [];
          this.newLabels = [];
          this.isMultiEditMode = false;
      } catch (error) {
          console.error('Error updating transactions:', error);
      }
    },
    cancelEditing() {
      this.editingTransactionIndex = null;
      this.editingTransactionField = null;
      this.editedTransaction = null;

      // Deselect all transactions
      this.selectedTransactions = [];

      // Toggle multi-edit mode off
      this.isMultiEditMode = false;

      // Uncheck the 'Select All' checkbox
      this.selectAll = false;
    },
    async commitTransaction(transaction) {
      await this.saveTransaction(transaction);
      this.cancelEditing();
    },
    startEditing(transactionId, field) {
      this.editingTransactionField = field;
      this.editingTransactionIndex = this.transactions.findIndex(t => t.id === transactionId);
      const transaction = this.transactions.find(t => t.id === transactionId);

      if (!transaction) {
        console.error('Transaction not found for editing:', transactionId);
        return;
      }

      // Capture the old values
      this.editedTransaction = {
        ...transaction, // Clone the current transaction
        oldValues: {
          id: transaction.id, // Add the transaction ID
          amount: transaction.amount,
          category: transaction.category,
          date: transaction.date,
          name: transaction.name,
        }
      };
      console.log("Edited transaction set:", this.editedTransaction);
    },
    isTransactionBeingEdited(index, field) {
      return this.editingTransactionIndex === index && this.editingTransactionField === field;
    },
    async saveTransaction(transaction) {
      try {

        console.log('Saving transaction:', transaction);

        if (this.editedTransaction && transaction.id === this.editedTransaction.id) {
        const transactionId = transaction.id;
        const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);

        // Create history entry
        const historyEntry = this.createHistoryObject(this.editedTransaction, transaction);
        this.lastEditedTransactionHistory = historyEntry;
        this.lastEditedTransaction = transaction;



        // Check if changes object is not empty
        if (Object.keys(historyEntry.changes).length > 0) {
          // Update the transaction with the new values and append history
          await updateDoc(doc(transactionsCollection, transactionId), {
            ...transaction,
            history: arrayUnion(historyEntry)
          });
        } else {
          // Update the transaction without appending history
          await updateDoc(doc(transactionsCollection, transactionId), transaction);
        }

        let message = '';

        const oldId = this.editedTransaction.oldValues['id'];
        const newId = transaction.id;
        // Check which field was edited and create an appropriate message
        switch (this.editingTransactionField) {
          case 'category':
            const oldCategory = this.previousCategory;
            const newCategory = transaction.category;
            if (oldCategory !== newCategory) {
              const detailMessage = `Updated category from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldCategory}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newCategory}</p>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }
            break;
          case 'amount':
            const oldAmount = parseFloat(this.editedTransaction.oldValues['amount']).toFixed(2);
            const newAmount = parseFloat(transaction.amount).toFixed(2);

            if (oldAmount !== newAmount  && oldId === newId) {
              const detailMessage = `Updated amount from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldAmount}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newAmount}</p>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }

            break;
          case 'name':
            const oldName = this.editedTransaction.oldValues['name'];
            const newName = transaction.name;
            if (oldName !== newName && oldId === newId) {
              const detailMessage = `Updated name from <p style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldName}</p> to <p style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newName}</p>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }
            break;
          case 'labels':
            const oldLabels = this.editedTransaction.oldValues['labels'] || [];
            const newLabels = transaction.labels || [];
            const oldLabelsFormatted = oldLabels.join(', ');
            const newLabelsFormatted = newLabels.join(', ');
            if (oldLabelsFormatted !== newLabelsFormatted) {
              const detailMessage = `Updated labels from <span style="color: grey; font-size: 14px; margin: 0px 5px; font-weight: bold;">${oldLabelsFormatted}</span> to <span style="color: black; font-size: 14px; margin: 0px 5px; font-weight: bold;">${newLabelsFormatted}</span>`;
              this.showUpdateNotification(transaction.name, detailMessage);
            }
            break;
            }
            this.$store.commit('updateTransaction', transaction);
            // After saving the transaction
            this.lastEditedTransactionNewValues = {
              name: transaction.name,
              amount: transaction.amount,
              category: transaction.category,
            };
            
            // Show the notification if there's a message
            if (message) {
              this.showUpdateNotification(message);
            }
          } else {
        }

        // Reset the editing state
        this.editedTransaction = null;
        this.editingTransactionField = null;
        this.editingTransactionIndex = null;
        this.previousCategory = null;
      } catch (error) {
        console.error('Error updating transaction:', error);
      }
    },
    // Helper function to create a history object
    createHistoryObject(editedTransaction, transaction) {
      const historyEntry = {
        dateModified: new Date().toISOString(), // Current date and time
        changes: {}
      };

      // Check each field for changes and add to the history entry
      for (const key in editedTransaction.oldValues) {
        if (editedTransaction.oldValues[key] !== transaction[key]) {
          historyEntry.changes[`original${key.charAt(0).toUpperCase() + key.slice(1)}`] = editedTransaction.oldValues[key];
        }
      }

      return historyEntry;
    },



// DELETE TRANSACTION
    confirmDeleteTransaction(transactionId) {
      if (confirm('Are you sure you want to delete this transaction?')) {
        this.deleteTransaction(transactionId);
      }
    },
    async deleteTransaction(transactionId) {
      try {
        const transactionsCollection = collection(db, `users/${auth.currentUser.uid}/transactions`);
        const transactionDoc = doc(transactionsCollection, transactionId);
        const transactionSnap = await getDoc(transactionDoc);
        const transactionData = transactionSnap.data();

        await deleteDoc(transactionDoc);
        this.$store.commit('deleteTransaction', transactionId);

        // If the transaction is part of a recurring rule
        if (transactionData.recurringId) {
          // Fetch the recurring rule
          const ruleDoc = doc(db, `users/${auth.currentUser.uid}/recurringRules`, transactionData.recurringId);
          const ruleSnap = await getDoc(ruleDoc);
          const ruleData = ruleSnap.data();

          // Remove the transaction from the rule's transactions array
          const updatedTransactions = ruleData.transactions.filter(t => t.id !== transactionId);

          // Update the rule in Firestore
          await updateDoc(ruleDoc, {
            transactions: updatedTransactions,
            total: ruleData.total - transactionData.amount,
            minAmount: Math.min(...updatedTransactions.map(t => t.amount)),
            maxAmount: Math.max(...updatedTransactions.map(t => t.amount)),
            numberOfTransactions: updatedTransactions.length
          });
        }

        console.log(`Transaction ${transactionId} and its reference in the recurring rule have been deleted.`);
      } catch (error) {
        console.error('Error deleting transaction:', error);
      }
    },
    async deleteSelectedTransactions() {
      if (window.confirm('Are you sure you want to delete the selected transactions? This cannot be undone.')) {
        this.bulkDeleting = true;
        this.transactionsEdited = 0;
        this.totalTransactionsToBeEdited = this.selectedTransactions.length;

        try {
          const promises = this.selectedTransactions.map(async transaction => {
            // Create a reference to the document in the Firestore collection
            const transactionRef = doc(db, `users/${auth.currentUser.uid}/transactions`, transaction.id);
            // Delete the transaction in the Firestore collection
            await deleteDoc(transactionRef);

            // Commit mutation to Vuex store for deleting the transaction
            this.$store.commit('deleteTransaction', transaction.id);

            // If the transaction is part of a recurring rule
            if (transaction.recurringId) {
              // Fetch the recurring rule
              const ruleDoc = doc(db, `users/${auth.currentUser.uid}/recurringRules`, transaction.recurringId);
              const ruleSnap = await getDoc(ruleDoc);
              if (ruleSnap.exists()) {
                const ruleData = ruleSnap.data();

                // Remove the transaction from the rule's transactions array
                const updatedTransactions = ruleData.transactions.filter(t => t.id !== transaction.id);

                // Update the rule in Firestore
                await updateDoc(ruleDoc, {
                  transactions: updatedTransactions,
                  total: updatedTransactions.reduce((total, t) => total + t.amount, 0),
                  minAmount: updatedTransactions.length ? Math.min(...updatedTransactions.map(t => t.amount)) : 0,
                  maxAmount: updatedTransactions.length ? Math.max(...updatedTransactions.map(t => t.amount)) : 0,
                  numberOfTransactions: updatedTransactions.length
                });
              }
            }

            // Update progress
            this.transactionsEdited++;
          });

          await Promise.all(promises);

          this.cancelEditing();
          this.isMultiEditMode = false;
        } catch (error) {
          console.error('Error deleting transactions:', error);
        } finally {
          // Reset progress and loading state
          this.bulkDeleting = false;
          this.transactionsEdited = 0;
          this.totalTransactionsToBeEdited = 0;
        }
      }
    },




// TRANSACTION UPDATED NOTIFICATION
    showUpdateNotification(transactionName, detailMessage) {
      const notification = {
        transactionName: transactionName,
        detailMessage: detailMessage,
        timeout: null,
      };

      this.notifications.push(notification);

      // Set a timeout to remove this notification after 10 seconds
      notification.timeout = setTimeout(() => {
        const index = this.notifications.indexOf(notification);
        if (index > -1) {
          this.notifications.splice(index, 1);
        }
      }, 10000);
    },


// RECURRING RULES MODAL
    async toggleRecurring(transaction) {
        const rulesRef = collection(db, `users/${this.userId}/recurringRules`);

        // If the transaction has a recurringId
        if (transaction.recurringId && transaction.recurringName !== 'Not Recurring') {

        const rulesSnapshot = await getDocs(rulesRef);
        this.allRecurringRules = rulesSnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() }));

          // Open the modal to confirm deletion
            this.isModalOpen = true;
            this.selectedRecurringTransaction = transaction;
        } else {
            // Fetch all rules and populate allRecurringRules for the modal dropdown
            const rulesSnapshot = await getDocs(rulesRef);
            this.allRecurringRules = rulesSnapshot.docs.map(docSnap => ({ id: docSnap.id, name: docSnap.data().name }));

            // Store the transaction you want to make recurring
            this.selectedRecurringTransaction = transaction;

            // Open the modal
            this.isModalOpen = true;
        }
    },




// LABELS
    startEditingLabels(transaction) {
      this.editingTransactionField = 'labels';
      this.editedTransaction = {
        ...transaction,
        oldValues: {
          // ... other fields if needed
          labels: [...transaction.labels],
        }
      };
    },
    enableLabelDropdown(transaction) {
      transaction.showDropdown = true;
    },
    disableLabelDropdown() {
      this.transactions.forEach(transaction => {
        transaction.showDropdown = false;
      });
    },
    openLabelManager(transaction) {
      this.editingTransaction = transaction;
      this.isLabelManagerVisible = true;
    },
    closeLabelManager() {
      this.isLabelManagerVisible = false;
      this.editingTransaction = null;
    },
    isSelectedLabel(transaction, label) {
      return Array.isArray(transaction.labels) && transaction.labels.includes(label);
    },



// FILTERS
    toggleFilters() {
        if (this.showFilters) {
            this.clearAllFilters();
        }
        this.showFilters = !this.showFilters;
    },
    clearAllFilters() {
        this.filters = {
            category: '',
            transactionName: '',
            fromDate: '',
            toDate: '',
            minAmount: null,
            maxAmount: null,
            manuallyCreated: false
        };
        this.filterLabels = [];
        console.log("Filters cleared:", this.filters, this.filterLabels);
    },



// CATEGORY
    storePreviousCategory(transaction) {
      this.previousCategory = transaction.category;
      this.editingTransactionField = 'category';


      // Capture the old category value
      if (!this.editedTransaction || this.editedTransaction.id !== transaction.id) {
        this.editedTransaction = {
          ...transaction,
          oldValues: {
            // ... other fields if needed
            category: transaction.category,
          }
        };
      }
    },
    checkCategory(transaction) {
      if (!this.categories.some(category => category.name === transaction.category)) {
        this.previousCategory = transaction.category;
      }
    },
    restoreCategory(transaction) {
      if (transaction.category === '') {
        transaction.category = this.previousCategory;
      }
    },



// CSV
    openFileInput() {
      if (this.showCsvImport) {
        // If the CSV import section is already shown, trigger the file input click
        this.$refs.fileInput.click();
      } else {
        // Otherwise, show the CSV import section first
        this.showCsvImport = true;
        // Use nextTick to wait for the next DOM update cycle to complete
        this.$nextTick(() => {
          this.$refs.fileInput.click();
        });
      }
    },
    async handleCsvFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const csvData = await this.readFileContent(file);
          this.fullCsvData = csvData; // Store the full CSV data
          const csvHeaders = await this.parseCsvFile(file);
          this.csvHeaders = csvHeaders;
          this.columnMappings = this.initializeColumnMappings(csvHeaders);
          this.showCSVModal = true; // Show the modal for mapping after file is read
        } catch (error) {
          console.error('Error reading file:', error);
        }
      }
    },
    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (event) => {
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    normalizeHeaderName(header) {
      return header.replace(/['"]/g, '').toLowerCase();
    },
    initializeColumnMappings(csvHeaders) {
      const mappings = {
        name: '',
        amount: '',
        date: '',
        category: '',
        labels: ''
      };

      csvHeaders.forEach((header, index) => {
        const normalizedHeader = this.normalizeHeaderName(header);

        // Check and assign the index if the header matches any field name
        if (normalizedHeader === 'name') {
          mappings.name = index;
        } else if (normalizedHeader === 'amount') {
          mappings.amount = index;
        } else if (normalizedHeader === 'date') {
          mappings.date = index;
        } else if (normalizedHeader === 'category') {
          mappings.category = index;
        } else if (normalizedHeader === 'labels') {
          mappings.labels = index;
        }
      });

      return mappings;
    },
    parseCsvFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = event.target.result;
          const rows = csvData.split('\n');
          const header = rows[0];
          const columns = header.split(',').map(col => col.trim().replace(/['"]/g, '')); // Remove quotes
          resolve(columns); // Resolve with the header columns
        };
        reader.onerror = (event) => {
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    async processCsvData(csvData, columnMappings) {
      if (typeof csvData !== 'string') {
        console.error('Invalid CSV data:', csvData);
        return { processedTransactions: [], skippedTransactions: [] };
      }
      const rows = csvData.split('\n').slice(1);

      // Filter out empty rows
      const filteredRows = rows.filter(row => row.trim() !== '');

      const csvTransactions = []; // To store valid transactions
      const skippedTransactions = []; // To store details of skipped transactions

      // Process each row
      filteredRows.forEach(row => {
        const rowValues = row.split(',');

        // Parse amount and check if it's valid
        const amountValue = this.removeQuotes(rowValues[columnMappings.amount])?.trim();
        const amount = Number(amountValue);
        if (isNaN(amount) || amountValue === '') {
          // Add details of the skipped transaction to the skippedTransactions array
          skippedTransactions.push({
            reason: 'Invalid amount',
            rowData: row,
          });
          return;
        }

        // Create and add valid transaction to csvTransactions array
        const transaction = {
          date: this.formatDate(this.removeQuotes(rowValues[columnMappings.date])),
          name: this.removeQuotes(rowValues[columnMappings.name])?.trim() || 'No Name',
          amount: amount,
          category: this.findCsvCategoryMatch(this.removeQuotes(rowValues[columnMappings.category])?.trim()),
          labels: columnMappings.labels ? this.removeQuotes(rowValues[columnMappings.labels])?.trim().split(';') : []
        };
        csvTransactions.push(transaction);
      });

      // Return both processed and skipped transactions
      return { processedTransactions: csvTransactions, skippedTransactions: skippedTransactions };
    },

    async processCsv() {
      this.showCsvImport = false;
      console.log("CSV Data:", this.fullCsvData); // Log to check the value

      // Process the CSV data and capture both processed and skipped transactions
      const { processedTransactions, skippedTransactions } = await this.processCsvData(this.fullCsvData, this.columnMappings);

      await this.handleCsvTransactions(processedTransactions);

      // Display skipped transactions
      this.displaySkippedTransactions(skippedTransactions);

      this.showCSVModal = false;
    },
    displaySkippedTransactions(skippedTransactions) {
      if (skippedTransactions.length > 0) {
        // Example: Display skipped transactions in the console
        // You can replace this with your preferred method of displaying
        console.log('Skipped Transactions:', skippedTransactions);

        // Alternatively, you can set this data to a component data property
        // and display it in your template (e.g., in a modal or a table)
        this.skippedTransactionsData = skippedTransactions;
      } else {
        console.log('No transactions were skipped.');
      }
    },


    removeQuotes(string) {
      if (string == null) {
        return '';
      }
      return string.replace(/['"]/g, '');
    },
    formatDate(dateString) {
      if (!dateString) {
        console.error('Invalid or missing date:', dateString);
        return ''; // Return a default value if the date is invalid
      }

      // Check if dateString is in the format 'YYYY-MM-DD'
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (isoDateRegex.test(dateString)) {
        // Return the date as is since it's in the correct ISO format
        return dateString;
      }

      // Check and handle other expected formats, e.g., 'MM/DD/YYYY'
      const parts = dateString.split('/');
      if (parts.length === 3) {
        const [month, day, year] = parts;
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
      }

      // Log error and return a default value for unexpected formats
      console.error('Unexpected date format:', dateString);
      return ''; // Return a default value
    },
    async handleCsvTransactions(csvTransactions) {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user is logged in.');
        return;
      }

      this.importing = true;
      this.totalTransactions = csvTransactions.length; // Assuming csvTransactions is the array of all transactions
      this.transactionsImported = 0;


      const transactionsCollection = collection(db, `users/${user.uid}/transactions`);
      const existingIdentifiers = await this.fetchExistingTransactionsIdentifiers(transactionsCollection);

      const transactionsToAdd = csvTransactions.map(transaction => {
        let myCategory = this.findCsvCategoryMatch(transaction.category); // Map CSV category to local category
        // let myEmoji = this.categoryToEmoji[myCategory]; // Find the associated emoji

        return {
          id: '', 
          date: transaction.date,
          name: transaction.name,
          amount: transaction.amount, 
          category: myCategory,
          // categoryEmoji: myEmoji, 
          labels: [], 
        };
      });

      // Write new transactions to Firestore
      for (const transaction of transactionsToAdd) {
        console.log('Adding transaction:', transaction);

        try {
          const updatedTransaction = await this.applyTransactionRules(transaction, this.$store.state.rules);
          const docRef = await addDoc(transactionsCollection, updatedTransaction);
          updatedTransaction.id = docRef.id;  // Set the Firestore document ID
          await updateDoc(docRef, updatedTransaction); // Update with the full transaction details
          // Update the progress
          this.transactionsImported++;
        } catch (error) {
          console.error('Error adding document:', error);
        }
      }
      // Check if import is complete
      if (this.transactionsImported === this.totalTransactions) {
        this.importing = false;
        // Any additional logic for when import is complete
      }
      // Force a page reload or update the state as needed
      // window.location.reload();
    },

    async fetchCategoryMap() {
      const docRef = doc(db, `users/${auth.currentUser.uid}/mappings`, 'categoryMap');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.categoryMap = docSnap.data(); // Assuming this.categoryMap is defined in data()
      } else {
        console.log("No category map document found!");
      }
    },

  }
};
</script>

<style scoped>

.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
.add-transaction-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}

.table-options {
  margin-bottom: 20px;
  display: flex;
  justify-content: right;
  align-items: center;
}
.spending-view {
  /* width: 80vw; */
  margin: 0 auto;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th {
  padding: 8px;
  text-align: left;
  font-family: unset;
}

td {
  padding: 4px;
  text-align: left;
  font-family: system-ui;
}

.date-td {
  color: #003f76
}

td:not(.date-td):not(.checkbox-td):not(.delete-td):not(.emoji-td):not(.labels-td):hover {
  outline: #e3e3e3 solid thin;
  border-radius: 5px;
  cursor: pointer;
  background-color: white;
}
td.labels-td:hover {
  outline: none;
  background-color: unset;
  cursor: pointer;
}
td:not(.delete-td,.emoji-td,):hover {
  outline: #e3e3e3;
  outline-style: solid;
  border-radius: 5px;
  outline-width: thin;
  background-color: white;
  cursor: pointer;
}

th {
  background-color: #f3f3f3;
}

th.text-left, td.text-left {
  text-align: left;
}

tr:not(.date-row,):hover {
  background-color: #fbfafa;
}

tr {
  height: 14px;
}

.date-row {
  background: #fbfafa;
  font-size: 18px;
  font-weight: 700;
  font-family: sans-serif;
  height: 35px;
  border-radius: 5px;
}

thead {
  display: none;
}

input {
  border: none;
  background: none;
}


.input-field {
  position: relative;
}

.input-field input,
.input-field select {
  width: 80%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 14px;
  color: gray;
  font-family: system-ui;
}
.input-field input:focus,
.input-field select:focus {
  outline: none;
  background-color: #e0e0e0;
}

.input-field select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.input-field select::-ms-expand {
  display: none;
}
.input-field select option:hover {
  background-color: #e0e0e0;
}

.button {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #4caf50;
  color: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

button {
  white-space: nowrap;
  font-size: .75rem;
  margin: 5px;
}

.delete-button {
  transition: color 0.3s ease, font-size 0.3s ease, font-weight 0.3s ease;
  padding: 5px 10px;
  margin: 0px auto;
  border: none;
  border-radius: 5px;
  background-color: #ffffff00;
  color: #dfdfdf;
  font-size: 14px;
  cursor: pointer;
  font-weight: bolder;
  display: inline-block;
  opacity: 0;
}

tr:hover .delete-button {
    opacity: 1; 
}

.delete-button:hover {
  background-color: white;
  font-weight: 800;
  color: darkgoldenrod;
}
.delete-multi-button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  border-radius: 3px;
  background-color: rgb(255, 43, 43);
  font-size: 12px;
  cursor: pointer;
  font-weight: bolder;
}
.delete-multi-button:hover {
  background-color: rgb(212, 0, 0);
  color: white;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

input:focus-visible {
  outline: none;
  transition: 0.3s ease-in-out;
  height: 30px;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
}
select:focus-visible:not(.edit-multiple-select):not(.filter-label-select) {
  outline: none;
}

.csvimport {
  display: none;
}

.filter-options {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.edit-multiple-options {
  display: flex;
}

.filter-row {
  display: flex;
  gap: 10px;
  text-align: right;
}

.filter-column {
  flex: 1;
  align-items: center;
}


.filter-class {
  width: -webkit-fill-available;
  padding: 10px;
  margin: 4px;
  height: 56px;
  background-color: #ffffff;
  border-radius: 5px;
  color: gray;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}


.filter-container {
  padding: 1% 3%;
  border-radius: 10px;
}

.filter-label {
  width: 120px;
  text-align: left;
  font-weight:700;
  border: none;
}

#category-filter {
  width: -webkit-fill-available;
    border: none;
    height: 56px;
    /* margin-left: 0; */
    color: grey;
    padding: 10px;
    margin: 4px;
    border-radius: 5px;
    background-color: #ffffff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline-style: solid;
    outline-width: thin;
    outline-color: #c8c8c8;
    font-family: system-ui;
}

.filter-label-select {
  width: -webkit-fill-available;
    border: none;
    overflow: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* margin-left: 0; */
    border-radius: 5px;
    color: grey;
    outline-style: solid;
    background-color: white;
    outline-width: thin;
    outline-color: #c8c8c8;
    margin: 4px;
    padding-bottom: 4px;
    font-family: system-ui;
}



.filter-label-select option {
  background-color: #f4f4f4;
  width: fit-content;
  padding: 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  font-family: system-ui;
}

.filter-label-select option:disabled {
  background-color: #fff;
  font-family: system-ui;
}

.category-dropdown {
  border: none;
  text-align: right;
  padding-right: 0;
  background: none;
  border-radius: 3px;
  overflow-y: auto;
  width: 200px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  visibility: hidden;
}

input[type="date"]:hover::-webkit-calendar-picker-indicator,
input[type="date"]:focus::-webkit-calendar-picker-indicator {
  visibility: visible;
}

.labels {
  color: #96c4ff;
  background: #F4F5F7;
  border-radius: 3px;
  cursor: pointer;
}

  .label-container:hover {
    background-color: white;
  }

  .label-item {
    padding: 4px 6px;
    border-radius: 5px;
    background-color: #f3f3f3;
    margin: 3px;
    font-size: 14px;
    color: grey;
    display: inline-flex;
  }

  .label-item:hover {
    cursor: pointer;
    background-color: #d0d0d0;
  }


.label-dropdown {
  width: 100%;
  height: auto;
  padding: 1px;
  border-radius: 4px;
  overflow: auto;
  border: none;
  background-color: white;
  display: inline-flex;
}

.labels-container {
  max-width: 130px;
  padding: 1px;
  border-radius: 4px;
  overflow: auto;
  white-space: nowrap;       /* Prevent text from wrapping */
  overflow: hidden;          /* Hide overflowing content */
  text-overflow: ellipsis;   /* Display ellipsis (...) for overflow */
  border: none;
  /* margin: 0 auto; */
  }

.label-dropdown option {
  /* Add desired styles for the dropdown options */
  padding: 4px;
  background-color: #f3f3f3;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 4px;
  width: min-content;
  display: inline-flex;
}

.label-dropdown option:checked {
  background-color: #96c4ff;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 3px;
  width: max-content;
  color: #fff;
  display: inline-flex;
}

.edit-multiple-select {
  width: 100%;
  color: grey;
  padding: 10px;
  border-radius: 5px;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  appearance: none;
  overflow: auto;
}

.edit-multiple-select option {
  background: #f4f4f4;
  margin: 4px;
  border-radius: 5px;
  padding: 4px;
  width: min-content;
}

.edit-multiple-select option:checked {
  background-color: #96c4ff;
  margin-bottom: 2px;
  margin-top: 2px;
  border-radius: 3px;
  width: max-content;
  color: #fff;
  display: inline-flex;
}

.edit-multiple-select option:disabled {
  background-color: white;
  margin-top: 0px;
}

.select-label-filter {
  background-color: white;
  margin-top: 0px;
}

.optionbuttons {
  padding-right: 0px;
}

.label-item:hover {
  cursor: pointer;
  background-color: #d0d0d0;
}

.hashtag {
  color: blue !important;
  background-color: red;
}

.categoriesPie {
  /* width: 500px; */
  display: flex;
  margin: 0 auto;
}


.chartsdiv {
  display: flex;
  align-items: baseline;
  border-radius: 10px;
  width: fit-content !important;
}

.chartswide {
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  /* background-color: white; */
  border-radius: 10px;
  margin: 2%;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
}
.table-group {
  align-items: center;
  overflow: scroll;
  padding: 2%;
  margin: 2%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.firstrowcharts {
  display: flex;
  justify-content: space-evenly;
}

.edit-multiple-buttons {
  display: flex;
  margin: 2% auto;
  justify-content: left;
}
.parent-edit-multiple-buttons {
  display: flex;
  margin: 0 auto;
  width: fit-content;
}

.notransactions {
  background-color: white;
  grid-area: 2 / 1 / 3 / 3;
  border-radius: 10px;
  padding: 5%;
  margin: 5%;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
}

.categories-td {
  align-items: center;
  cursor: pointer;
}

.emoji-td {
  margin: 0 auto;
  text-align: center;
  padding-left: 0px;
}
.name-td {
  width: 35%;
}

.name-td input {
  width: -webkit-fill-available;
}

.amount-td {
  margin: 0 auto;
  text-align: center;
  width: 15%;
}

.labels-td {
  width: 20%;
}


.parent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  height: 100vh; /* set the height to fit the viewport height */
  overflow: auto; /* allow scrolling */
}

/* .spending-div1 { 
  grid-area: 1 / 1 / 2 / 2; 
  text-align: left;
  padding-left: 5%;
} */
.spending-div2 { grid-area: 1 / 2 / 2 / 3; }
.spending-div3 { grid-area: 2 / 1 / 3 / 2; 
  max-height: calc(100vh - 100px); /* Adjust this value as needed */
  overflow: auto; /* Enable scrolling if content exceeds max-height */
}
.spending-div4 { grid-area: 2 / 1 / 3 / 3; 
}
/* .spending-div4 { 
  grid-area: 2 / 2 / 3 / 3; 
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  background-color: #ffffff;
  margin: 5% 5% 0% 0%;
  height: 30vh;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
} */
/* .spending-div5 { grid-area: 3 / 1 / 4 / 3; } */
.spending-div6 { grid-area: 3 / 1 / 6 / 3; }

.sticky-wrapper {
  grid-area: 1 / 3 / 6 / 4; 
  position: sticky; 
  top: 0; /* stick to the top */
}

.sticky-wrapper {
  display: flex;
  flex-direction: column;
}

.spending-div7 { 
  grid-area: 1 / 3 / 3 / 4; 
  position: sticky; 
  background-color: white;
  height: 60%;
  top: 0; /* stick to the top */
  display: flex;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 16px;
  margin: 12px 12px 12px 0px;
}
.spending-div8 { 
  grid-area: 3 / 3 / 6 / 4; 
  position: sticky; 
  background-color: #fff;
  top: 0; /* stick to the top */
  height: -webkit-fill-available;
  /* box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%); */
  border-radius: 16px;
  margin: 12px 12px 12px 0px;
  max-height: calc(100vh - 100px); /* Adjust this value as needed */
  overflow: auto; /* Enable scrolling if content exceeds max-height */
}

.export-csv-button, .filter-button, .import-csv-button, .create-rule-button2 {
  /* box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px; */
  padding: 7.5px 12px;
  background: white;
  height: 35px;
  color: black;
  border-radius: 7px;
  transition: 0.3s ease-in-out;
  margin: 0 4px;
  display: inline-block;
  width: 90px;
}

.export-csv-button:hover, .filter-button:hover, .import-csv-button:hover, .create-rule-button2:hover {
  background-color: #666766;
  color: white;
  transition: 0.3s ease-in-out;
}

/* input::file-selector-button {
  margin: 10px 10px;
  background-color: #f8f7f1;
  color: black;
  width: -webkit-fill-available;
  padding: 20px 0px !important;
  border-radius: 13px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: #fff;
  outline-style: solid;
} */

.button-row1 {
  display: flex;
  margin: 12px;
  justify-content: center;
}
.button-row2 {
  display: flex;
  margin: 10px;
}

.summary {
  text-align: left;
  padding-left: 10px;
}

.summary-details{
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: right;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: -10px;
}

.summary-title {
  font-size: 2em;
  margin-left: 20px;
  margin-bottom: 0px;
}

.spending-bar-chart {
  padding: 5%;
}


.add-label-button {
  opacity: 0;
  transition: display 0.3s ease, box-shadow 0.3s ease;
}

.add-label-button:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}


.labels-td:hover .add-label-button {
  opacity: 1;
  display: flex;
  background-color: #f0f0f0;
  color: grey;
  font-size: 12px;
  font-family: unset;
  border-radius: 5px;
}






.select-transactions-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selected-transactions-count {
  text-align: right;
  font-weight: 800;
}

#select-all {
  width: fit-content;
  margin: 10px;
}
.select-all-container {
  text-align: left;
  font-weight: 800;
  width: 150px;
  align-items: center;
}

.categories-select {
  padding: 10px 0px;
  background-color: unset;
  width: min-content;
  border: none;
  float: right;
  text-align: right;
  border-radius: 15px;
  font-size: 14px;
  color: gray;
  margin: 1%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

/* .categories-select:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
} */

.emoji-span {
  margin-right: 10px;
}

.category-name {
  padding: 10px;
}

.Loading-more-transactions {
  text-align: left;
  font-weight: 800;
  border-radius: 10px;
  padding: 2%;
  background: #ffffff;
}


.filter-sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
}

.filter-sidebar {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  transition: transform 0.5s ease;
  transform: translateX(100%);
}


.filter-sidebar2 {
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  box-sizing: border-box;
  transition: transform 0.5s ease;
  transform: translateX(100%);
  display: flex;
}

.filter-sidebar-shown {
  transform: translateX(0);
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.5s;
}

.slide-enter-from, .slide-leave-to {
  transform: translateX(100%);
}

.slide-enter-to, .slide-leave-from {
  transform: translateX(0);
}

/* Backdrop styles */
.modal-backdrop {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Black with 70% opacity */
  display: flex;
  justify-content: center; /* Center modal horizontally */
  align-items: center;     /* Center modal vertically */
  z-index: 999;            /* Make sure it sits on top of everything else */
  transition: 0.3s ease-in-out;
}

/* Modal styles */
.modal-content {
  width: 50%;           /* Example width, you can adjust as needed */
  max-width: 500px;     /* Maximum width */
  background-color: #fff;
  border-radius: 15px;   /* Rounded corners */
  padding: 20px 50px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  position: relative;
  transition: 0.3s ease-in-out;
}

.transaction-rule-modal-content {

  min-width: 60%;
  background-color: #fff;
  border-radius: 15px;   /* Rounded corners */
  padding: 30px 0px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  position: relative;
  transition: 0.3s ease-in-out;
}

.modal-field2 label {
  width: 80px;
  margin: 10px 0px;
  text-align: left;
}

.modal-field2 {
  display: flex;
  text-align: left;
}
.csv-column-map-select {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 14px;
  color: gray;
  font-family: system-ui;
  appearance: none;
}

.csv-column-map-select:hover {
  cursor: pointer;
}

.title-bold {
    font-weight: bold;
}

.filter-column-manual {
  float: left;
  text-align: left;
  text-wrap: nowrap;
  width: 30px;
  color: gray;
  margin-top: 10px;
}

.notification-container {
  position: fixed;
  right: 15px;
  width: max-content;
  background-color: #ffffff;
  text-align: left;
  justify-content: space-between;
  margin-top: 10px;
  border-radius: 10px;
  display: flex;
  vertical-align: middle;
  min-height: 60px;
  z-index: 100;
  box-shadow: 0px 6px 15px 10px rgb(29 29 29 / 5%);
  transition: bottom 0.3s;
}

.notification-progress {
  position: absolute;
  bottom: 0px;
  left: 0;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  height: 5px;
  background-color: #88c6c8; /* or any color of your choice */
  width: 100%;
  animation: decreaseWidth 10s linear;
}

@keyframes decreaseWidth {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}

.notification-title {
  font-weight: bold;
  margin: 5px;
  padding: 10px;
  font-size: 20px;
}

/* .notification-detail {
 color: black;
} */

.notification-detail {
  font-size: 14px;
  display: flex;
  margin: auto 15px;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.progress-bar {
  height: 20px;
  background-color: green;
  border-radius: 4px;
}

.create-rule-button {
  margin: 0;
  width: -moz-min-content;
  font-size: 14px;
  border: 1px solid rgb(216 216 216);
  font-weight: 600;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #f1f1f1;
  white-space: unset;
  color: #555555;
  transition: 0.3s ease-in-out;
}
.create-rule-button:hover {
  background: #ffffff;
  transition: 0.3s ease-in-out;
}

.current-month-spend {
  font-size: 1.5vw;
  margin: 0px;
  text-align: right;
  padding-right: 14px;
  padding-top: 0px;
}

.spend-difference {
  font-size: 1vw;
  margin: 0px;
  text-align: right;
  padding-right: 14px;
  padding-top: 10px;
}

.current-month-spend-amount {
  text-align: right;
  margin: 0px;
  padding-right: 14px;
  font-size: 4vw;
}

.form-group {
  text-align: left;
  display: grid;
  align-items: center;
  padding: 10px 20px;
  background: #f6eef5;
  border-radius: 6px;
  margin: 10px 0px;
  transition: 0.3s ease-in-out;
  border: 1px solid rgb(218, 225, 234);
}

.form-group input {
  background-color: white;
  border: 1px solid rgb(218, 225, 234);
  padding: 6px;
  margin: 15px 0px;
  border-radius: 3px;
}

.form-group label {
  text-align: left;
}

.categories-select-rule {
  padding: 6px;
  border: 1px solid rgb(218, 225, 234) !important;
  background-color: white;
  width: -moz-min-content;
  border: none;
  float: right;
  text-align: left;
  border-radius: 3px;
  font-size: 14px;
  color: #131313;
  margin: 15px 0px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.add-transaction-button, .edit-multiple-transaction-button, .close-add-transaction-button {
  background-color: #f0f0f0;
  color: grey;
  padding: 10px;
  border-radius: 5px;
  /* box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px; */
  transition: 0.3s ease-in-out;
  font-family: system-ui;
  width: 130px;
}

.add-transaction-button:hover, .edit-multiple-transaction-button:hover, .close-add-transaction-button:hover {
  background-color: #666766;
  color: white;
  transition: 0.3s ease-in-out;
}

.create-rule-modal-button {
  margin: 10px auto;
  background: #88c6c8;
  transition: 0.3s ease-in-out;
}
.create-rule-modal-button:hover {
  background: #3d6869;
  transition: 0.3s ease-in-out;
}

.match-conditions-group {
  background: #ffffff;
}
.match-conditions-group, .new-values-group {
  width: 50%;
  text-align: left;
  padding: 3%;
}

.new-values-group {
  border-left: 1px solid rgb(218, 225, 234);
}

#close-modal-rule-button {
  color: black;
  font-weight: bold;
  transition: 0.3s ease-in-out;
}

#close-modal-rule-button:hover {
  color: white;
  scale: 1.1;
  transition: 0.3s ease-in-out;
}

.transaction-rules-parent {
  display: flex; /* changed from inline-flex to flex */
  width: 100%; /* ensure it takes full width */
  margin: 0 auto;
  justify-content: space-between; /* Adjust as needed */
  align-items: stretch; /* Adjust as needed */
  border-top: 1px solid rgb(218, 225, 234);
  border-bottom: 1px solid rgb(218, 225, 234);
}

.transaction-rules-parent p {
  font-size: 18px;
  font-weight: 600;
}

#ruleName {
  border: 1px solid rgb(218, 225, 234);
}

.amount-more {
  color: red;
}

.amount-less {
  color: green;
}

.form-group-header {
  display: flex;
  justify-content: space-between; /* Aligns children to opposite ends */
  align-items: center; /* Centers children vertically */
  width: 100%; /* Ensures the header takes the full width of its parent */
  cursor: pointer;
}

/* The switch - the box around the slider */
.transaction-rule-toggle-switch {
  position: relative;
  display: inline-block;
  width: 30px; /* Width of the switch */
  height: 17px; /* Height of the switch */
}

/* Hide default checkbox */
.transaction-rule-toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc; /* Slider background when it's not checked */
  transition: 0.4s ease-in-out;
  border-radius: 34px; /* Rounded corners for the slider */
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px; /* Height of the slider button */
  width: 13px; /* Width of the slider button */
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.3s ease-in-out;
  border-radius: 50%; /* Rounded corners for the slider button */
}

/* When the checkbox is checked, change the slider */
input:checked + .slider {
  background-color: #2196F3; /* Slider background when it's checked */
}

input:checked + .slider:before {
  transform: translateX(13px); /* Move the slider button when it's checked */
}

.updated-transactions-list {
  /* margin-top: 20px; */
  cursor: pointer;
  max-height: 40vh; /* 40% of the viewport height */
  overflow-y: auto; /* Enables vertical scrolling if content exceeds max-height */
  border: 1px solid rgb(218, 225, 234);
  transition: 0.3s ease-in-out;
}

.updated-transactions-list:hover {
  background-color: #f7f6f6;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  transition: 0.3s ease-in-out;
}

.updated-transactions-list ul {
  list-style: none;
  padding: 0;
  transition: 0.3s ease-in-out;
}

.updated-transactions-list li {
  margin-bottom: 10px;
  transition: 0.3s ease-in-out;
  /* Additional styling */
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.amount-range {
  display: flex;
  justify-content: space-evenly;
}

.click-to-view {
  color: #292997;
  font-size: 1rem;
  font-weight: 300;
  transition: 0.3s ease-in-out;
}

.edit-multiple-header {
  text-align: left;
  margin: 0;
  font-size: 20px;
}

.csv-import-buttons {
  margin: 20px 10px 0px;
  display: flex;
  justify-content: flex-end;
}







.multi-row {
  display: flex;
  gap: 10px;
  text-align: right;
}

.multi-column {
  flex: 1;
  align-items: center;
}


.multi-class {
  width: -webkit-fill-available;
  padding: 10px;
  margin: 4px;
  height: 12px;
  background-color: #ffffff;
  border-radius: 5px;
  color: gray;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}


.multi-container {
  padding: 1% 3%;
  border-radius: 10px;
}

.multi-label {
  width: 120px;
  text-align: left;
  font-weight:700;
  border: none;
}

#category-multi {
  width: -webkit-fill-available;
  border: none;
  /* margin-left: 0; */
  color: grey;
  padding: 10px;
  margin: 4px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline-style: solid;
  outline-width: thin;
  outline-color: #c8c8c8;
  font-family: system-ui;
}

.multi-label-select {
  width: -webkit-fill-available;
  border: none;
  overflow: auto;
  appearance: none;
  /* margin-left: 0; */
  border-radius: 5px;
  color: grey;
  outline-style: solid;
  background-color: white;
  outline-width: thin;
  outline-color: #c8c8c8;
  margin: 4px;
  height: 156px;
  font-family: system-ui;
}

.multi-label-select option {
  background-color: #f4f4f4;
  width: fit-content;
  padding: 5px;
  margin-top: 5px;
  margin-left: 5px;
  border-radius: 5px;
  font-family: system-ui;
}

.multi-label-select option:disabled {
  background-color: #fff;
  font-family: system-ui;
}

.edit-multi-col2, .edit-multi-col1 {
  width: 50%;
}

.search-transactions {
  width: -webkit-fill-available;
  height: 30px;
  padding: 8px;
  margin: 4px;
  border-radius: 5px;
  color: gray;
  /* outline-style: solid; */
  background-color: white;
  outline-width: thin;
  outline-color: #dcdcdc;
  font-family: system-ui;
}


.select-button {
  background-color: #ffffff00;
  color: gray;
  border: 1px solid #e9e9e9;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  width: -moz-max-content;
  width: max-content;
  text-align: center;
}

.select-button:hover {
  background-color: #eeeeee;
  color: black;
  cursor: pointer;
}

.options-row {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
}

.time-select-button {
  background-color: #ffffff00;
  color: #aaaaaa;
  border: none;
  width: 35px;
  font-size: 12px;
  padding: 4px;
  border-radius: 3px;
  outline: none;
  text-align: center;
  margin: 5px;
}

.time-select-button:hover {
  background-color: #bdccd9;
  color: black;
  cursor: pointer;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.time-select-button.active {
  background-color: #c7e6ff !important;
  color: black;
  width: 35px;
  border-radius: 10px;
  margin: 5px;
}

.summary-container {
  display: inline;
  align-items: baseline;
  margin-right: 20px;
  justify-content: space-between;
}

.selected-date-summary {
  color: #78440f;
  margin-left: 20px;
  margin-top: 0px;
  font-size: 18px;
}

.no-transactions-message {
padding: 3%;
}

.review-label {
  border: 1px solid red;
}

.green-amount {
  color: green;
}
.done-onboarding {
  margin: 0 auto 5%;
  background: #2c2c2c;
  color: white;
  width: max-content;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
  outline-color: unset;
}

.done-onboarding:hover {
  background-color: #3f4a40;
}

.add-transaction-button, .edit-multiple-transaction-button, .close-add-transaction-button {
  background-color: #ffffff;
  box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px;
  transition: 0.3s ease-in-out;
  font-family: system-ui;
  width: 150px;
  padding: 8px;
}

.onboarding-style-recurring {
  margin-top: 150px;
}

</style>
