<template>
  <div style="background-color: white">
    <div class="budgetparent">
      <div class="budget-progress-wrapper">
        <div class="budget-progress-container">
          <div class="budget-progress-bar">
            <div 
              class="budget-progress-fill" 
              :style="{ width: `${progressPercentage}%` }"
            ></div>
          </div>
          <!-- <div class="budget-labels">
            <span class="spent-label">${{ formatCurrency(monthlySpend) }} Spent</span>
            <span class="remaining-label">${{ formatCurrency(budgetDifference) }} Remaining</span>
          </div> -->
        </div>
      </div>
      <div v-if="isModalOpen" class="modal-backdrop">
        <BudgetTemplateModal
          :isOpen="isModalOpen"
          :templates="availableTemplates"
          :userId="userId"
          :selectedDate="selectedDate"
          :categoryBudgets="categoryBudgets"
          @close-modal="isModalOpen = false"
          @confirm-selection="selectTemplate"
          @create-new-template="createTemplate"
          @template-applied="onTemplateApplied"
        />
      </div>

      <div class="budgetdiv1">
        <!-- <h1 class="budget">Budget</h1> -->
      </div>
      <div class="budgetdiv2">
        <div
          style="
            font-weight: 900;
            text-align: right;
            font-size: 32px;
            padding-top: 15%;
          "
        >
          ${{ new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 }).format(monthlySpend) }}
        </div>
        <div style="font-weight: 900; text-align: right">Monthly Spend</div>
      </div>
      <div class="budgetdiv3">
        <div
          :style="{color: budgetDifference < 0 ? '#d55d2e' : '#4caf50'}"
          style="font-weight: 900; font-size: 32px; text-align: center"
        >
        ${{ new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 }).format(Math.abs(budgetDifference)) }}
        {{ budgetDifference < 0 ? 'Over' : 'Left' }}
        </div>
        <div
          :style="{color: (monthlySpend / monthlyBudget) * 100 > 100 ? '#d55d2e' : '#4caf50'}"
          style="font-weight: 900; font-size: 18px; text-align: center"
        >
          ({{ Math.round((monthlySpend / monthlyBudget) * 100) }}% Spent)
        </div>
      </div>
      <div class="budgetdiv4">
        <div
          style="
            font-weight: 900;
            text-align: left;
            font-size: 32px;
            padding-top: 15%;
          "
        >
          ${{ new Intl.NumberFormat('en-US', { style: 'decimal', maximumFractionDigits: 0 }).format(monthlyBudget) }}
        </div>
        <div style="font-weight: 900; text-align: left">Monthly Budget</div>
      </div>
      <div class="budgetdiv5">
        <canvas ref="pieChartCanvas"></canvas>
      </div>
      <div class="budgetdiv6">
        <div style="font-weight: 900; text-align: left">Trend</div>
        <div style="font-weight: 900; text-align: left; font-size: 32px">
          ${{ dailySpend.toFixed(0) }}
          <span style="font-size: 12pt">daily avg</span>
        </div>
        <div v-if="isCurrentMonthAndYear">
          <div style="font-weight: 400; text-align: left">
            <span v-if="dailyRemainingBudget >= 0">
              ${{ dailyRemainingBudget.toFixed(0) }} per day remaining this
              month
            </span>
            <span v-else>
              -${{ Math.abs(dailyRemainingBudget).toFixed(0) }} per day
              remaining this month
            </span>
          </div>
        </div>
      </div>
      <div class="budgetdiv7">
        <select
          class="budgetmonthselect"
          v-model="selectedDate"
          @change="handleMonthChange"
          id="budget-month-select"
          name="budget-month-select"
        >
          <option
            v-for="month in availableMonths"
            :key="month.value.month"
            :value="month.value"
          >
            {{ month.label }}
          </option>
        </select>
      </div>
      <div class="budgetdiv8">
        <BudgetLineChart
          :chartData="chartDataCollection"
          :selectedMonth="selectedMonth"
          :selectedYear="selectedYear"
        />
      </div>

      <div class="budgetdiv9">
        <div class="headersection">
          <button class="budgettemplate" @click="toggleTemplate">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="2em"
              viewBox="0 0 448 512"
            >
              >
              <path
                d="M8 256a56 56 0 1 1 112 0A56 56 0 1 1 8 256zm160 0a56 56 0 1 1 112 0 56 56 0 1 1 -112 0zm216-56a56 56 0 1 1 0 112 56 56 0 1 1 0-112z"
              />
            </svg>
          </button>
          <div style="padding: 0px 3%;">
            <div
              class="datemark"
              :class="{ 'current-month': isCurrentMonthAndYear }"
              :style="{ left: datePercentage -3 + '%'  }"
              :data-date="currentDateMark"
            ></div>
          </div>
        </div>

        <v-switch
          v-model="showGroupedCategories"
          label="Show Grouped Categories"
          color="primary"
          hide-details
          class="grouped-categories-switch"
          @change="saveTogglesToDatabase"
        ></v-switch>
        <template v-if="showGroupedCategories">
          <div
            class="budgetgroup"
            v-for="(groupData, groupName) in groupedCategories"
            :key="groupName"
            :class="{ 'grey-border': hasSpendingButNoBudget(groupData) }"
          >
            <!-- Conditional block to display the text -->
            <div
              v-if="hasSpendingButNoBudget(groupData)"
              class="spending-but-no-budget-text"
            >
              Categories with Unbudgeted Monthly Spending
            </div>

            <div
              v-if="!showAllGroups && !isGroupHidden(groupData) || showAllGroups"
              class="header"
              @click="toggleGroup(groupName)"
              :style="getGroupBackgroundStyle(groupData)"
            >
              <div class="groupheader">
                <h2 class="budgetgroupname">{{ groupName }}</h2>
                <h2 class="group-percentage">
                  {{ calculateBudgetUsagePercentage(groupData) }}%
                </h2>
              </div>
              <div class="budgettotals">
                <div style="display: inline-grid;">
                  <h2
                    class="group-total"
                    :style="{'color': calculateBudgetUsagePercentage(groupData) > 100 ? '#fff' : '#777'}"
                  >
                    ${{ calculateTotalForGroup(groupData) }}
                  </h2>
                  <h4 class="budget-subtitle">Spent</h4>
                </div>
                <div :style="{display: 'inline-grid', 'border-radius': calculateBudgetUsagePercentage(groupData) > 100 ? '4px' : '', 'padding-top': '10px'}">
                  <h2
                    class="group-budget"
                    :style="{'color': calculateBudgetUsagePercentage(groupData) > 100 ? '#f00' : '#333'}"
                  >
                    ${{ calculateBudgetForGroup(groupData).toFixed(0) }}
                  </h2>
                  <h4 class="budget-subtitle">Budget</h4>
                </div>
              </div>
            </div>

            <transition name="fade" mode="out-in">
              <ul v-if="groupData.showCategories">
                <li
                  class="category-parent"
                  v-for="category in groupData.categories"
                  :key="category.id"
                  @click="toggleAdditionalDiv(category)"
                >
                  <span class="category-emoji">{{ category.emoji }}</span>
                  <span class="category-name"
                    >{{ category.name }}
                    <span class="category-percentage"
                      >{{ calculateCategoryUsagePercentage(category) }}%</span
                    >
                    <span
                      v-if="category.name === 'Credit Card Payment' && creditCardToggle !== null"
                      class="category-toggle"
                    >
                      <input
                        type="checkbox"
                        v-model="creditCardToggle"
                        @click="handleToggleChange"
                        @click.stop
                      />

                      <span class="category-toggle-slider"></span>

                      <!-- Tooltip -->
                      <span class="category-toggle-tooltip">
                        <!-- <i class="info-icon">i</i> -->
                        If you use credit cards for everyday spending and pay them
                        off each month, you may want to disable this category and
                        monitor transactions from the credit card account directly.
                        <br>
                        Disabling this category will exclude it from charts and total
                        calculations.
                      </span>
                    </span>
                  </span>
                  <span
                    class="category-total"
                    :class="{ 'positive-total': calculateTotalForCategory(category) > 0 }"
                  >
                    ${{ calculateTotalForCategory(category).toFixed(0) }} /

                    <input
                      class="budgetinput"
                      :class="{ 'editing': editedCategoryId === category.id }"
                      type="number"
                      v-model="categoryBudgets[category.id]"
                      @blur="updateBudget(category)"
                      @keyup.enter="updateBudget(category)"
                      @click.stop
                      @focus="editedCategoryId = category.id"
                      :id="'budget-input-' + category.id" 
                    />
                  </span>

                  <div
                    v-if="category.name !== 'Credit Card Payment' || (category.name === 'Credit Card Payment' && creditCardToggle)"
                    class="category-fill"
                    :style="getCategoryBackgroundStyle(category)"
                  ></div>
                  
                  
                  <div
                    v-if="showCategoryInsights[category.id]"
                    class="category-insights-1"
                  ></div>

                  <div
                    v-if="showCategoryInsights[category.id]"
                    class="category-insights-2"
                  >
                    <div>Monthly Average</div>
                    <div>
                      <span class="bold-currency">
                        3M: {{ calculateAverageForCategory(category, 3) < 0 ? '-' : '' }}${{ Math.abs(calculateAverageForCategory(category, 3)).toFixed(0) }}
                      </span>
                    </div>
                    <div>
                      <span class="bold-currency">
                        6M: {{ calculateAverageForCategory(category, 6) < 0 ? '-' : '' }}${{ Math.abs(calculateAverageForCategory(category, 6)).toFixed(0) }}
                      </span>
                    </div>
                    <div>
                      <span class="bold-currency">
                        12M: {{ calculateAverageForCategory(category, 12) < 0 ? '-' : '' }}${{ Math.abs(calculateAverageForCategory(category, 12)).toFixed(0) }}
                      </span>
                    </div>
                  </div>

                  <div
                    v-if="showCategoryInsights[category.id]"
                    class="category-insights-3"
                  >
                  </div>

                  <div v-if="showCategoryInsights[category.id]" class="category-insights-4">
                    <div >
                      Suggested:
                      <span class="bold-currency">
                        {{ getSuggestedBudget(category) < 0 ? '-' : '' }}${{ Math.abs(getSuggestedBudget(category)).toFixed(0) }}
                      </span>
                    </div>
                    <div >
                      Last Month Budget:
                      <span class="bold-currency"
                        >${{ getLastMonthBudget(category).toFixed(0) }}</span
                      >
                    </div>
                    <div>
                    </div>
                  <div >
                      Rollover from last month:
                      <span
                        class="bold-currency"
                        :class="{ 'text-red': getAmountOverOrUnderLastMonth(category) < 0, 'text-green': getAmountOverOrUnderLastMonth(category) >= 0 }"
                      >
                        {{ getAmountOverOrUnderLastMonth(category) < 0 ? '-' : '+' }}
                        ${{ Math.abs(getAmountOverOrUnderLastMonth(category)).toFixed(0) }}
                      </span>
                      <div style="font-size: 14px;" v-if="showCategoryInsights[category.id]">
                        <div class="transaction-table">
                          <div class="transaction-row" v-for="transaction in filterTransactionsByCategory(category)" :key="transaction.id">
                            <div class="transaction-cell" style="font-weight: 600;">{{ transaction.name }}</div>
                            <div class="transaction-cell">{{ new Date(transaction.date + 'T00:00:00').toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' }) }}</div>
                            <div class="transaction-cell" :style="{color: transaction.amount < 0 ? 'green' : ''}">{{ transaction.amount < 0 ? '+' : '' }}${{ Math.abs(transaction.amount).toFixed(2) }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div>
                      Budget: <span class="bold-currency"> ${{ getLastMonthBudget(category).toFixed(0) }}</span>
                    </div>
                    <div>
                      Spent: <span class="bold-currency">${{ getLastMonthBudget(category).toFixed(0) - getAmountOverOrUnderLastMonth(category).toFixed(0) }}</span> 
                    </div> -->
                    <!-- <label for="rolloverToggleInput" class="rollover-label">Rollover</label>
                      <span class="rollover-toggle"> 
                        <input id="rolloverToggleInput" type="checkbox" v-model="category.rolloverEnabled" @click.stop="handleRolloverToggle(category)"/>
                          <span class="rollover-toggle-slider"></span>
                          <div class="rollover-toggle-tooltip">
                              Enable the Rollover feature for this category? When activated, any unused budget from this category will be carried over to the next month, allowing you to accumulate or offset any overspending.
                          </div>
                      </span> -->
                  </div>
                </li>
              </ul>
            </transition>
          </div>
        </template>
        <template v-else>
        <div
          class="budgetgroup category-parent"
          v-for="category in sortedCategories"
          :key="category.id"
          @click="toggleAdditionalDiv(category)"
          :class="{ 'grey-border': hasSpendingButNoBudget({ categories: [category] }) }"
          v-show="showAllGroups || (!showAllGroups && (calculateTotalForCategory(category) > 0 || (parseFloat(categoryBudgets[category.id]) || 0) > 0))"
        >
          <div
            v-if="hasSpendingButNoBudget({ categories: [category] })"
            class="spending-but-no-budget-text"
          >
            Category with Unbudgeted Monthly Spending
          </div>
          <div class="header" style="grid-area: 1 / 1 / 2 / 4;">
            <div class="groupheader2">
              <h2 class="budgetgroupname">{{ category.emoji }} {{ category.name }}</h2>
              <h2 class="group-percentage" style="color: #cac8c0;">
                {{ calculateCategoryUsagePercentage(category) }}%
              </h2>
              <span
                v-if="category.name === 'Credit Card Payment' && creditCardToggle !== null"
                class="category-toggle2"
              >
                <input
                  type="checkbox"
                  v-model="creditCardToggle"
                  @click="handleToggleChange"
                  @click.stop
                />

                <span class="category-toggle-slider"></span>

                <!-- Tooltip -->
                <span class="category-toggle-tooltip">
                  <!-- <i class="info-icon">i</i> -->
                  If you use credit cards for everyday spending and pay them
                  off each month, you may want to disable this category and
                  monitor transactions from the credit card account directly.
                  <br>
                  <br>
                  Disabling this category will exclude it from charts and total
                  calculations.
                </span>
              </span>
            </div>
            <div class="budgettotals2">
              <div style="display: inline-grid;">
                <h2 class="group-total">${{ calculateTotalForCategory(category).toFixed(0) }}</h2>
                <h4 class="budget-subtitle">Spent</h4>
              </div>
              <div style="display: inline-grid;">

                <input
                  class="budgetinput"
                  :class="{ 'editing': editedCategoryId === category.id }"
                  style="margin: 10px 0px -20px 0px; padding: 0px 10px 0px 10px; font-size: 24px; width: 100px;"
                  type="number"
                  v-model="categoryBudgets[category.id]"
                  @blur="updateBudget(category)"
                  @keyup.enter="updateBudget(category)"
                  @click.stop
                  @focus="editedCategoryId = category.id"
                  :id="'budget-input-' + category.id"
                  :name="'budget-input-' + category.id"
                />
                
                <h4 class="budget-subtitle">Budget</h4>
              </div>
            </div>
          </div>

          <div
            v-if="category.name !== 'Credit Card Payment' || (category.name === 'Credit Card Payment' && creditCardToggle)"
            class="category-fill-2"
            :style="getCategoryBackgroundStyle(category)"
          ></div>
          
          
          <div
            v-if="showCategoryInsights[category.id]"
            class="category-insights-1"
          ></div>

          <div
              v-if="showCategoryInsights[category.id]"
              class="category-insights-22"
            >
              <div>Monthly Average</div>
              <div>
                <span class="bold-currency">
                  3M: {{ calculateAverageForCategory(category, 3) < 0 ? '-' : '' }}${{ Math.abs(calculateAverageForCategory(category, 3)).toFixed(0) }}
                </span>
              </div>
              <div>
                <span class="bold-currency">
                  6M: {{ calculateAverageForCategory(category, 6) < 0 ? '-' : '' }}${{ Math.abs(calculateAverageForCategory(category, 6)).toFixed(0) }}
                </span>
              </div>
              <div>
                <span class="bold-currency">
                  12M: {{ calculateAverageForCategory(category, 12) < 0 ? '-' : '' }}${{ Math.abs(calculateAverageForCategory(category, 12)).toFixed(0) }}
                </span>
              </div>
            </div>

            <div
              v-if="showCategoryInsights[category.id]"
              class="category-insights-3"
            >
            </div>

            <div v-if="showCategoryInsights[category.id]" class="category-insights-4">
              
              <div>
                Suggested:
                <span class="bold-currency">
                  {{ getSuggestedBudget(category) < 0 ? '-' : '' }}${{ Math.abs(getSuggestedBudget(category)).toFixed(0) }}
                </span>
              </div>
              <div >
                Last Month Budget:
                <span class="bold-currency"
                  >${{ getLastMonthBudget(category).toFixed(0) }}</span
                >
              </div>
              <div>
              </div>
              <div >
                  Rollover from last month:
                  <span
                    class="bold-currency"
                    :class="{ 'text-red': getAmountOverOrUnderLastMonth(category) < 0, 'text-green': getAmountOverOrUnderLastMonth(category) >= 0 }"
                  >
                    {{ getAmountOverOrUnderLastMonth(category) < 0 ? '-' : '+' }}
                    ${{ Math.abs(getAmountOverOrUnderLastMonth(category)).toFixed(0) }}
                  </span>
                  <div style="font-size: 14px;" v-if="showCategoryInsights[category.id]">
                    <div class="transaction-table">
                      <div class="transaction-row" v-for="transaction in filterTransactionsByCategory(category)" :key="transaction.id">
                        <div class="transaction-cell" style="font-weight: 600;">{{ transaction.name }}</div>
                        <div class="transaction-cell">{{ new Date(transaction.date + 'T00:00:00').toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' }) }}</div>
                        <div class="transaction-cell" :style="{color: transaction.amount < 0 ? 'green' : ''}">{{ transaction.amount < 0 ? '+' : '' }}${{ Math.abs(transaction.amount).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div>
                  Budget: <span class="bold-currency"> ${{ getLastMonthBudget(category).toFixed(0) }}</span>
                </div>
                <div>
                  Spent: <span class="bold-currency">${{ getLastMonthBudget(category).toFixed(0) - getAmountOverOrUnderLastMonth(category).toFixed(0) }}</span> 
                </div> -->
                <!-- <label for="rolloverToggleInput" class="rollover-label">Rollover</label>
                  <span class="rollover-toggle"> 
                    <input id="rolloverToggleInput" type="checkbox" v-model="category.rolloverEnabled" @click.stop="handleRolloverToggle(category)"/>
                      <span class="rollover-toggle-slider"></span>
                      <div class="rollover-toggle-tooltip">
                          Enable the Rollover feature for this category? When activated, any unused budget from this category will be carried over to the next month, allowing you to accumulate or offset any overspending.
                      </div>
                  </span> -->
              </div>
        </div>
      </template>

      <v-switch
        v-model="showAllGroups"
        label="Show Unbudgeted Categories"
        color="primary"
        hide-details
        class="unbudgeted-switch"
        @change="saveTogglesToDatabase"
      ></v-switch>
      </div>
    </div>
    <annual-spend-chart :annual-data="annualData"></annual-spend-chart>
  </div>
</template>

<script>
/* eslint-disable */
import { collection, getDocs, getDoc, addDoc, writeBatch, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';
import BudgetLineChart from '../components/BudgetLineChart.vue';
import BudgetTemplateModal from '../components/BudgetTemplateModal.vue';
import AnnualSpendChart from '../components/AnnualSpendChart.vue';
import { Chart } from 'chart.js';

export default {
  name: 'BudgetView',
  components: {
    BudgetLineChart,
    BudgetTemplateModal,
    AnnualSpendChart,

  },

  data() {
    const today = new Date();
    const localDate = new Date(today.getTime() - (today.getTimezoneOffset() * 60000));
    return {
      budget: 0,
      categories: [],
      groupedCategories: {},
      selectedMonth: new Date().getMonth(), // This will default to the current month
      selectedYear: new Date().getFullYear(),
      selectedDate: {
          month: localDate.getMonth(),
          year: localDate.getFullYear()
        },
      categoryBudgets: {},
      focusedCategory: null,
      pieChart: null,
      creditCardToggle: '',
      showCategoryInsights: {}, // This is an object that will track which categories should show the additional div
      availableTemplates: [],
      isModalOpen: false,
      annualData: [],
      recurringRules: [],
      editedCategoryId: null,
      triggerRecalculation: 0, // Dummy variable to trigger recalculation

      showGroupedCategories: false,
      showAllGroups: false,
    };
  },

  async mounted() {
    this.retrieveCategories();
    this.groupedCategories = this.getSortedGroupedCategories();
    this.fetchRecurringRules();
    this.applyDefaultTemplateIfRequired();
    await this.retrieveTogglesFromDatabase();
  },

  computed: {
    progressPercentage() {
      return Math.min((this.monthlySpend / this.monthlyBudget) * 100, 100);
    },
    chartDataCollection() {
      console.log('Collecting chart data...'); // Log when the function starts

      const today = new Date();
      const daysInMonth = new Date(today.getFullYear(), this.selectedDate.month + 1, 0).getDate();
      const isCurrentMonth = this.selectedDate.month === today.getMonth() && this.selectedDate.year === today.getFullYear();

      // Log to check if fetchRecurringRules has been completed
      if (this.recurringRules && this.recurringRules.length) {
        console.log('Recurring rules are ready for chart data collection:', this.recurringRules);
      } else {
        console.log('Recurring rules not ready at the time of chart data collection.');
      }

      let budgetData = new Array(daysInMonth).fill(0);
      let cumulativeRecurring = 0;  // Track cumulative recurring expenses
      let spendData = new Array(daysInMonth).fill(null);

      // Calculate the total recurring expenses for the month from this.recurringRules
      const recurringTotal = this.recurringRules.reduce((acc, rule) => {
        if (rule.isActive) {
          const day = rule.nextExpectedDate.getDate();
          acc[day] = (acc[day] || 0) + rule.expectedAmount;
        }
        return acc;
      }, {});

      // Calculate the remainder of the budget after recurring expenses
      const recurringSum = Object.values(recurringTotal).reduce((acc, value) => acc + value, 0);
      const dailyIncrement = (this.monthlyBudget - recurringSum) / daysInMonth;
      // console.log("Daily Budget Increment: " + dailyIncrement)

      for (let i = 1; i <= daysInMonth; i++) {
        // Add the recurring expenses for the day to the cumulative total
        cumulativeRecurring += recurringTotal[i] || 0;

        // Calculate the budget up to this day, including recurring expenses
        // If dailyIncrement is negative, it should not be added to the budget
        const increment = dailyIncrement > 0 ? dailyIncrement * i : 0;
        const dayBudget = increment + cumulativeRecurring;
        budgetData[i - 1] = dayBudget;

        // For the current month, stop updating spendData beyond today
        if (isCurrentMonth && i > today.getDate()) {
          continue;
        }

        // Calculate day spend excluding negative transactions (income)
        const currentYear = this.selectedDate.year;
        const daySpend = this.transactions
          .filter(t => {
            // Extract the date part and manually set to midnight UTC to avoid timezone issues
            const dateParts = t.date.split('-');
            const transactionDate = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0));

            const isCorrectDate = transactionDate.getUTCDate() <= i &&
                                  transactionDate.getUTCMonth() === this.selectedDate.month &&
                                  transactionDate.getUTCFullYear() === currentYear;
            const isPositiveAmount = t.amount >= 0;
            const isNotCreditCardPayment = this.creditCardToggle || t.category !== 'Credit Card Payment';
            const doesNotHaveIgnoreLabel = !t.labels || !t.labels.includes('ignore');

            return isCorrectDate && isPositiveAmount && isNotCreditCardPayment && doesNotHaveIgnoreLabel;
          })
          .reduce((total, t) => total + t.amount, 0);
        spendData[i - 1] = daySpend;
      }

      return {
        labels: Array.from({ length: daysInMonth }, (_, i) => i + 1),
        datasets: [
          {
            label: 'Budget',
            data: budgetData,
            borderDash: [10, 10],
            borderWidth: 2,
            fill: false
          },
          {
            label: 'Spend',
            data: spendData,
            fill: 'origin'  // Keep this as 'origin'
          }
        ]
      };
    },
    visibleCategories() {
      return this.categories.filter(category => {
        const totalSpending = this.calculateTotalForCategory(category);
        const totalBudget = parseFloat(this.categoryBudgets[category.id]) || 0;
        return !(totalSpending === 0 && totalBudget === 0);
      });
    },
    sortedCategories() {
      if (this.editedCategoryId !== null) {
        // If a category is being edited, return the unsorted categories
        return this.categories;
      }

      return this.categories.sort((a, b) => {
        const spendA = this.calculateTotalForCategory(a);
        const spendB = this.calculateTotalForCategory(b);
        const budgetA = parseFloat(this.categoryBudgets[a.id]) || 0;
        const budgetB = parseFloat(this.categoryBudgets[b.id]) || 0;

        if (spendA > 0 && budgetA > 0 && (spendB === 0 || budgetB === 0)) {
          return -1; // a has spend and budget, b doesn't, so a comes first
        } else if ((spendA === 0 || budgetA === 0) && spendB > 0 && budgetB > 0) {
          return 1; // b has spend and budget, a doesn't, so b comes first
        } else if (spendA > 0 && budgetA === 0 && (spendB === 0 || budgetB > 0)) {
          return -1; // a has spend but no budget, b doesn't have spend or has budget, so a comes first
        } else if ((spendA === 0 || budgetA > 0) && spendB > 0 && budgetB === 0) {
          return 1; // b has spend but no budget, a doesn't have spend or has budget, so b comes first
        } else {
          return spendB - spendA; // sort by spend in descending order
        }
      });
    },

    displayedCategories() {
      return this.showAllGroups ? this.sortedCategories : this.visibleCategories;
    },
    currentDateMark() {
      const months = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
      const now = new Date();
      return `${months[now.getMonth()]} ${now.getDate()}`;
    },
    datePercentage() {
        const today = new Date();

        if (this.isCurrentMonthAndYear) {
            let daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
            return (today.getDate() / daysInMonth) * 100;
        }

        return 0; // default, or handle for other months if needed
    },
    isCurrentMonthAndYear() {
      const today = new Date();
      return this.selectedDate.month === today.getMonth() && this.selectedDate.year === today.getFullYear();
    },
    dayOfMonthSpend() {
        const currentDay = new Date().getDate();
        const currentYear = this.selectedDate.year;

        return this.transactions
          .filter(t => {
              const date = new Date(t.date);
              return date.getDate() <= currentDay && date.getMonth() === this.selectedDate.month && date.getFullYear() === currentYear;
          })
          .reduce((total, t) => total + t.amount, 0);
    },
    previousMonthDayOfMonthSpend() {
        const currentDay = new Date().getDate();
        const previousMonth = this.selectedDate.month - 1;
        const yearOfPreviousMonth = (previousMonth === -1) ? this.selectedDate.year - 1 : this.selectedDate.year;

        return this.transactions
          .filter(t => {
              const date = new Date(t.date);
              return date.getDate() <= currentDay && date.getMonth() === previousMonth && date.getFullYear() === yearOfPreviousMonth;
          })
          .reduce((total, t) => total + t.amount, 0);
    },
    spendingDifferencePercentage() {
        if (this.previousMonthDayOfMonthSpend === 0) return 0;
        const difference = this.dayOfMonthSpend - this.previousMonthDayOfMonthSpend;
        return (difference / this.previousMonthDayOfMonthSpend) * 100;
    },
    budgetDifference() {
        return this.monthlyBudget - this.monthlySpend;
    },
    daysPassed() {
        const today = new Date();
        return today.getDate();
    },
    daysInMonth() {
        const currentYear = new Date().getFullYear();
        return new Date(currentYear, this.selectedDate.month + 1, 0).getDate();
    },
    daysRemaining() {
        return this.daysInMonth - this.daysPassed;
    },
    dailySpend() {
        return this.monthlySpend / this.daysPassed;
    },
    dailyRemainingBudget() {
        return this.budgetDifference / this.daysRemaining;
    },
    groupTotalsForSelectedMonth() {
      let totals = {};

      // Calculate total for each group
      for (const [group, data] of Object.entries(this.groupedCategories)) {
          totals[group] = this.calculateTotalForGroup(data);
      }

      // If the creditCardToggle is disabled, adjust the totals to exclude 'Credit Card Payment' category
      if (!this.creditCardToggle) {
          const creditCardCategory = this.categories.find(category => category.name === 'Credit Card Payment');

          // Check if the category exists and has a group associated with it
          if (creditCardCategory && creditCardCategory.group) {
              totals[creditCardCategory.group] -= this.calculateTotalForCategory(creditCardCategory);
          }
      }

      return totals;
    },
    monthlyBudget() {
      return this.categories.reduce((total, category) => {
          if (category.name === 'Credit Card Payment' && !this.creditCardToggle) {
              return total;
          }

          const currentBudget = (category.budget || []).find(b => b.month === this.selectedDate.month && b.year === this.selectedDate.year);
          return total + (currentBudget ? currentBudget.amount : 0);
      }, 0);
    },
    monthlySpend() {
      // Access the dummy variable to establish a dependency
      console.log(this.triggerRecalculation); 

      return this.categories.reduce((total, category) => {
        if (category.name === "Credit Card Payment" && !this.creditCardToggle) {
          return total;
        }
        return total + this.calculateTotalForCategory(category);
      }, 0);
    },
    transactions() {
      return this.$store.state.transactions.filter(transaction => {
        // Extract the date part and manually set to midnight UTC to avoid timezone issues
        const dateParts = transaction.date.split('-');
        const transactionDate = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0));

        const isCorrectMonthAndYear = transactionDate.getUTCMonth() === this.selectedDate.month &&
                                      transactionDate.getUTCFullYear() === this.selectedDate.year;
        const isNotIncome = !(transaction.amount < 0 && transaction.categoryGroup === "Income");

        return isCorrectMonthAndYear && isNotIncome;
      });
    },
    availableMonths() {
      const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
      const currentMonth = new Date().getMonth();
      const currentYear = new Date().getFullYear();
      let availableMonths = [];

      for(let offset = -12; offset <= 12; offset++) {
        const monthIndex = (currentMonth + offset + 12) % 12;
        const yearOffset = Math.floor((currentMonth + offset) / 12);  // Adjust for previous or next year
        availableMonths.push({
          label: `${months[monthIndex]} ${currentYear + yearOffset}`,
          value: {
            month: monthIndex,
            year: currentYear + yearOffset
          }
        });
      }

      return availableMonths.reverse();
    }
  },

  watch: {
    selectedMonth() {
        if (this.pieChart) {
            this.pieChart.destroy();
            this.createPieChart();
        }
    },
    selectedDate: {
      deep: true,
      handler() {
        this.applyDefaultTemplateIfRequired();
      }
    },
  },

  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat('en-US', { 
        style: 'decimal', 
        maximumFractionDigits: 0 
      }).format(value);
    },
    async saveTogglesToDatabase() {
      try {
        const user = auth.currentUser;
        if (user) {
          await updateDoc(doc(db, 'users', user.uid), {
            groupToggle: this.showGroupedCategories,
            unbudgetedToggle: this.showAllGroups,
          });
        }
      } catch (error) {
        console.error('Error saving toggles to database:', error);
      }
    },
    async retrieveTogglesFromDatabase() {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            this.showGroupedCategories = userData.groupToggle || false; 
            this.showAllGroups = userData.unbudgetedToggle || false;
          }
        }
      } catch (error) {
        console.error('Error retrieving toggles from database:', error);
      }
    },
    filterTransactionsByCategory(category) {
      return this.transactions
        .filter(transaction => transaction.category === category.name)
        .sort((a, b) => new Date(b.date) - new Date(a.date));
    },

    async applyDefaultTemplateIfRequired() {
      const { month, year } = this.selectedDate;
      const user = auth.currentUser;
      if (user) {
        // Fetch the current budgets for the selected month.
        const categoriesCollection = collection(db, `users/${user.uid}/categories`);
        const categoriesSnapshot = await getDocs(categoriesCollection);

        // Find out if any category lacks a budget for the selected month/year.
        let isBudgetApplied = false;
        for (const docSnapshot of categoriesSnapshot.docs) {
          const data = docSnapshot.data();
          const budgetEntry = data.budget && data.budget.find(entry => entry.month === month && entry.year === year);
          if (budgetEntry) {
            isBudgetApplied = true;
            break;
          }
        }

        // If no category has a budget applied for the selected month/year, apply the default template.
        if (!isBudgetApplied) {
          const templatesCollection = collection(db, `users/${user.uid}/budgetTemplates`);
          const querySnapshot = await getDocs(templatesCollection);
          const defaultTemplate = querySnapshot.docs.find(doc => doc.data().isDefault === true);

          if (defaultTemplate) {
            await this.applyBudgetTemplate(month, year, defaultTemplate.data());
            this.onTemplateApplied(); // Call the method after applying the template
          }
        }
      }
    },

    async applyBudgetTemplate(month, year, templateData) {
        console.log('Attempting to apply template:', 'for', month, year, templateData);

        const user = auth.currentUser;
        if (user) {
            try {
                if (!templateData || !templateData.categoriesBudget) {
                    console.error('Invalid template data:', templateData);
                    return;
                }

                // Initialize Firestore batch
                const batch = writeBatch(db);

                // Prepare an object to accumulate local state updates
                const localStateUpdates = {};

                // Map over categories and prepare promises
                const promises = templateData.categoriesBudget.map(async categoryBudget => {

                    const categoryDocRef = doc(db, `users/${user.uid}/categories/${categoryBudget.categoryName}`);
                    const categoryDoc = await getDoc(categoryDocRef);

                    if (categoryDoc.exists()) {
                        const categoryData = categoryDoc.data();
                        if (!categoryData) {
                            console.error('Invalid category data:', categoryData);
                            return;
                        }

                        const existingBudgetIndex = categoryData.budget
                            ? categoryData.budget.findIndex(b => b.month === month && b.year === year)
                            : -1;

                        if (existingBudgetIndex > -1) {
                            categoryData.budget[existingBudgetIndex].amount = categoryBudget.amount;
                            categoryData.budget[existingBudgetIndex].templateName = templateData.name;
                            console.log('Budget Exists for Category: ' + categoryBudget.categoryName);
                        } else {
                            const newBudget = {
                                amount: categoryBudget.amount,
                                month: month,
                                year: year,
                                templateName: templateData.name
                            };
                            console.log('Applying new budget for category: ' + categoryBudget.categoryName)
                            categoryData.budget = categoryData.budget || [];
                            categoryData.budget.push(newBudget);
                        }

                        // Add to Firestore batch
                        batch.update(categoryDocRef, { budget: categoryData.budget });

                        // Accumulate local state updates
                        localStateUpdates[categoryBudget.categoryName] = categoryBudget.amount;
                    }
                });

                // Wait for all promises to resolve
                await Promise.all(promises);

                // Commit the batch
                await batch.commit();
                this.$emit('close-modal');
                this.$emit('template-applied');

                // Update local state in one go
                // this.categoryBudgets = { ...this.categoryBudgets, ...localStateUpdates };

                // Trigger a UI update, if necessary

            } catch (error) {
                console.error('Error applying template:', error);
            }
        }
    },

    onTemplateApplied() {
      this.retrieveCategories();
      this.groupedCategories = this.getSortedGroupedCategories();
    },

    async fetchRecurringRules() {
      console.log('Fetching recurring rules...'); // Log when the function starts
      try {
        const user = auth.currentUser;
        if (user) {
          // Construct the path to the user's rules
          const rulesQuery = query(collection(db, `users/${user.uid}/recurringRules`));

          // Fetch the rules from Firestore
          const querySnapshot = await getDocs(rulesQuery);
          this.recurringRules = querySnapshot.docs.map(doc => {
            let rule = doc.data();
            // Convert nextExpectedDate from string to Date object, assuming the date is in YYYY-MM-DD format
            const dateParts = rule.nextExpectedDate.split('-').map(part => parseInt(part, 10));
            // The month is 0-indexed in JavaScript's Date, so subtract 1 from the month part
            rule.nextExpectedDate = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
            return rule;
          });

          // Log the fetched and processed recurring rules
          console.log('Recurring rules fetched:', this.recurringRules.map(rule => ({
            date: rule.nextExpectedDate.getDate(),
            amount: rule.expectedAmount
          })));
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error fetching recurring rules:', error);
      }
    },

    toggleHiddenGroup(groupName) {
      // Toggle the visibility state
      this.visibleGroups[groupName] = !this.visibleGroups[groupName];
    },

    isBudgetZero(groupData) {
      // Determine if the group's budget is zero
      return this.calculateBudgetForGroup(groupData).toFixed(0) === '0';
    },

    isGroupHidden(groupData) {
      // Get the total spending and budget for the group
      const totalSpending = this.calculateTotalForGroup(groupData);
      const totalBudget = this.calculateBudgetForGroup(groupData);

      // Check if both spending and budget are zero
      return totalSpending === 0 && totalBudget === 0;
    },

    async toggleTemplate() {
        this.isModalOpen = true;
    },

    async handleRolloverToggle(category) {
        try {
            const user = auth.currentUser;
            if (user) {
                const categoryRef = doc(db, `users/${user.uid}/categories/${category.id}`);

                // Assuming the category has a field named rolloverEnabled in the database
                // Toggle the rolloverEnabled field
                const updatedRolloverStatus = !category.rolloverEnabled;
                await updateDoc(categoryRef, {
                    rolloverEnabled: updatedRolloverStatus
                });

                // Update the local category data with the new rollover status
                category.rolloverEnabled = updatedRolloverStatus;
            } else {
                console.log('No user is logged in.');
            }
        } catch (error) {
            console.error('Error updating rolloverEnabled:', error);
        }
    },

    getCurrentMonthSpendingSoFar(category) {
        const currentMonth = this.selectedDate.month;
        const currentYear = this.selectedDate.year;
        const transactionsForCategory = this.transactions.filter(transaction => {
            const transactionDate = new Date(transaction.date);
            return transaction.category === category.name &&
                  transactionDate.getMonth() === currentMonth &&
                  transactionDate.getFullYear() === currentYear;
        });
        return transactionsForCategory.reduce((total, transaction) => total + transaction.amount, 0);
    },

    estimateCurrentMonthTotalSpending(category) {
        const today = new Date();
        const daysPassed = today.getDate();
        const spendingSoFar = this.getCurrentMonthSpendingSoFar(category);
        return (30 / daysPassed) * spendingSoFar;  // Using 30 days for simplicity. You can make this more accurate if needed.
    },

    getSuggestedBudget(category) {
      const amountOverOrUnder = this.getAmountOverOrUnderLastMonth(category);
      let lastMonthActualSpend = this.getLastMonthBudget(category);

      // Check if the amountOverOrUnder is positive or negative
      if (amountOverOrUnder > 0) {
        lastMonthActualSpend -= amountOverOrUnder;
      } else {
        lastMonthActualSpend += Math.abs(amountOverOrUnder);
      }

      const threeMonthAverage = this.getAverageOfLastNMonths(category, 3);
      const currentMonthEstimate = this.estimateCurrentMonthTotalSpending(category);

      // You can adjust the weights if needed. Here I'm giving 50% weightage to the current month's estimate.
      const suggested = 0.25 * currentMonthEstimate + 0.25 * lastMonthActualSpend + 0.75 * threeMonthAverage;
      return suggested;
    },

    getLastMonthBudget(category) {
        const previousMonth = this.selectedDate.month - 1;
        const budget = (category.budget || []).find(b => b.month === previousMonth);
        return budget ? budget.amount : 0;
    },

    getAverageOfLastNMonths(category, n) {
        let total = 0;
        let count = 0;
        for (let i = 1; i <= n; i++) {
            const month = this.selectedDate.month - i;
            const budget = (category.budget || []).find(b => b.month === month);
            if (budget) {
                total += budget.amount;
                count++;
            }
        }
        return count ? total / count : 0;
    },

    getAmountOverOrUnderLastMonth(category) {
        const lastMonthBudget = this.getLastMonthBudget(category);
        const lastMonthSpend = this.calculateTotalForCategoryLastMonth(category);
        return lastMonthBudget - lastMonthSpend;
    },

    calculateTotalForCategoryLastMonth(category) {
        const previousMonth = this.selectedDate.month - 1;
        const yearOfPreviousMonth = previousMonth < 0 ? this.selectedDate.year - 1 : this.selectedDate.year;
        const correctedPreviousMonth = previousMonth < 0 ? 11 : previousMonth; // Adjust for year boundary

        const transactionsForCategory = this.$store.state.transactions.filter(transaction => {
            const transactionDate = new Date(transaction.date);
            const localTransactionDate = new Date(transactionDate.getTime() - transactionDate.getTimezoneOffset() * 60000);

            return transaction.category === category.name &&
                  localTransactionDate.getMonth() === correctedPreviousMonth &&
                  localTransactionDate.getFullYear() === yearOfPreviousMonth &&
                  !(transaction.amount < 0);
        });

        return transactionsForCategory.reduce((total, transaction) => total + transaction.amount, 0);
    },


    calculateAverageForCategory(category, numberOfMonths) {
        const endMonth = this.selectedDate.month - 1;
        const startMonth = endMonth - numberOfMonths + 1;
        const currentYear = this.selectedDate.year;
        const transactionsForCategory = this.$store.state.transactions.filter(transaction => {
            const transactionDate = new Date(transaction.date);
            const month = transactionDate.getMonth();
            const year = transactionDate.getFullYear();
            return transaction.category === category.name &&
                  month >= startMonth && month <= endMonth &&
                  year === currentYear;
        });
        const totalAmount = transactionsForCategory.reduce((total, transaction) => total + transaction.amount, 0);
        return totalAmount / numberOfMonths;
    },

    getSortedGroupedCategories() {
      return Object.entries(this.groupedCategories)
        .sort((a, b) => {
          // Calculate budget usage percentage for each grouphttps://a.slack-edge.com/production-standard-emoji-assets/14.0/apple-medium/1f44b@2x.png
          const percentageA = this.calculateBudgetUsagePercentage(a[1]);
          const percentageB = this.calculateBudgetUsagePercentage(b[1]);

          if (percentageA !== percentageB) {
            // Sort primarily by budget usage percentage
            return percentageB - percentageA;
          } else {
            // When percentages are equal (including both zero), sort by spending
            const spendingA = this.calculateTotalForGroup(a[1]);
            const spendingB = this.calculateTotalForGroup(b[1]);
            return spendingB - spendingA;
          }
        })
        .filter(([key]) => key !== 'Income') // Exclude the "Income" group here
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    },

    toggleAdditionalDiv(category) {
        if (this.showCategoryInsights[category.id]) {
            this.showCategoryInsights[category.id] = !this.showCategoryInsights[category.id];
        } else {
            this.showCategoryInsights = {
                ...this.showCategoryInsights,
                [category.id]: true
            };
        }
    },

    hasSpendingButNoBudget(groupData) {
      const totalSpending = this.calculateTotalForGroup(groupData);
      const totalBudget = this.calculateBudgetForGroup(groupData);
      return totalSpending > 0 && totalBudget === 0;
    },

    async handleToggleChange() {
      // Update the local state first
      this.creditCardToggle = !this.creditCardToggle;

      // Update the database
      try {
          const user = auth.currentUser;
          if (user) {
              // Find the Credit Card Payment category
              const creditCardCategory = this.categories.find(cat => cat.name === 'Credit Card Payment');
              if (creditCardCategory) {
                  const categoryRef = doc(db, `users/${user.uid}/categories`, creditCardCategory.id);
                  await updateDoc(categoryRef, {
                      includeInBudget: this.creditCardToggle
                  });
                  console.log('Updated includeInBudget in the database.');
              }
          } else {
              console.log('No user is logged in.');
          }
      } catch (error) {
          console.error('Error updating includeInBudget:', error);
      }
    // Refresh the pie chart to reflect the changes
    this.createPieChart();
    this.triggerRecalculation++;
    this.updateAnnualData();

    },

    createPieChart() {
      if (this.pieChart) {
        this.pieChart.destroy();
      }

      let labels = [];
      let dataValues = [];
      Object.entries(this.groupTotalsForSelectedMonth).forEach(([label, value]) => {
        if (value > 0) {
          labels.push(label);
          dataValues.push(value);
        }
      });

      // Sort dataValues and simultaneously sort labels
      const combined = dataValues.map((value, index) => ({
        value,
        label: labels[index],
      }));

      combined.sort((a, b) => b.value - a.value); // Sort by largest first

      // Unpack the combined array back into separate arrays
      labels = combined.map(item => item.label);
      dataValues = combined.map(item => item.value);

      // Generate colors programmatically after sorting
      let backgroundColors = [];
      const startHue = 900; // Blue hue value
      const endHue = 220; // End hue value
      const startLightness = 30; // Start with a light shade of blue
      const endLightness = 90; // End with a dark shade of blue

      labels.forEach((_, index) => {
        const hue = startHue;
        const lightness = startLightness - (index / (labels.length - 1)) * (startLightness - endLightness);
        backgroundColors.push(`hsl(${hue}, 100%, ${lightness}%)`);
      });

      const remainingAmount = this.monthlyBudget - this.monthlySpend;
      if (remainingAmount > 0) {
        labels.push("Remaining");
        dataValues.push(remainingAmount);
        backgroundColors.push('#f2f2ec'); // Color for the "Remaining" slice
      }

      const ctx = this.$refs.pieChartCanvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio || 1;
      this.$refs.pieChartCanvas.width = this.$refs.pieChartCanvas.offsetWidth * pixelRatio;
      this.$refs.pieChartCanvas.height = this.$refs.pieChartCanvas.offsetHeight * pixelRatio;
      ctx.scale(pixelRatio, pixelRatio);



      // Center Text Plugin
      const centerTextPlugin = {
        id: 'centerTextPlugin',
        afterDraw: (chart) => {
          if (chart.tooltip._active && chart.tooltip._active.length) {
            const activePoint = chart.tooltip._active[0];
            const dataset = chart.data.datasets[activePoint.datasetIndex];

            // Check if `dataset` and `dataset.data` exist before accessing their `length` properties
            if (dataset && dataset.data && activePoint.index < dataset.data.length) {
              const ctx = chart.ctx,
                value = dataset.data[activePoint.index],
                totalValue = dataset.data.reduce((a, b) => a + b, 0),
                percentageValue = Math.round((value / totalValue * 100)) + '%',
                label = chart.data.labels[activePoint.index],  // Fetch the label for the active slice
                centerX = (chart.chartArea.left + chart.chartArea.right) / 2,
                centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

              // Format the value as currency
              const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

              // Draw the text in the center
              ctx.save();
              ctx.font = '16px Arial'; // Set font size and style
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#000'; // Or any other color
              ctx.fillText(label, centerX, centerY - 30);  // Display the label above the percentage
              ctx.fillText(formattedValue, centerX, centerY + 30);  // Optionally display the formatted value below the percentage

              ctx.font = 'bold 24px Arial'; // Increase font size for the percentage
              ctx.fillText(percentageValue, centerX, centerY);
              ctx.restore();
            }
          }
        },
      };

      Chart.register(centerTextPlugin);

      // Chart options
      const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '65%',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: false,  // Disable the display of data labels
          },
        },
        hover: {
          mode: 'nearest',
          intersect: true,
          animationDuration: 400,
        },
        layout: {
          padding: {
            left: 5,
            right: 5,
            top: 5,
            bottom: 5
          }
        }
      };

      // Create the chart
      this.pieChart = new Chart(ctx, {
          type: 'doughnut',
          data: {
              labels: labels,
              datasets: [{
                  data: dataValues,
                  backgroundColor: backgroundColors,
                  cutout: chartOptions.cutout,
                  borderWidth: 0, // This will remove the border/outline
                  hoverOffset: 10  // Adjust this value to control the size of the shadow on hover
              }]
          },
          options: chartOptions,
          plugins: [centerTextPlugin]
      });
      Chart.unregister(centerTextPlugin);  // Unregister the plugin after using it to avoid conflicts
    },

    getCategoryBackgroundStyle(category) {
        const percentage = this.calculateCategoryUsagePercentage(category);
        let fill, circlePosition;

        if (percentage <= 100) {
            fill = percentage.toFixed(2);
            circlePosition = fill;
        } else {
            fill = (100 / percentage * 100).toFixed(2);
            circlePosition = 100;
        }

        return {
            background: percentage <= 100 ? `linear-gradient(90deg, rgb(184, 210, 219) ${fill}%, transparent 0)` : `linear-gradient(90deg, rgb(184, 210, 219) ${fill}%, #ff7b7b 100%)`,
            "--blueFill": `${circlePosition}%`
        };
    },

    getGroupBackgroundStyle(group) {
      const percentage = this.calculateBudgetUsagePercentage(group);
      let darkFill;

      if (percentage <= 100) {
          darkFill = percentage.toFixed(2);
          return {
              background: `linear-gradient(90deg, rgb(184, 210, 219) ${darkFill}%, transparent ${darkFill}%)`
          };
      } else {
          darkFill = (100 / percentage * 100).toFixed(2);
          return {
              background: `linear-gradient(90deg, rgb(184, 210, 219) 0%, rgb(138, 177, 191) ${darkFill}%,rgb(138, 177, 191) ${darkFill}%, rgb(34 42 80) 100%)`
              // background: `linear-gradient(90deg, rgb(184, 210, 219) 0%, rgb(184, 210, 219) ${darkFill}%,rgba(131,131,131,0.6) calc(${darkFill}% + 2px), rgb(184, 210, 219) ${darkFill}%, rgb(34 42 80) 100%)`
          };
      }
    },

    calculateCategoryUsagePercentage(category) {
      const actualAmount = parseFloat(this.calculateTotalForCategory(category));
      const budgetAmount = parseFloat(this.categoryBudgets[category.id] || 0);

      // Prevent division by zero
      if (budgetAmount === 0) return 0;

      const percentage = (actualAmount / budgetAmount) * 100;
      return Math.round(percentage); // Round to the nearest whole number
    },

    calculateBudgetUsagePercentage(group) {
      const actualAmount = parseFloat(this.calculateTotalForGroup(group));
      const budgetAmount = parseFloat(this.calculateBudgetForGroup(group));

      // Prevent division by zero
      if (budgetAmount === 0) return 0;

      const percentage = (actualAmount / budgetAmount) * 100;
      return Math.round(percentage); // Round to the nearest whole number
    },

    async updateBudget(category) {
      const user = auth.currentUser;
      if (user && this.categoryBudgets[category.id]) {
        try {
          const categoryRef = doc(db, `users/${user.uid}/categories/${category.id}`);

          let updatedBudget = category.budget || [];

          // Check if the budget for the selected month and year already exists
          const existingBudgetIndex = updatedBudget.findIndex(b => b.month === this.selectedDate.month && b.year === this.selectedDate.year);

          if (existingBudgetIndex !== -1) {
            // If it exists, update the amount
            updatedBudget[existingBudgetIndex].amount = parseFloat(this.categoryBudgets[category.id]);
          } else {
            // If not, add a new budget entry for the selected month and year
            updatedBudget.push({
              month: this.selectedDate.month,
              year: this.selectedDate.year,
              amount: parseFloat(this.categoryBudgets[category.id])
            });
          }

          console.log(`Updating budget for category ${category.name} for month ${this.selectedDate.month} and year ${this.selectedDate.year}`);
          console.log("Old Budget:", category.budget);
          console.log("Updated Budget:", updatedBudget);

          await updateDoc(categoryRef, {
            budget: updatedBudget
          });

          document.getElementById(`budget-input-${category.id}`).blur();

          // After updating the budget, recalculate and fetch budget totals
          this.$store.dispatch('recalculateAndFetchBudgetTotals');

          // Re-sort the categories based on the updated budget
          this.groupedCategories = this.getSortedGroupedCategories();

        } catch (error) {
          console.error('Error updating budget:', error);
        }
      }
      this.editedCategoryId = null;
    },

    calculateBudgetForGroup(group) {
        return group.categories.reduce((acc, category) => {
            // Exclude the Credit Card Payment category if its value is false
            if (category.name === 'Credit Card Payment' && !this.creditCardToggle) {
                return acc;
            }
            const categoryBudget = parseFloat(this.categoryBudgets[category.id]) || 0;
            return acc + categoryBudget;
        }, 0);
    },

    calculateTotalForGroup(group) {
        const total = group.categories.reduce((acc, category) => {
            // Exclude the Credit Card Payment category if its value is false
            if (category.name === 'Credit Card Payment' && !this.creditCardToggle) {
                return acc;
            }
            const categoryTotal = this.calculateTotalForCategory(category);
            return acc + categoryTotal;
        }, 0);
        return Math.round(total);  // Round to the nearest whole number
    },

    calculateTotalForCategory(category) {
      const currentYear = this.selectedDate.year;  // Note that we're using selectedYear here
      const transactionsForCategory = this.transactions.filter(transaction => {
        // Extract the date part and manually set to midnight UTC to avoid timezone issues
        const dateParts = transaction.date.split('-');
        const transactionDate = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2]));

        return transaction.category === category.name &&
              transactionDate.getUTCMonth() === this.selectedDate.month &&
              transactionDate.getUTCFullYear() === currentYear && 
              transaction.amount >= 0 &&  // Filter out negative transactions
              !transaction.labels.includes('ignore');
      });

      return transactionsForCategory.reduce((total, transaction) => total + transaction.amount, 0);
    },
  


    handleMonthChange() {
        this.categories.forEach(category => {
            const currentBudget = (category.budget || []).find(b => b.month === this.selectedDate.month);
            if (currentBudget) {
                this.categoryBudgets[category.id] = currentBudget.amount;
            } else {
                // If no budget is set for the selected month, set it to a default value (e.g., 0 or empty).
                this.categoryBudgets[category.id] = 0;
            }
        });
        console.log(`Selected month: ${this.selectedDate.month}`);
        console.log(`Selected Year: ${this.selectedDate.year}`);

        // Call the groupCategories method to re-sort the categories based on the new totals for the selected month
        this.getSortedGroupedCategories();

        // After sorting, update the groupedCategories to reflect the sorted order
        this.groupedCategories = this.getSortedGroupedCategories();

        // Refresh the pie chart to reflect the changes
        this.createPieChart();
    },

    toggleGroup(groupName) {
      this.groupedCategories[groupName].showCategories = !this.groupedCategories[groupName].showCategories;
    },

    async retrieveCategories() {
        try {
            const user = auth.currentUser;
            if (user) {
                const categoriesCollection = collection(db, `users/${user.uid}/categories`);
                const orderedQuery = query(categoriesCollection, orderBy('order'));
                const querySnapshot = await getDocs(orderedQuery);

                // Process the categories
                this.categories = querySnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const currentBudget = (data.budget || []).find(b => b.month === this.selectedDate.month && b.year === this.selectedDate.year);
                    if (currentBudget) {
                        this.categoryBudgets[doc.id] = currentBudget.amount;
                    } else {
                        this.categoryBudgets[doc.id] = 0;
                    }
                    // Check if the category is "Credit Card Payment" and set the toggle
                    if (data.name === 'Credit Card Payment' && data.hasOwnProperty('includeInBudget')) {
                        this.creditCardToggle = data.includeInBudget;
                    }
                    return {
                        id: doc.id,
                        name: data.name,
                        emoji: data.emoji,
                        group: data.group,
                        order: data.order,
                        budget: data.budget,
                        rolloverEnabled: data.rolloverEnabled || false,
                    };
                });

                this.groupCategories();
                // Ensure the DOM has been updated before creating the pie chart
                // Sort the groups once categories are retrieved
                this.groupedCategories = this.getSortedGroupedCategories();

                this.$nextTick(() => {
                  this.createPieChart();
                  this.updateAnnualData();
                });
            } else {
                console.log('No user is logged in.');
            }
        } catch (error) {
            console.error('Error retrieving categories:', error);
        }
    },

    groupCategories() {
      this.groupedCategories = {};
      this.categories.forEach((category) => {
        if (!this.groupedCategories[category.group]) {
          this.groupedCategories[category.group] = {
            categories: [],
            showCategories: false // this property will determine if the categories should be displayed
          };
        }
        this.groupedCategories[category.group].categories.push(category);
      });

      // Sorting categories within each group by their totals
      for (let group in this.groupedCategories) {
        this.groupedCategories[group].categories.sort((a, b) => {
          return this.calculateTotalForCategory(b) - this.calculateTotalForCategory(a); // Sort in descending order
        });
      }
    },

    // Calculates budget and spend totals for a given month and year
    calculateTotalsForMonth(month, year) {
        const monthlyTotals = {
            monthName: new Date(year, month).toLocaleString('default', { month: 'long' }), // Convert month number to month name
            budget: 0,
            spend: 0
        };

        this.categories.forEach(category => {
            const currentBudget = (category.budget || []).find(b => b.month === month && b.year === year);
            if (currentBudget) {
                monthlyTotals.budget += currentBudget.amount;
            }

            const transactionsForCategory = this.transactions.filter(transaction => {
                const transactionDate = new Date(transaction.date);
                return transaction.category === category.name &&
                        transactionDate.getMonth() === month &&
                        transactionDate.getFullYear() === year;
            });

            monthlyTotals.spend += transactionsForCategory.reduce((total, transaction) => total + transaction.amount, 0);
        });

        return monthlyTotals;
    },

    updateAnnualData() {
      const newAnnualData = [];
      const currentYear = this.selectedDate.year;

      for (let month = 0; month < 12; month++) {
        const monthlyTransactions = this.$store.state.transactions.filter(transaction => {
          // Extract the date part and manually set to midnight UTC to avoid timezone issues
          const dateParts = transaction.date.split('-');
          const transactionDate = new Date(Date.UTC(dateParts[0], dateParts[1] - 1, dateParts[2], 0, 0, 0));

          const isCurrentYear = transactionDate.getUTCFullYear() === currentYear;
          const isCurrentMonth = transactionDate.getUTCMonth() === month;
          const isPositiveTransaction = transaction.amount >= 0;
          const isCreditCardPayment = transaction.category === 'Credit Card Payment';
          const doesNotHaveIgnoreLabel = !transaction.labels || !transaction.labels.includes('ignore');

          // If toggle is ON (true), exclude Credit Card Payment transactions
          return isCurrentYear && isCurrentMonth && isPositiveTransaction &&
            !(!this.creditCardToggle && isCreditCardPayment) && doesNotHaveIgnoreLabel;
        });

        const monthlyTotals = this.calculateTotalsForMonthWithTransactions(month, currentYear, monthlyTransactions);
        newAnnualData.push({
          monthName: monthlyTotals.monthName,
          budgetAmount: monthlyTotals.budget,
          totalSpend: monthlyTotals.spend
        });
      }

      this.annualData = newAnnualData;
    },

    calculateTotalsForMonthWithTransactions(month, year, transactions) {
      const monthlyTotals = {
        monthName: new Date(year, month).toLocaleString('default', { month: 'long' }),
        budget: 0,
        spend: 0
      };

      this.categories.forEach(category => {
        // If toggle is ON and the category is 'Credit Card Payment', skip it
        if (!this.creditCardToggle && category.name === 'Credit Card Payment') {
          return;
        }

        const currentBudget = (category.budget || []).find(b => b.month === month && b.year === year);
        if (currentBudget) {
          monthlyTotals.budget += currentBudget.amount;
        }

        const transactionsForCategory = transactions.filter(transaction => {
          return transaction.category === category.name;
        });

        monthlyTotals.spend += transactionsForCategory.reduce((total, transaction) => total + transaction.amount, 0);
      });

      return monthlyTotals;
    }



  }
};
</script>

<style scoped>
canvas {
  width: 100%;
  height: 400px; /* or whatever height you prefer */
}

.budgetparent {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Removed the grid-template-rows property to allow rows to size based on content */
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  border-color: lightgrey;
  background-color: white;
}

.budgetdiv1 {
  grid-area: 1 / 1 / 2 / 2;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
}
.budgetdiv2 {
  grid-area: 1 / 2 / 2 / 3;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
}
.budgetdiv3 {
  grid-area: 1 / 3 / 2 / 4;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
  margin: 0 auto;
  margin-top: 15%;
}
.budgetdiv4 {
  grid-area: 1 / 4 / 2 / 5;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
}
.budgetdiv5 {
  grid-area: 2 / 1 / 3 / 2;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 10%;
  height: 300px;
}
.budgetdiv6 {
  grid-area: 3 / 1 / 4 / 2;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
  padding-left: 15%;
}
.budgetdiv7 {
  grid-area: 4 / 1 / 5 / 2;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
  padding-left: 15%;
  text-align: left;
  display: flex;
}
.budgetdiv8 {
  grid-area: 2 / 2 / 5 / 5;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
}
.budgetdiv9 {
  grid-area: 5 / 1 / 6 / 5;
  border-style: none;
  border-width: thin;
  border-radius: 10px;
  border-color: lightgrey;
  padding: 0px 3%;
  position: relative;
}

h2 {
  text-align: center;
}

.budget-table {
  width: 75vw;
  margin: 0 auto;
}

.budget {
  text-align: left;
  padding-left: 5%;
}

.budgetgroup {
  margin: 15px 0px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: left;
  transition: all 0.3s ease-in-out;
}

.budgetgroup:hover {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.budgetgroup ul {
  list-style-type: none;
  padding-left: 0; /* This will remove the default padding of the unordered list */
  padding: 1% 2%;
  transition: all 0.3s ease-in-out;
}

.category-total {
  grid-area: 1 / 4 / 2 / 5;
  font-weight: bold;
  color: #c0c0c0;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 60px;
  text-align: right;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* The gradient will be applied directly here via the `:style` binding */
  border-radius: 10px; /* This will ensure the gradient looks smooth */
  padding: 6px 0px;
}

.budgetgroupname {
  margin: 0;
  flex: 1;
  text-align: left;
  border-radius: 10px;
  padding: 2%;
  min-width: -moz-max-content;
  min-width: max-content;
}

.group-budget {
  flex-shrink: 0;
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  margin-left: 0px;
  margin-bottom: -20px;
  /* padding: 2% 0%; */
  min-width: 100px;
  text-align: right;
}

.category-container {
  display: flex;
  justify-content: space-between; /* This will separate category name and total */
  align-items: center; /* Vertically align the items */
}

.category-name {
  grid-area: 1 / 2 / 2 / 4;
  flex: 1; /* This will allow the category name to take up all available space */
  text-align: left;
  font-size: 20px;
  font-weight: 800;
}

.budgetinput {
  width: 70px;
  margin: 0px -5px 0px 0px;
  padding: 10px 10px 5px 10px;
  text-align: right;
  font-size: 16px;
  color: #333777;
  font-weight: bold;
  background-color: unset;
  border: none;
  outline: none;
  border-radius: 5px;
}

.budgetinput:hover {
  border: 1px solid #777;
}



.group-total {
  font-weight: bold;
  color: #777;
  padding-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 60px;
  text-align: right;
  margin-bottom: -20px;
}
.budgettotals {
  display: flex;
  align-items: center;
  padding-right: 1%;
}

.budgettotals2 {
  display: flex;
  align-items: center;
  padding-right: 0%;
}

.groupheader {
  display: flex;
  align-items: center;
  margin-left: 2%;
}

.groupheader2 {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.group-percentage {
  font-weight: bold;
  color: #f8f7f1; /* Making it a bit lighter for differentiation */
  margin-left: 10px;
  font-size: 20px;
  text-align: right;
}

.positive-total {
  color: black;
}

.category-fill {
  grid-area: 2 / 2 / 3 / 5;
  position: relative;
  /* width: 100%; */
  margin-right: 10px;
  height: 8px;
  border-radius: 10.5px;
  margin-top: 16px;
  background-color: #ebebeb !important;
}

.category-fill::after {
  content: "";
  position: absolute;
  left: calc(var(--blueFill));
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  outline-style: solid;
  outline-color: #757575;
  z-index: 1;
}

.category-fill-2 {
  grid-area: 2 / 1 / 3 / 5;
  position: relative;
  /* width: 100%; */
  margin-left: 10px;
  height: 8px;
  border-radius: 10.5px;
  margin-top: 16px;
  background-color: #f6f6f6 !important;
}

.category-fill-2::after {
  content: "";
  position: absolute;
  left: calc(var(--blueFill));
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 50%;
  outline-style: solid;
  outline-color: #757575;
  z-index: 1;
}

.category-parent {
  display: grid;
  grid-template-columns: 80px repeat(2, 1fr);
  grid-template-rows: 30px 30px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-right: 0px;
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  transition: box-shadow 0.3s ease, border-radius 0.3s ease, padding 0.3s ease;
}

.category-parent:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background: #f6f6f6;
}
.category-emoji {
  grid-area: 1 / 1 / 3 / 2;
  font-size: 30px;
  display: flex; /* make it a flex container */
  justify-content: center; /* center its content horizontally */
  align-items: center; /* center its content vertically */
}

.category-percentage {
  font-weight: bold;
  color: #c0c0c0;
  margin-left: 10px;
  min-width: 60px;
  text-align: right;
  font-size: smaller;
}

.budgetmonthselect {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #5d5d5d;
  font-size: 24px;
  color: white;
  margin: 10% 0%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease,
    transform 0.6s ease;
}

.budgetmonthselect:focus-visible {
  outline: none;
}

.budgetmonthselect:hover {
  background-color: #444343;
  transform: scale(1.01);
}

/* The switch - the box around the slider */
.category-toggle {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-left: 15px;
  vertical-align: middle;
}

.category-toggle2 {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 17px;
  margin-left: 15px;
  vertical-align: middle;
}

/* Adjust default HTML checkbox */
.category-toggle input {
  position: absolute; /* Absolute positioning */
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire switch */
  height: 100%; /* Cover the entire switch */
  opacity: 0; /* Keep it invisible */
  cursor: pointer; /* Change the cursor to a pointer */
  z-index: 2; /* Keep it above the slider */
}

.category-toggle2 input {
  position: absolute; /* Absolute positioning */
  top: 0;
  left: 0;
  width: 100%; /* Cover the entire switch */
  height: 100%; /* Cover the entire switch */
  opacity: 0; /* Keep it invisible */
  cursor: pointer; /* Change the cursor to a pointer */
  z-index: 2; /* Keep it above the slider */
}

/* The slider */
.category-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 17px;
  z-index: 1; /* Keep it below the checkbox */
}

.category-toggle-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .category-toggle-slider {
  background-color: #2196f3;
}

input:focus + .category-toggle-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .category-toggle-slider:before {
  transform: translateX(13px);
}

/* Tooltip styles */
.category-toggle-tooltip {
  visibility: hidden;
  width: 250px;
  background-color: #555;
  color: #fff;
  text-align: left;
  font-size: 14px;
  border-radius: 6px;
  padding: 50%;
  position: absolute;
  z-index: 1;
  pointer-events: none; /* Ensure the tooltip does not intercept mouse clicks */
  bottom: 125%; /* Position the tooltip above the toggle */
  left: 50%;
  margin-left: -15px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.category-toggle-tooltip .info-icon {
  display: inline-block;
  background-color: #555;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  font-style: normal; /* Override italic font style */
  font-weight: bold;
  cursor: pointer;
  margin-left: 10px;
}

.category-toggle:hover .category-toggle-tooltip {
  visibility: visible;
  opacity: 1;
}

.category-toggle2:hover .category-toggle-tooltip {
  visibility: visible;
  opacity: 1;
}

/* The switch - the box around the slider for rollover-toggle */
.rollover-toggle {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-left: 15px;
  vertical-align: middle;
}

/* Adjust default HTML checkbox for rollover-toggle */
.rollover-toggle input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

/* The slider for rollover-toggle */
.rollover-toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 17px;
  z-index: 1;
}

.rollover-toggle-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.rollover-toggle input:checked + .rollover-toggle-slider {
  background-color: #2196f3;
}

.rollover-toggle input:focus + .rollover-toggle-slider {
  box-shadow: 0 0 1px #2196f3;
}

.rollover-toggle input:checked + .rollover-toggle-slider:before {
  transform: translateX(13px);
}

/* Tooltip styles for rollover-toggle */
.rollover-toggle-tooltip {
  visibility: hidden;
  width: 205px;
  background-color: #555;
  color: #fff;
  text-align: left;
  font-size: 14px;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 3; /* Ensure it appears on top */
  bottom: 125%; /* Position the tooltip above the toggle */
  left: 0;
  transform: translateX(-50%); /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.rollover-toggle:hover .rollover-toggle-tooltip {
  visibility: visible;
  opacity: 1;
  box-shadow: 0 2px 5px rgb(0 0 0 / 30%);
}

.category-insights-1 {
  height: 100px;
  grid-area: 3 / 1 / 3 / 2;
  text-align: right;
  font-size: 12px;
}

.category-insights-2 {
  height: 100px;
  grid-area: 3 / 2 / 3 / 3;
  text-align: left;
  font-size: 12px;
}

.category-insights-22 {
  height: 100px;
  grid-area: 3 / 1 / 3 / 3;
  text-align: left;
  margin-left: 15px;
  font-size: 12px;
}

.category-insights-3 {

  grid-area: 3 / 3 / 3 / 4;
  text-align: right;
  font-size: 12px;
}

.category-insights-4 {
  grid-area: 3 / 3 / 3 / 5;
  text-align: right;
  font-size: 12px;
  margin-right: 10px;
}

.bold-currency {
  font-weight: 600;
  font-size: 18px;
}

.rollover-label {
  margin-right: 10px;
  vertical-align: top;
  font-size: 16px;
}

.datemark {
  position: absolute;
  background: repeating-linear-gradient(
    to bottom,
    #e7e7e7,
    #e7e7e7 15px,
    /* Solid color for 5px */ transparent 15px,
    transparent 30px /* Transparent for the next 5px */
  );
  top: 50px;
  width: 2px; /* You can adjust the width of the 'tick' as you see fit */
  height: -webkit-fill-available;
  border-radius: 10px;
  /* box-shadow: 0 2px 5px rgb(255 255 255 / 10%); */
}

.datemark.current-month::after {
  content: attr(data-date);
  position: absolute;
  top: -20px; /* Position it above the line. Adjust as needed. */
  left: 50%; /* Center it horizontally on the line */
  transform: translateX(
    -50%
  ); /* This will ensure it's perfectly centered, irrespective of its width */
  font-size: 12px;
  color: #9f9e9e;
  text-wrap: nowrap;
}

.budgettemplate {
  background-color: unset;
  margin-right: 0;
  margin-top: 0px;
  margin-bottom: 25px;
  padding: 0px 20px;
}

/* Backdrop styles */

/* Modal styles */
.modal-content {
  width: 50%; /* Example width, you can adjust as needed */
  max-width: 500px; /* Maximum width */
  background-color: #fff;
  border-radius: 10px; /* Rounded corners */
  padding: 20px 50px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2); /* Soft shadow */
  position: relative;
}

.title-bold {
  font-weight: bold;
}

.header.hidden {
  display: none;
}

.unbudgeted-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: unset;
  color: #909090;
  border: #e1e1e1;
  border-style: solid;
  border-width: thin;
  border-radius: 3px;
  cursor: pointer;
}
.unbudgeted-button:hover {
  background-color: #fff;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.grey-border {
  border: 1px dashed grey;
  position: relative;
}

.spending-but-no-budget-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: grey;

  padding: 10px;
  font-size: 12px;
  display: none;
}

.grey-border:hover .spending-but-no-budget-text {
  display: block;
}

.forecast {
  padding: 0px 15px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  border: none;
  border-radius: 5px;
  background-color: #f4f4f4;
  font-size: 14px;
  color: #616161;
  margin: 15% 2%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease,
    transform 0.6s ease;
}

.forecast:hover {
  background-color: #444343;
  color: white;
  transform: scale(1.01);
}
.budget-subtitle {
  font-size: 10px;
  font-weight: 400;
  text-align: right;
  margin-right: 10px;
  color: rgb(119, 119, 119);
  margin-top: 15px;
  margin-bottom: 5px;
  visibility: hidden;
}

.budgetgroup:hover .budget-subtitle {
  visibility: visible; /* Show on hover or when expanded */
}

.transaction-table {
  display: table;
  margin-top: 10px;
  float: right;
  border-collapse: collapse;
}

.transaction-row {
  display: table-row;
}

.transaction-cell {
  display: table-cell;
  padding: 3px 0px 3px 15px;
  text-align: right;
}

.transaction-row.header {
  font-weight: bold;
  background-color: #f4f4f4;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

.toggle-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: unset;
  color: #909090;
  border: #e1e1e1;
  border-style: solid;
  border-width: thin;
  border-radius: 3px;
  cursor: pointer;
}
.toggle-button:hover {
  background-color: #fff;
}

.grouped-categories-switch,
.unbudgeted-switch {
  margin: -10px auto;
  display: flex;
  justify-content: right;
}

.headersection {
  display: flex;
}

.budgetinput.editing {
  background-color: #fff;
  border: 1px solid #777;
}

.budget-progress-wrapper {
  grid-area: 1 / 1 / 2 / 5;
  padding: 0px 3% 0;
}

.budget-progress-container {
  width: 100%;
  margin: 20px 0;
}

.budget-progress-bar {
  height: 15px;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.budget-progress-fill {
  height: 100%;
  background-color: #b8d2da;
  transition: width 0.5s ease-in-out;
}

.budget-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}

.spent-label {
  font-weight: bold;
}

.remaining-label {
  text-align: right;
}


</style>
