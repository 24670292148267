<template>
  <div class="debt-to-income">
    <h1>Debt to Income</h1>
  </div>
</template>

<script>
export default {
  name: 'DTI',
  data() {
    return {
      dti: 0, // Initialize the DTI value
    };
  },
};
</script>

<style scoped>

h2 {
  text-align: center;
}
</style>
