import { createStore } from 'vuex';
import { auth, db } from './firebaseInit'; // Import the initialized Firebase auth instance
import { collection, query, getDocs, where, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';

let unsubscribeTransactions = null;
// let syncingTimer = null;


function updateTransactionsInLocalStorage(state) {
  localStorage.setItem('transactions', JSON.stringify(state.transactions));
}

function updateBudgetTotalsInLocalStorage(state) {
  localStorage.setItem('budgetTotals', JSON.stringify(state.budgetTotals));
}

function getBudgetTotalsFromLocalStorage() {
  const storedBudgetTotals = localStorage.getItem('budgetTotals');
  return storedBudgetTotals ? JSON.parse(storedBudgetTotals) : null;
}



const store = createStore({
  state: {
    isAuthenticated: false,
    user: null, 
    transactions: [], 
    rules: [],
    categories: [], 
    rulesFetched: false,
    categoriesFetched: false,
    isFetchingAll: false,
    budgetTotals: {},
    syncingTransactions: false,
    recurringRules: [],
    recurringIncome: [],
    lightMode: false,
    subscription: JSON.parse(localStorage.getItem('subscription')) || null,
  },
  getters: {
    getTransactionsSlice: (state) => (start, end) => {
      return state.transactions.slice(start, end);
    },
    isSubscriptionActive: (state) => {
      return state.subscription && state.subscription.status === 'active';
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      state.isAuthenticated = !!user; // If user exists, isAuthenticated is true. Else, it's false.
    },
    setLightMode(state, mode) {
      state.lightMode = mode;
    },
    setSubscription(state, subscription) {
      console.log('Setting subscription in store:', subscription);
      state.subscription = subscription;
      localStorage.setItem('subscription', JSON.stringify(subscription));
    },
    addTransactions(state, newTransactions) {
      state.transactions = [...state.transactions, ...newTransactions];
    },
    setSyncingTransactions(state, status) {
      state.syncingTransactions = status;
      localStorage.setItem('syncingTransactions', status);
    },
    clearAllData(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.transactions = [];
      state.categories = [];
      state.rules = [];
      state.rulesFetched = false;
      state.categoriesFetched = false;
      state.isFetchingAll = false;
      state.budgetTotals = {};
    },
    setBudgetTotals(state, budgetTotals) {
      state.budgetTotals = budgetTotals;
      updateBudgetTotalsInLocalStorage(state);
    },
    setTransactions(state, transactions) { 
      console.log("Setting transactions in Vuex:", transactions); // Debugging statement
      state.transactions = transactions;
    },
    setAuthenticationStatus(state, status) {
      state.isAuthenticated = status;
    },
    addTransaction(state, newTransaction) {
      state.transactions.push(newTransaction);
      updateTransactionsInLocalStorage(state);
    },
    updateTransaction(state, updatedTransaction) {
      const index = state.transactions.findIndex(t => t.id === updatedTransaction.id);
      if (index !== -1) {
        state.transactions.splice(index, 1, updatedTransaction);
        updateTransactionsInLocalStorage(state);
      }
    },
    deleteTransaction(state, transactionId) {
      state.transactions = state.transactions.filter(t => t.id !== transactionId);
      updateTransactionsInLocalStorage(state);
    },
    setRules(state, rules) {
      state.rules = rules;
    },
    addRule(state, newRule) {
      state.rules.push(newRule);
    },
    deleteRule(state, ruleId) {
      state.rules = state.rules.filter(rule => rule.id !== ruleId);
    },
    setCategories(state, categories) { 
      state.categories = categories;
    },
    setRecurringRules(state, recurringRules) {
      state.recurringRules = recurringRules;
    },
    setRecurringIncome(state, recurringIncome) {
      state.recurringIncome = recurringIncome;
    },
    SET_ALL_TRANSACTIONS_FETCHED(state, value) {
      state.allTransactionsFetched = value;
    },
    SET_RULES_FETCHED(state, value) {
      state.rulesFetched = value;
    },
    SET_CATEGORIES_FETCHED(state, value) {
      state.categoriesFetched = value;
    },
    SET_FETCHING_STATE(state, value) {
      state.isFetchingAll = value;
    },
    UPDATE_RECURRING_RULE(state, rule) {
      const index = state.recurringRules.findIndex(r => r.docId === rule.docId);
      if (index !== -1) {
        state.recurringRules.splice(index, 1, { ...state.recurringRules[index], ...rule });
        localStorage.setItem('recurringRules', JSON.stringify(state.recurringRules));
      }
    },
    UPDATE_RECURRING_INCOME(state, income) {
      const index = state.recurringIncome.findIndex(r => r.docId === income.docId);
      if (index !== -1) {
        state.recurringIncome.splice(index, 1, { ...state.recurringIncome[index], ...income });
        localStorage.setItem('recurringIncome', JSON.stringify(state.recurringIncome));
      }
    },
    ADD_RECURRING_RULE(state, rule) { 
        state.recurringRules.push(rule);
        localStorage.setItem('recurringRules', JSON.stringify(state.recurringRules));
    },
    ADD_RECURRING_INCOME(state, income) { 
      state.recurringIncome.push(income);
      localStorage.setItem('recurringIncome', JSON.stringify(state.recurringIncome));
  },
    REMOVE_RECURRING_RULE(state, ruleId) { 
        state.recurringRules = state.recurringRules.filter(rule => rule.docId !== ruleId);
        localStorage.setItem('recurringRules', JSON.stringify(state.recurringRules));
    },
    REMOVE_RECURRING_INCOME(state, incomeId) { 
      state.recurringIncome = state.recurringIncome.filter(income => income.docId !== incomeId);
      localStorage.setItem('recurringIncome', JSON.stringify(state.recurringIncome));
  },

  },
  actions: {
    // clearUserData({ commit }) {
    //   commit('setUser', null);
    //   commit('setTransactions', []);
    //   commit('setCategories', []);
    //   commit('setRules', []);
    //   // Any other state resets needed
    // },
    clearUserData({ commit }) {
      commit('clearAllData'); // This will reset all the relevant state
      localStorage.removeItem('transactions');
      localStorage.removeItem('budgetTotals');
    },
    toggleLightMode({ commit }, mode) {
      commit('setLightMode', mode);
    },
    updateSubscription({ commit }, subscription) {
      commit('setSubscription', subscription);
    },
    updateSyncingTransactions({ commit }, status) {
      commit('setSyncingTransactions', status);
    },
    initializeSyncingTransactions({ commit }) {
      const savedSyncingTransactions = localStorage.getItem('syncingTransactions');
      if (savedSyncingTransactions !== null) {
        commit('setSyncingTransactions', JSON.parse(savedSyncingTransactions));
      }
    },
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setTransactions({ commit }, transactions) { 
      console.log("Setting transactions in Vuex:", transactions);
      commit('setTransactions', transactions);
    },
    setRules({ commit }, rules) {
      commit('setRules', rules);
    },
    addRule({ commit }, newRule) {
      commit('addRule', newRule);
    },
    deleteRule({ commit }, ruleId) {
      commit('deleteRule', ruleId);
    },
    updateRecurringRule({ commit }, rule) {
      commit('UPDATE_RECURRING_RULE', rule);
    },
    addRecurringRule({ commit }, rule) {
      commit('ADD_RECURRING_RULE', rule);
    },
    removeRecurringRule({ commit }, ruleId) {
        commit('REMOVE_RECURRING_RULE', ruleId);
    },
    async loginUser({ commit, dispatch }, { email, password }) {
      try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        console.log('User ID:', userCredential.user.uid);
        const user = userCredential.user;
        commit('setUser', user);
    
        // Fetch user document from Firestore
        const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
    
        if (userDoc.exists()) {
          const userData = userDoc.data();
          console.log('User data from Firestore:', userData);
          const subscription = userData.subscription || null;
          commit('setSubscription', subscription);
          if (userData.subscription && userData.subscription.status === 'active') {
            // User has an active subscription, proceed with fetching data and redirecting to the spending page
            await dispatch('fetchCategories');
            await dispatch('fetchTransactions');
            await dispatch('fetchRules');
            await dispatch('fetchRecurringRules');
            await dispatch('fetchRecurringIncome');
    
            return { 
              success: true, 
              user,
              subscription,
              redirectTo: subscription && subscription.status === 'active' ? 'Spending' : 'Subscription'
            };
          } else {
            // User doesn't have an active subscription, redirect to the subscription page
            return { success: true, redirectTo: 'Subscription' };
          }
        } else {
          // User document doesn't exist, handle the error or redirect to an appropriate page
          return { success: false, error: 'User document not found' };
        }
      } catch (error) {
        // Handle error
        return { success: false, error: error.message };
      }
    },
    async setupTransactionListener({ commit, state }) {
      if (state.user) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 3);
        const oneYearAgoString = oneYearAgo.toISOString().split('T')[0];
  
        const transactionsCollection = collection(db, `users/${state.user.uid}/transactions`);
        const q = query(transactionsCollection, where("date", ">=", oneYearAgoString));
  
        unsubscribeTransactions = onSnapshot(q, (snapshot) => {
          let allTransactions = [];
          snapshot.forEach(doc => {
            allTransactions.push({ id: doc.id, ...doc.data() });
          });
          console.log("Fetched transactions: ", allTransactions);
          commit('setTransactions', allTransactions);
          localStorage.setItem('transactions', JSON.stringify(allTransactions));
        });
      }
    },
    logoutUser({ commit }) {
        auth.signOut().then(() => {
            // Clear local storage
            localStorage.removeItem('transactions');
            localStorage.removeItem('categories');
            localStorage.removeItem('rules');
            localStorage.removeItem('budgetTotals');
            localStorage.removeItem('recurringRules');
            localStorage.removeItem('recurringIncome');
            localStorage.removeItem('subscription');

            
            // Reset Vuex state
            commit('setUser', null);
            commit('setTransactions', []);
            commit('setCategories', []);
            commit('setRules', []);
            commit('setRecurringRules', []);
            commit('setRecurringIncome', []);
            commit('setBudgetTotals', {});
            commit('SET_CATEGORIES_FETCHED', false);
            commit('SET_ALL_TRANSACTIONS_FETCHED', false);
            commit('SET_RULES_FETCHED', false);

            if (unsubscribeTransactions) {
              unsubscribeTransactions();
            }
    
            // Redirect to login page
            this.$router.push('/login');
        }).catch(error => {
            console.error("Logout failed: ", error);
        });
    },
    async fetchRecurringRules({ commit, state }) {
      if (state.user) {
        commit('setRecurringRules', []); // Clear the existing recurring rules data
        const recurringRulesCollection = collection(db, `users/${state.user.uid}/recurringRules`);
        const snapshot = await getDocs(recurringRulesCollection);
        const recurringRules = snapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));
        commit('setRecurringRules', recurringRules);
        localStorage.setItem('recurringRules', JSON.stringify(recurringRules));
      }
    },
    async fetchRecurringIncome({ commit, state }) {
      if (state.user) {
        commit('setRecurringIncome', []); // Clear the existing recurring income data

        const recurringIncomeCollection = collection(db, `users/${state.user.uid}/recurringIncome`);
        const snapshot = await getDocs(recurringIncomeCollection);
        const recurringIncome = snapshot.docs.map(doc => ({ docId: doc.id, ...doc.data() }));
        commit('setRecurringIncome', recurringIncome);
        localStorage.setItem('recurringIncome', JSON.stringify(recurringIncome));
      }
    },
  
  
    async fetchTransactionsFromTwoYearsAgo({ commit, state }) {
      try {
        if (state.user && !state.isFetchingAll) {
          commit('SET_FETCHING_STATE', true); // Start loading
          const twoYearsAgo = new Date();
          twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);
          const twoYearsAgoString = twoYearsAgo.toISOString().split('T')[0];

          const transactionsCollection = collection(db, `users/${state.user.uid}/transactions`);
          const q = query(transactionsCollection, where("date", ">=", twoYearsAgoString));
          let allTransactions = [];
          const snapshot = await getDocs(q);
          snapshot.forEach(doc => {
            allTransactions.push({ id: doc.id, ...doc.data() });
          });

          // Update Vuex store
          commit('setTransactions', allTransactions); // Commit the mutation
          commit('SET_FETCHING_STATE', false); // Stop loading after fetching
          localStorage.setItem('transactions', JSON.stringify(allTransactions));
        }
      } catch (error) {
        console.error("Error fetching all transactions:", error);
        commit('SET_FETCHING_STATE', false); // Ensure loading is stopped in case of error
      }
    },
    async fetchAllTransactions({ commit, state }) { 
      try {
        if (state.user && !state.isFetchingAll) {
          commit('SET_FETCHING_STATE', true); // Start loading  
          const transactionsCollection = collection(db, `users/${state.user.uid}/transactions`);
          const q = query(transactionsCollection); // Query without date limit
          let allTransactions = [];
          const snapshot = await getDocs(q);
          snapshot.forEach(doc => {
            allTransactions.push({ id: doc.id, ...doc.data() });
          });

          commit('setTransactions', allTransactions); // Commit the mutation
          commit('SET_ALL_TRANSACTIONS_FETCHED', true); // Indicate all transactions are fetched
          localStorage.setItem('allTransactionsFetched', 'true');
          commit('SET_FETCHING_STATE', false); // Stop loading after fetching
          localStorage.setItem('transactions', JSON.stringify(allTransactions));
        }
      } catch (error) {
        console.error("Error fetching all transactions:", error);
        commit('SET_FETCHING_STATE', false); // Ensure loading is stopped in case of error
      }
    },
    async fetchTransactions({ commit, state }) {
      if (state.user) {
        console.log("Fetching transactions..."); // Debugging statement
    
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 3);
        const oneYearAgoString = oneYearAgo.toISOString().split('T')[0];
    
        const transactionsCollection = collection(db, `users/${state.user.uid}/transactions`);
        const q = query(transactionsCollection, where("date", ">=", oneYearAgoString));
    
        try {
          console.log("Fetching transactions with getDocs..."); // Debugging statement
    
          const snapshot = await getDocs(q);
          let allTransactions = [];
          snapshot.forEach(doc => {
            allTransactions.push({ id: doc.id, ...doc.data() });
          });
    
          console.log("Fetched transactions: ", allTransactions); // Debugging statement
          commit('setTransactions', allTransactions);
          localStorage.setItem('transactions', JSON.stringify(allTransactions));
    
          console.log("Setting up onSnapshot listener..."); // Debugging statement
    
          unsubscribeTransactions = onSnapshot(q, (snapshot) => {
            let updatedTransactions = [];
            snapshot.forEach(doc => {
              updatedTransactions.push({ id: doc.id, ...doc.data() });
            });
            console.log("Updated transactions: ", updatedTransactions); // Debugging statement
            commit('setTransactions', updatedTransactions);
            localStorage.setItem('transactions', JSON.stringify(updatedTransactions));
          });
    
          console.log("onSnapshot listener set up"); // Debugging statement
        } catch (error) {
          console.error("Error fetching transactions: ", error); // Debugging statement
        }
      }
    },
    async fetchCategories({ commit, dispatch, state }) {
      if (state.user) {
        const categoriesCollection = collection(db, `users/${state.user.uid}/categories`);
        const snapshot = await getDocs(categoriesCollection);
        const categories = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        commit('setCategories', categories);
        commit('SET_CATEGORIES_FETCHED', true);
        localStorage.setItem('categories', JSON.stringify(categories));
        // Dispatch action to compute budget totals
        dispatch('computeBudgetTotals');
      }
    },
    async fetchRules({ commit, state }) {
      if (state.user) {
        const rulesCollection = collection(db, `users/${state.user.uid}/transactionRules`);
        const snapshot = await getDocs(rulesCollection);
        const rules = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        commit('setRules', rules);
        console.log("Fetched Rules: ", rules); 
        commit('SET_RULES_FETCHED', true);
        localStorage.setItem('rules', JSON.stringify(rules));
      }
    },
    allTransactionsFetched({ commit }) {
      commit('SET_ALL_TRANSACTIONS_FETCHED', true); 
    },
    async recalculateAndFetchBudgetTotals({ dispatch }) {
      await dispatch('fetchCategories'); // Re-fetch categories to get updated budgets
      dispatch('computeBudgetTotals'); // Recompute budget totals
    },
    computeBudgetTotals({ state, commit }) {
      const totalsByYear = {};
      const totalsByMonth = {};
  
      // Check if categories exist and are an array
      if (state.categories && Array.isArray(state.categories)) {
        state.categories.forEach(category => {
          // Check if category.budget exists and is an array
          if (category.budget && Array.isArray(category.budget)) {
            category.budget.forEach(budgetItem => {
              const year = budgetItem.year;
              const month = budgetItem.month;
              const monthKey = `${year}-${month}`;
              const yearKey = `${year}`;
  
              // Compute totals by month
              if (!totalsByMonth[monthKey]) {
                totalsByMonth[monthKey] = { total: 0, categories: {} };
              }
              totalsByMonth[monthKey].total += budgetItem.amount;
              if (!totalsByMonth[monthKey].categories[category.name]) {
                totalsByMonth[monthKey].categories[category.name] = 0;
              }
              totalsByMonth[monthKey].categories[category.name] += budgetItem.amount;
  
              // Compute totals by year
              if (!totalsByYear[yearKey]) {
                totalsByYear[yearKey] = { total: 0, categories: {} };
              }
              totalsByYear[yearKey].total += budgetItem.amount;
              if (!totalsByYear[yearKey].categories[category.name]) {
                totalsByYear[yearKey].categories[category.name] = 0;
              }
              totalsByYear[yearKey].categories[category.name] += budgetItem.amount;
            });
          }
        });
      }
  
      // Console log the totals
      // console.log("Budget Totals by Year:", totalsByYear);
      // console.log("Budget Totals by Month:", totalsByMonth);
  
      commit('setBudgetTotals', totalsByMonth); // Assuming you want to store monthly totals
    },
  
  },
});

// Listen for auth state changes
auth.onAuthStateChanged(async (user) => {
  store.dispatch('setUser', user);
  if (user) {
    const savedTransactions = localStorage.getItem('transactions');
    const savedCategories = localStorage.getItem('categories');
    const savedRules = localStorage.getItem('rules');
    const savedBudgetTotals = getBudgetTotalsFromLocalStorage();
    const savedRecurringRules = localStorage.getItem('recurringRules');
    const savedRecurringIncome = localStorage.getItem('recurringIncome');
    await store.dispatch('setupTransactionListener'); // Set up the transaction listener



    if (savedTransactions) {
      store.commit('setTransactions', JSON.parse(savedTransactions));
    } else if (store.state.transactions.length === 0) {
      await store.dispatch('fetchTransactions');
    }

    if (savedCategories) {
      store.commit('setCategories', JSON.parse(savedCategories));
    } else {
      await store.dispatch('fetchCategories');
    }

    if (savedRules) {
      store.commit('setRules', JSON.parse(savedRules));
    } else {
      await store.dispatch('fetchRules');
    }

    if (savedRecurringRules) {
      store.commit('setRecurringRules', JSON.parse(savedRecurringRules));
    } else {
      await store.dispatch('fetchRecurringRules');
    }

    if (savedRecurringIncome) {
      store.commit('setRecurringIncome', JSON.parse(savedRecurringIncome));
    } else {
      await store.dispatch('fetchRecurringIncome');
    }

    // Check if subscription is already in the store
    if (!store.state.subscription) {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const subscription = userData.subscription || null;
        store.commit('setSubscription', subscription);
      }
    }

    if (savedBudgetTotals) {
      store.commit('setBudgetTotals', savedBudgetTotals);
    } else {
      // Fetch budget data if not available in local storage
      await store.dispatch('fetchCategories'); // Assuming fetchCategories computes budget totals
    }
  }
});

window.store = store;

export default store;
