<template>
  <div class="Annual-Summary">
    <h1 class="annual-summary-title">Annual Summary</h1>
    <div class="annual-summary-table">
      <table class="summarytable">
        <thead class="summaryhead">
          <tr>
            <th></th>
            <th v-for="(item, index) in headerItems" :key="index" @click="item.includes('-') ? toggleMonths(item.split('-')[1]) : toggleMonths(item)" :class="{'month-view': item.includes('-'), 'year-header': !item.includes('-')}">
              {{ item.includes('-') ? item.split('-')[1] : item }} <!-- Extract month name for month columns -->
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- Income Section -->
          <tr>
            <td class="section-row" >Income</td>
            <td v-for="item in headerItems" :key="item + 'income'" :class="{'month-column': item.includes('-')}"></td>
            
          </tr>
          <tr>
            <td>Total Income</td>
            <td v-for="item in headerItems" :key="item + 'income'" :class="{'month-column': item.includes('-')}">
              <template v-if="item.includes('-')"> <!-- Month-Year format -->
                {{ totalIncomeByMonth()[item] ? formatMoney(totalIncomeByMonth()[item]) : '' }}
              </template>
              <template v-else> <!-- Year format -->
                {{ formatMoney(totalIncomeByYear()[item]) || '' }}
              </template>
            </td>
          </tr>
          <tr>
            <td>Expected Earnings</td>
            <td v-for="item in headerItems" :key="item + 'expectedEarnings'" :class="{'month-column': item.includes('-')}">
              <template v-if="new Date().getFullYear().toString() === item">
                {{ calculateExpectedEarnings() !== null ? formatMoney(calculateExpectedEarnings()) : '' }}
              </template>
              <template v-else>
                {{ formatMoney(totalIncomeByYear()[item]) || '' }}
              </template>
            </td>
          </tr>
          <tr>
            <td>Change</td>
            <td v-for="item in headerItems" :key="item + 'change'" :class="{'month-column': item.includes('-')}">
              <template v-if="item.includes('-')"> <!-- Month-Year format, MoM Change -->
                <span :style="calculateMoMChange()[item] >= 0 ? 'color: green;' : 'color: red;'">
                  {{ calculateMoMChange()[item] ? formatPercentage(calculateMoMChange()[item], true) : '' }}
                </span>
              </template>
              <template v-else> <!-- Year format, YoY Change -->
                <span :style="calculateYoYChange()[item] >= 0 ? 'color: green;' : 'color: red;'">
                  {{ calculateYoYChange()[item] ? formatPercentage(calculateYoYChange()[item], true) : '' }}
                </span>
              </template>
            </td>
          </tr>

          <tr>
            <td></td>
            <td v-for="item in headerItems" :key="item + 'income'" :class="{'month-column': item.includes('-')}"></td>
          </tr>

          <!-- Spending Section -->
          <tr>
            <td class="section-row">Spending</td>
            <td v-for="item in headerItems" :key="item + 'spend'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Total Spend</td>
            <td v-for="item in headerItems" :key="item + 'spending'" :class="{'month-column': item.includes('-')}">
              <template v-if="item.includes('-')"> <!-- Month-Year format -->
                {{ formatMoney(totalSpendByMonth()[item]) || '' }}
              </template>
              <template v-else> <!-- Year format -->
                {{ formatMoney(totalSpendByYear()[item]) || '' }}
              </template>
            </td>
          </tr>
          <!-- <tr>
            <td>Expected Spend</td>
            <td v-for="item in headerItems" :key="item + 'expectedSpend'">
            </td>
          </tr> -->
          <tr>
            <td>Change</td>
            <td v-for="item in headerItems" :key="item + 'spendingChange'" :class="{'month-column': item.includes('-')}">
              <template v-if="item.includes('-')"> <!-- Month-Year format, MoM Change -->
                <span :style="calculateMoMSpendingChange()[item] >= 0 ? 'color: green;' : 'color: red;'">
                  {{ calculateMoMSpendingChange()[item] ? formatPercentage(calculateMoMSpendingChange()[item], true) : '' }}
                </span>
              </template>
              <template v-else> <!-- Year format, YoY Change -->
                <span :style="calculateYoYSpendingChange()[item] >= 0 ? 'color: green;' : 'color: red;'">
                  {{ calculateYoYSpendingChange()[item] ? formatPercentage(calculateYoYSpendingChange()[item], true) : '' }}
                </span>
              </template>
            </td>
          </tr>

          <tr>
            <td></td>
            <td v-for="item in headerItems" :key="item + 'spending'" :class="{'month-column': item.includes('-')}"></td>
          </tr>


          <!-- Budget Section -->
          <tr>
            <td class="section-row" >Budget</td>
            <td v-for="item in headerItems" :key="item + 'budget'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Total Budget</td>
            <td v-for="item in headerItems" :key="item + 'budget'" :class="{'month-column': item.includes('-')}">
              {{ item.includes('-') ? formatMoney(displayBudgetTotalForItem(item)) : formatMoney(getYearlyBudgetTotal(item)) }}
            </td>
          </tr>
          <!-- <tr>
            <td>YoY Budget Change</td>
            <td v-for="item in headerItems" :key="item + 'budgetChange'" :class="{'month-column': item.includes('-')}">

              <template v-if="item.includes('-')">
                {{ calculateMoMBudgetChange()[item] ? formatPercentage(calculateMoMBudgetChange()[item]) : '' }}
              </template>

              <template v-else>
                {{ calculateYoYBudgetChange()[item] ? formatPercentage(calculateYoYBudgetChange()[item]) : '' }}
              </template>
            </td>
          </tr> -->
          <tr>
            <td>Over/Under Budget</td>
            <td v-for="item in headerItems" :key="item + 'overUnderBudget'" :class="{'month-column': item.includes('-')}">
              <span :style="calculateOverUnderBudget()[item] < 0 ? 'color: #00c200;' : 'color: red;'">
                {{ formatMoney(Math.abs(calculateOverUnderBudget()[item])) }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Spending % of Budget</td>
            <td v-for="item in headerItems" :key="item + 'spendingPercentage'" :class="{'month-column': item.includes('-')}">
              <span :style="calculateSpendingPercentage()[item] <= 100 ? 'color: #00c200;' : 'color: red;'">
                {{ calculateSpendingPercentage()[item] ? formatPercentage(calculateSpendingPercentage()[item]) : '' }}
              </span>
            </td>
          </tr>


          <tr>
            <td></td>
            <td v-for="item in headerItems" :key="item + 'budget'" :class="{'month-column': item.includes('-')}"></td>
          </tr>

          <!-- Savings Section -->
          <!-- 
          <tr>
            <td class="section-row" >Savings</td>
            <td v-for="item in headerItems" :key="item + 'income'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Total Saved</td>
            <td v-for="item in headerItems" :key="item + 'savings'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Expected Savings</td>
            <td v-for="item in headerItems" :key="item + 'savings'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>YoY Change</td>
            <td v-for="item in headerItems" :key="item + 'savings'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Savings Rate</td>
            <td v-for="item in headerItems" :key="item + 'savings'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Savings Percentage of Spend</td>
            <td v-for="item in headerItems" :key="item + 'savings'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td></td>
            <td v-for="item in headerItems" :key="item + 'savings'" :class="{'month-column': item.includes('-')}"></td>
          </tr> 
          -->

          

          <!-- Net Worth Section -->
          <!-- <tr>
            <td class="section-row" >Net Worth</td>
            <td v-for="item in headerItems" :key="item + 'networth'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Growth in Networth</td>
            <td v-for="item in headerItems" :key="item + 'networth'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td>Change</td>
            <td v-for="item in headerItems" :key="item + 'networth'" :class="{'month-column': item.includes('-')}"></td>
          </tr>
          <tr>
            <td></td>
            <td v-for="item in headerItems" :key="item + 'networth'" :class="{'month-column': item.includes('-')}"></td>
          </tr> -->


          <!-- Milestones / Highlights Section -->
          <tr>
            <td class="section-row">Milestones / Highlights</td>
            <td v-for="item in headerItems" :key="item + 'income'" :class="{'month-column': item.includes('-')}">
              <input v-if="!item.includes('-')" 
                    class="event-input" 
                    type="text" 
                    placeholder="Add Highlight/Event"
                    v-model="eventData[item]"
                    @keyup.enter="saveEvent(item, eventData[item])">
            </td>
          </tr>
          <tr v-for="n in maxEvents" :key="n">
            <td v-if="n === 1">Events</td>
            <td v-else></td>
            <td v-for="item in headerItems" :key="`event-${item}-${n}`" :class="{'month-column': item.includes('-')}">
              <div v-if="fetchedEvents[item] && fetchedEvents[item][n - 1]">
                <div v-if="editingEvent[item] !== n-1">
                  <!-- Display mode -->
                  <span @click="startEditing(item, n-1)">
                    {{ fetchedEvents[item][n - 1].text }}
                  </span>
                </div>
                <div v-else>
                  <!-- Edit mode -->
                  <input type="text"
                        :value="fetchedEvents[item][n - 1].text"
                        @blur="saveEditedEvent(item, n-1, $event.target.value)"
                        @keyup.enter="saveEditedEvent(item, n-1, $event.target.value)"
                        autofocus>
                </div>
              </div>
            </td>
          </tr>

        </tbody>
      </table>

  <div style="display: flex; justify-content: center; align-items: center; margin: 30px;">
    <v-btn-toggle style="height:30px;" v-model="annualChartType" mandatory>
      <v-btn style="background-color: #f6f6f6;" value="line">Line</v-btn>
      <v-btn style="background-color: #f6f6f6;" value="bar">Bar</v-btn>
    </v-btn-toggle>
  </div>
  <div style="display: flex; justify-content: space-evenly; height: 30vh; margin-bottom: 100px">
    <canvas style="max-width: 40vw;" ref="chartAnnualSpendingCanvas" class="linechart-canvas"></canvas>
    <canvas style="max-width: 40vw;" ref="chartAnnualIncomeCanvas" class="linechart-canvas"></canvas>
  </div>

  <div>
    <div style="display: flex; justify-content: center; align-items: center;  margin-bottom: 20px;">
      <v-btn-toggle style="height:30px;" v-model="selectedYear" mandatory>
        <v-btn style="background-color: #f6f6f6;" v-for="year in years" :key="year" :value="year">{{ year }}</v-btn>
      </v-btn-toggle>
    </div>

    <div style="display: flex; justify-content: center; align-items: center; margin-bottom: 20px;">
      <v-btn-toggle style="height:30px;" v-model="chartType" mandatory>
        <v-btn style="background-color: #f6f6f6;" value="line">Line</v-btn>
        <v-btn  style="background-color: #f6f6f6;" value="bar">Bar</v-btn>
      </v-btn-toggle>
    </div>
  </div>
  <div style="display: flex; justify-content: space-evenly; height: 30vh">
    <canvas style="max-width: 40vw;" ref="incomeVsSpendChart" class="linechart-canvas"></canvas>
    <canvas style="max-width: 40vw" ref="cumulativeChart" class="linechart-canvas"></canvas>
    
  </div>
</div>
</div>
 
</template>

<script>
/* eslint-disable */
import { collection, getDocs, getDoc, limit, setDoc, addDoc, writeBatch, query, orderBy, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';
import { Chart } from 'chart.js/auto';
import 'chartjs-plugin-datalabels';

export default {
  name: 'AnnualSummary',

  data() {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth(); // Add this line to get the current month
    const years = [currentYear, currentYear - 1, currentYear - 2]; // Modify as needed for the number of years

    return {
      years: years,
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      expandedYear: null,
      headerItems: years.map(year => year.toString()), // Convert years to strings
      eventData: {},
      editingEvent: {},
      currentMonth: currentMonth,
      fetchedEvents: {},
      budgetTotals: {},
      additionalIncomes: [],
      chart: null,
      categories: {},
      selectedYear: new Date().getFullYear(), // Set the default year to the current year
      chartType: 'bar', // default chart type for incomve vs spending chart
      annualChartType: 'line',
    };
  },
  async mounted() {
    await this.fetchEvents();
    await this.fetchCategories();
    await this.fetchAdditionalIncomes();
    this.renderAnnualSpendingChart()
    this.renderAnnualIncomeChart()
    this.renderIncomeVsSpendChart();
    this.renderCumulativeChart();


    // await this.fetchBudgets(); // Uncomment if fetchBudgets is used and should be awaited.

    const today = new Date();

    const totals = {
      totalAnnualSpend: this.totalSpendByYear(), // Keep year as key
      totalSpendByMonth: this.totalSpendByMonth(),
      totalAnnualIncome: this.totalIncomeByYear(), // Keep year as key
      totalIncomeByMonth: this.totalIncomeByMonth(),
      yoyChange: this.calculateYoYChange(),
      momChange: this.calculateMoMChange(),
      yoySpendingChange: this.calculateYoYSpendingChange(),
      momSpendingChange: this.calculateMoMSpendingChange(),
      dateSaved: today
    };

      await this.saveTotals(totals);
  },
  watch: {
    fetchedEvents: {
      handler(newEvents, oldEvents) {
        // Code to handle the update
        console.log("Events updated", newEvents);
        // You can put logic here if needed to react to the change in events
      },
      deep: true // This ensures the watcher is triggered even for nested data changes
    },
    selectedYear() {
      this.renderIncomeVsSpendChart();
      this.renderCumulativeChart();
    },
    chartType() {
      this.renderIncomeVsSpendChart();
      this.renderCumulativeChart();
    },
    annualChartType() {
      this.renderAnnualIncomeChart();
      this.renderAnnualSpendingChart();
    }
  },
  computed: {
    budgetData() {
    const data = this.$store.state.budgetTotals;
    console.log('Computed Budget Data:', data);
    return data;
  },
  
  budgetTotals() {
    return this.$store.state.budgetTotals;
  },
    getMonthlyBudgetTotal() {
      return (year, month) => {
        const budgetData = this.$store.state.budgetTotals[year];
        return budgetData ? (budgetData.categories[month] || 0) : 0;
      };
    },
    totalColumns() {
      // Calculate the total number of columns
      return this.headerItems.length + 1; // +1 for the 'Category/Year' column
    },
    getYearlyBudgetTotal() {
      return (year) => {
        let yearlyTotal = 0;
        Object.entries(this.$store.state.budgetTotals).forEach(([key, value]) => {
          if (key.startsWith(year)) {
            yearlyTotal += value.total;
          }
        });
        return yearlyTotal;
      };
    },
    getBudgetTotal(year, month) {
      if (this.budgetTotals[year] && typeof this.budgetTotals[year][month] === 'number') {
        return this.budgetTotals[year][month];
      }
      return 0;
    },
    incomeTransactions() {
      console.log('Categories:', this.categories); // Debugging: Check the categories content

      // Ensure that categories is loaded and filter transactions accordingly
      return this.$store.state.transactions.filter(transaction => {
        const categoryGroup = this.categories?.[transaction.category]?.toLowerCase();
        // console.log(`Transaction category: ${transaction.category}, Group: ${categoryGroup}`); // Debugging
        return transaction.amount < 0 && 
              categoryGroup === 'income' && 
              !transaction.labels?.includes("ignore");
      });
    },
    spendingTransactions() {
      return this.$store.state.transactions.filter(transaction => 
        transaction.amount > 0 && 
        transaction.category !== "Credit Card Payment" &&
        !transaction.labels?.includes("ignore")
      );
    },
    maxEvents() {
      const eventCounts = Object.values(this.fetchedEvents).map(events => events.length);
      return Math.max(0, ...eventCounts);
    }
  },
  methods: {
    async fetchCategories() {
      const categoriesRef = collection(db, `users/${auth.currentUser.uid}/categories`);
      const snapshot = await getDocs(categoriesRef);
      this.categories = {}; // Initialize categories to ensure it's not undefined.
      snapshot.forEach(doc => {
        this.categories[doc.id] = doc.data().group.toLowerCase();
      });
      console.log('Fetched Categories:', this.categories); // Debugging
    },
    updateChartType() {
      this.renderAnnualSpendingChart(); // Assuming you have methods to render charts
      this.renderAnnualIncomeChart();
    },
    getIncomeVsSpendChartData() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const incomeData = Array(12).fill(0);
      const spendData = Array(12).fill(0);

      this.incomeTransactions.forEach(transaction => {
        const date = new Date(transaction.date);
        if (date.getFullYear() === this.selectedYear) {
          const monthIndex = date.getMonth();
          incomeData[monthIndex] += Math.abs(transaction.amount);
        }
      });

      this.spendingTransactions.forEach(transaction => {
        const date = new Date(transaction.date);
        if (date.getFullYear() === this.selectedYear) {
          const monthIndex = date.getMonth();
          spendData[monthIndex] += transaction.amount;
        }
      });
      return {
        labels: months,
        datasets: [
          {
            label: 'Income',
            data: incomeData,
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return;
              }
              const gradientBackground = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
              gradientBackground.addColorStop(0, 'rgba(0, 128, 0, 0.6)');
              gradientBackground.addColorStop(1, 'rgba(0, 255, 0, 0.2)');
              return gradientBackground;
            },
            borderColor: 'rgba(0, 128, 0, 1)',
            fill: true,
            tension: 0.4,
          },
          {
            label: 'Spend',
            data: spendData,
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return;
              }
              const gradientBackground = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
              gradientBackground.addColorStop(0, 'rgba(135, 206, 250, 0.8)'); // Darker blue at the top
              gradientBackground.addColorStop(1, 'rgba(135, 206, 250, 0.1)'); // Transparent at the bottom
              return gradientBackground;
            },
            borderColor: 'rgba(135, 206, 250, 1)',
            fill: true,
            tension: 0.4,
          },
        ],
      };
    },
    renderIncomeVsSpendChart() {
      const ctx = this.$refs.incomeVsSpendChart.getContext('2d');
      if (this.incomeVsSpendChart) {
        this.incomeVsSpendChart.destroy();
      }
      this.incomeVsSpendChart = new Chart(ctx, {
        type: this.chartType,
        data: this.getIncomeVsSpendChartData(),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
            hoverRadius: 5,
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
                drawOnChartArea: false,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
                drawOnChartArea: false,
              },
              ticks: {
                callback: function (value) {
                  return '$' + value.toLocaleString();
                },
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: true,
              text: 'Income Vs Spend',
              position: 'bottom',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            datalabels: {
              color: '#000000',
              display: false,
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
        },
      });
    },
    renderAnnualSpendingChart() {
      const spendingCtx = this.$refs.chartAnnualSpendingCanvas.getContext('2d');
      if (this.spendingChart) {
        this.spendingChart.destroy();
      }
      this.spendingChart = new Chart(spendingCtx, {
        type: this.annualChartType,
        data: this.getChartData(), // Use the getChartData method
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false,
              hoverRadius: 5,
          },
          scales: {
              x: {
                  display: true,
                  grid: {
                      display: false,
                      drawOnChartArea: false,
                  },
              },
              y: {
                  display: false,
                  grid: {
                      display: false,
                      drawOnChartArea: false,
                  },
                  ticks: {
                      callback: function(value) {
                          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
                      }
                  },
              },
          },
          plugins: {
            legend: {
              position: 'bottom'
            },
            title: {
              display: true,
              text: 'Annual Spend',
              position: 'bottom',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            datalabels: {
                color: '#000000',
                display: false,
            },
          },
          hover: {
            mode: 'index',  // Affect the nearest item on the x-axis
            intersect: false
          },
        },
      });
    },
    renderAnnualIncomeChart() {
      const incomeCtx = this.$refs.chartAnnualIncomeCanvas.getContext('2d');
      if (this.incomeChart) {
        this.incomeChart.destroy();
      }
      this.incomeChart = new Chart(incomeCtx, {
        type: this.annualChartType,
        data: this.getIncomeChartData(), // Use the getChartData method
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false,
              hoverRadius: 5,
          },
          scales: {
              x: {
                  display: true,
                  grid: {
                      display: false,
                      drawOnChartArea: false,
                  },
              },
              y: {
                  display: false,
                  grid: {
                      display: false,
                      drawOnChartArea: false,
                  },
                  ticks: {
                      callback: function(value) {
                          return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 }).format(value);
                      }
                  },
              },
          },
          plugins: {
            legend: {
              position: 'bottom'
            },
            title: {
              display: true,
              text: 'Annual Income',
              position: 'bottom',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            datalabels: {
                color: '#000000',
                display: false,
            },
          },
          hover: {
            mode: 'index',  // Affect the nearest item on the x-axis
            intersect: false
          },
        },
      });
    },

    getChartData() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


      let dataByYear = {};
      this.spendingTransactions.forEach((transaction) => {
        const date = new Date(transaction.date);
        const year = date.getFullYear();
        const month = date.getMonth(); // 0 for January, 11 for December

        if (!dataByYear[year]) {
          dataByYear[year] = Array(12).fill(0); // 12 months
        }

        dataByYear[year][month] += transaction.amount;
      });

      const years = Object.keys(dataByYear).sort();
      const baseColor = { r: 135, g: 206, b: 250 }; // Light blue color

      const getColor = (index, total) => {
        const fraction = index / total;
        const darkenFactor = fraction * 0.6;
        return `rgba(${baseColor.r * (1 - darkenFactor)}, ${baseColor.g * (1 - darkenFactor)}, ${baseColor.b * (1 - darkenFactor)}, 1)`;
      };

      const datasets = years.map((year, index) => ({
        label: year,
        data: dataByYear[year],
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradientFill.addColorStop(0, getColor(index, years.length).replace(', 1)', ', 0)')); // Transparent at bottom
          gradientFill.addColorStop(1, getColor(index, years.length)); // Opaque at top
          return gradientFill;
        },
        borderColor: getColor(index, years.length -5),
        tension: 0.4,
        spanGaps: true,
        datalabels: {
          align: 'end',
          anchor: 'end',
        },
        pointStyle: 'circle',
        radius: 0, // Adjust as needed for visibility
        pointHoverRadius: 5,
        pointHoverBackgroundColor: getColor(index, years.length -5),
        pointHoverBorderWidth: 2,
      }));

      return {
        labels: months,
        datasets: datasets,
      };
    },
    getIncomeChartData() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      // Use totalIncomeByMonth to gather data, which includes additional income sources
      let dataByYear = {};
      let monthlyIncomes = this.totalIncomeByMonth();

      Object.keys(monthlyIncomes).forEach((yearMonth) => {
        const [year, month] = yearMonth.split('-');
        const monthIndex = months.indexOf(month);
        if (!dataByYear[year]) {
          dataByYear[year] = Array(12).fill(0); // Initialize 12 months
        }
        dataByYear[year][monthIndex] = monthlyIncomes[yearMonth];
      });

      const years = Object.keys(dataByYear).sort();
      const baseColor = { r: 135, g: 206, b: 250 }; // Light blue color

      const getColor = (index, total) => {
        const fraction = index / total;
        const darkenFactor = fraction * 0.6;
        return `rgba(${baseColor.r * (1 - darkenFactor)}, ${baseColor.g * (1 - darkenFactor)}, ${baseColor.b * (1 - darkenFactor)}, 1)`;
      };

      const datasets = years.map((year, index) => ({
        label: year,
        data: dataByYear[year],
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          const gradientFill = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradientFill.addColorStop(0, getColor(index, years.length).replace(', 1)', ', 0)')); // Transparent at bottom
          gradientFill.addColorStop(1, getColor(index, years.length)); // Opaque at top
          return gradientFill;
        },
        borderColor: getColor(index, years.length -5),
        tension: 0.4,
        spanGaps: true,
        datalabels: {
          align: 'end',
          anchor: 'end',
        },
        pointStyle: 'circle',
        radius: 0, // Adjust as needed for visibility
        pointHoverRadius: 5,
        pointHoverBackgroundColor: getColor(index, years.length -5),
        pointHoverBorderWidth: 2,
      }));

      return {
        labels: months,
        datasets: datasets,
      };
    },

    // Helper method to generate random colors for the chart lines
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    async fetchAdditionalIncomes() {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      const incomeDocRef = doc(db, `users/${user.uid}/totals/income`);
      const docSnap = await getDoc(incomeDocRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        // Assuming the document structure is { Business: [], Investments: [] }
        this.additionalIncomes = Object.values(data).flat();
        console.log(this.additionalIncomes);
      } else {
        console.log("No additional income found");
      }
    },

    async getLastSavedMonth() {
      const user = auth.currentUser;
      const summaryDocRef = doc(db, `users/${user.uid}/totals/summary`);
      const docSnap = await getDoc(summaryDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        return data.dateSaved.toDate(); // Assuming 'dateSaved' is stored as a Timestamp
      }
      return null;
    },

    async saveTotals(totals) {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      try {
        const summaryDocRef = doc(db, `users/${user.uid}/totals/summary`);
        await setDoc(summaryDocRef, totals, { merge: true });
        console.log("Totals saved successfully to summary");
      } catch (error) {
        console.error("Error saving totals to summary: ", error);
      }
    },
    calculateOverUnderBudget() {
      const overUnderBudget = {};

      this.headerItems.forEach(item => {
        let budget, spending;
        if (item.includes('-')) { // Month-Year format
          const [year, month] = item.split('-');
          const monthIndex = this.months.indexOf(month);
          budget = this.getMonthlyBudgetTotal(year, monthIndex);
          spending = this.totalSpendByMonth()[item] || 0;
        } else { // Year format
          budget = this.getYearlyBudgetTotal(item);
          spending = this.totalSpendByYear()[item] || 0;
        }

        overUnderBudget[item] = spending - budget;
      });

      return overUnderBudget;
    },

    calculateSpendingPercentage() {
      const spendingPercentage = {};

      this.headerItems.forEach(item => {
        let budget, spending;
        if (item.includes('-')) { // Month-Year format
          const [year, month] = item.split('-');
          const monthIndex = this.months.indexOf(month);
          budget = this.getMonthlyBudgetTotal(year, monthIndex);
          spending = this.totalSpendByMonth()[item] || 0;
        } else { // Year format
          budget = this.getYearlyBudgetTotal(item);
          spending = this.totalSpendByYear()[item] || 0;
        }

        if (budget !== 0) {
          spendingPercentage[item] = (spending / budget) * 100;
        } else {
          spendingPercentage[item] = ""; // Handle division by zero or no budget set
        }
      });

      return spendingPercentage;
    },
    calculateMoMBudgetChange() {
      const momBudgetChange = {};
      let previousMonthBudget = null;
      let currentYear = this.years[0]; // Start with the most recent year

      this.headerItems.forEach(item => {
        if (!item.includes('-')) {
          // Update the current year when a new year column is encountered
          currentYear = parseInt(item);
          return;
        }

        const month = item.split('-')[1];
        const monthIndex = this.months.indexOf(month);
        const currentMonthBudget = this.getMonthlyBudgetTotal(currentYear, monthIndex);

        // Create a key in the format 'YYYY-Mon' for indexing
        const yearMonthKey = `${currentYear}-${month}`;

        // Handle the year transition from December to January
        if (month === 'Jan' && previousMonthBudget !== null) {
          momBudgetChange[yearMonthKey] = ((currentMonthBudget - previousMonthBudget) / previousMonthBudget) * 100;
        } else if (previousMonthBudget !== null && previousMonthBudget !== 0) {
          momBudgetChange[yearMonthKey] = ((currentMonthBudget - previousMonthBudget) / previousMonthBudget) * 100;
        } else if (previousMonthBudget === 0) {
          momBudgetChange[yearMonthKey] = "";
        }

        previousMonthBudget = currentMonthBudget;
      });

      return momBudgetChange;
    },
    calculateYoYBudgetChange() {
      const yoyBudgetChange = {};
      let previousYearBudget = null;

      // Create a copy of the years array in reverse order
      const reversedYears = [...this.years].reverse();

      for (const year of reversedYears) {
        const currentYearBudget = this.getYearlyBudgetTotal(year);
        if (previousYearBudget === 0) {
          // Handle the case where the previous year's budget is zero
          yoyBudgetChange[year] = ""; // Or use null or any other indicator
        } else if (previousYearBudget !== null) {
          yoyBudgetChange[year] = ((currentYearBudget - previousYearBudget) / previousYearBudget) * 100;
        }
        previousYearBudget = currentYearBudget;
      }
      return yoyBudgetChange;
    },
    getBudgetTotal(year, month) {
      if (this.budgetTotals[year] && typeof this.budgetTotals[year][month] === 'number') {
        return this.budgetTotals[year][month];
      }
      return 0;
    },


    displayBudgetTotalForItem(item) {
      if (!item.includes('-')) return '';
      const [year, monthName] = item.split('-');
      const monthIndex = this.months.indexOf(monthName);
      const budgetData = this.$store.state.budgetTotals[`${year}-${monthIndex}`];
      return budgetData ? budgetData.total : 0;
    },

    processBudgets(categoryBudgets) {
      categoryBudgets.forEach(budget => {
        const year = budget.year;
        const month = budget.month;
        if (!this.budgetTotals[year]) {
        this.budgetTotals[year] = Array(12).fill(0);
      }
      this.budgetTotals[year][month] += budget.amount;
    });
    },
    startEditing(item, index) {
      this.editingEvent[item] = index;
    },
    async saveEditedEvent(item, index, newText) {
      const eventId = this.fetchedEvents[item][index].id;
      if (newText.trim()) {
        this.updateEvent(eventId, newText.trim());
      } else {
        this.deleteEvent(eventId);
      }
      this.editingEvent = {}; // Clear editing state
      await this.fetchEvents();
    },
    async updateEvent(eventId, newText) {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      try {
        const eventDocRef = doc(db, `users/${user.uid}/events`, eventId);
        await updateDoc(eventDocRef, { eventText: newText });
        console.log("Event updated successfully");
      } catch (error) {
        console.error("Error updating event: ", error);
      }
    },
    async deleteEvent(eventId) {
      try {
        const eventDocRef = doc(db, `users/${this.userId}/events`, eventId);
        await deleteDoc(eventDocRef);
        await this.fetchEvents(); // Refresh events
      } catch (error) {
        console.error("Error deleting event: ", error);
      }
    },
    async fetchEvents() {
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      try {
        const querySnapshot = await getDocs(collection(db, `users/${user.uid}/events`));
        querySnapshot.forEach(doc => {
          const eventData = doc.data();
          const key = eventData.month ? `${eventData.year}-${eventData.month}` : eventData.year;

          if (!this.fetchedEvents[key]) {
            this.fetchedEvents[key] = [];
          }
          this.fetchedEvents[key].push({ id: doc.id, text: eventData.eventText });
        });
      } catch (error) {
        console.error("Error fetching events: ", error);
      }
    },
    async saveEvent(key, value) {
      // Prevent saving empty events
      if (!value.trim()) {
        console.error("Cannot save an empty event");
        // Optionally, show an error message to the user
        return;
      }

      // Extract year and month from the key
      let [year, month] = key.includes('-') ? key.split('-') : [key, null];

      // Get current user from Firebase Authentication
      const user = auth.currentUser;
      if (!user) {
        console.error("No user logged in");
        return;
      }

      // Prepare the event object
      const eventObj = {
        year,
        month,
        eventText: value.trim(),
        userId: user.uid
      };

      try {
        // Reference to the Firestore collection
        const eventsCollectionRef = collection(db, `users/${user.uid}/events`);

        // Add the event to Firestore
        const docRef = await addDoc(eventsCollectionRef, eventObj);
        console.log("Event saved with ID: ", docRef.id);

        // Clear the input field
        this.$set(this.eventData, key, "");

        // Refetch events to update the list with the newly added event
        await this.fetchEvents();
      } catch (error) {
        console.error("Error adding event: ", error);
      }
      await this.fetchEvents();
    },

    calculateMoMSpendingChange() {
      const monthlySpend = this.totalSpendByMonth();
      let previousMonthSpend = null;
      const momChange = {};

      Object.keys(monthlySpend).sort().forEach(yearMonth => {
        if (previousMonthSpend !== null) {
          momChange[yearMonth] = ((monthlySpend[yearMonth] - previousMonthSpend) / previousMonthSpend) * 100;
        }
        previousMonthSpend = monthlySpend[yearMonth];
      });
      return momChange;
    },
    totalSpendByYear() {
      const totals = this.spendingTransactions.reduce((acc, transaction) => {
        const year = new Date(transaction.date).getFullYear();
        if (!acc[year]) acc[year] = 0;
        acc[year] += transaction.amount;
        return acc;
      }, {});
      return totals;
    },

    totalSpendByMonth() {
      const monthMapping = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let monthlyTotals = {};

      this.spendingTransactions.forEach(transaction => {
        const date = new Date(transaction.date + 'T00:00:00'); // Append 'T00:00:00' to ensure it's treated as local time
        const yearMonth = `${date.getFullYear()}-${monthMapping[date.getMonth()]}`;
        if (!monthlyTotals[yearMonth]) monthlyTotals[yearMonth] = 0;
        monthlyTotals[yearMonth] += transaction.amount;
      });

      return monthlyTotals;
    },
    formatMoney(amount) {
      if (typeof amount === 'number' && amount != null) {
        let formattedAmount = Math.abs(amount).toFixed(0).replace(/\d(?=(\d{3})+\b)/g, '$&,');
        return (amount < 0 ? `-$${formattedAmount}` : `$${formattedAmount}`);
      } else {
        return ''; // Return an empty string for invalid amount
      }
    },

    formatPercentage(percentage, showSign = false) {
      const sign = showSign && percentage > 0 ? '+' : '';
      return `${sign}${Math.round(percentage)}%`;
    },
    totalIncomeByYear() {
      let totals = this.incomeTransactions.reduce((acc, transaction) => {
        const year = new Date(transaction.date).getFullYear();
        if (!acc[year]) acc[year] = 0;
        acc[year] += Math.abs(transaction.amount);
        return acc;
      }, {});

      // Iterate over all additional incomes
      this.additionalIncomes.forEach(income => {
        console.log(`Processing additional income: ${income.amount}, ${income.frequency}, ${income.source}`);
        const startDate = new Date(income.startDate);
        const endDate = income.endDate && income.endDate.toLowerCase() === 'ongoing' ? new Date() : new Date(income.endDate);
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();

        for (let year = startYear; year <= endYear; year++) {
          if (!totals[year]) totals[year] = 0;
          const months = this.getMonthsCount(startDate, endDate, year);
          totals[year] += this.calculateIncomeForYear(income.amount, income.frequency, months);
        }
      });


      return totals;
    },

    totalIncomeByMonth() {
      let monthlyTotals = this.incomeTransactions.reduce((acc, transaction) => {
        const date = new Date(transaction.date + 'T00:00:00'); // Append 'T00:00:00' to ensure it's treated as local time
        const yearMonth = `${date.getFullYear()}-${this.months[date.getMonth()]}`;
        if (!acc[yearMonth]) acc[yearMonth] = 0;
        acc[yearMonth] += Math.abs(transaction.amount);
        return acc;
      }, {});

      // Iterate over all additional incomes
      this.additionalIncomes.forEach(income => {
        const startDate = new Date(income.startDate);
        const endDate = income.endDate ? (income.endDate.toLowerCase() === 'ongoing' ? new Date() : new Date(income.endDate)) : new Date();
        const startYear = startDate.getFullYear();
        const endYear = endDate.getFullYear();

        for (let year = startYear; year <= endYear; year++) {
          for (let month = 0; month < 12; month++) {
            const yearMonth = `${year}-${this.months[month]}`;
            if (!monthlyTotals[yearMonth]) monthlyTotals[yearMonth] = 0;
            const isMonthInRange = new Date(year, month) >= startDate && new Date(year, month) <= endDate;
            if (isMonthInRange) {
              const months = this.getMonthsCount(startDate, endDate, year);
              monthlyTotals[yearMonth] += this.calculateIncomeForYear(income.amount, income.frequency, months) / months;
            }
          }
        }
      });

      return monthlyTotals;
    },



    calculateIncomeForYear(amount, frequency, months) {
      let multiplier = 0;
      switch (frequency) {
        case 'Monthly':
          multiplier = months;
          break;
        case 'Weekly':
          multiplier = months * 4; // Approximation
          break;
        case 'Biweekly':
          multiplier = months * 2;
          break;
        case 'Semi-Annually':
          multiplier = months / 6;
          break;
        case 'Annually':
          multiplier = months / 12;
          break;
      }
      const incomeForYear = amount * multiplier;
      // console.log(`Calculating: Amount: $${amount}, Frequency: ${frequency}, Months: ${months}, Calculated Income: $${incomeForYear}`);
      return incomeForYear;
    },

    getMonthsCount(startDate, endDate, year) {
      let startMonth = startDate.getFullYear() === year ? startDate.getMonth() : 0;
      let endMonth = endDate.getFullYear() === year ? endDate.getMonth() : 11; // December
      return endMonth - startMonth + 1;
    },
    calculateMoMChange() {
      const monthlyIncome = this.totalIncomeByMonth();
      let previousMonthIncome = null;
      const momChange = {};

      for (const yearMonth in monthlyIncome) {
        if (previousMonthIncome !== null) {
          momChange[yearMonth] = ((monthlyIncome[yearMonth] - previousMonthIncome) / previousMonthIncome) * 100;
        }
        previousMonthIncome = monthlyIncome[yearMonth];
      }
      return momChange;
    },
    calculateYoYChange() {
      const yearlyIncome = this.totalIncomeByYear();
      let previousYearIncome = 0;
      const yoyChange = {};

      for (const year in yearlyIncome) {
        if (previousYearIncome !== 0) {
          yoyChange[year] = ((yearlyIncome[year] - previousYearIncome) / previousYearIncome) * 100;
        }
        previousYearIncome = yearlyIncome[year];
      }
      return yoyChange;
    },
    calculateYoYSpendingChange() {
      const yearlySpend = this.totalSpendByYear();
      let previousYearSpend = null;
      const yoyChange = {};

      Object.keys(yearlySpend).sort().forEach(year => {
        if (previousYearSpend !== null) {
          yoyChange[year] = ((yearlySpend[year] - previousYearSpend) / previousYearSpend) * 100;
        }
        previousYearSpend = yearlySpend[year];
      });
      return yoyChange;
    },
    calculateExpectedEarnings() {
      const currentYear = new Date().getFullYear();
      console.log("Current Year:", currentYear);

      const today = new Date();
      const startOfYear = new Date(currentYear, 0, 1);
      const endOfYear = new Date(currentYear + 1, 0, 1);
      const daysPassed = Math.ceil((today - startOfYear) / (1000 * 60 * 60 * 24));
      const totalDays = Math.ceil((endOfYear - startOfYear) / (1000 * 60 * 60 * 24));

      console.log("Days Passed:", daysPassed);
      console.log("Total Days:", totalDays);

      const incomeSoFar = this.incomeTransactions
        .filter(transaction => new Date(transaction.date).getFullYear() === currentYear)
        .reduce((acc, transaction) => acc - transaction.amount, 0);

      console.log("Income So Far:", incomeSoFar);

      const expectedEarnings = (incomeSoFar / (daysPassed / totalDays)).toFixed(2);
      console.log("Expected Earnings for current year:", expectedEarnings);

      return parseFloat(expectedEarnings); // Ensures the return value is a number
    },
    toggleMonths(item) {
      // Check if the clicked item is a month (contains '-')
      if (item.includes('month-view')) {
        // If any month is clicked, collapse the expanded months
        if (this.expandedYear) {
          this.collapseMonths(this.expandedYear);
        }
        return; // Exit the function early
      }

      // Below this point, we know a year has been clicked
      const year = item.toString();

      // Check if the clicked year is already expanded
      if (this.expandedYear === year) {
        // Collapse the year
        this.collapseMonths(year);
      } else {
        // If another year is expanded, collapse it first
        if (this.expandedYear) {
          this.collapseMonths(this.expandedYear);
        }

        // Now expand the clicked year
        this.expandMonths(year);
      }
    },
    expandMonths(year) {
      const yearIndex = this.headerItems.findIndex(item => item === year);
      if (yearIndex !== -1) {
        this.expandedYear = year;
        
        // Determine the current year and month
        const currentYear = new Date().getFullYear().toString();
        const currentMonthIndex = new Date().getMonth(); // 0 for January, 11 for December

        let expandedMonths;
        if (year === currentYear) {
          // If the current year is selected, limit the months to the current month
          expandedMonths = this.months.slice(0, currentMonthIndex + 1).map(month => `${year}-${month}`);
        } else {
          // For other years, expand all months
          expandedMonths = this.months.map(month => `${year}-${month}`);
        }
        
        this.headerItems.splice(yearIndex + 1, 0, ...expandedMonths);
        console.log("Header Items after Expand: ", this.headerItems); // Debugging
      }
    },

    collapseMonths(year) {
      const yearIndex = this.headerItems.findIndex(item => item === year);
      if (yearIndex !== -1) {
        // Determine the current year and month
        const currentYear = new Date().getFullYear().toString();
        const currentMonthIndex = new Date().getMonth(); // 0 for January, 11 for December

        let numberOfMonthsToRemove = 12;
        if (year === currentYear) {
          // If the current year is selected, count the months up to the current month
          numberOfMonthsToRemove = currentMonthIndex + 1;
        }

        this.headerItems.splice(yearIndex + 1, numberOfMonthsToRemove);
        this.expandedYear = null;
        console.log("Header Items after Collapse: ", this.headerItems); // Debugging
      }
    },
    getCumulativeChartData() {
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();

      const cumulativeIncomeData = Array(12).fill(0);
      const cumulativeSpendData = Array(12).fill(0);

      this.incomeTransactions.forEach(transaction => {
        const date = new Date(transaction.date);
        if (date.getFullYear() === this.selectedYear) {
          const monthIndex = date.getMonth();
          for (let i = monthIndex; i < 12; i++) {
            if (this.selectedYear === currentYear && i > currentMonth) {
              break; // Skip updating data for months past the current month in the current year
            }
            cumulativeIncomeData[i] += Math.abs(transaction.amount);
          }
        }
      });

      this.spendingTransactions.forEach(transaction => {
        const date = new Date(transaction.date);
        if (date.getFullYear() === this.selectedYear) {
          const monthIndex = date.getMonth();
          for (let i = monthIndex; i < 12; i++) {
            if (this.selectedYear === currentYear && i > currentMonth) {
              break; // Skip updating data for months past the current month in the current year
            }
            cumulativeSpendData[i] += transaction.amount;
          }
        }
      });

      return {
        labels: months,
        datasets: [
          {
            label: 'Cumulative Income',
            data: cumulativeIncomeData,
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return;
              }
              const gradientBackground = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
              gradientBackground.addColorStop(0, 'rgba(0, 128, 0, 0.6)');
              gradientBackground.addColorStop(1, 'rgba(0, 255, 0, 0.2)');
              return gradientBackground;
            },
            borderColor: 'rgba(0, 128, 0, 1)',
            fill: true,
            tension: 0.4,
          },
          {
            label: 'Cumulative Spend',
            data: cumulativeSpendData,
            backgroundColor: (context) => {
              const chart = context.chart;
              const { ctx, chartArea } = chart;
              if (!chartArea) {
                return;
              }
              const gradientBackground = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.bottom);
              gradientBackground.addColorStop(0, 'rgba(135, 206, 250, 0.8)'); // Darker blue at the top
              gradientBackground.addColorStop(1, 'rgba(135, 206, 250, 0.1)'); // Transparent at the bottom
              return gradientBackground;
            },
            borderColor: 'rgba(135, 206, 250, 1)',
            fill: true,
            tension: 0.4,
          },
        ],
      };
    },
    renderCumulativeChart() {
      const ctx = this.$refs.cumulativeChart.getContext('2d');
      if (this.cumulativeChart) {
        this.cumulativeChart.destroy();
      }
      this.cumulativeChart = new Chart(ctx, {
        type: this.chartType,
        data: this.getCumulativeChartData(),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
            hoverRadius: 5,
          },
          scales: {
            x: {
              display: true,
              grid: {
                display: false,
                drawOnChartArea: false,
              },
            },
            y: {
              display: false,
              grid: {
                display: false,
                drawOnChartArea: false,
              },
              ticks: {
                callback: function (value) {
                  return '$' + value.toLocaleString();
                },
              },
            },
          },
          plugins: {

              legend: {
                display: false,
              },
            title: {
              display: true,
              text: 'Cumulative Income vs Spend',
              position: 'bottom',
              font: {
                size: 16,
                weight: 'bold'
              }
            },
            datalabels: {
              color: '#000000',
              display: false,
            },
          },
          hover: {
            mode: 'index',
            intersect: false,
          },
        },
      });
    },
  }
};
</script>

<style>
h1 {
  text-align: center;
}

.annual-summary-table {
  margin: 2%;
  overflow-x: auto;  /* Enable horizontal scrolling */
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;;
  padding: 20px;
  max-width: 100%;
  background-color: white;
}


.annual-summary-title {
  margin-top: 0px;
  text-align: left;
  margin-left: 4%;
}

.month-header {
  font-weight: 100;
  color: #989898;
}

.year-header {
  /* Your specific styles for year headers go here */
  font-weight: bold; /* Example style */
  
  font-size: 22px;
  font-weight: 600;
}

.summarytable th:hover {
  cursor: pointer;
}

.summarytable tr {
  height: 40px;
}

.summarytable .section-row {
  font-weight: 900;
  /* background-color: #fff !important; */
}

.summarytable td.section-row:hover {
  background-color: #fff !important;
}

.summarytable {
  /* width: 100%; */
  table-layout: fixed;
  border-collapse: collapse;
}

.summarytable td {
  /* border: 1px solid #fff; */
  padding: 6px 12px;
  text-align: center;
  border: thin solid #efefef;
  /* min-width: 50px; */
}

.summarytable th {
  /* border: 1px solid #fff; */
  padding: 8px;
  text-align: center;
  border: none; 
  min-width: 50px;
}

.summarytable tr:not(.date-row):hover {
    background-color: #fff !important;
}

.summarytable {
    background-color: #fff !important;
}

.summarytable tr:not(.month-column):hover {
    background-color: #fff !important;
}


.summarytable td:not(.section-td,.checkbox-td,.delete-td):hover {
  outline: #fff;
  /* outline-style: solid; */
  /* border-radius: 5px; */
  outline-width: thin;
  background-color:#fff;
  cursor: default !important;
}

summarytable th:first-child, .summarytable td:first-child {
    text-align: left;
    position: sticky;
    left: 0;
    background-color: #fbfbfb;
    min-width: 200px;
    z-index: 1;
}


.event-input {
  background-color: inherit;
  padding: 0px 0px 0px 20px;
    margin: 0 auto;
    border-color: #d7d7d7;
  border: none;
  display: none;
}


.event-input:hover {
  border-style: solid;
  border-width: thin;
  padding: 0px 0px 0px 20px;
    margin: 0 auto;
    border-color: #d7d7d7;
  transition: 0.3s ease-in-out;
  /* box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%); */
}


.summarytable td:not(.section-td,.checkbox-td,.delete-td):hover .event-input {
  display: block;
}


.month-column {
  background-color: #fff; /* Example: light gray background */
}

table {
  border-spacing: 0px !important;
}

.month-view, .month-column {
  width: 80px !important;
  min-width: 80px !important;
  max-width: 60px !important; /* Ensures that the width does not exceed 10px */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes dropIn {
    from { transform: translateY(-20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}


.Annual-Summary {
    animation: fadeIn .5s ease-in forwards;
}



</style>
