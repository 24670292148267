<template>
  <div :class="{ 'light-mode': lightMode }">
    <TheHeader @toggle-light-mode="toggleLightMode" />
  
    <div class="manifesto-parent">
      <div class="manifesto-container">
    <h1>MISSION</h1>

    <p>Something Happened to Our Finances</p>
    <p>There was a time when financial security seemed achievable. You saved diligently, managed your expenses, and planned for the future. But the landscape has changed dramatically:</p>
    <ul>
      <li><p>Personal savings rates have plummeted to just 3.2% in 2024, a stark decline from the 32% seen during the pandemic.</p></li>
      <li><p>Credit card debt has surged to an all-time high of $1.05 trillion.</p></li>
      <li><p>9 out of 10 Americans are not living their definition of financial freedom.</p></li>
    </ul>

    <p>Today, achieving financial independence feels like an uphill battle. Society encourages spending over saving, and financial literacy is alarmingly low. Many financial institutions profit from our debt and lack of knowledge, rather than empowering us to succeed.</p>

    <p>At fiyr, we’re here to change the game. We believe that everyone should be free to dream again and do what they are passionate about—whether that brings in money or not. Financial independence is not just about having money; it's about reclaiming your time and living life on your terms.</p>

    <p>FIRE stands for Financial Independence, Retire Early. It's a lifestyle movement dedicated to achieving financial independence and retiring early by saving aggressively and investing wisely. Here's how it's possible:</p>
    <ul>
      <li><p><strong>Aggressive Saving</strong>: Aim to save 50-70% of your income. This high savings rate accelerates the accumulation of wealth.</p></li>
      <li><p><strong>Smart Investing</strong>: Invest in diversified, low-cost index funds to grow your savings exponentially over time.</p></li>
      <li><p><strong>Frugal Living</strong>: Minimize expenses by focusing on what truly adds value to your life, cutting out unnecessary spending.</p></li>
      <li><p><strong>Passive Income</strong>: Build multiple streams of passive income, such as dividends, rental income, and interest, to ensure a steady cash flow.</p></li>
    </ul>

    <p>Financial independence should allow you to reclaim your time and pursue your passions without worrying about paying bills. Whether you dream of starting a creative agency, pursuing music, traveling the world, or anything else, fiyr provides the insights and tools to make those dreams a reality. By analyzing your income, expenses, and savings, our app gives you a comprehensive view of your money habits and a realistic timeline for achieving financial independence.</p>

    <p>It’s time to take control. It’s time to ignite your path to financial independence with fiyr.</p>
  </div>
</div>
  <TheFooter class="footer-container" :lightMode="lightMode" />
</div>
</template>

<script>
import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'

export default {
  name: "ManifestoView",
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      lightMode: false,
    };
  },
  methods: {
    toggleLightMode() {
      this.lightMode = !this.lightMode;
    },
  },
};
</script>

<style scoped>
.manifesto-parent {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  text-align: left;

  margin: 0 auto;
  padding-top: 200px;
  background-color: black;
  color: white;
}


p {
  font-size: .95rem;
  margin: 1rem;
}

h1 {
  font-size: 2rem;
  margin: none;
}

.blog-manifesto {
  background-color: black;
  color: white;
  padding-top: 200px;
  text-align: left;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.manifesto-parent a {
  color: white;
}

.light-mode .manifesto-parent a {
  color: black;
}

.manifesto-container {
  margin: 0 auto;
  width: 80%;
  padding-bottom: 200px;
}

ul{
  padding: 0px 30px;
  font-size: .85rem;
}

.light-mode .manifesto-parent {
  background-color: white;
  color: black;
}


/* .manifesto h1, .manifesto h2 {
  color: #2c3e50;
} */

/* .manifesto ul {
  list-style-type: disc;
  margin: 1em 0;
  padding-left: 20px;
}

.manifesto p {
  margin: 0.5em 0;
} */
</style>

