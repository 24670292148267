<template>
  <div :class="{ 'light-mode': lightMode }">
    <TheHeader @toggle-light-mode="toggleLightMode" />
    <div class="blog-parent">
      <div class="blog-container">
        <h1>Blog</h1>
        <ul>
          <li><router-link to="/blog/unlocking-fire">Unlocking FIRE</router-link></li>
          <li><router-link to="/blog/emergency-fund">You Need an Emergency Fund</router-link></li>
          <li><router-link to="/blog/four-percent">Understanding The 4% Rule</router-link></li>
          <li><router-link to="/blog/fire-frugality">Frugality: The Secret Weapon of FIRE</router-link></li>
          <li><router-link to="/blog/savings-rate">Boost Your Savings Rate: A Shortcut to FIRE</router-link></li>
          <li><router-link to="/blog/step-by-step">Step by Step Guide to unlocking Your Financial Freedom</router-link></li>
          <li><router-link to="/blog/maximize-retirement">How To Maximize Your Retirement Savings</router-link></li>
          <li><router-link to="/blog/loan-repayment-strategies">Loan Repayment: Whats the best way to payoff debt</router-link></li>
          <!-- Add more links to your blog posts here -->
        </ul>
      </div>
    </div>
    <TheFooter class="footer-container" :lightMode="lightMode" />
  </div>
</template>

<script>
import TheHeader from '../TheHeader.vue'
import TheFooter from '../TheFooter.vue'

export default {
  name: "BlogHome",
  components: {
    TheHeader,
    TheFooter,
  },
  data() {
    return {
      lightMode: false,
    };
  },
  methods: {
    toggleLightMode() {
      this.lightMode = !this.lightMode;
    },
  },
};
</script>

<style scoped>
.blog-parent{
  height: 100vh;
  padding-top: 200px
}

.blog-parent {
  background-color: black;
  color: white;
  padding-top: 200px;
  text-align: left;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.blog-parent a {
  color: white;
}

.light-mode .blog-parent {
  background-color: white;
  color: black;
}


.light-mode .blog-parent a {
  color: black;
}

.blog-container {
  margin: 0 auto;
  width: 80%;
  padding-bottom: 200px;
}

.footer-container {
  position: absolute;
  bottom: 0;
  width: 100vw;
}
</style>
