<template>
  <div>
      <div class="label-manager"> 
        <!-- Add label form -->
        <form @submit.prevent="addLabel">
          <!-- <label for="newLabelInput">New Label:</label> -->
          <input id="newLabelInput" name="newLabel" class="new-inline-label-input" v-model="newLabel" type="text" placeholder="New Label" required>
        </form>
        <!-- <button class="new-inline-label-button" type="submit">✔️</button> -->

      </div>
        <!-- Display existing labels -->
        <!-- <ul>
          <li class="inline-label-li" v-for="label in labels" :key="label.id">
            <template v-if="label.editing">
              <input
                v-model="label.name"
                type="text"
                required
                @keyup.enter="saveLabel(label)"
              />
              <button class="labelbuttons" @click="saveLabel(label)">Save</button>
            </template>
            <template v-else>
              <span class="labelname">{{ label.name }}</span>
              <button class="inlinelabelbuttons" @click="toggleEditing(label)">
                🔧
              </button>
              <button class="inlinelabelbuttons" @click="deleteLabel(label.id)">
                ❌
              </button>
            </template>
          </li>
        </ul> -->
  </div>
</template>

<script>
import { collection, addDoc, doc, deleteDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseInit';

export default {
  name: 'LabelManager',
  data() {
    return {
      newLabel: '',
      labels: [],
      isExpanded: false, // Added data property
    };
  },
  mounted() {
    this.retrieveLabels();
  },
  methods: {
    async retrieveLabels() {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const querySnapshot = await getDocs(labelsCollection);
          this.labels = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().name,
          }));
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error retrieving labels:', error);
      }
    },
    async addLabel() {
      try {
        const user = auth.currentUser;
        if (user) {
          const label = {
            name: this.newLabel,
          };
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const docRef = await addDoc(labelsCollection, label);
          console.log('Label added with ID:', docRef.id);
          this.newLabel = '';
          this.retrieveLabels();
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error adding label:', error);
      }
      this.$emit('close');
    },
    async editLabel(label) {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const labelDocRef = doc(labelsCollection, label.id);
          const newName = prompt('Enter the new label name', label.name);

          if (newName) {
            await updateDoc(labelDocRef, { name: newName });
            console.log('Label edited:', label.id);
            this.retrieveLabels();
          }
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error editing label:', error);
      }
    },
    async deleteLabel(labelId) {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          await deleteDoc(doc(labelsCollection, labelId));
          console.log('Label deleted:', labelId);
          this.retrieveLabels();
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error deleting label:', error);
      }
    },
    toggleEditing(label) {
      label.editing = !label.editing;
    },
    async saveLabel(label) {
      try {
        const user = auth.currentUser;
        if (user) {
          const labelsCollection = collection(db, `users/${user.uid}/labels`);
          const labelDocRef = doc(labelsCollection, label.id);

          await updateDoc(labelDocRef, { name: label.name });
          console.log('Label saved:', label.id);

          this.toggleEditing(label);
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error saving label:', error);
      }
    },
    expandContent() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
  display: inline-grid;
}

.inline-label-li {
  display: flex;
  height: 30px;
  background-color: #f8f8f8;
  padding: 0px 10px;
  border-radius: 10px;
  align-items: center;
  margin-bottom: 5px;
}

input{
    width: auto;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 14px;
    color: gray;
}

.inlinelabelbuttons {
  margin-left: 10px;
  padding: 2%;
  background-color: unset;
}

.inlinelabelbuttons:hover {
  background-color: #4caf50;
}

.labelname {
  width: 140px;
  text-align: left;
}


.manage-labels-button {
  margin-bottom: 10px;
}

.settings-title {
  text-align: center;
}

.new-inline-label-input {
  display: inline-flex;
  background-color: white;
  padding: 15px;
  height: 5px;
  border-style: solid;
  border-width: thin;
  border-color: lightgrey;
  border-radius: 5px;
  align-items: center;
}

.new-inline-label-button {
  background-color: unset;
  margin: 2px 5px;
  padding: 0px 10px;
}

.new-inline-label-button:hover {
  background-color: #4caf50;
  color: white;
}

.label-manager {
  display: flex;

}
</style>
