<template>
  <div class="loader-container" v-if="loading">
    <span class="loader"></span>
  </div>
  <div class="onboarding-style-accounts">
    <div v-if="tokens.length === 0" class="no-accounts-message">
      <p>You have no linked accounts. Click the add account button in the sidebar to link an account.</p>
    </div>
    <div v-else>
      <div  class="linked-account-parent" v-for="token in tokens" :key="token.id">
        <!-- <h2>Institution ID: {{ token.institution_id }}</h2>  -->
        <div style="display: flex;">
          <div v-for="account in token.accounts" :key="account.id" class="account-info">
            <div class="account-name">{{ account.name }}</div>
            <div class="account-details">
              <span>{{ account.official_name }}</span> <br>
              <span>•••• {{ account.mask }}</span> <br>
              <span>Balance: {{ formatCurrency(account.balances?.current) }}</span> <br>
              <span v-if="account.balances?.available !== null">Available: {{ formatCurrency(account.balances?.available) }}</span> <br>
            </div>
          </div>
        </div>
        <div style="display: flex; margin: 0 auto;">
          <v-btn style="box-shadow: rgba(8, 40, 100, 0.1) 0px 1px 2px !important; width: 200px;" @click="unlinkAccount(token.id)">Unlink</v-btn>

          <span v-if="token.accounts.length > 1" class="info-icon">&#9432;
            <div class="tooltip">Unlinking will remove all accounts associated with this token. To keep some accounts, first unlink then add only the desired accounts.</div>
          </span>
        </div>
      </div>
    </div>
    <!-- Confirmation message -->
    <div v-if="showConfirmation" :class="['confirmation-message', { 'sidebar-open': sidebarOpen }]">
      {{ confirmationMessage }}
    </div>
     <!-- Input field and button to unlink by access token -->
     <div v-if="showUnlinkByTokenInput">
      <input type="text" v-model="accessTokenToUnlink" placeholder="Enter access token">
      <v-btn @click="unlinkAccountWithToken">Unlink by Token</v-btn>
    </div>
  </div>
</template>


<script>
import { getFirestore, doc, getDoc, collection, getDocs, updateDoc, deleteField, query, where, deleteDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import axios from 'axios';


export default {
  name: 'LinkedAccounts',
  data() {
    return {
      tokens: [], // Array to store tokens with their accounts
      showTooltip: false,
      confirmationMessage: '',
      showConfirmation: false,
      showUnlinkByTokenInput: false,
      accessTokenToUnlink: '',
    };
  },
  methods: {
    async unlinkAccountWithToken() {
      const accessToken = this.accessTokenToUnlink; // Access token from input
      if (!accessToken) {
        console.error('Access token is required');
        return;
      }
      const user = getAuth().currentUser;
      if (!user) {
        console.error('No user is logged in.');
        return;
      }

      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/unlink_item', { access_token: accessToken });
        if (response.data.success) {
          console.log(response.data.message);
        }
      } catch (error) {
        console.error('Error unlinking account:', error.response.data);
      }
    },
    async fetchLinkedAccounts() {
      const user = getAuth().currentUser;
      if (!user) {
        console.error('No user is logged in.');
        this.loading = false;
        return;
      }
      const db = getFirestore();
      const tokensRef = doc(db, `plaid_access_tokens/${user.uid}`);
      const accountsRef = collection(db, `users/${user.uid}/accounts`);

      const [tokenDoc, accountsSnapshot] = await Promise.all([
        getDoc(tokensRef),
        getDocs(accountsRef)
      ]);

      const accounts = {};
      accountsSnapshot.forEach(doc => {
        const data = doc.data();
        accounts[data.account_id] = {
          ...data,
          balances: data.balances || {} // Ensure balances object is always present
        };
      });

      console.log("Accounts Data:", accounts); // Log the accounts data

      if (tokenDoc.exists()) {
        const userTokens = tokenDoc.data().tokens || {};
        this.tokens = Object.keys(userTokens).map(tokenId => {
          const tokenData = userTokens[tokenId]; // Reference to the whole token data including access_token
          const tokenAccounts = tokenData.accounts.map(account => {
            const mergedAccount = {
              ...account,
              ...accounts[account.id] // Merge, ensuring all account objects are complete
            };

            // Include checking if the account exists in the accounts object to avoid undefined errors
            if (accounts[account.id]) {
              mergedAccount.balances = accounts[account.id].balances;
            }

            return mergedAccount;
          });

          console.log("Merged Accounts for Token ID " + tokenId + ":", tokenAccounts); // Log merged accounts

          return {
            id: tokenId,
            institution_id: tokenData.institution_id,
            accounts: tokenAccounts,
            access_token: tokenData.access_token // Ensure access_token is included in the merged data
          };
        });
      } else {
        console.log("No such document!");
      }
      this.loading = false;
    },
    formatCurrency(value) {
      return value !== null && value !== undefined
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
        : '-';
    },
    async unlinkAccount(tokenId) {
      const accessToken = this.tokens.find(token => token.id === tokenId).access_token; // Ensure you have access_token stored
      console.log("Access token: ", accessToken) 
      const user = getAuth().currentUser; // Make sure you handle authentication

      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/unlink_item', { access_token: accessToken });
        if (response.data.success) {
          console.log(response.data.message);

          // Firestore deletion
          const db = getFirestore();
          const tokenRef = doc(db, `plaid_access_tokens/${user.uid}`);
          const accountsRef = collection(db, `users/${user.uid}/accounts`);

          // Remove token from Firestore
          await updateDoc(tokenRef, {
            [`tokens.${tokenId}`]: deleteField()
          });

          // Query and delete all accounts associated with the tokenId
          const querySnapshot = await getDocs(query(accountsRef, where("token_id", "==", tokenId)));
          querySnapshot.forEach(async doc => {
            await deleteDoc(doc.ref);
          });

          // Optionally update local state to reflect these deletions
          this.tokens = this.tokens.filter(token => token.id !== tokenId);
        // Set the confirmation message and show it
        this.confirmationMessage = 'Account unlinked successfully!';
        this.showConfirmation = true;

        // Hide the confirmation message after a few seconds (e.g., 3 seconds)
        setTimeout(() => {
          this.showConfirmation = false;
        }, 3000);
      }
      } catch (error) {
        console.error('Error unlinking account:', error.response.data);
      }
    }
      
  },
  mounted() {
    this.fetchLinkedAccounts();
  },
};
</script>

<style>
.onboarding-style-accounts .plaid-button {
  animation: fadeInSlideDown 1s ease-in-out 2s !important;
  animation-fill-mode: forwards !important;
  width: max-content;
  background: #2c2c2c;
  color: white;
  font-weight: bold;
  font-family: system-ui;
  font-size: 20px;
  height: 45px;
  opacity: 0;
  margin: 50px auto !important;
}

.centered-content {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-slide-down {
  opacity: 0;
  transform: translateY(-20px);
  animation-fill-mode: forwards !important; /* Keeps the styles set by the last keyframe */
}

#welcome-text {
  animation: fadeInSlideDown 1s ease-in-out 0s;
}

#import-text {
  animation: fadeInSlideDown 1s ease-in-out 1s; /* Delayed start */
  text-align: center;
  margin-bottom: 2.5rem;
}

.account-info {
  margin: 1rem;
  padding: 1rem;
  min-width: 250px;
  min-height: 150px;
  border: 1px solid #ddd;
  box-shadow: 3px 5px 0px 1px rgb(0 0 0 / 17%);
  border-radius: 6px;
  background-color: #ffffff;
}

  .account-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .account-details {
    font-size: 0.9rem;
    color: #666;
  }

  .accounts-parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }


.loader-container {
    display: flex;
    background-color: #000000;
    opacity: 40%;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    position: fixed; /* So that it doesn't affect other elements */
    top: 0;
    left: 0;
}

.loader {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    border-top: 6px solid #9ec9ff;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}

.linked-account-parent {
  display: inline-grid;
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: visible;
  text-align: center;
  background-color: #ffffff;
  margin: 20px;
  min-height: 30vh;
  padding: 30px;
  height: -moz-max-content;
  height: max-content;
  box-shadow: 3px 4px 0px 0px rgb(29 29 29 / 5%);
  border-radius: 5px;
}
.info-icon {
  position: relative;
  display: inline-block;
  cursor: help;
  margin: 8px;
  width: min-content;
}

.tooltip {
  position: absolute;
  bottom: 100%; /* Position above the icon */
  left: 50%; /* Start from the middle of the icon */
  transform: translateX(-50%); /* Center align the tooltip */
  width: max-content;
  padding: 20px;
  width: 400px;
  background-color: #f4f2f2;
  color: #333;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #ddd;
  display: none;
  box-shadow: 0px 6px 15px 0px rgb(29 29 29 / 5%);
  z-index: 9000;
}

.info-icon:hover .tooltip {
  display: block;
}

.confirmation-message {
  position: fixed;
  top: 20px;
  right: -100px;
  transform: translateX(-50%);
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  z-index: 9999;
}

.no-accounts-message {
  text-align: center;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 500px;
}

.no-accounts-message p {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  color: #333;
}

.no-accounts-message .v-btn {
  margin-top: 1rem;
}
</style> 