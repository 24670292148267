<template>
  <div class="AI">
    <h1>AI</h1>

    <!-- Display Conversation -->
    <div v-for="(message, index) in conversation" :key="index" class="message">
      <h3>{{ message.role }}</h3>
      <div v-if="isLoading" class="loading-indicator"></div>
      <p class="ai-prompt-paragraph" v-html="message.content"></p>
    </div>
    <div style="display: inline;">
      <textarea class="ai-input" name="ai-input" ref="textarea" rows="4" cols="50" type="text" v-model="customPrompt" @input="autoResizeTextarea" placeholder="Send a message"></textarea>
      <div v-if="hasError" class="error-message">{{ error }}</div>

      <div style="width: 50%;">
        <button class="ai-submit" @click="submitPrompt">Submit Prompt</button>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';

export default {
  name: 'AI',
  data() {
    return {
      customPrompt: '',
      isLoading: false,
      conversation: [],
      aiResponse: null,
      error: null,
      initialPromptSent: false,
    };
  },
  computed: {
    hasError() {
      return this.error !== null;
    },
    transactions() {
      return this.$store.state.transactions;
    },
    filteredTransactions() {
      const currentDate = new Date();
      const pastDate = new Date();
      pastDate.setMonth(currentDate.getMonth() - 4); // 6 months
      return this.transactions.filter(transaction => new Date(transaction.date) >= pastDate);
    }
  },
  mounted() {
    this.autoResizeTextarea();
  },
  methods: {
    autoResizeTextarea() {
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    },
    formatResponse(response) {
      const regex = /(\d+\.\s[a-zA-Z].*?[\.!\?](?=\s\d+\.|\s*$))/g;
      const matches = [...response.matchAll(regex)];

      if (matches.length) {
        return `
          <ul>
            ${matches.map(match => `<li>${match[1]}</li>`).join('')}
          </ul>
        `;
      }

      return response;
    },
    doesResponseImplyContinuation(response) {
      const continuationKeywords = ["crunch the numbers", "give me a moment", "let me check", "let's see", "hold on"];
      return continuationKeywords.some(keyword => response.toLowerCase().includes(keyword));
    },
    async submitPrompt() {
      if (!this.customPrompt) {
        console.log('No prompt entered.');
        return;
      }

      const systemInstructions = "You are a helpful assistant designed to generate professional and engaging financial advice and direction with a touch of wit and sarcasm.";

      let messages = [
        {
          role: "system",
          content: systemInstructions
        },
        ...this.conversation,
        {
          role: "user",
          content: `Provide professional and engaging financial advice based on the following content. You will use the following writing and tone style: 
            1. Professional yet Engaging: The writing seamlessly blends a professional tone with engaging and relatable language. It strikes a balance between being informative and entertaining, making complex concepts accessible and enjoyable to read.
            2. Witty and Slightly Sarcastic: The style incorporates a subtle layer of wit and sarcasm, adding a playful edge without detracting from the seriousness of the content. This approach helps in keeping the reader intrigued and adds personality to the text.
            3. Clear and Concise Explanations: Despite the playful tone, the writing remains clear and to the point, focusing on delivering straightforward explanations and actionable advice. It avoids unnecessary jargon, making it suitable for a broad audience.
            4. Motivational and Aspirational: The tone is motivational, encouraging readers to take action and consider new possibilities. It paints an aspirational picture of what life could look like, appealing to the reader’s desires for freedom and control over their own life.
            5. Use of Analogies and Metaphors: The use of analogies and metaphors is prevalent, helping to explain financial concepts in a way that is easy to understand and relate to everyday experiences. This technique also adds a layer of depth and creativity to the writing.
            6. Interactive and Direct Address: The writing often directly addresses the reader, creating a conversational feel. This direct engagement is effective in capturing and maintaining the reader’s attention throughout the advice. 
            7. Most importantly, try to write in the style of Scott Galloway. Don't be too lengthy in your response.
            
            Here is the user's question/prompt that you should respond to:\n\n${this.customPrompt}`
        }
      ];

      if (!this.initialPromptSent) {
        const financialData = {
          transactions: this.filteredTransactions,
          // Include other financial data as needed
        };
        messages.splice(1, 0, {
          role: "user",
          content: `Here is my financial data from the last 1.5 years: ${JSON.stringify(financialData)}`
        });
        this.initialPromptSent = true;
      }

      this.conversation.push({
        role: "user",
        content: this.customPrompt
      });

      this.isLoading = true;

      try {
        const response = await axios.post(
          'https://api.openai.com/v1/chat/completions',
          {
            model: "gpt-4o",
            messages: messages,
            temperature: 0.7,
            top_p: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
          },
          {
            headers: {
              'Authorization': `Bearer sk-proj-AECkTm8ZEdjdq4iFhTc8T3BlbkFJhOps8k0rofAmUzNSAFhw`,
              'Content-Type': 'application/json'
            }
          }
        );

        this.aiResponse = this.formatResponse(response.data.choices[0].message.content);

        this.conversation.push({
          role: "system",
          content: this.aiResponse
        });

        if (this.doesResponseImplyContinuation(this.aiResponse)) {
          const followUpPrompt = "Can you continue and provide the analysis?";
          this.customPrompt = followUpPrompt;
        }

      } catch (error) {
        console.error('Error from API:', error);

        if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
          this.error = error.response.data.error.message;
        } else {
          this.error = 'An unexpected error occurred';
        }

        if (error.response && error.response.data) {
          console.error('Detailed error:', error.response.data);
        }
      } finally {
        this.isLoading = false;
        this.customPrompt = '';
        this.autoResizeTextarea();
      }
    }
  },
};
</script>

<style scoped>

.parent {
  display: grid;
}

.div1 { 
  grid-area: 1 / 1 / 2 / 3; 
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: -moz-fit-content;
  margin: 2%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.div2 { 
  grid-area: 1 / 3 / 2 / 5;
  align-items: baseline;
  padding: 5%;
  height: fit-content;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: -moz-fit-content;
  margin: 2%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.div3 { 
  grid-area: 2 / 1 / 3 / 5; 
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: -moz-fit-content;
  margin: 2%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.div4 { grid-area: 3 / 1 / 4 / 2; }
.div5 { grid-area: 3 / 2 / 4 / 3; }
.div6 { grid-area: 3 / 3 / 4 / 4; }
.div7 { grid-area: 3 / 4 / 4 / 5; }

h2 {
  text-align: center;
}

.notransactions {
  background-color: white;
  border-radius: 10px;
  padding: 5%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ai-prompter {
  grid-area: 1 / 1 / 2 / 3;
  align-items: baseline;
  padding: 5%;
  justify-content: space-evenly;
  background-color: white;
  border-radius: 10px;
  width: 800px;
  text-align: left;
  margin: 0 auto;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.ai-response {
  font: 20px;
}

.message h3 {
  font-weight: 500;
    font-size: small;
    color: #b9b9b9;
    width: 820px;
    text-align: left;
    margin: 0 auto;
    margin-bottom: -20px;
}

.ai-select {
  background-color: white;
  width: 90%;
}

.ai-input {
  background-color: white;
  height: 200px;
  width: 800px;
    padding: 10px;
    margin: 0 10%;

  -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.ai-input::-webkit-scrollbar {
    display: none;
  }


.ai-submit {
  margin: 20px auto !important;
  background-color: #486c78;
  color: white;
  width: fit-content;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  /* font-weight: bold; */
  transition: 0.3s ease-in-out;
  box-shadow: 0px 2px 7px 0px rgb(29 29 29 / 5%);
}

.ai-submit:hover {
  background-color: #2e2e2e;
}

.ai-prompt-paragraph {
  font-size: 1rem;
  width: 820px;
  margin: 20px auto;
  text-align: left;
}

@keyframes ellipsis {
    0% {
        content: "•";
    }
    33% {
        content: "••";
    }
    66% {
        content: "•••";
    }
    100% {
        content: "•";
    }
}

.loading-indicator::after {
    content: ".";
    animation: ellipsis 1s infinite;
}

.error-message {
  color: red;
  width: 820px;
  margin: 0 auto;
  font-size: small;
  text-align: left;
}
</style>