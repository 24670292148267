<template>
  <div>
    <div>
      <canvas class="spendingPieChart" ref="spendingDoughnutCanvas"></canvas>
    </div>

  </div>
</template>

<script>
/* eslint-disable */
import { Chart, DoughnutController, ArcElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ref, watch, onBeforeUnmount, computed, onMounted} from 'vue';
import { useStore } from 'vuex';


Chart.register(DoughnutController, ArcElement, Title, Tooltip, Legend, ChartDataLabels);

export default {

  props: {
    sortedVuexTransactions: {
      type: Array,
      required: true
    },
    dateOption: {
      type: String,
      required: true,
    },
  },
  setup(props) {    
    const store = useStore(); // Access the Vuex store using useStore
    const spendingDoughnutCanvas = ref(null);
    const chart = ref(null);

    const pieCategories = computed(() => {
      return store.state.categories; // Use store to access the state
    });

    function interpolateHue(startHue, endHue, index, maxIndex) {
      const hueRange = endHue - startHue;
      const hueIncrement = (index / maxIndex) * hueRange;
      return startHue + hueIncrement;
    }

    function getRandomPieColor(index, maxIndex) {
      const startHue = 900; // Blue hue value
      const endHue = 220; // Same as startHue to keep the hue constant
      const hue = startHue;

      const startLightness = 90; // Start with a dark shade of blue
      const endLightness = 20; // End with a light shade of blue

      // Calculate the lightness step based on the total number of slices
      const lightnessStep = (endLightness - startLightness) / (maxIndex - 1);

      // Calculate the lightness value for the current index
      const lightness = startLightness + (index * lightnessStep);

      return `hsl(${hue}, 100%, ${lightness}%)`;
    }


    

    // Computed property for filtered transactions
    const filteredTransactions = computed(() => {
      const today = new Date();
      let startDate;
      // Use props.dateOption instead of a local variable
      switch (props.dateOption) {
        case 'week':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 7));
            break;
        case '1year':
            startDate = new Date(Date.UTC(today.getFullYear() - 1, today.getMonth(), today.getDate()));
            break;
        case '2year':
            startDate = new Date(Date.UTC(today.getFullYear() - 2, today.getMonth(), today.getDate()));
            break;
        case 'month':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));
            break;
        case '30':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 30));
            break;
        case '60':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 60));
            break;
        case '90':
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 90));
            break;
        case 'year':
            startDate = new Date(Date.UTC(today.getFullYear(), 0, 1));
            break;
        case 'all':
            return props.sortedVuexTransactions; // No filtering for 'all'
        default:
            startDate = new Date(Date.UTC(today.getFullYear(), today.getMonth(), today.getDate() - 30));
            break;
    }

      return props.sortedVuexTransactions.filter(transaction => 
        new Date(transaction.date) >= startDate && 
        new Date(transaction.date) <= today
      );
    });

    const pieChartData = computed(() => {

      const totalsByCategory = filteredTransactions.value.reduce((acc, transaction) => {
        if (!acc[transaction.category]) {
          // Find the matching category from the categories prop

          const matchedCategory = pieCategories.value.find(category => category.name === transaction.category);

          // Use the emoji from the matched category, or a default value if no match is found
          const emoji = matchedCategory ? matchedCategory.emoji : '🔍'; // Default emoji if not found

          acc[transaction.category] = {
            total: 0,
            emoji: emoji
          };
        }
        acc[transaction.category].total += transaction.amount || 0; // Fallback amount
        return acc;
      }, {});
    return totalsByCategory;
    });

    const centerTextSpendingPiePlugin = {
      id: 'centerTextSpendingPiePlugin',
      afterDraw: (chart) => {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.tooltip._active[0];
          const dataset = chart.data.datasets[activePoint.datasetIndex];

            // Check if `dataset`, `dataset.data`, and `dataset.additionalData` exist before accessing their `length` properties
            if (dataset && dataset.data && dataset.additionalData && activePoint.index < dataset.data.length && activePoint.index < dataset.additionalData.length) {
              if (activePoint.index < dataset.data.length && activePoint.index < dataset.additionalData.length) {
              const ctx = chart.ctx,
                value = dataset.data[activePoint.index],
                totalValue = dataset.data.reduce((a, b) => a + b, 0),
                percentageValue = Math.round((value / totalValue * 100)) + '%',
                categoryName = dataset.additionalData[activePoint.index],
                centerX = (chart.chartArea.left + chart.chartArea.right) / 2,
                centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

              // Format the value as currency
              const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

              // Draw the text in the center
              ctx.save();
              ctx.font = '20px Arial'; // Set font size and style
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillStyle = '#000'; // Or any other color
              ctx.fillText(formattedValue, centerX, centerY - 35);
              ctx.fillText(categoryName, centerX, centerY + 35);

              ctx.font = 'bold 36px Arial'; // Increase font size for the percentage
              ctx.fillText(percentageValue, centerX, centerY);
              ctx.restore();
            }
          }
        }
      },
    };
    Chart.register(centerTextSpendingPiePlugin);

    const chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      cutout: '65%',
      hoverOffset: 30,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false, 
        },
        datalabels: {
          color: '#000000',
          font: {
            size: 20, // Adjust this value to change the font size of the labels
          },
          formatter: function(value, context) {
            const total = context.chart.data.datasets[0].data.reduce((a, b) => a + b, 0);
            const percentage = Math.round((value / total * 100));
            if (percentage < 5) {
              return null;
            }
            // const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(value);
            return context.chart.data.labels[context.dataIndex];
          }
        }
      },
      hover: {
        mode: 'nearest',
        intersect: true,
        animationDuration: 400,
      },
      layout: {
        padding: {
          left: 20,
          right: 20,
          top: 40,
          bottom: 40
        }
      }
    }


    const chartData = computed(() => {
      const dataArray = Object.keys(pieChartData.value).map(categoryName => {
        return {
          category: categoryName,
          emoji: pieChartData.value[categoryName].emoji,
          total: pieChartData.value[categoryName].total,
        };
      });


      const colors = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40'
      ];

      // Sort the dataArray in ascending order of 'total'
        dataArray.sort((a, b) => a.total - b.total);

      const labels = dataArray.map(item => item.emoji);
      const datasetValues = dataArray.map(item => item.total);
      const backgroundColors = dataArray.map((_, index) => getRandomPieColor(index, dataArray.length));

      const chartDataStructure = {
        labels: labels,
        datasets: [{
          data: datasetValues,
          additionalData: dataArray.map(item => item.category), 
          backgroundColor: backgroundColors,
          borderWidth: 0,
        }]
      };
      return chartDataStructure;
      });

      const updateChart = () => {
        if (chart.value) {
          chart.value.destroy();
        }

        createChart();
      };

    const createChart = () => {
      if (chart.value) {
        chart.value.destroy();
      }

      chart.value = new Chart(spendingDoughnutCanvas.value.getContext('2d'), {
        type: 'doughnut',
        data: chartData.value,
        options: chartOptions,
        plugins: [centerTextSpendingPiePlugin]
      });
      Chart.unregister(centerTextSpendingPiePlugin);
    };
   
    watch(() => props.dateOption, () => {
      updateChart();
    });

    onMounted(() => {
      createChart();
    });

    onBeforeUnmount(() => {
      if (chart.value) {
        chart.value.destroy();
      }
    });

    return { spendingDoughnutCanvas }; // Include dateOption
  },
};
</script>

<style scoped>


.spendingPieChart {
  width: 400px; 
  height: 400px;
}


</style>
