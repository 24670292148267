/* eslint-disable */
import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";
import { getAuth, setPersistence, browserLocalPersistence, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyCs_YRJTO-VFDUNT9j6EIMpUnMD5WhMIwo",
  authDomain: "fivebags-vue.firebaseapp.com",
  databaseURL: "https://fivebags-vue-default-rtdb.firebaseio.com",
  projectId: "fivebags-vue",
  storageBucket: "fivebags-vue.appspot.com",
  messagingSenderId: "1019145987336",
  appId: "1:1019145987336:web:1beeb3ae05709f8333f8bd",
  measurementId: "G-83L3EEYG4H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();


// Get a reference to the auth service
const auth = getAuth(app);

// Set persistence to LOCAL
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    // Persistence enabled
  })
  .catch((error) => {
    console.error('Error setting persistence:', error);
  });

const db = getFirestore(app);

export { app, auth, db, googleProvider, signInWithPopup };