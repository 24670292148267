<template>
  <div class="bar-chart-container">
    <canvas ref="barChartCanvas"></canvas>
  </div>
</template>


<script>
/* eslint-disable */
import Chart from 'chart.js/auto'; // Importing the default auto build of Chart.js
import 'chartjs-plugin-datalabels';


export default {
  props: {
    recurringChartData: {
      type: Array,
      required: true,
      default: () => []
    },
    sidebarIsCollapsed: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
    };
  },
  computed: {
    chartContainerClass() {
      return this.sidebarIsCollapsed ? 'bar-chart-container-collapsed' : 'bar-chart-container-expanded';
    }
  },
  watch: {
    recurringChartData(newRecurringData) {
      console.log('chartData has been updated:', newRecurringData);
      if (this.recurringChartInstance) {
        this.recurringChartInstance.destroy();  
      }
      this.renderBarChart(newRecurringData);  
    },
  },
  mounted() {
    console.log("Received chartData:", this.recurringChartData);
      this.$nextTick(() => {
      setTimeout(() => {
        this.renderBarChart(this.recurringChartData);
      }, 100);  // Delay for 500ms
    });
  },
  beforeUnmount() {
    if (this.recurringChartInstance) {
      this.recurringChartInstance.destroy();
    }
  },
  methods: {
    getTotalAmount() {
      return this.recurringChartData.reduce((sum, item) => sum + item.amount, 0);
    },

    getRandomColor(index) {
      const hue = 900; // Fixed hue value for blueish colors
      const saturation = 50 + Math.random() * 5;
      const lightness = 20 + index * 5; // Decrease lightness based on index
      return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    },

    renderBarChart() {
      if (this.recurringChartInstance) {
        this.recurringChartInstance.destroy();  // Destroy the old chart instance if it exists.
      }

      const ctx = this.$refs.barChartCanvas.getContext('2d');
      const totalAmount = this.getTotalAmount();

      const datasets = this.recurringChartData.map((item, index) => {
        const percentage = (item.amount / totalAmount) * 100;
        return {
          label: item.name,
          data: [percentage], // Set data to the calculated percentage
          originalAmount: item.amount,
          borderColor: this.getRandomColor(index),
          backgroundColor: this.getRandomColor(index),
          borderRadius: 5,
          stack: 'stacked',
        };
      });

      const data = {
        labels: ['Recurring Transactions'], // Single label since we have only one stacked bar
        datasets: datasets,
      };

      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            display: false,
          },
          y: {
            stacked: true,
            display: false,
            ticks: {
              beginAtZero: true,
              callback: function(value) {
                return value + '%'; // Display values as percentages
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            display: true,
            color: '#ffffff',
            anchor: 'middle',
            align: 'middle',
            formatter: function(value, context) {
              const originalAmount = context.chart.data.datasets[context.datasetIndex].originalAmount;
              const percentage = (originalAmount / totalAmount * 100).toFixed(2);
              return `${context.chart.data.datasets[context.datasetIndex].label} (${percentage}%)`;
            },
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                var label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                var value = context.raw;
                return label + value.toFixed(2) + '%';
              },
            },
          },
        },
      };

      this.recurringChartInstance = new Chart(ctx, {
        type: 'bar',
        data: data,
        options: options,
      });
    },
  },

};
</script>

<style>
.bar-chart-container {
  /* width: 100%; */
  width: 23vw;
  margin: auto;
  height: 65vh;
  /* padding-top: 100px; */
}

</style>