<template>
  <h1>Terms of Service</h1>
  <div class="TermsofService">
    
    <p>These Terms of Service ("Terms") govern your access to and use of the Fivebags website, including any content, functionality, and services offered on or through <a href="http://www.fivebags.co">Fivebags</a> (the "Website"), whether as a guest or a registered user.</p>
    <p>Please read the Terms carefully before you start to use the Website. By using the Website or by clicking to accept or agree to the Terms when this option is made available to you, you accept and agree to be bound and abide by these Terms and our Privacy Policy, incorporated herein by reference. If you do not want to agree to these Terms or the Privacy Policy, you must not access or use the Website.</p>

    <h2>1. Accounts</h2>
    <p>When you create an account with us, you guarantee that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Website. You are responsible for maintaining the confidentiality of your account and password, including but not limited to the restriction of access to your computer and/or account. You agree to accept responsibility for any and all activities or actions that occur under your account and/or password, whether your password is with our Website or a third-party service. You must notify us immediately upon becoming aware of any breach of security or unauthorized use of your account.</p>

    <h2>2. Content</h2>
    <p>Our Website allows you to import and create transaction data. You are responsible for the data that you import or create on the Website, including its legality, reliability, and appropriateness. All data imported or created by you remains your own property.</p>

    <h2>3. Intellectual Property</h2>
    <p>The Website and its original content (excluding Content provided by users), features, and functionality are and will remain the exclusive property of Fivebags and its licensors. The Website is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Fivebags.</p>

    <h2>4. Feedback</h2>
    <p>We welcome feedback, comments, and suggestions for improvements to the Website ("Feedback"). You agree that we may use the Feedback you provide to us in any way, including in future modifications of the Website, other products or services, and advertising or marketing materials. You grant us a perpetual, worldwide, fully transferable, sublicensable, non-revocable, fully paid-up, royalty-free license to use the feedback you provide to us in any way.</p>

    <h2>5. Subscriptions</h2>
    <p>Some parts of the Website are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.Some parts of the Website are billed on a subscription basis ("Subscription(s)"). You will be billed in advance on a recurring and periodic basis ("Billing Cycle"). Billing cycles are set either on a monthly or annual basis, depending on the type of subscription plan you select when purchasing a Subscription.</p>

    <h2>6. Free Trial</h2>
    <p>We offer a free trial to new users who register on our website. At the end of the free trial period, unless you cancel your Subscription, you will automatically be charged the applicable Subscription fees for the next billing cycle.</p>

    <h2>7. Promotions and Contests</h2>
    <p>We may, from time to time, offer promotions and contests to users. The terms and conditions of those promotions and contests will be specified at the time of the promotion.</p>

    <h2>8. Termination</h2>
    <p>To terminate your account, please contact support at support@fivebags.co and request the deletion/termination of your account. We may terminate or suspend your account and access to the Website immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Website will immediately cease.</p>

    <h2>9. Indemnification</h2>
    <p>You agree to defend, indemnify and hold harmless Fivebags and its licensee and licensors, and their employees, contractors, agents, officers and directors, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees), resulting from or arising out of a) your use and access of the Service, by you or any person using your account and password, or b) a breach of these Terms.</p>

    <h2>10. Limitation of Liability</h2>
    <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL [Fivebags], ITS OFFICERS, DIRECTORS, EMPLOYEES, PARTNERS, SUPPLIERS, OR AFFILIATES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICE; (B) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE; (C) ANY CONTENT OBTAINED FROM THE SERVICE; AND (D) UNAUTHORIZED ACCESS, USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, WHETHER OR NOT WE HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.</p>

    <h2>11. Disclaimer</h2>
    <p>Your use of the service is at your sole risk. The service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is not intended to provide legal, tax, or financial advice. [Fivebags] is not a financial planner, broker, or tax advisor. The service is intended only to assist you in your financial organization and decision-making and is broad in scope. Your personal financial situation is unique, and any information and advice obtained through the service may not be appropriate for your situation. Accordingly, before making any final decisions or implementing any financial strategy, you should consider obtaining additional information and advice from your accountant or other financial advisers who are fully aware of your individual circumstances.</p>

    <h2>12. Governing Law</h2>
    <p>These Terms shall be governed and construed in accordance with the laws of the State of Delaware, United States, without regard to its conflict of law provisions.</p>

    <h2>13. Severability</h2>
    <p>If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.</p>

    <h2>14. Changes</h2>
    <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. By continuing to access or use our Website after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Website.</p>

    <h2>15. Contact Us</h2>
    <p>If you have any questions about these Terms, please contact us at support@fivebags.co.</p>


  </div>
</template>


<script>
/* eslint-disable */


export default {
  name: 'Terms of Service',
  components: {
  },
  data() {
    return {
      
    };
  },
  mounted() {
  },
  computed: {
  },

};
</script>

<style scoped>

.TermsofService {
  width: 60%;
  margin: 0 auto;
}

.TermsofService h2 {
  text-align: left;
  font-size: 16px;
}

.TermsofService p {
  text-align: left;
  margin-left: 20px;
  font-size: 14px;
}


</style> 