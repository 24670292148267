<template>
  <div class="main-body" :class="{ 'light-mode': lightMode }">
    <div v-if="loading" class="loading-indicator"></div>
    <TheHeader :lightMode="lightMode" @toggle-light-mode="toggleLightMode" />
      <div class="spacer"></div>
      <div class="headline-container" ref="headlineContainer">
        <h1 class="headline">
          <span class="headline-top">Take control of your financial future</span>
        </h1>
        <p class="subheadline">
          <!-- <span class="subheadline-line">The most advanced money app that shows you when you can</span> -->
          <!-- <span class="subheadline-line">leave the 9-to-5 grind and start living your dreams.</span> -->
          <!-- <span class="subheadline-line">Find out when you can quit your job and start living your dreams.</span> -->
          <span class="subheadline-line">Monitor your spending, manage budgets, and track your net worth.</span><br>

          <span class="subheadline-line2">Fiyr calculates your Financial Independence Retire Early (FIRE) date in minutes, using your real data.</span><br>
          <span class="subheadline-line2">Find out when you can <span class="underline">quit your job</span> and start living your dreams.</span>

          <!-- <span class="subheadline-line2">Monitor your spending, manage budgets, track assets, liabilities, goals, and oversee your net worth—all in one seamless platform.</span> -->
        </p>
        <div class="main-image-container">
          <div class="tab-container2">
              <button
                v-for="(tab, index) in tabs"
                :key="index"
                @click="changeTab(tab.image)"
                :class="{ 'active-tab': currentTab === tab.image }"
              >
                {{ tab.name }}
              </button>
          </div>

          <div class="image-wrapper">
            <img :src="currentTab" alt="Tab Image" class="image-image" @mousemove="tiltImage" />

          </div>
          <div class="glare"></div>
        </div>
        <div class="tab-container">

        <div class="feature-details">
          <div class="feature-details-list" v-if="currentTab === tabs[0].image">
            <p>Take control of your spending with our intuitive transaction management. View detailed summaries, use bulk editing for efficiency, and set up custom categorization rules. Import/export via CSV and enjoy fully editable transactions, giving you complete oversight of your finances.</p>
          </div>
          <div class="feature-details-list" v-else-if="currentTab === tabs[1].image">
            <p>Calculate your path to financial independence with our FIRE tool. Set your retirement target, adjust key factors like spending and savings rate, and watch as we project your FIRE date. Track your progress in real-time and make informed decisions to reach your goals faster.</p>
          </div>
          <div class="feature-details-list" v-else-if="currentTab === tabs[2].image">
            <p>Stay on top of your recurring expenses effortlessly. Preview upcoming transactions, identify regular charges, and customize frequencies to match your financial patterns. Pause recurring transactions when needed, ensuring you're always in control of your regular spending.</p>
          </div>
          <div class="feature-details-list" v-else-if="currentTab === tabs[3].image">
            <p>Experience smart budgeting that adapts to your life. Our system adjusts to your recurring subscriptions, tracks daily progress, and allows future planning. Toggle credit card spending and create budget templates to streamline your financial management.</p>
          </div>
          <div class="feature-details-list" v-else-if="currentTab === tabs[4].image">
            <p>Achieve your financial dreams with our goal-setting features. Set targets, allocate funds, and track your safe-to-spend balance. Our tool calculates daily savings needed and allows you to manage multiple accounts, turning aspirations into reality.</p>
          </div>
          <div class="feature-details-list" v-else-if="currentTab === tabs[5].image">
            <p>Get a comprehensive view of your wealth with our net worth tracker. Monitor diverse assets, keep an eye on liabilities, and analyze your financial health over time. Gain insights with detailed breakdowns by type and asset class, empowering smarter financial decisions.</p>
          </div>
        </div>
        </div>
        <div class="feature-boxes-container">
          <div class="feature-row">
            <div class="feature-box">
              <div class="feature-title">
                <span style="background-color: transparent;">master your spending</span>
              </div>
              <p>Take control of your finances with our powerful spending tracker. Get detailed transaction summaries, utilize bulk editing for efficiency, and create custom rules to categorize your expenses automatically. With CSV import/export and full editability, you'll have unprecedented control over your financial data.</p>
            </div>
            <div class="feature-image">
              <img style="width: 180%; margin-left: -500px" :src="require('@/assets/spending.png')" alt="Spending Feature" />
              <!-- <img :src="require('@/assets/spending.gif')" alt="Spending Feature" /> -->
            </div>
          </div>

          <div class="feature-row reverse">
            <div class="feature-image">
              <img style="width: 150%;" src="fire.png" alt="FIRE Calculator Feature" />
            </div>
            <div class="feature-box">
              <div class="feature-title">
                <span style="background-color: transparent;">calculate your fire date</span>
              </div>
              <p>Chart your path to financial independence with our FIRE calculator. Watch as we project your FIRE date based on your current finances, and adjust key factors like annual spending and savings rate to see how they impact your goals. Track your progress in real-time and make informed decisions to reach financial freedom faster.</p>
            </div>
          </div>

          <div class="feature-row">
            <div class="feature-box">
              <div class="feature-title">
                <span style="background-color: transparent;">tame recurring expenses</span>
              </div>
                <p>Never be surprised by a subscription again. Our recurring transaction tracker helps you identify and manage your regular expenses with ease. Set custom frequencies, pause subscriptions when needed, and get a clear view of your upcoming financial commitments. Stay on top of your cash flow like never before.</p>
            </div>
            <div class="feature-image">
              <img style="width: 180%; margin-left: -500px" :src="require('@/assets/recurring.png')" alt="Recurring Transactions Feature" />
            </div>
          </div>

          <div class="feature-row reverse">
            <div class="feature-image">
              <img style="width: 150%;" src="/budget.png" alt="Budgeting Feature" />
            </div>
            <div class="feature-box">
              <div class="feature-title">
                <span style="background-color: transparent;">smart budgeting, simplified</span>
              </div>
              <p>Experience budgeting that adapts to your life. Our dynamic budgets automatically adjust to your recurring subscriptions, giving you a more accurate picture of your finances. Toggle credit card categories, track daily progress, and plan for the future with customizable budget templates. Budgeting has never been this flexible or insightful.</p>
            </div>
          </div>

          <div class="feature-row">
            <div class="feature-box">
              <div class="feature-title">
                <span style="background-color: transparent;">your wealth, at a glance</span>
              </div>
              <p>Get a comprehensive view of your financial health with our net worth tracker. Monitor diverse assets from investments to real estate, keep tabs on your liabilities, and watch your wealth grow over time. With detailed breakdowns by type and asset class, you'll have the insights you need to make smarter financial decisions.</p>
            </div>
            <div class="feature-image">
              <img style="width: 180%; margin-left: -500px" :src="require('@/assets/networth.png')" alt="Net Worth Feature" />
            </div>
          </div>

          <div class="feature-row reverse">
            <div class="feature-image">
              <img style="width: 150%;" src="/goals.png" alt="Goals Feature" />
            </div>
            <div class="feature-box">
              <div class="feature-title">
                <span style="background-color: transparent;">turn dreams into reality</span>
              </div>
              <p>Set, track, and achieve your financial goals with ease. Whether it's a dream vacation or a new home, our goal tracker calculates your daily savings target and shows your safe-to-spend balance. Aggregate multiple accounts, choose which to include in calculations, and watch as your dreams become reality, one transaction at a time.</p>
            </div>
          </div>
        </div>
        <TheFooter class="footer-container" :lightMode="lightMode" />
      </div>
</div>

</template>

<script>

import { ref, onMounted } from 'vue';
import { useStore } from 'vuex'; // Make sure to import useStore if not already imported
import TheHeader from '../components/TheHeader.vue'
import TheFooter from '../components/TheFooter.vue'


export default {
  components: {
    TheHeader,
    TheFooter,
  },
  
  mounted() {
    window.addEventListener('wheel', this.handleScroll, { passive: false });
  },
  computed: {
    lightMode() {
      return this.$store.state.lightMode;
    },
  },

  setup() {
    const store = useStore();
    const email = ref('');
    const toggleLightMode = () => {
      store.dispatch('toggleLightMode', true);
    };
    const countdown = ref({
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    // let countdownInterval = null;


    const tabs = ref([
      { name: 'Spending', image: require('@/assets/spending-final2.png') },  
      { name: 'FIRE', image: require('@/assets/fire-final3.png') },
      { name: 'Recurring', image: require('@/assets/recurring-final.png') },
      { name: 'Budget', image: require('@/assets/budget-final2.png') },
      { name: 'Goals', image: require('@/assets/goals-final3.png') },
      { name: 'Net Worth', image: require('@/assets/networth-final.png') },
    ]);

    const currentTab = ref(tabs.value[0].image);

    const changeTab = (tabImage) => {
      currentTab.value = tabImage;
    };

    onMounted(() => {
      toggleLightMode();
    });

    // const updateCountdown = () => {
    //   const eventTime = new Date("July 1, 2024").getTime();
    //   const currentTime = new Date().getTime();
    //   const difference = eventTime - currentTime;
    //   if (difference < 0) {
    //     clearInterval(countdownInterval);
    //     return;
    //   }
    //   countdown.value.days = Math.floor(difference / (1000 * 60 * 60 * 24));
    //   countdown.value.hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //   countdown.value.minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    //   countdown.value.seconds = Math.floor((difference % (1000 * 60)) / 1000);
    // };

    const submitForm = () => {
      // Implement form submission logic here
      console.log('Form submitted with email:', email.value);
    };

    const loading = ref(true); // Add loading state

    return { email, countdown, submitForm, tabs, currentTab, changeTab, loading, toggleLightMode }; // Include loading state in the return object
  },
  methods: {
    goToLogin() {
      this.$router.push('/login');
    },
    
    preloadImages() {
      const imageUrls = [];
      for (let i = 1; i <= 282; i += 2) {
        const frameNumber = i.toString().padStart(4, '0');
        const imageUrl = require(`@/assets/fiyr4/fiyr-${frameNumber}.png`);
        imageUrls.push(imageUrl);
      }

      const preloadPromises = imageUrls.map(url => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = url;
        });
      });

      return Promise.all(preloadPromises);
    },
    tiltImage(event) {
      const image = event.currentTarget;
      const rect = image.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const mouseX = event.clientX - centerX;
      const mouseY = event.clientY - centerY;
      const rotateX = (mouseY / rect.height) * 3;
      const rotateY = (mouseX / rect.width) * -3;
      image.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
    },
    // toggleLightMode() {
    //   this.$store.dispatch('toggleLightMode', !this.lightMode);
    // },
    goToManifesto() {
      this.$router.push('/manifesto');
    },
    goToBlog() {
      this.$router.push('/blog');
    },
    scrollToEarlyAccess() {
      const earlyAccessElement = document.querySelector('.early-access');
      earlyAccessElement.scrollIntoView({ behavior: 'smooth' });
    },
    
    goToPrivacyPolicy() {
      this.$router.push('/privacy-policy');
    },
    goToTermsOfService() {
      this.$router.push('/terms-of-service');
    },
  },
  beforeUnmount() {
    // clearInterval(this.countdownInterval);
    window.removeEventListener('scroll', this.handleScroll);
    
  },
};
</script>

<style scoped>

body {
  background-color: black;
  color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

body.light-mode {
  background-color: white;
  color: black;
}

body.light-mode .tab-container {
  background-color: white;
}

body.light-mode .feature-details{
  background-color: white;
  color: black;
}

body.light-mode .headline-container {
  background-color: white;
}

body.light-mode .tab-container2{
  background-color: #eef7f8;
  border: thin solid #f0f0f0;
}

body.light-mode .tab-container2 button:hover {
  color: black;
}

body.light-mode .tab-container2{
  background-color: #eef7f8;
  border: thin solid #ede7e7;
}

body.light-mode .spacer {
  background-color: white;
}

body.light-mode .image-image {
  padding: 25px 25px 0px 25px;
  border: 8px solid #b3200c;
  border-radius: 42px;
  position: relative;

  background-color: rgba(var(--color-v3-white), .5);
  border-end-start-radius: 0;
  width: 80%;
  margin: 30px;
  border-end-end-radius: 0;
  -webkit-mask-image: linear-gradient(180deg, #000 0 81%, transparent 90%);
  mask-image: linear-gradient(180deg, #000 0 60%, transparent 100%);
  background-color: rgb(0 0 0);
  /* box-shadow: 1px 5px 20px 1px rgba(0, 0, 0, 0.1); */
}

.feature-details {
  /* height: 240px; */
  text-align: left;
  background-color: black;
  color: white;
}

.feature-details-list {
  width: 90%;
    margin: 0 auto;
    color: #696969;
    text-align: center;
}


.feature-details-list path {
  font-size: 1rem;
}

.feature-details-list p {
  margin-top: -50px;
  color: #4c92ae;
  font-weight: 600;
  font-size: 20px;
}

.header,
.content-wrapper,
.footer {
  background-color: black;
  transition: background-color 0.3s ease-in-out;
}

body.light-mode .content-wrapper,
body.light-mode .scroll-container,
body.light-mode .footer {
  background-color: white;
  color: black;
}

body.light-mode .headline-container {
  color: black;
}

body.light-mode .main-image-container {
  background-color: white;
}

body.light-mode a {
  color: black;
}

.subscribe {
  background-color: black;
  color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

body.light-mode .subscribe {
  background-color: white;
  color: black;
}

.subscribe-button {
  background-color: black;
  color: white;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

body.light-mode .subscribe-button {
  background-color: white;
  color: black;
  border-color: black;
}

.tab-container2 button {
  color: #8c8c8c;
  transition: color 0.3s ease-in-out;
}

body.light-mode .tab-container2 button {
  color: black;
}

body.light-mode .active-tab {
  color: white !important;
    background-color: #ff5252 !important;
    box-shadow: 2px 7px 20px 0px rgba(0, 0, 0, 0.1);
}

.transparent-button {
  color: white;
  transition: color 0.3s ease-in-out;
}


body.light-mode .transparent-button {
  color: black;
}

.red-button {
  background-color: red;
  color: white;
}

body::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../assets/grain.jpg'); */
  background-repeat: repeat;
  opacity: 1;
  z-index: 9999;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

body.light-mode::before {
  opacity: 0.2;
}


.main-image-container {
  background-color: black;
  /* padding-top: 30%; */
}

  @media (max-width: 500px) {
    .scroll-container {
      /* height: 100vh; */
    }

    .headline-container {
      bottom: 50px !important;
      left: 50px !important;
      transform: unset !important;
    }
    .headline {
      font-size: 22px !important;
    }



    .header {
      padding: 0px !important;
    }

    .logo {
      padding: 0px !important;
      margin: 0px !important;
    }

    .feature-details {
      font-size: 12px !important;
      padding: 20px !important;
    }

    .header-buttons {
      font-size: 12px !important;
    }
    .red-button {
      width: unset !important;
      margin: 0px 10px 0px 0px !important;
      font-size: 12px !important;
    }
    .transparent-button {
      padding: 0px !important;
      font-size: 12px !important;
    }

    .subheadline>.subheadline-line[data-v-c6bdc170] {
        font-size: .4em;
    }
    .image-image {
      height: unset !important;
      /* margin-top: 50px !important; */
    }

    .image-frame {
      height: 60% !important;
      margin-top: 250px !important;
    }

    .image-container {
      height: 60% !important;
      /* margin-top: 250px !important; */
    }

    .tab-container2 {
      padding: 0px !important;
    }

    .tab-container button {
      margin: 5px 5px !important;
      font-size: 12px !important;
      padding: 5px !important;
    }

    .footer-right {
      gap: 50px !important;
    }

    .footer {
      padding: 10px !important;
      font-size: 12px !important;
    }

    .footer p {
      font-size: 12px !important;
      margin-bottom: 0px !important;
    }

    .footer-column {
      font-size: 12px !important;
    }

    .content {
      width: 100% !important;
      height: auto;
      max-height: 100%;
      object-fit: contain;
      position: static;
    }

    .subscribe-list {
      margin: 0 auto;
      max-width: 80vw;
    }
  }


.authoptions {
  display: flex;
  justify-content: space-between;
}

.subscribe-list {
  width: max-content;
  margin: 0 auto;
}

.subscribe {
  /* background-color: black; */
  border-radius: 5px;
  color: white;
}

.subscribe-button {
  margin: 15px 0;
  transition: 0.3s ease-in-out;
}

.subscribe-button:hover {
  /* color: white; */
  /* background-color: black; */
  cursor: pointer;
  transition: 0.3s ease-in-out;
}

.early-access {
  color: black;
  width: 300px;
}

#subscribe_form {
  width: 500px;
}
.image-wrapper {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
}

.content-wrapper {
  position: relative;
  z-index: 2;
  /* background-color: black; */
}

.scroll-container {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}




.image-frame {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 200px;
  left: 0;
  opacity: 1;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
}

.image-frame.scale-down {
  transform: scale(0.8);
  opacity: 0;
}

.image-container {
  position: absolute;
  top: 150px;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
  pointer-events: none;
  z-index: 1;
  perspective: 1000px;
  
}

.image-container.show {
  opacity: 1;
  pointer-events: auto;
  
}

.image-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.1s ease;
  background-color: rgb(14, 14, 14);
  border: 1px solid rgb(44, 44, 44);
  border-radius: 1.5rem;
  /* padding: 3rem 2.5rem; */
}


.tab-container2, .feature-details {
  display: grid;
  justify-content: center;
  padding: 0px 0px 50px;
  color: white;
}

.tab-container2 button {
  margin: 10px 5px;
  padding: 5px 15px;
  background-color: transparent;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.tab-container2 button:hover {
  background-color: rgb(204 204 204);
  box-shadow: 2px 7px 20px 6px rgba(0, 0, 0, 0.1);
  transition: all ease;
}

.active-tab {
  background-color: red !important;
    color: white !important;
    font-weight: bold;
}

.early-access {
  /* padding: 150px 0px; */
  margin: 0 auto;
  width: 100%;
  /* background-color: black; */
}

body.light-mode .countdown {
  color: black;
}

.countdown {
  padding: 20px;
  color: white;
}

.headline-container {
  position: absolute;
  width: 100vw;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 1;
  color: white;
  background-color: black;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.headline-container.fade-out {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.headline {
  font-size: 5vw;
  line-height: 1.15;
  margin-bottom: 24px;
  letter-spacing: -2px;
  font-weight: 900;
  text-align: center;
  background-color: transparent;
  background-image: linear-gradient(to bottom, #55e2ff, #84a2c8);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.headline-top {
  display: block;
  background-color: transparent;
}

.subheadline {
  color: rgb(226, 238, 235);
  display: block;
  margin-top: 12px;
  letter-spacing: -1px;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  background-color: transparent;
}


.subheadline-line {
  display: block;
  background-color: transparent;
}

.subheadline>.subheadline-line {
  font-size: 2.5vw;
  /* margin-top: 20px;
  line-height: 1.8; */
}

.subheadline>.subheadline-line2 {
  font-size: 1.4vw;
  margin-top: 20px;
  /* line-height: 2.8; */
}

.subheadline>.subheadline-line3 {
  font-size: 1em;
  margin-top: 20px;
  line-height: 1.8;
}


.tab-container2 {
  display: flex;
  justify-content: center;
  background-color: #0e0e0e;
  border-radius: 30px;
  border: thin solid #2b2b2b;
  /* box-shadow: 1px 5px 20px 1px rgba(0, 0, 0, 0.1); */
  max-width: fit-content;
  margin: 20px auto 0px;
  padding: 0px 20px;
}

.tab-container {
  padding-top: 20px;
  width: 60%;
  margin: 0 auto;
  background-color: black;
}


.logo {
  color: red;
    font-size: 34px;
    font-weight: 900;
    margin: 10px;
    padding: 10px;
    background-color: unset;
}

.header-buttons {
  display: flex;
  gap: 10px;
  background-color: unset;
}

.header-left {
  display: flex;
  align-items: center;
  background-color: unset;
}

.logo {
  margin-right: 10px;
}


.header {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  z-index: 10000;
  background-color: white; 
  align-items: center;
  padding: 0px;
}


.transparent-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: unset;
}

.red-button {
  background-color: red;
    color: white;
    padding: 10px;
    border: none;
    width: 150px;
    margin: 20px 20px;
    font-size: 16px;
    cursor: pointer;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
  background-color: black;
  color: white;
  font-size: 20px;
}

.footer-left {
  text-align: left;
}

.footer-right {
  display: flex;
  gap: 80px;
  text-align: left;
}

.footer-column {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.footer a {
  color: white;
  text-decoration: none;
}

.footer p {
  font-size: 14px;
}

.main-body {
  background-color: black;
}

.light-mode .subheadline {
  color: rgb(53 53 53);
}

.spacer {
  height: 140px;
}



.feature-boxes-container {
  padding: 40px 20px;

}

.feature-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}



.feature-box {
  flex: 1;
  z-index: 1;
    /* background-color: #1a1a1a; */
    /* border-radius: 10px; */
    background-color: rgb(14, 14, 14);
    border: 1px solid rgb(231 231 231);
    border-radius: 1rem;
    padding: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    max-width: 40%;
    height: 350px;
    margin: 0 auto;
}

.feature-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-box h2 {
  color: #55e2ff;
  font-size: 2rem;
  margin-bottom: 15px;
}

.feature-box p {
  color: #e2eeeb;
  font-size: 0.9rem;
  line-height: 1.6;
}

.feature-image {
  flex: 1;
  max-width: 50%;
  padding: 20px;
}

.feature-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .feature-row, .feature-row.reverse {
    flex-direction: column;
  }

  .feature-box, .feature-image {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .feature-box {
    padding: 15px;
  }

  .feature-box h2 {
    font-size: 1.2rem;
  }

  .feature-box p {
    font-size: 0.8rem;
  }
}

/* Light mode styles */
body.light-mode .feature-boxes-container {
  /* background-color: #f0f0f0; */
}

body.light-mode .feature-box {
  background-color: #ffffff;
  box-shadow: 5px 9px 0px 2px rgb(29 29 29 / 5%);
}

body.light-mode .feature-box h2 {
  color: #0066cc;
}

body.light-mode .feature-box p {
  color: #333333;
}

.feature-title {
  font-size: 3vw;
    line-height: 1.15;
    margin-bottom: 24px;
    letter-spacing: -2px;
    font-weight: 900;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #55e2ff, #84a2c8);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}


.image-wrapper {
  position: relative;
  display: inline-block; /* Adjust based on your layout needs */

}


body.light-mode .image-wrapper::after {
  background: linear-gradient(to bottom, rgba(255,255,255,0), rgba(255,255,255,1));
}

body:not(.light-mode) .image-wrapper::after {
  background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
}
</style>