<template>
    <div class="loader-container hidden">
      <span class="loader"></span>
    </div>
  <div class="onboarding-style-spending">
    <h1 class="fade-slide-down" id="welcome-text">Welcome to Fiyr</h1>
    <h2 class="fade-slide-down" id="import-text">Import your transactions to get started. <br>
      You'll be able to view and edit them later.</h2>
    <!-- <PlaidLink :onTransactionsFetched="handleTransactions" /> -->
  
    <v-btn style="margin-right: 10px;"  @click="linkNewAccount">Link Transactions</v-btn>
    <v-btn style="width: 110px" class="import-csv-button" @click="openFileInput">Import CSV</v-btn>
    <div class="csvimport" v-if="showCsvImport">
      <input type="file" ref="fileInput" @change="handleCsvFileChange" accept=".csv">
    </div>
  </div>
  <div v-if="transactionsImportedCheck" class="continue-section">
      <p class="continue-message">Your transactions are being imported. Let's continue to the next step!</p>
      <v-btn @click="goToOnboarding2">Continue</v-btn>
    </div>
  <div v-if="showPlaidModal" class="modal-backdrop">
    <div class="modal-content">
      <!-- Loading bar and transaction count -->
      <div v-if="importingPlaid">
        <div class="progress-bar-container">
          <div class="progress-bar" :style="{ width: plaidProgressBarWidth }"></div>
        </div>
        <p>Importing Transactions: {{ plaidTransactionsImported }}/{{ totalPlaidTransactions }} ({{ plaidProgressBarPercentage }}%)</p>
      </div>
      <button @click="showPlaidModal = false">Cancel</button>
    </div>
  </div> 
      <!-- Overlay modal for CSV Import -->
      <div v-if="showCSVModal" class="modal-backdrop">
      <div class="modal-content">
        <div v-for="(value, key) in columnMappings" :key="key" class="modal-field2">
          <label>{{ key.charAt(0).toUpperCase() + key.slice(1) }}:</label>
          <select v-if="key !== 'labels'" class="csv-column-map-select" v-model="columnMappings[key]" :class="{ 'error': errors[key] }">
            <option disabled value="">Select a column</option>
            <option v-for="(header, index) in csvHeaders" :value="index.toString()" :key="index">
              {{ header }}
            </option>
          </select>
          <select v-else class="csv-column-map-select" v-model="columnMappings[key]">
            <option value="">Select a column (optional)</option>
            <option v-for="(header, index) in csvHeaders" :value="index.toString()" :key="index">
              {{ header }}
            </option>
          </select>
          <div v-if="errors[key]" class="error-message">{{ errors[key] }}</div>
        </div>
        <!-- Loading bar and transaction count -->
        <div v-if="importing">
          <div class="progress-bar-container">
            <div class="progress-bar" :style="{ width: progressBarWidth }"></div>
          </div>
          <p>{{ transactionsImported }}/{{ totalTransactions }} ({{ progressBarPercentage }}%)</p>
        </div>
        <div class="csv-import-buttons">
          <!-- <v-btn v-if="!importing" @click="processCsv">Import</v-btn> -->
          <v-btn v-if="!importing" @click="validateAndImport" ref="importButton">Import</v-btn>
          <v-btn @click="showCSVModal = false; showCsvImport = false">Cancel</v-btn>
        </div>
      </div>
    </div>
    <div v-if="showCategoryMappingModal" class="modal-backdrop">
      <div class="modal-content" style="max-height: 80%; overflow: scroll;">
          <h2 style="margin-bottom: 20px;">Map CSV Categories</h2>
          <div v-for="(csvCategory, index) in csvCategories" :key="index" class="category-mapping">
            <label>{{ csvCategory }}</label>
            <select class="csv-select" v-model="categoryMappings[csvCategory]">
              <option value="">Select a category</option>
              <option v-for="category in categories" :key="category.name" :value="category.name">
                {{ category.name }}
              </option>
            </select>
          </div>
          <div class="modal-actions">
            <v-btn @click="showCategoryMappingModal = false">Save</v-btn>
          </div>
        </div>
      </div>
      
</template>


<script>
/* eslint-disable */
import axios from 'axios';
import { query, where, collection, onSnapshot, addDoc, doc, setDoc, getDoc, getDocs, updateDoc} from 'firebase/firestore'
import { auth, db } from '../firebaseInit'; // Import the initialized Firebase auth and Firestore instances
import mitt from 'mitt';
import PlaidLink from '../components/PlaidLink.vue';

const eventBus = mitt();


export default {
  name: 'Onboarding',
  components: {
    PlaidLink,
  },
  data() {
    return {
      transactions: [],
      // Plaid import
      showPlaidModal: false,
      importingPlaid: false,
      plaidTransactionsImported: 0,
      totalPlaidTransactions: 0,
      categoryMap: {},
      // Csv import
      errors: {
        name: '',
        amount: '',
        date: '',
        category: '',
      },
      csvCategories: [],
      categoryMappings: {},
      showCategoryMappingModal: false,
      showCsvImport: false,
      showCSVModal: false,
      importing: false,
      totalTransactions: 0,
      transactionsImported: 0,
      transactionsImportedCheck: false,
      columnMappings: { 
        name: '',
        amount: '',
        date: '',
        category: '',
        labels: ''
      },
    };
  },
  computed: {
    plaidProgressBarWidth() {
      return `${(this.plaidTransactionsImported / this.totalPlaidTransactions) * 100}%`;
    },
    plaidProgressBarPercentage() {
      return Math.round((this.plaidTransactionsImported / this.totalPlaidTransactions) * 100);
    },
    progressBarWidth() {
      return `${(this.transactionsImported / this.totalTransactions) * 100}%`;
    },
    progressBarPercentage() {
      return Math.round((this.transactionsImported / this.totalTransactions) * 100);
    },
    categories() {
      return this.$store.state.categories;
    },
  },
  directives: { 
    'click-outside': {
      bind(el, binding) {
        el.clickOutsideEvent = function (event) {
          // Check if the click was outside the element
          if (!(el === event.target || el.contains(event.target))) {
            // If so, call the provided method
            binding.value();
          }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unbind(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    },
  },
  mounted() {
    this.$store.dispatch('initializeSyncingTransactions');
      // Check the sync status on page load
      const itemIds = Object.keys(localStorage).filter(key => key.startsWith('syncItem_'));
      itemIds.forEach(itemId => {
        const syncStartTime = localStorage.getItem(itemId);
        const elapsedTime = Date.now() - syncStartTime;
        if (elapsedTime > this.progressBannerTimeout) {
          localStorage.removeItem(itemId);
          this.$store.dispatch('updateSyncingTransactions', false);
        } else {
          this.checkTransactionSyncStatus(itemId.replace('syncItem_', ''));
        }
      });

      // Listen for sync events
      eventBus.on('syncStarted', () => {
        this.$store.dispatch('updateSyncingTransactions', true);
        // Navigate to the second onboarding page
        this.$router.push('/onboarding2');
      });

      eventBus.on('syncComplete', async () => {
        this.$store.dispatch('updateSyncingTransactions', false);
        // Fetch transactions and categories when sync is complete
        await this.$store.dispatch('fetchTransactions');
        await this.$store.dispatch('fetchCategories');
      });

      eventBus.on('syncTimeout', async () => {
        this.$store.dispatch('updateSyncingTransactions', false);
        // Fetch transactions and categories when sync times out
        await this.$store.dispatch('fetchTransactions');
        await this.$store.dispatch('fetchCategories');
      });
  },
  methods: {
    goToOnboarding2() {
      this.$router.push('/onboarding2');
    },
    async linkNewAccount() {
      await this.createLinkToken();;
      // this.$store.dispatch('updateSyncingTransactions', false)
      this.openPlaidLink();
    },
    interpolateValues(startDate, endDate, startValue, endValue) {
      if (isNaN(startValue) || isNaN(endValue)) {
        // If start or end values are not numbers, return only the current value and date
        return [{
          date: endDate,
          value: isNaN(endValue) ? 0 : Math.round(endValue)
        }];
      }
      
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffYear = end.getFullYear() - start.getFullYear();
        const diffMonth = end.getMonth() - start.getMonth();
        const monthDiff = diffYear * 12 + diffMonth;
        let interpolatedValues = [];
        
        for (let i = 0; i <= monthDiff; i++) {
          const monthOffset = start.getMonth() + i;
          const year = start.getFullYear() + Math.floor(monthOffset / 12);
          const month = monthOffset % 12;
          const daysInMonth = new Date(year, month + 1, 0).getDate();
          const day = start.getDate() > daysInMonth ? daysInMonth : start.getDate();
          const date = new Date(year, month, day);
          const fraction = monthDiff === 0 ? 1 : i / monthDiff;
          const interpolatedValue = startValue + (endValue - startValue) * fraction;
          interpolatedValues.push({
            date: `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`,
            value: Math.round(interpolatedValue)
          });
        }
        return interpolatedValues;
      },
    async fetchClassCollections() {
      try {
        const user = auth.currentUser;
        if (user) {
          const assetClassesCollection = collection(db, `users/${user.uid}/assetclasses`);
          const assetClassesSnapshot = await getDocs(assetClassesCollection);
          this.assetClasses = assetClassesSnapshot.docs.map(doc => doc.data().name);

          const liabilityClassesCollection = collection(db, `users/${user.uid}/liabilityclasses`);
          const liabilityClassesSnapshot = await getDocs(liabilityClassesCollection);
          this.liabilityClasses = liabilityClassesSnapshot.docs.map(doc => doc.data().name);
        } else {
          console.log('No user is logged in.');
        }
      } catch (error) {
        console.error('Error fetching class collections:', error);
      }
    }, 
    async onTransactionSyncComplete() {
      this.$store.dispatch('updateSyncingTransactions', false);
      await this.$store.dispatch('fetchTransactions');
      await this.$store.dispatch('fetchCategories');
    },
    async checkTransactionSyncStatus(item_id) {
      const userId = auth.currentUser.uid; // Ensure you have the current user's ID
      // Store the sync start time in local storage
      localStorage.setItem(`syncItem_${item_id}`, Date.now());

      // Emit the 'syncStarted' event
      eventBus.emit('syncStarted');
      console.log(`Starting to check sync status for user: ${userId} and item: ${item_id}`);
      
      this.$store.dispatch('updateSyncingTransactions', true); // Initially set to true

      let syncComplete = false;
      let timeoutId = null; // Declare a variable to store the timeout ID

      // Emit the 'syncComplete' event when the sync is complete
      eventBus.emit('syncComplete');


      timeoutId = setTimeout(() => {
        console.log(`Sync timeout reached for item ${item_id}. Hiding progress banner.`);
        this.$store.dispatch('updateSyncingTransactions', false);
      }, this.progressBannerTimeout);

      while (!syncComplete) {
        const response = await axios.get(`https://fivebags-server.nn.r.appspot.com/check_sync_status`, {
          params: { userId, item_id }
        });
        syncComplete = response.data.syncComplete;

        console.log(`Received sync status for item ${item_id}: ${syncComplete}`);

        // Update the Vuex store only if the status changes
        if (!syncComplete) {
          console.log(`Sync is not complete for item ${item_id}. Waiting before next check...`);
          this.$store.dispatch('updateSyncingTransactions', true);
          await new Promise(resolve => setTimeout(resolve, 5000)); // Wait before next check
        } else {
          console.log(`Sync is complete for item ${item_id}. Updating UI...`);
          this.$store.dispatch('updateSyncingTransactions', false);
          clearTimeout(timeoutId); // Clear the timeout when sync completes successfully
        }
      }
      // Once the loop exits, it means sync is complete
      console.log(`Sync is complete for item ${item_id}. Updating UI...`);
      this.$store.dispatch('updateSyncingTransactions', false);
      await this.onTransactionSyncComplete();
      
    },
    async openPlaidLink() {
      console.log('Creating Plaid Link handler...');
      const linkHandler = window.Plaid.create({
        token: this.linkToken,
        onSuccess: async (public_token, metadata) => {
          try {
            console.log('Public token:', public_token);
            const response = await axios.post('https://fivebags-server.nn.r.appspot.com/exchange_public_token', { public_token });

            const access_token = response.data.access_token;
            const item_id = response.data.item_id;
            console.log('Access token:', access_token);
            console.log('Item ID:', item_id);

            const user = auth.currentUser;
            const user_id = user ? user.uid : null;

            // Store access token and account details
            await axios.post('https://fivebags-server.nn.r.appspot.com/store_access_token', {
              access_token,
              item_id,
              user_id,
              institution_id: metadata.institution.institution_id,
              accounts: metadata.accounts.map(account => ({
                id: account.id,
                name: account.name,
                mask: account.mask,
                type: account.type,
                subtype: account.subtype,
              })),
            });

            console.log("Access token and account details have been saved.");

            // Fetch transactions
            await axios.post('https://fivebags-server.nn.r.appspot.com/get_transactions', {
              access_token,
              item_id,
              user_id,
              institution_id: metadata.institution.institution_id,
              accounts: metadata.accounts.map(account => ({
                id: account.id,
                name: account.name,
                mask: account.mask,
                type: account.type,
                subtype: account.subtype,
              })),
              days_requested: 730
            });

            const accounts = await this.fetchAccounts(access_token);
            
            this.checkTransactionSyncStatus(item_id);

            for (const account of accounts) {
              await this.processAccount(account, user_id);
            }

            await this.fetchInvestments(access_token, user_id);

          } catch (error) {
            console.error('Error details:', error.response?.data);
            this.errorMessage = `An error occurred: ${error.response?.data?.error || error.message}`;
          }
        },
        onExit: (error, metadata) => {
          if (error) {
            console.log('Plaid error:', error);
          }
          console.log('Plaid metadata:', metadata);
        },
      });

      console.log('Opening Plaid Link...');
      linkHandler.open();
    },
    async createLinkToken() {
      console.log('Starting to create link token...');
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/create_link_token');
        this.linkToken = response.data.link_token;
        console.log('Fetched link token:', this.linkToken);
      } catch (error) {
        console.error('Error fetching link token:', error);
      }
    },
    async fetchTransactions(access_token, item_id, user_id) {
      let cursor = "";
      let transactions = [];
      let hasMore = false;

      do {
        try {
          console.log(`Fetching transactions with cursor: ${cursor}`);
          const response = await axios.post('https://fivebags-server.nn.r.appspot.com/get_transactions', {
            access_token,
            cursor,
            item_id, 
            user_id, 
          });

          // Update the cursor and hasMore flag
          cursor = response.data.next_cursor;
          hasMore = response.data.has_more;

          console.log(`Next cursor: ${cursor}`);
          console.log(`Has more: ${hasMore}`);

          // Add the returned transactions to the list
          transactions = transactions.concat(response.data.added);
        } catch (error) {
          console.error('Error fetching transactions:', error);
          
          // If a request fails, wait a bit and then try again
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      } while (hasMore);

      console.log(`Total transactions fetched: ${transactions.length}`);
      return transactions;
    },
    async fetchInvestments(access_token, user_id) {
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/get_investments', {
          access_token,
        });

        console.log('Investments Response:', response.data);

        const { accounts, holdings } = response.data.holdings;

        if (!Array.isArray(accounts) || !Array.isArray(holdings)) {
          throw new Error("Invalid data structure for accounts or holdings");
        }

        const assetsLiabilitiesMapRef = doc(db, `users/${user_id}/mappings`, 'assetsLiabilitiesMap');
        const mapSnapshot = await getDoc(assetsLiabilitiesMapRef);
        const assetsLiabilitiesMap = mapSnapshot.exists() ? mapSnapshot.data() : {};

        const currentDate = new Date().toISOString().split('T')[0];

        for (const account of accounts) {
          const accountHoldings = holdings.filter(holding => holding.account_id === account.account_id);
          let valueHistory = accountHoldings.map(holding => ({
            date: holding.institution_price_as_of,
            value: Number(holding.institution_value.toFixed(2))
          }));

          // Sort valueHistory by date to ensure correct order
          valueHistory.sort((a, b) => new Date(a.date) - new Date(b.date));

          let earliestDate = valueHistory.length > 0 ? valueHistory[0].date : currentDate;
          const startValue = valueHistory.length > 0 ? valueHistory[0].value : account.balances.current;
          const endValue = account.balances.current;

          // Interpolate missing values between earliestDate and currentDate
          const interpolatedValues = this.interpolateValues(earliestDate, currentDate, startValue, endValue);

          let assetClass = "🧩 Other Assets"; // Default class
          let accountType = "Other"; // Default type
          const accountSubtypeLower = account.subtype.toLowerCase();
          const accountTypeLower = account.type.toLowerCase();
          for (const [classKey, classDetails] of Object.entries(assetsLiabilitiesMap)) {
            if (classDetails.types.map(value => value.toLowerCase()).includes(accountSubtypeLower) ||
                classDetails.types.map(value => value.toLowerCase()).includes(accountTypeLower)) {
              assetClass = classKey;
              accountType = classDetails.accountType || "Other";
              break;
            }
          }

          const accountData = {
            ...account,
            purchaseDate: earliestDate,
            currentValue: account.balances.current,
            holdings: accountHoldings,
            valueHistory: interpolatedValues,
            assetClass: assetClass,
            accountType: accountType,
          };

          let collectionRef;
          if (accountType === 'Asset') {
            collectionRef = collection(db, `users/${user_id}/assets`);
          } else if (accountType === 'Liability') {
            collectionRef = collection(db, `users/${user_id}/liabilities`);
            accountData.currentValue = -Math.abs(accountData.currentValue);
            accountData.valueHistory = accountData.valueHistory.map(vh => ({
              ...vh,
              value: -Math.abs(vh.value)
            }));
          } else {
            console.error('Unknown account type:', accountType);
            continue;
          }

          const matchingAccountsQuery = query(collectionRef, where("mask", "==", account.mask), where("name", "==", account.name));
          const querySnapshot = await getDocs(matchingAccountsQuery);
          if (querySnapshot.empty) {
            await addDoc(collectionRef, accountData);
          } else {
            const docRef = querySnapshot.docs[0].ref;
            await setDoc(docRef, accountData, { merge: true });
          }
        }

      } catch (error) {
        console.error('Error fetching investments:', error);
      }
    },  
    async fetchAccounts(access_token) {
      try {
        const response = await axios.post('https://fivebags-server.nn.r.appspot.com/accounts_get', {
          access_token,
        });
        return response.data.accounts; // Return the accounts array
      } catch (error) {
        console.error('Error fetching accounts:', error);
        throw error;
      }
    },
    async processAccount(account, user_id) {
      const userAccountsRef = collection(db, `users/${user_id}/accounts`);

      // Query Firestore for an existing account by account_id, mask, and official_name
      const querySnapshot = await getDocs(query(userAccountsRef, where("mask", "==", account.mask), where("official_name", "==", account.official_name)));
      
      let existingAccountRef = null;

      // Check if any document matches either account_id or both mask and official_name
      querySnapshot.forEach((doc) => {
        if (doc.id === account.account_id || (doc.data().mask === account.mask && doc.data().official_name === account.official_name)) {
          existingAccountRef = doc.ref;
        }
      });

      if (existingAccountRef) {
        // Update the existing account if it exists and fields are different
        const existingAccountData = (await getDoc(existingAccountRef)).data();
        if (JSON.stringify(account) !== JSON.stringify(existingAccountData)) {
          await setDoc(existingAccountRef, account, { merge: true });
        }
      } else {
        // Add the account if it does not exist
        const newAccountRef = doc(userAccountsRef, account.account_id);
        await setDoc(newAccountRef, account);
      }
    },


    // CSV
    openFileInput() {
      if (this.showCsvImport) {
        // If the CSV import section is already shown, trigger the file input click
        this.$refs.fileInput.click();
      } else {
        // Otherwise, show the CSV import section first
        this.showCsvImport = true;
        // Use nextTick to wait for the next DOM update cycle to complete
        this.$nextTick(() => {
          this.$refs.fileInput.click();
        });
      }
    },
    validateAndImport() {
      const requiredFields = ['name', 'amount', 'date', 'category'];
      let isValid = true;

      // Reset all error messages
      requiredFields.forEach(field => {
        this.errors[field] = '';
      });

      // Check each required field and set error messages if necessary
      requiredFields.forEach(field => {
        if (this.columnMappings[field] === '') {
          this.errors[field] = 'Please select a column';
          isValid = false;
        }
      });

      if (isValid) {
        this.processCsv();
      }
    },
    async handleCsvFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          const csvData = await this.readFileContent(file);
          this.fullCsvData = csvData; // Store the full CSV data
          const csvHeaders = await this.parseCsvFile(file);
          this.csvHeaders = csvHeaders;
          this.columnMappings = this.initializeColumnMappings(csvHeaders);
          this.showCSVModal = true; // Show the modal for mapping after file is read
        } catch (error) {
          console.error('Error reading file:', error);
        }
      }
    },
    readFileContent(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          resolve(event.target.result);
        };
        reader.onerror = (event) => {
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    normalizeHeaderName(header) {
      return header.replace(/['"]/g, '').toLowerCase();
    },
    initializeColumnMappings(csvHeaders) {
      const mappings = {
        name: '',
        amount: '',
        date: '',
        category: '',
        labels: ''
      };

      csvHeaders.forEach((header, index) => {
        const normalizedHeader = this.normalizeHeaderName(header);

        // Check and assign the index if the header matches any field name
        if (normalizedHeader === 'name') {
          mappings.name = index.toString(); // Convert to string
        } else if (normalizedHeader === 'amount') {
          mappings.amount = index.toString();
        } else if (normalizedHeader === 'date') {
          mappings.date = index.toString();
        } else if (normalizedHeader === 'category') {
          mappings.category = index.toString();
        } else if (normalizedHeader === 'labels') {
          mappings.labels = index.toString();
        }
      });

      return mappings;
    },
    parseCsvFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const csvData = event.target.result;
          const rows = csvData.split('\n');
          const header = rows[0];
          const columns = header.split(',').map(col => col.trim().replace(/['"]/g, '')); // Remove quotes
          resolve(columns); // Resolve with the header columns
        };
        reader.onerror = (event) => {
          reject(event.target.error);
        };
        reader.readAsText(file);
      });
    },
    async processCsvData(csvData, columnMappings) {
      if (typeof csvData !== 'string') {
        console.error('Invalid CSV data:', csvData);
        return { processedTransactions: [], skippedTransactions: [] };
      }
      const rows = csvData.split('\n').slice(1);

      // Filter out empty rows
      const filteredRows = rows.filter(row => row.trim() !== '');

      const csvTransactions = []; // To store valid transactions
      const skippedTransactions = []; // To store details of skipped transactions

      // Extract unique categories from the "category" column
      const categoryColumnIndex = parseInt(columnMappings.category);
      const uniqueCategories = new Set(
        filteredRows.map(row => {
          const rowValues = row.split(',');
          return this.removeQuotes(rowValues[categoryColumnIndex])?.trim();
        })
      );
      this.csvCategories = Array.from(uniqueCategories);

      // Process each row
      filteredRows.forEach(row => {
        const rowValues = row.split(',');

        // Parse amount and check if it's valid
        const amountValue = this.removeQuotes(rowValues[columnMappings.amount])?.trim();
        const amount = Number(amountValue);
        if (isNaN(amount) || amountValue === '') {
          // Add details of the skipped transaction to the skippedTransactions array
          skippedTransactions.push({
            reason: 'Invalid amount',
            rowData: row,
          });
          return;
        }

        // Create and add valid transaction to csvTransactions array
        const transaction = {
          date: this.formatDate(this.removeQuotes(rowValues[columnMappings.date])),
          name: this.removeQuotes(rowValues[columnMappings.name])?.trim() || 'No Name',
          amount: amount,
          category: this.removeQuotes(rowValues[columnMappings.category])?.trim() || 'Uncategorized',
          labels: columnMappings.labels ? this.removeQuotes(rowValues[columnMappings.labels])?.trim().split(';') : []
        };
        csvTransactions.push(transaction);
      });

      // Return both processed and skipped transactions
      return { processedTransactions: csvTransactions, skippedTransactions: skippedTransactions };
    },
    async processCsv() {
      console.log("CSV Data:", this.fullCsvData);

      const { processedTransactions, skippedTransactions } = await this.processCsvData(this.fullCsvData, this.columnMappings);
      this.processedTransactions = processedTransactions;

      await this.mapCategories();
      this.displaySkippedTransactions(skippedTransactions);
    },
    async mapCategories() {
      // Create an object to store the category mappings
      this.categoryMappings = {};

      // Initialize the category mappings with empty values
      this.csvCategories.forEach(category => {
        this.categoryMappings[category] = '';
      });

      return new Promise((resolve) => {
        // Show the category mapping modal
        this.showCategoryMappingModal = true;

        // Create a watcher to detect when the modal is closed
        const unwatch = this.$watch('showCategoryMappingModal', (newValue) => {
          if (!newValue) {
            // Modal is closed, resolve the promise
            unwatch(); // Unregister the watcher
            console.log("Category Mappings:", this.categoryMappings); // Log the category mappings
            resolve();
          }
        });
      }).then(() => {
        return this.processCsvDataWithCategoryMappings();

      });
    },
    async processCsvDataWithCategoryMappings() {
      const mappedTransactions = this.processedTransactions.map(transaction => {
        const mappedCategory = this.categoryMappings[transaction.category] || 'Uncategorized';
        console.log("Original Category:", transaction.category, "Mapped Category:", mappedCategory);
        return {
          ...transaction,
          category: mappedCategory,
        };
      });

      console.log("Mapped Transactions:", mappedTransactions);
      await this.handleCsvTransactions(mappedTransactions);
      this.showCSVModal = false; // Close the CSV modal after handling transactions
    },
    displaySkippedTransactions(skippedTransactions) {
      if (skippedTransactions.length > 0) {
        // Example: Display skipped transactions in the console
        // You can replace this with your preferred method of displaying
        console.log('Skipped Transactions:', skippedTransactions);

        // Alternatively, you can set this data to a component data property
        // and display it in your template (e.g., in a modal or a table)
        this.skippedTransactionsData = skippedTransactions;
      } else {
        console.log('No transactions were skipped.');
      }
    },
    removeQuotes(string) {
      if (string == null) {
        return '';
      }
      return string.replace(/['"]/g, '');
    },
    formatDate(dateString) {
      if (!dateString) {
        console.error('Invalid or missing date:', dateString);
        return ''; // Return a default value if the date is invalid
      }

      // Check if dateString is in the format 'YYYY-MM-DD'
      const isoDateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (isoDateRegex.test(dateString)) {
        // Return the date as is since it's in the correct ISO format
        return dateString;
      }

      // Check and handle other expected formats, e.g., 'MM/DD/YYYY' or 'M/D/YY'
      const parts = dateString.split('/');
      if (parts.length === 3) {
        let [month, day, year] = parts;
        
        // Parse the month and day as numbers
        month = parseInt(month, 10);
        day = parseInt(day, 10);

        // If the year is in two-digit format, determine the century
        if (year.length === 2) {
          const currentYear = new Date().getFullYear();
          const currentCentury = Math.floor(currentYear / 100);
          const yearNumber = parseInt(year, 10);
          
          // Assume the year belongs to the current century if it's less than or equal to the current year's last two digits
          // Otherwise, assume it belongs to the previous century
          year = yearNumber <= currentYear % 100 ? currentCentury * 100 + yearNumber : (currentCentury - 1) * 100 + yearNumber;
        }
        
        // Create a new Date object with the parsed values
        const date = new Date(year, month - 1, day);
        
        // Format the date in the desired 'YYYY-MM-DD' format
        const formattedDate = date.toISOString().slice(0, 10);
        return formattedDate;
      }

      // Log error and return a default value for unexpected formats
      console.error('Unexpected date format:', dateString);
      return ''; // Return a default value
    },
    async applyTransactionRules(transaction, rules) {
      // Log the original transaction details
      console.log("Original transaction:", transaction);

      // Iterate through each rule and apply if the transaction matches
      for (const rule of rules) {
        if (this.doesTransactionMatchRule(transaction, rule.conditions)) {
          // If transaction matches, prepare updated transaction data
          const updatedTransactionData = { ...transaction, ...rule.newValues };

          // Ensure the labels field is an array
          if (!transaction.labels) {
            transaction.labels = [];
          }

          // Merge the existing labels with the new labels from the rule
          if (rule.newValues.labels) {
            updatedTransactionData.labels = Array.from(new Set([...transaction.labels, ...rule.newValues.labels]));
          }

          // Log the updated transaction details
          console.log("Updated transaction:", updatedTransactionData);

          return updatedTransactionData;
        }
      }

      // Return original transaction if no rule matches
      return transaction;
    },
    doesTransactionMatchRule(transaction, conditions) {
      let nameMatches = true;
      let amountMatches = true;
      let categoryMatches = true;

      if (conditions.name) {
        const transactionNameLower = transaction.name.toLowerCase();
        const conditionNameLower = conditions.name.value.toLowerCase();

        if (conditions.name.matchType === 'Exact Match') {
          nameMatches = transactionNameLower === conditionNameLower;
        } else if (conditions.name.matchType === 'Contains') {
          nameMatches = transactionNameLower.includes(conditionNameLower);
        }
      }

      if (conditions.amount) {
        switch (conditions.amount.matchType) {
          case 'Equals':
            amountMatches = transaction.amount === conditions.amount.value;
            break;
          case 'More than':
            amountMatches = transaction.amount > conditions.amount.value;
            break;
          case 'Less than':
            amountMatches = transaction.amount < conditions.amount.value;
            break;
          case 'Within':
            amountMatches = transaction.amount >= conditions.amount.min && transaction.amount <= conditions.amount.max;
            break;
          default:
            amountMatches = true;
        }
      }

      if (conditions.category) {
        categoryMatches = transaction.category === conditions.category;
      }

      return nameMatches && amountMatches && categoryMatches;
    },
    async handleCsvTransactions(csvTransactions) {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user is logged in.');
        return;
      }

      this.importing = true;
      this.totalTransactions = csvTransactions.length;
      this.transactionsImported = 0;

      const transactionsCollection = collection(db, `users/${user.uid}/transactions`);

      const transactionsToAdd = csvTransactions.map(transaction => {
        return {
          id: '',
          date: transaction.date,
          name: transaction.name,
          amount: transaction.amount,
          category: transaction.category, 
          labels: [],
        };
      });

      // Write new transactions to Firestore
      for (const transaction of transactionsToAdd) {
        console.log('Adding transaction:', transaction); // Log the transaction being added

        try {
          const updatedTransaction = await this.applyTransactionRules(transaction, this.$store.state.rules);
          const docRef = await addDoc(transactionsCollection, updatedTransaction);
          updatedTransaction.id = docRef.id;
          await updateDoc(docRef, updatedTransaction);
          this.transactionsImported++;
        } catch (error) {
          console.error('Error adding document:', error);
        }
      }

      if (this.transactionsImported === this.totalTransactions) {
        this.importing = false;
        this.transactionsImportedCheck = true;
      }
    },
    findCsvCategoryMatch(csvCategory) {
      let matchedCategory = 'Uncategorized'; // Default category
      if (this.categoryMap[csvCategory]) {
        matchedCategory = this.categoryMap[csvCategory];
      } else {
        const categoryExists = this.categories.some(category => category.name === csvCategory);
        if (categoryExists) {
          matchedCategory = csvCategory;
        }
      }
      return matchedCategory;
    },
    async fetchCategoryMap() {
      const docRef = doc(db, `users/${auth.currentUser.uid}/mappings`, 'categoryMap');
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        this.categoryMap = docSnap.data(); // Assuming this.categoryMap is defined in data()
      } else {
        console.log("No category map document found!");
      }
    },
  },
};

</script>


<style>
.onboarding-style-spending .plaid-button {
  animation: fadeInSlideDown 1s ease-in-out 2s !important;
  animation-fill-mode: forwards !important;
  width: max-content;
  background: #2c2c2c;
  color: white;
  font-weight: bold;
  font-family: system-ui;
  font-size: 20px;
  height: 45px;
  opacity: 0;
  margin: 0 auto !important;
}

.onboarding-style-spending {
  margin-top: 25%;
}
.centered-content {
  text-align: center;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade-slide-down {
  opacity: 0;
  transform: translateY(-20px);
  animation-fill-mode: forwards !important; /* Keeps the styles set by the last keyframe */
}

#welcome-text {
  animation: fadeInSlideDown 1s ease-in-out 0s;
}

#import-text {
  animation: fadeInSlideDown 1s ease-in-out 1s; /* Delayed start */
  text-align: center;
  margin-bottom: 2.5rem;
}


.modal-backdrop {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); /* Black with 70% opacity */
  display: flex;
  justify-content: center; /* Center modal horizontally */
  align-items: center;     /* Center modal vertically */
  z-index: 999;            /* Make sure it sits on top of everything else */
  transition: 0.3s ease-in-out;
}

/* Modal styles */
.modal-content {
  width: 50%;           /* Example width, you can adjust as needed */
  max-width: 500px;     /* Maximum width */
  background-color: #fff;
  border-radius: 15px;   /* Rounded corners */
  padding: 20px 50px 50px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);  /* Soft shadow */
  position: relative;
  transition: 0.3s ease-in-out;
}


.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 4px;
}

.progress-bar {
  height: 20px;
  background-color: green;
  border-radius: 4px;
}


@keyframes fadeInSlideDown {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}


.loader-container {
    display: flex;
    background-color: #000000;
    opacity: 40%;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full height of the viewport */
    width: 100vw; /* Full width of the viewport */
    position: fixed; /* So that it doesn't affect other elements */
    top: 0;
    left: 0;
}

.loader {
    width: 78px;
    height: 78px;
    border-radius: 50%;
    border-top: 6px solid #9ec9ff;
    border-right: 6px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hidden {
    display: none;
}


.csv-column-map-select.error {
  border-color: red;
}


.error-message {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.category-mapping {
  margin-bottom: 10px;
  text-align: left;
}

.csv-import-buttons {
  margin: 20px 10px 0px;
  display: flex;
  justify-content: flex-end;
}


.csv-column-map-select {
  width: 80%;
  padding: 10px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  background-color: #fafafa;
  font-size: 14px;
  color: gray;
  font-family: system-ui;
  appearance: none;
}

.csv-column-map-select:hover {
  cursor: pointer;
}


.csvimport {
  display: none;
}

.modal-actions {
  margin-top: 20px;
}

.csv-select {
  text-align: left;
    display: grid;
    align-items: center;
    padding: 10px 20px;
    background: #f6eef5;
    border-radius: 6px;
    width: 100%;
    margin: 10px 0px;
    transition: 0.3s ease-in-out;
    border: 1px solid rgb(218, 225, 234);
}

.modal-field2 label {
  width: 80px;
  margin: 10px 0px;
  text-align: left;
}

.modal-field2 {
  display: flex;
  text-align: left;
}

.continue-section {
  margin-top: 20px;
  text-align: center;
}

.continue-message {
  margin-bottom: 10px;
}


</style>