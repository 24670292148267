<template>
  <h3 style="margin-top: 20vh; font-size: 35px;">Choose Your Subscription</h3>
  <div class="subscription-container">
    <div class="subscription-options">
      <v-btn-toggle class="subscription-options" v-model="subscriptionType" mandatory>
        <v-btn style="background-color: #f6f6f6;" value="monthly">Monthly</v-btn>
        <v-btn style="background-color: #f6f6f6;" value="yearly">Yearly</v-btn>
        <v-btn v-if="showFreeOption" style="background-color: #f6f6f6;" value="free">Free</v-btn>
      </v-btn-toggle>
      <v-text-field class="promo-field" v-model="promoCode" label="Enter promo code" density="comfortable"></v-text-field>
      <v-btn style="width: 205px" @click="startSubscription" color="primary">Subscribe</v-btn>
    </div>
    <div class="subscription-cost" style="margin: 20px 0px;">
      <div v-if="subscriptionType === 'yearly'"> <div style="display: flex; align-items: baseline;"><h1 style="margin-bottom: 0;">$96 </h1><h4> / Year</h4> </div><h4 style="color: green; margin-top: -10px;">Save 20%</h4></div>
      <div v-else-if="subscriptionType === 'monthly'"> <div style="display: flex; align-items: baseline;"><h1 style="margin-bottom: 0;">$10 </h1><h4> / Month</h4> </div><h4 style="color: green; margin-top: -10px;"></h4></div>

      <div v-else-if="showFreeOption"> <div style="display: flex; align-items: baseline;"><h1 style="margin-bottom: 0;">$0.00 </h1><h4></h4> </div><h4 style="color: green; margin-top: -10px;">Thanks for Testing</h4></div>

    </div>
  </div>
  <div v-if="!currentSubscription || currentSubscription.status !== 'active'" class="subscription-required-message">
      <p style="margin-bottom: 0; font-size: 20px;">A subscription is required to access all features.</p>
    </div>
    <div v-if="currentSubscription">
      <p style="font-size: 20px;">Your Current Subscription: {{ currentSubscription.status }}</p>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { auth, db } from "../firebaseInit";
import { doc, getDoc } from 'firebase/firestore';

export default {
  name: 'SubscriptionView',
  data() {
    return {
      subscriptionType: 'monthly', // Default to monthly subscription
      promoCode: '',
      showFreeOption: false,
      currentSubscription: null,
    };
  },
  async created() {
    await this.fetchCurrentSubscription();
  },
  watch: {
    promoCode() {
      this.checkPromoCode();
    }
  },
  methods: {
    async fetchCurrentSubscription() {
      const userId = auth.currentUser.uid;
      const userDoc = await getDoc(doc(db, 'users', userId));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        this.currentSubscription = userData.subscription || null;
      }
    },
    formatDate(timestamp) {
      if (!timestamp) return '';
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString();
    },
    checkPromoCode() {
      if (this.promoCode === 'alphatesters001') {
        this.showFreeOption = true;
        this.subscriptionType = 'free';
      } else {
        this.showFreeOption = false;
        if (this.subscriptionType === 'free') {
          this.subscriptionType = 'monthly';
        }
      }
    },
    async startSubscription() {
      const stripe = await loadStripe('pk_live_51OHGrdJ5RBp9e05KJRq3ELsVx6rnFzz5ASZTytEO65DZCYPsUEgjHk1OEKGso1eSePo8dYBxdT11gkBQO2kNjRDk00fRT6sOIN');
      const userId = auth.currentUser.uid;
      let priceId;
      if (this.subscriptionType === 'yearly') {
        priceId = 'price_1ObdS3J5RBp9e05KyfzNUPvs';
      } else if (this.subscriptionType === 'monthly') {
        priceId = 'price_1ObdINJ5RBp9e05K1igkbNvO';
      } else {
        priceId = 'price_1PRNtDJ5RBp9e05KCMQo98Ip';
      }

      const response = await fetch('https://fivebags-server.nn.r.appspot.com/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, priceId }),
      });

      const session = await response.json();

      if (session.error) {
        console.error('Error creating checkout session:', session.error);
        return;
      }

      // Redirect to Stripe Checkout
      const { error } = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (error) {
        console.error('Stripe checkout error:', error);
      }
    },
  },
};
</script>
<style scoped>

h1 {
  font-size: 5.5rem;
}
.subscription-container {
  margin: 20px auto 0px;
    background-color: #f9f9f9;
    text-align: center;
    justify-content: center;
    width: 80%;
    display: flex;
}

.promo-field {
  width: 205px;
  margin: 10px auto 0px;
}

.subscription-options {
  margin: 10px 30px 0px;
}

.subscription-required-message {
  background-color: #ffe0e0;
    border: 1px solid #ff9999;
    border-radius: 5px;
    padding: 15px;
    width: 500px;
    margin: 100px auto 20px;
    font-weight: bold;
}

.subscription-options .subscription-cost {
  width: 300px;
}
</style>